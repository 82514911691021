import FormRequestOTP from "components/FormRequestOTP";
import useGetBusinessInfo from "hooks/useGetBusiness";
import useModal from "hooks/useModal";
import React, { useState } from "react";
import { disableBusinessOTP } from "services/bussiness/auth.service";
import colors from "styles/common/colors";
import { Header3 } from "styles/common/index.js";
import { Margin20, BtnPrimaryLg } from "styles/Styled";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import FormEnableOTP from "./FormEnableOTP";

const TwoFactorAuth = () => {
  const {
    businessInfo,
    fetchBusiness: refreshBusinessInfo,
  } = useGetBusinessInfo();
  const [Modal, open, openModal, onClose] = useModal();
  const [
    RequestOTPModal,
    isOpenRequestOTP,
    openRequestOTP,
    onCloseRequestOTP,
  ] = useModal();
  const [otpToken, setOtpToken] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const onClickDisableBtn = () => {
    if (businessInfo.otpEnabled) {
      openRequestOTP();
    }
  };

  const handleDisableBusinessOTP = async (e) => {
    e.preventDefault();
    try {
      const { success } = await disableBusinessOTP(otpToken);
      if (success) {
        setErrorMsg("");
        swal2.fire({
          title: "",
          text: "Se desactivó el doble factor de autenticación.",
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        await refreshBusinessInfo();
        onCloseRequestOTP();
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response?.data?.code === "invalid-otp") {
        setErrorMsg("Codigo de verificación inválido.");
        return;
      }
    }
  };

  return (
    <>
      <RequestOTPModal
        open={isOpenRequestOTP}
        onClose={onCloseRequestOTP}
        closeOnOverlayClick={false}
      >
        <FormRequestOTP
          onSubmit={handleDisableBusinessOTP}
          setOtpToken={setOtpToken}
          otpToken={otpToken}
          errorMsg={errorMsg}
        />
      </RequestOTPModal>

      <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
        <FormEnableOTP
          refreshBusinessInfo={refreshBusinessInfo}
          closeModal={onClose}
        />
      </Modal>

      <Header3>
        Autenticación de 2 factores{" "}
        {businessInfo.otpEnabled && (
          <>
            <svg
              style={{ color: "green", height: "17px", marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              class="css-kddq4d"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z"
                fill="currentColor"
              ></path>
            </svg>
            <span style={{ fontSize: "12px" }}>Activado</span>
          </>
        )}
      </Header3>
      <p style={{ color: colors.dark_blue }}>
        Protege tu cuenta y transacciones usando Google Authenticator
        (recomendado)
      </p>

      <Margin20></Margin20>
      {businessInfo.otpEnabled ? (
        <BtnPrimaryLg
          onClick={() => onClickDisableBtn()}
          style={{ marginTop: 0 }}
        >
          Desactivar
        </BtnPrimaryLg>
      ) : (
        <BtnPrimaryLg onClick={() => openModal()} style={{ marginTop: 0 }}>
          Activar
        </BtnPrimaryLg>
      )}
    </>
  );
};

export default TwoFactorAuth;
