import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "Auth";
import feanorapi from "feanorapi.js";
import { Link } from "react-router-dom";
import swal from "sweetalert";

// import ReactGA from 'react-ga';

import analyticsCode from "analyticsCode.js";
import useGetUserInfo from "hooks/useGetUser";
import constants from "utils/constants";

const Stepper = ({ walletUSDT, walletUSDTTron, walletBTC, loading }) => {
  const {
    userInfo: { kycVerificationStatus },
  } = useContext(AuthContext);
  // const {
  //   userInfo: { kycVerificationStatus },
  // } = useGetUserInfo();

  let { currentToken } = useContext(AuthContext);
  const [user, setDataUser] = useState({});

  const [saldoUSD, setSaldoUSD] = useState(0);
  const [saldoBTC, setSaldoBTC] = useState(0);
  const [hasWalletCrypto, setHasWalletCrypto] = useState(false);

  // For statuses
  const [hasBankAccount, setHasBankAccount] = useState(false);
  const [hasFunds, setHasFunds] = useState(false);
  const [hasBitcoins, setHasBitcoins] = useState(false);
  const [completedAll, setCompletedAll] = useState(false);

  //  ReactGA.initialize(analyticsCode);

  const sendAnalitycs = async () => {
    // ReactGA.event({
    //   category: 'Botones',
    //   action: 'Boton Comprar Bitcoin (Stepper)'
    // });
  };

  useEffect(() => {
    if (walletUSDT || walletUSDTTron || walletBTC) {
      setHasWalletCrypto(true);
    }
  }, [walletUSDT, walletUSDTTron, walletBTC]);

  // const getBankAccounts = () => {
  //   let data = {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       Authorization: `Bearer ${currentToken}`,
  //     },
  //   };
  //   let endpoint = `/client/user/bank`;
  //   let url = `${feanorapi}${endpoint}`;
  //   fetch(url, data)
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //     })
  //     .then((responseJson) => {
  //       if (responseJson.usd != 0) setHasBankAccount(true);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const getWallet = async () => {
  //   let data = {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${currentToken}`,
  //     },
  //   };
  //   let endpoint = "/client/wallet";
  //   let url = `${feanorapi}${endpoint}`;
  //   await fetch(url, data)
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw { type: "non-user" };
  //       }
  //     })
  //     .then((responseJson) => {
  //       console.log(responseJson);

  //       const walletData = responseJson;

  //       // Si tiene billetera
  //       if (
  //         walletData.some(
  //           (item) => item.currency == "BTC" || item.currency == "USDT"
  //         )
  //       ) {
  //         setHasWalletCrypto(true);
  //       }

  //       if (walletData) {
  //         walletData.forEach(function(item) {
  //           console.log(
  //             "🚀 ~ file: index.js:94 ~ walletData.forEach ~ item",
  //             item
  //           );
  //           if (item.currency == "USD") {
  //             setSaldoUSD(item.balance.toFixed(2));
  //           }

  //           if (item.currency == "BTC") {
  //             setSaldoBTC(item.balance.toFixed(7));
  //           }
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Ocurrió un problema en la lectura de billeteras");
  //     });
  // };

  useEffect(() => {
    // getBankAccounts();
    // getWallet();
  }, []);

  function scrollToWallets() {
    const element = document.getElementById("wallets");
    if (element) {
      const windowHeight = window.innerHeight;
      const elementOffset = element.offsetTop;
      const scrollPosition = elementOffset - windowHeight / 3;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }

  return (
    <>
      <div className="md-stepper-horizontal orange">
        <Link
          className={`md-step ${
            kycVerificationStatus === constants.verificationStatus.verified
              ? ""
              : "active"
          }`}
          to={`/app/profile/activation`}
        >
          <div className="md-step-circle">
            <span>
              {kycVerificationStatus === constants.verificationStatus.verified
                ? "✓"
                : "1"}
            </span>
          </div>
          <div className="md-step-title">Valida tu cuenta</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </Link>

        <Link
          className={`md-step ${hasWalletCrypto ? "" : "active"}`}
          to={`#`}
          onClick={scrollToWallets}
          // to={`/app/profile/usdt`}
        >
          <div className="md-step-circle">
            <span> {hasWalletCrypto ? "✓" : "2"}</span>
          </div>
          <div className="md-step-title">Crea una billetera crypto</div>
          <div className="md-step-optional">USDT o BTC</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </Link>

        <Link
          className={`md-step ${hasWalletCrypto ? "active" : ""}`}
          // className={`md-step ${
          //   saldoUSD > 0 && hasBankAccount ? "active" : "active"
          // }`}
          to={`#`}

          // to={`/app/profile/usdt`}
        >
          <div className="md-step-circle">
            <span>3</span>
          </div>
          <div className="md-step-title">Abona cryptos</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </Link>

        {/* <Link
          className={`md-step ${saldoBTC > 0 ? "active" : ""}`}
          onClick={sendAnalitycs}
          to={`/app/operation/new`}
        >
          <div className="md-step-circle">
            <span>4</span>
          </div>
          <div className="md-step-title">Compra Bitcoin ahora</div>
          <div className="md-step-optional">
            Puedes comprar desde 60 Dólares :)
          </div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </Link> */}
      </div>

      {/* <p>
        ¡Hola! Estamos contentos que nos hayas elegido para comenzar tu
        experiencia Bitcoin. ¿Deseas que te demos un breve tour por el sitio? :)
      </p> */}
    </>
  );
};

export default Stepper;
