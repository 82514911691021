import React, { useContext, useRef, useEffect } from "react";
import MaterialTable from "material-table";
import { icons, translations } from "components/DataTableProps";
import { AuthContext } from "Auth";
import { Icon } from "@iconify/react";
import usdtLogo from "images/usdt-logo.png";
import usdtTronLogo from "images/usdt-tron-logo.svg";
import bitcoinLogo from "images/bitcoin-logo.png";
import useGetPuntoCobroWallets from "hooks/useGetPuntoCobroWallets";
const TableWallets = ({ puntoCobroId, setWalletSelected }) => {
  const {  } = useContext(AuthContext);
  const {
    getPuntoCobroWallets,
    puntoCobroWallets,
    loading,
  } = useGetPuntoCobroWallets();

  useEffect(() => {
    getPuntoCobroWallets(puntoCobroId);
  }, []);

  const getData = () => {
    let data = [{ currency: "BTC" }, { currency: "USDT_TRON" }];
    if (puntoCobroWallets.find((e) => e.currency == "USDT")) {
      data.push({ currency: "USDT" });
    }
    return data;
  };
  return (
    <div>
      {!loading && (
        <MaterialTable
          localization={{ ...translations, header: { actions: "QR" } }}
          icons={icons}
          columns={[
            {
              title: "Moneda",
              // cellStyle: rowData => rowData.type == 'send' ? ({color: 'red'}) : ({color: "black"}),
              // render: rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}/>
              render: (rowData) => {
                const icono =
                  rowData.currency == "USD" ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={usdtLogo}
                        width="20px"
                        margin
                        style={{ marginRight: "10px" }}
                      ></img>{" "}
                      USD
                    </span>
                  ) : rowData.currency == "BTC" ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={bitcoinLogo}
                        width="20px"
                        margin
                        style={{ marginRight: "10px" }}
                      ></img>{" "}
                      BTC
                    </span>
                  ) : rowData.currency == "USDT" ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={usdtLogo}
                        width="20px"
                        margin
                        style={{ marginRight: "10px" }}
                      ></img>{" "}
                      USDT ETH
                    </span>
                  ) : rowData.currency == "USDT_TRON" ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={usdtTronLogo}
                        width="25px"
                        margin
                        style={{ marginRight: "10px" }}
                      ></img>{" "}
                      USDT TRON
                    </span>
                  ) : (
                    ""
                  );
                return icono;
              },
            },
          ]}
          title={""}
          data={getData()}
          // data={(query) =>
          //   new Promise(async (resolve, reject) => {
          //     let params = `&punto=${puntoCobroId}`;

          //     let data = {
          //       method: "GET", // *GET, POST, PUT, DELETE, etc.
          //       mode: "cors", // no-cors, *cors, same-origin
          //       cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //       credentials: "same-origin", // include, *same-origin, omit
          //       headers: {
          //         "Content-Type": "application/json",
          //         Authorization: `Bearer ${currentToken}`,
          //       },
          //     };
          //     let endpoint = "/business/punto/wallet/list";
          //     let url = `${feanorapi}${endpoint}?${params}`;

          //     fetch(url, data)
          //       .then((response) => {
          //         console.log(response);
          //         if (response.ok) {
          //           return response.json();
          //         }
          //       })
          //       .then((responseJson) => {
          //         // if (responseJson.length !== 0) {
          //         const withoutUSD = responseJson.wallets.filter(
          //           (e) => e.currency != "USD"
          //         );
          //         resolve({
          //           data: withoutUSD,
          //           page: 0,
          //           totalCount: responseJson.totalRows,
          //         });
          //         // }
          //       });
          //   })
          // }
          options={{
            search: false,
            debounceInterval: 700,
          }}
          actions={[
            {
              icon: () => <Icon icon="mdi:qrcode" />,
              tooltip: "Ver QR",
              title: "QR",
              onClick: (event, rowData) => {
                setWalletSelected(rowData);
              },
            },
          ]}
        ></MaterialTable>
      )}
    </div>
  );
};

export default TableWallets;
