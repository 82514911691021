import { useEffect, useState } from "react";
import {
  fetchIsUpdatesAccepted,
  requestAcceptUpdates,
} from "services/agreements.service";
import swal from "sweetalert";

const useAgreementUpdates = () => {
  const [isUpdatesAccepted, setIsUpdatesAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  const getIsUpdatesAccepted = async () => {
    setLoading(true);
    try {
      const { accepted } = await fetchIsUpdatesAccepted();
      setIsUpdatesAccepted(accepted);
    } catch (error) {
      console.log(error);
      swal({
        text: "Ha habido un error en nuestro sistema",
        button: false,
        timer: 1000,
      });
    } finally {
      setLoading(false);
    }
  };

  const acceptUpdates = async () => {
    try {
      await requestAcceptUpdates();
    } catch (error) {
      console.log(error);
      swal({
        text: "Ha habido un error en nuestro sistema",
        button: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    getIsUpdatesAccepted();
  }, []);

  return { isUpdatesAccepted, acceptUpdates, loading };
};

export default useAgreementUpdates;
