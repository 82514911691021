import styled from "styled-components";
import colors from 'styles/common/colors';
import { Link } from "react-router-dom";
import theme from 'styles/theme';


export const ProfileWrapper = styled.div`
  display: contents;
`;


export const ProfileList = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 50px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const ProfileTitle = styled.h2`
  color: ${colors.dark_blue};
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 1px 50px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const ProfileDescription = styled.p`
  color: ${colors.dark_blue};
  font-weight: 300;
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1px 50px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const ButtonSave = styled.button`
  width: 390px;
  max-width: 95%;
  height: 45px;
  border-radius: 5px;
  background-color: ${colors.yellow_agente_btc};
  outline: none;
  border-color: none;
  border: 0px;
  font-weight: 600;
  cursor: pointer;
  color: ${colors.dark_blue};
  text-decoration: none;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
`;


export const ProfileItem = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  box-sizing: border-box;
`;

export const ProfileItemLabel = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
  font-weight: bolder;
`;

export const ProfileItemValue = styled.div`
  width: 50%;
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TextInput = styled.input`
  height: 30px;
  width: 50%;
  font-family: Montserrat;
  font-size: 13px;
  text-align: left;
  margin: 10px 0;
  border: none;
  color: ${colors.dark_blue};
  border-bottom: 1px solid #3C4A5B;
  box-sizing: border-box;
  text-align: left;
  font-weight: 400;
  display: flex;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TextInputContainer = styled.div`
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerImages = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DisplayImage = styled.img`
  display: flex;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 10px;
  width: 400px;
  max-width: 100%;
  height: auto;
  padding: 0 20px 0px 0px;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`; 


// Bank Accounts Items

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding:5px;
  height: 100%;
  width: 95%;
  flex-flow: row wrap;
  @media(min-width:640px) {
    flex-flow: row nowrap;
  }
 
`;

export const Box = styled.div`
    width: 48%;
    padding: 10px;  
    margin: 1%;
    box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    
`;

export const BoxLeft = styled.div`
    width: 40%;
    padding: 5px 20px; 
    margin-left:3%;
    margin-right:3%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px; 

    @media(max-width:640px) {
      margin:2% 6%;
      width: 100%;
    }

`;


export const TitleBox = styled.h4`
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  color: #3C4A5B;
  line-height: 0.5;
  font-weight: 600;
  text-align: left;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const ItemsBox = styled.ul`
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  color: #171717;
  font-weight: 400;
  text-align: left;
  padding-left:0px;
  line-height:2;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const Item = styled.li`
  
`;


export const AddButtonLink = styled(Link)`
  text-align: left;
  color: #486FEE;
  font-weight: 600;
  cursor: pointer;
  width: 95%;
  padding:40px 0px;
  margin-left:5%;
  text-decoration: none
`; 


// Modales 

export const BodyModal = styled.div`
    padding: 0 10px;  
    max-width:410px;
    font-family: Montserrat, sans-serif;
`;

export const TitleModal = styled.h3`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: #3C4A5B;
  font-weight: 600;
  text-align: center;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;


export const ImageModal = styled.img`
margin: 0 auto;
display: block;
float: none; 
padding: 5px 0;
width:150px;
`;


export const FooterModal = styled.p`
  font-family: Montserrat, sans-serif;
  margin: 20px 0 0;
  font-size: 13px;
  text-align: center;
  color: #758698;
  opacity: .7;
  font-weight: 400;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;


export const LabelModal = styled.label`
    display: block;
    padding-right:20px;
    padding-top:10px;
`;

export const LabelSpan = styled.span`
    display: block;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #353c43;

    .alert {
      color: #e55675;
    }
`;


export const LabelSpanAlert = styled(LabelSpan)`
   color: #e55675;
`;

export const InputModal = styled.input`
  width: 100%;
  padding: 6px 10px;
  background-color: #f9f9fb;
  background-image: none;
  box-shadow: none;
  border: 1px solid #d6dce1;
  border-radius: 3px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  -webkit-transition: border-color .3s ease-out;
  transition: border-color .3s ease-out;
`;

export const BtnPrimary = styled.button`
    background-color: #486FEE;
    width: 120px;
    border: 1px solid transparent;
    font-weight: 500; 
    display: inline-block;
    padding: 8px 20px;
    border: none;
    border-radius: ${theme.borderRadius};
    box-shadow: 0 4px 4px 0 rgba(24,28,32,.05), 0 1px 2px 0 #b8c1c9, 0 1px 0 0 #2196c8;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    margin: 5px 0px;
    margin-right: 20px;
`;

export const BtnPrimaryLg = styled(BtnPrimary)`
    width: 50%;
    padding: 15px 20px;
    margin: 0 auto;
    margin-top: 30px;
    display: block;
`;


export const ContentDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentIcons = styled.div`
  padding:10px 5px;
  color: #000;
`;


export const Icon = styled.span`
  padding-left:20px;
  cursor:pointer;
  opacity:0.5;
  &:hover {
    opacity: 1; 
  }
`;

export const Padding20 = styled.div`
  padding:20px 0px;
`;

export const CheckLabel = styled.label`
  
  font-family: Montserrat;
  font-size: 13px;
  text-align: left;
  display:inline;
  width: 95%;
  color: ${colors.dark_blue};
  
`;


export const CheckInput = styled.input`
  height: 15px;
  width: 5%;
  font-family: Montserrat;
  font-size: 13px;
  border: none;
  margin-top:10px;
  color: ${colors.dark_blue};
  box-sizing: border-box;
  font-weight: 400;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;


export default {
  ProfileList,
  ProfileItem,
  ProfileItemLabel,
  ProfileItemValue,
  TextInput,
  DisplayImage,
  ContainerImages,
  ButtonSave,
  ProfileTitle,
  ProfileWrapper, 
  ProfileDescription,
  Content,
  Box, 
  BoxLeft,
  TitleBox,
  ItemsBox,
  Item, 
  AddButtonLink,
  BodyModal,
  TitleModal,
  ImageModal,
  FooterModal,
  LabelModal,
  LabelSpan,
  LabelSpanAlert,
  InputModal,
  BtnPrimaryLg,
  ContentDiv,
  ContentIcons,
  Icon,
  Padding20,
  CheckLabel,
  CheckInput,
  TextInputContainer
}
