import http from "http-common";
import { getToken } from "./common";

export const fetchIsUpdatesAccepted = async (token) => {
  const response = await http.get(
    "/client/agreement/updates",
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};
export const requestAcceptUpdates = async (values, token) => {
  const response = await http.post("/client/agreement/updates", values, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};
