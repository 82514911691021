import React, { useState } from "react";
import { updatePassword } from "services/bussiness/security.service";
import colors from "styles/common/colors";
import { Header3, Paragraph } from "styles/common/index.js";
import {
  InputModal,
  LabelModal,
  LabelSpan,
  Margin20,
  BtnPrimaryLg,
} from "styles/Styled";
import swal from "sweetalert";
import swal2 from "sweetalert2";

const UpdatePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const handleSubmit = async () => {
    if (currentPassword === "") {
      swal({ text: "Ingrese su actual contraseña", button: false });
      return;
    }

    if (newPassword === "") {
      swal({ text: "Ingrese una nueva contraseña", button: false });
      return;
    }

    try {
      const { type } = await updatePassword(currentPassword, newPassword);
      if (type === "incorrect-credentials") {
        swal({ text: "Contraseña actual incorrecta", button: false });
        return;
      }

      if (type === "password-changed") {
        swal2.fire("Contraseña cambiada", "");
        return;
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header3>Cambio de contraseña</Header3>

      <p style={{ color: colors.dark_blue }}>
        Puedes cambiar tu contraseña a una nueva si lo deseas.
      </p>

      <LabelModal>
        <LabelSpan>Actual contraseña</LabelSpan>
        <InputModal
          type="text"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </LabelModal>

      <LabelModal>
        <LabelSpan>Nueva contraseña</LabelSpan>
        <InputModal
          type="text"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </LabelModal>

      <Margin20></Margin20>

      <BtnPrimaryLg disabled={false} onClick={handleSubmit}>
        Cambiar contraseña
      </BtnPrimaryLg>
    </>
  );
};

export default UpdatePassword;
