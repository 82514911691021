import styled from "styled-components";

export const CardTitle = styled.div`
  @media (max-width: 330px) {
    font-size: 18px;
  }
`;
export const CardDescription = styled.div`
  @media (max-width: 330px) {
    font-size: 14px;
  }
`;
