import React, { useState, useEffect, useContext } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import logoFeanor from "media/logo.png";
import logoMetamask from "media/icons/metamask.png";
import * as Styled from "./styles";
import { PurchaseNFTContext } from "contexts/purchaseNFT";

const SelectWallet = ({ stepCorrect }) => {
//   const [walletType, setWalletType] = useState("");
  const { walletType, setWalletType } = useContext(PurchaseNFTContext);

  const handleChange = (event, newWalletType) => {
    if (newWalletType != null) {
      stepCorrect(true);
    } else {
      stepCorrect(false);
    }
    setWalletType(newWalletType);
  };
  useEffect(() => {
    if (!walletType) {
      stepCorrect(false);
    }
  }, []);

  return (
    <ToggleButtonGroup
      color="primary"
      value={walletType}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="metamask" style={{ alignItems: "center" }}>
        Metamask
        <Styled.imgLogo src={logoMetamask} />
      </ToggleButton>
      <ToggleButton value="feanor">
        Feanor
        <Styled.imgLogo src={logoFeanor} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default SelectWallet;
