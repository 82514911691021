import React, { useState, useContext, useRef, useEffect } from "react";
import { withRouter } from "react-router";
import { AuthContext } from "Auth";
import feanorapi from "feanorapi.js";
import MaterialTable from "material-table";
import { icons, translations } from "components/DataTableProps";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import { PuntosCobroContext } from "contexts/puntosCobro";
import QRViewer from "components/QRViwer";
import TableWallets from "components/TableWallets";
import useModal from "hooks/useModal";
import { BodyModal } from "styles/Styled";
const TablePuntosCobro = () => {
  const { currentToken } = useContext(AuthContext);
  const [Modal, open, openModal, onClose] = useModal();
  const tableRef = useRef();
  const { setPuntosCobroTableRef } = useContext(PuntosCobroContext);
  const [puntoCobroSelected, setPuntoCobroSelected] = useState({});
  const [walletSelected, setWalletSelected] = useState({});

  useEffect(() => {
    setPuntosCobroTableRef(tableRef);
  }, []);

  return (
    <div id="container-historial-transacciones">
      <MaterialTable
        tableRef={tableRef}
        localization={translations}
        icons={icons}
        columns={[
          {
            title: "Nombre",
            field: "name",
            // cellStyle: rowData => rowData.type == 'send' ? ({color: 'red'}) : ({color: "black"}),
            // render: (rowData) => {
            //     const symbol = rowData.transactionType.isIncome ? '' : '-'
            //     return rowData ? `${symbol} ${rowData.amount.toFixed(2)}` : ""
            // }
          },
          {
            title: "Descripcion",
            field: "description",
          },
        ]}
        title={""}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            let params = "";
            if (query.search) {
              params += `q=${query.search}`;
            }
            if (query.orderBy) {
              params += `&sort=${query.orderBy.field}&order=${query.orderDirection}`;
            }
            if (query.filters.length) {
              const filter = query.filters.map((filter) => {
                return `&${filter.column.field}${filter.operator}${filter.value}`;
              });
              params += filter.join("");
            }
            params += `&page=${query.page + 1}`;
            params += `&limit=${query.pageSize}`;

            let data = {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "same-origin", // include, *same-origin, omit
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentToken}`,
              },
            };
            let endpoint = "/business/punto";
            let url = `${feanorapi}${endpoint}?${params}`;

            fetch(url, data)
              .then((response) => {
                console.log(response);
                if (response.ok) {
                  return response.json();
                } else {
                  throw { type: "non-available" };
                }
              })
              .then((responseJson) => {
                // if (responseJson.length !== 0) {
                resolve({
                  data: responseJson.puntosCobro,
                  page: query.page,
                  totalCount: responseJson.totalRows,
                });
                // }
              });
          })
        }
        options={{
          debounceInterval: 700,
        }}
        actions={[
          {
            icon: () => <VisibilityOutlined />,
            tooltip: "Ver detalle",
            onClick: (event, rowData) => {
              setPuntoCobroSelected(rowData._id);
              openModal();
            },
          },
        ]}
      ></MaterialTable>
      <Modal
        open={open}
        center
        onClose={() => {
          onClose();
          setWalletSelected({});
        }}
      >
        <BodyModal>
          {JSON.stringify(walletSelected) == "{}" && (
            <TableWallets
              puntoCobroId={puntoCobroSelected}
              setWalletSelected={setWalletSelected}
            />
          )}
          {JSON.stringify(walletSelected) != "{}" && (
            <QRViewer
              puntoCobroId={puntoCobroSelected}
              setWalletSelected={setWalletSelected}
              walletSelected={walletSelected}
            />
          )}
        </BodyModal>
      </Modal>
    </div>
  );
};

export default withRouter(TablePuntosCobro);
