import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  StepLabel,
  Stepper,
  Step,
  Button,
  Container,
  Box,
  CircularProgress,
} from "@material-ui/core";
import EnterAmount from "./steps/EnterAmount";
import { PurchaseNFTContext } from "contexts/purchaseNFT";
import SelectWallet from "./steps/SelectWallet";
import ConfirmPurchase from "./steps/ConfirmPurchase";
import useAlertMessage from "hooks/alert";
import Success from "./steps/Success";
import { AuthContext } from "Auth";

function getSteps() {
  return [
    "Ingrese un monto a comprar",
    "Seleccione una wallet",
    "Confirmar compra",
  ];
}

function getStepContent(stepIndex, handleDisableNextButton) {
  switch (stepIndex) {
    case 0:
      return <EnterAmount stepCorrect={handleDisableNextButton} />;
    case 1:
      return <SelectWallet stepCorrect={handleDisableNextButton} />;
    case 2:
      return <ConfirmPurchase stepCorrect={handleDisableNextButton} />;
    default:
      return "Paso no reconocido";
  }
}

const PurchaseStepper = ({ projectDetailProp, getProject }) => {
  const {
    amount,
    walletType,
    projectDetail,
    setProjectDetail,
    askContractToMintNft,
    mintUsingFeanorConn,
    tokenCost,
    loader,
    setTransaction,
  } = useContext(PurchaseNFTContext);
  const { currentToken } = useContext(AuthContext);
  const [
    Alert,
    showAlert,
    title,
    description,
    severity,
    open,
    handleClose,
  ] = useAlertMessage();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [stepCorrect, setStepCorrect] = useState(true);
  const handleDisableNextButton = (state) => {
    setStepCorrect(!state);
  };
  const handleNext = async () => {
    let success = false;
    switch (activeStep) {
      case 0:
        if (amount > 0 && /^[-+]?[0-9]+\.[0-9]+$/.test(amount) == false) {
          success = true;
        } else {
          alert("Monto invalido");
        }
        break;

      case 1:
        if (walletType === "metamask" || walletType === "feanor") {
          success = true;
        } else {
          alert("Seleccione un tipo de wallet");
        }
        break;

      case 2:
        try {
          if (walletType === "metamask") {
            const res = await askContractToMintNft(amount * tokenCost);
            setTransaction(res);
            setProjectDetail(projectDetail.selledNfts + 1);
            await getProject();
            console.log(res);
            success = true;
          }
          if (walletType === "feanor") {
            const res = await mintUsingFeanorConn(currentToken);
            if (!res.tx) {
              throw new Error(res.msg || res.message);
            }
            setTransaction({
              transactionHash: res.tx,
              ...res,
            });
            console.log(res);
            await getProject();
            success = true;
          }
        } catch (error) {
          // setTransactionSuccess(false);
          let msg = error.message;
          if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
            msg = "Saldo insuficiente";
          }
          showAlert("Error", "error", msg);
          console.log(error);
        }
        break;

      default:
        break;
    }

    if (success) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    setProjectDetail({
      ...projectDetailProp,
      contractAbi: JSON.parse(projectDetailProp.contractAbi),
    });

    return () => {
      setProjectDetail({});
    };
  }, []);

  return (
    <Container>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <Success stepCorrect={stepCorrect} handleReset={handleReset} />
      ) : (
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            {getStepContent(activeStep, handleDisableNextButton)}
          </Grid>
          <Box mt={3} />
          {loader && (
            <>
              <CircularProgress />
              <span style={{ color: "black" }}>minteando NFT(s)</span>
            </>
          )}

          {/* {error && ( */}
          <Alert
            typeMessage={severity}
            open={open}
            title={title}
            closeAlert={handleClose}
          >
            {description}
          </Alert>
          {/* )} */}
          <Box mt={1} />
          <Grid item xs={12}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              // className={classes.backButton}
            >
              Atras
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={activeStep === steps.length - 1 ? loader : stepCorrect}
              onClick={handleNext}
            >
              {activeStep === steps.length - 1
                ? "Confirmar compra"
                : "Continuar"}
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default PurchaseStepper;
