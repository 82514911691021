import React, { useState } from "react";
import question_mark from "media/question-mark@2x.png";
import swal from "sweetalert";
import { VisorPrecio, IconClickable } from "./../styles";
import { Ellipsis } from "react-awesome-spinners";

const CryptoPriceInfo = ({ calculatorViewer, currentCrypto }) => {
  const showInfoAboutPrice = () => {
    swal({
      text:
        "Los precios son referenciales y son actualizados cada 10 segundos.",
      button: false,
      timer: 3000,
    });
  };

  return (
    <div>
      {calculatorViewer.rates.BTCUSDBuy && calculatorViewer.rates.USDTUSDBuy ? (
        <VisorPrecio>
          <IconClickable
            src={question_mark}
            onClick={showInfoAboutPrice}
          ></IconClickable>
          1 {currentCrypto} ={" "}
          {calculatorViewer.transactionType === "BUY"
            ? currentCrypto === "BTC"
              ? calculatorViewer.rates.BTCUSDBuy
              : calculatorViewer.rates.USDTUSDBuy
            : currentCrypto === "BTC"
            ? calculatorViewer.rates.BTCUSDSell
            : calculatorViewer.rates.USDTUSDSell}{" "}
          USD
        </VisorPrecio>
      ) : (
        <VisorPrecio>
          1 {currentCrypto} = <Ellipsis color={"#3C4A5B"} />
        </VisorPrecio>
      )}
    </div>
  );
};

export default CryptoPriceInfo;
