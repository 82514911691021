import { useState, useEffect, useContext } from "react";
import { AuthContext } from "Auth";
import { fetchAllCards } from "services/card.service";
import swal from "sweetalert";

const useGetCards = () => {
  const { currentToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [premium, setPremium] = useState([]);
  const [regular, setRegular] = useState([]);
  const [fetchingCards, setFetchingCards] = useState([]);

  const getCards = async () => {
    try {
      setLoading(true);
      setFetchingCards(true);
      const response = await fetchAllCards(currentToken);
      setCards(response.cards);
      setPremium(response.cards.filter((e) => e.cardProvider === "VISA"));
      setRegular(response.cards.filter((e) => e.cardProvider === "MASTERCARD"));
    } catch (error) {
      console.log(error);
      swal({
        text: "Hubo un problema al obtener tus tarjetas",
        button: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
    setFetchingCards(false);
  };

  const getCardByNumber = (cardNumber) => {
    return (
      { ...cards.find((card) => card.number === cardNumber), found: true } || {
        balance: 0.0,
        state: "INACTIVE",
        found: false,
      }
    );
  };

  useEffect(() => {
    getCards();
  }, []);

  return {
    cards,
    fetchingCards,
    getCardByNumber,
    getCards,
    premium,
    regular,
    loading,
  };
};

export default useGetCards;
