import styled from "styled-components";
import colors from "styles/common/colors";

export const ProfileList = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 50px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const ButtonSave = styled.button`
  width: 390px;
  max-width: 95%;
  height: 45px;
  border-radius: 5px;
  background-color: ${colors.feanor_gold};
  outline: none;
  border-color: none;
  border: 0px;
  font-weight: 600;
  cursor: pointer;
  color: ${colors.dark_blue};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  &:disabled {
    background: #ffd16685;
    cursor: default;
  }
  &:hover {
    box-shadow: 2px 2px 8px rgba(44, 44, 44, 0.2);
  }
  &:active {
    box-shadow: 2px 2px 3px rgba(44, 44, 44, 0.2);
  }
`;

export const ButtonCancel = styled.button`
  padding: 10px; 
  background-color: #f03e3e;
  color: #FFF; 
  border-radius:4px;
  text-align: center;
  width:180px;
  cursor: pointer;
  text-decoration: none;
  font-weight:600;
  outline: none;
  border-color: none;
  border: 0px;
  margin 0px 10px;
  font-size:12px;
`;

export const ProfileItem = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  box-sizing: border-box;
  position: relative;
`;

export const ProfileItemLabel = styled.div`
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
  font-weight: bolder;
`;

export const ProfileItemValue = styled.div`
  width: 50%;
  font-family: Montserrat, sans-serif;
  color: ${colors.dark_blue};
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TextInput = styled.input`
  height: 30px;
  width: 50%;
  font-family: Montserrat;
  font-size: 13px;
  text-align: left;
  margin: 10px 0;
  border: none;
  color: ${colors.dark_blue};
  border-bottom: 1px solid #3c4a5b;
  box-sizing: border-box;
  text-align: left;
  font-weight: 400;
  display: flex;
  outline: none;
  position: absolute;
  right: 0;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CheckLabel = styled.label`
  font-family: Montserrat;
  font-size: 13px;
  text-align: left;
  display: inline;
  width: 95%;
  color: ${colors.dark_blue};
`;

export const CheckInput = styled.input`
  height: 15px;
  width: 5%;
  font-family: Montserrat;
  font-size: 13px;
  border: none;
  margin-top: 10px;
  color: ${colors.dark_blue};
  box-sizing: border-box;
  font-weight: 400;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerImages = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DisplayImage = styled.img`
  display: flex;
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 10px;
  width: 400px;
  max-width: 100%;
  height: auto;
  padding: 0 20px 0px 0px;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

export default {
  ProfileList,
  ProfileItem,
  ProfileItemLabel,
  ProfileItemValue,
  TextInput,
  DisplayImage,
  ContainerImages,
  ButtonSave,
  ButtonCancel,
  CheckInput,
  CheckLabel,
};
