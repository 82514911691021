import PuntosCobro from "./PuntosCobro";
import Reportes from "./Reportes";
import Transacciones from "./Transacciones";
import Security from "./Segurity";
import Referidos from "./Referidos";
import FeanorCard from "./FeanorCard";

import {
  faExchangeAlt,
  faCashRegister,
  faChartLine,
  faUsers,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

export const profileComponents = [
  {
    value: "card",
    label: "Feanor Card",
    show: true,
    component: FeanorCard,
    icon: faCreditCard,
  },
  {
    value: "puntos",
    label: "Puntos de cobro",
    show: true,
    component: PuntosCobro,
    icon: faCashRegister,
  },
  {
    value: "transacciones",
    label: "Transacciones",
    show: true,
    component: Transacciones,
    icon: faExchangeAlt,
  },
  {
    value: "reportes",
    label: "Reportes",
    show: true,
    component: Reportes,
    icon: faChartLine,
  },
  {
    value: "security",
    label: "Seguridad",
    show: false,
    component: Security,
    icon: faChartLine,
  },
  {
    value: "referrals",
    label: "Referidos",
    show: true,
    component: Referidos,
    icon: faUsers,
  },
];

export const profileComponentsMobile = [
  {
    value: "card",
    label: "Feanor Card",
    show: true,
    component: FeanorCard,
    icon: faCreditCard,
  },
  {
    value: "puntos",
    label: "Puntos de cobro",
    show: true,
    component: PuntosCobro,
    icon: faCashRegister,
  },
  {
    value: "transacciones",
    label: "Transacciones",
    show: true,
    component: Transacciones,
    icon: faExchangeAlt,
  },
  {
    value: "reportes",
    label: "Reportes",
    show: true,
    component: Reportes,
    icon: faChartLine,
  },
  {
    value: "referrals",
    label: "Referidos",
    show: true,
    component: Referidos,
    icon: faUsers,
  },
];
