import React, { useState, createContext } from 'react';
export const PuntosCobroContext = createContext();

export const PuntosCobroProvider = ({ children }) => {
  const [puntosCobroTableRef, setPuntosCobroTableRef] = useState({});

  return (
    <PuntosCobroContext.Provider
      value={{
        puntosCobroTableRef,
        setPuntosCobroTableRef
      }}
    >
      {children}
    </PuntosCobroContext.Provider>
  );
};
