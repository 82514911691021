import React from "react";
import { Header2, Paragraph } from "styles/common/index.js";
import SaldoUSDTTron from "components/SaldoUSDTTron";

const MoneyUSDTTron = () => {
  return (
    <React.Fragment>
      <Header2>Billetera Tethers (₮) Red de Tron</Header2>
      <Paragraph className="boxCenter">
      Esta es tu billetera exclusiva en Fëanor, desde aquí puedes enviar y recibir tethers a tus amigos o familiares. Úsala sabiamente, funciona como una cuenta bancaria. ¡Eres libre de disponer de tus fondos y moverlos!. 
      </Paragraph>
      <SaldoUSDTTron />
    </React.Fragment>
  );
};

export default MoneyUSDTTron;
