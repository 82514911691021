import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "styles/common/colors";
// Modales

export const Modal = styled.div`  
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  align-content: center;
  /* opacity: 0; */
  justify-content: center;
  /* opacity: ${(props) => {
    switch (props.fadeType) {
      case "in":
        return "1";
      default:
        return "0";
    }
  }};
  transition: ${(props) => {
    switch (props.fadeType) {
      case "in":
        return `opacity linear 0.25s;`;
      case "out":
        return `opacity linear 0.25s;`;
      default:
        return "";
    }
  }}; */
`;

export const Background = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.8);
`;

export const BodyModal = styled.div`
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
  left: 0;
  right: 0;
  width: 600px;
  /* height: 400px; */
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 80px;
  @media screen and (max-width: 768px) {
    width: 400px;
    padding: 20px 50px;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
  }
  @media screen and (max-width: 350px) {
    padding: 20px 30px;
    width: 230px;
  }
`;

export const CloseBtn = styled(FontAwesomeIcon)`
  position: absolute;
  right: 9px;
  top: 6px;
  color: black;
  font-size: 21px;
  cursor: pointer;
  padding: 10px;
`;

export const TitleModal = styled.h3`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 22px;
  color: #3c4a5b;
  font-weight: 600;
  text-align: center;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 350px) {
    font-size: 18px;
  }
`;

export const StyledButtonYellow = styled.a`
  background: ${colors.feanor_gold};
  margin: 30px auto 30px auto;
  cursor: pointer;
  text-decoration: none;
  font-family: "Bahnschift Light", sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 10px;
  font-weight: bold;
  color: black;
  padding: 10px 55px;
  width: 120px;
  @media (max-width: 640px) {
    width: 80px;
  }
  @media (max-width: 350px) {
    height: 20px;
    width: 120px;
  }
`;

export const LabelModal = styled.label`
  display: block;
  width: 100%;
  padding-right: 20px;
  padding-top: 10px;
`;

export const TextAreaMsg = styled.textarea`
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 10px;
  border: 1px solid rgb(0, 0, 0, 0.4);
  height: 80px;
  max-height: 80px;
  min-height: 80px;
`;

export const LabelSpan = styled.span`
  display: block;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #353c43;

  .alert {
    color: #e55675;
  }
`;

export const InputModal = styled.input`
  width: 100%;
  padding: 6px 10px;
  display: block;
  background-color: #f9f9fb;
  background-image: none;
  box-shadow: none;
  border: 1px solid rgb(0, 0, 0, 0.4);
  border-radius: 3px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  -webkit-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out;
  @media (max-width: 350px) {
    width: 90%;
  }
`;
