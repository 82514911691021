import styled from "styled-components";
import colors from "styles/common/colors";
import { DateRangePicker } from "react-date-range/";

export const Filter = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
`

export const DateRageExt = styled(DateRangePicker)`
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -100;
  opacity: 0;
  transition: all 0.3s;
`;

export const InputContainer = styled.div`
  display: inline;
  position: relative;
  width: 40%;
  margin-right: 30px;
  &:hover ${DateRageExt} {
    z-index: 1000;
    opacity: 1;
  }
`;



export const TextInput = styled.input`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 10px;
  font-family: Montserrat, sans-serif;
  color: ${colors.feanor_dark_bg};

  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  &:focus-visible {
    outline: none;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
