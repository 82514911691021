import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "Auth";
import SaldoDoughnuts from "./charts/SaldoDoughnut";
import {
  TextSaldo,
  AmountCurrency,
  TextCuponAviso,
  TextCuponAvisoVenc,
  WalletContent,
  WalletBox,
  WalletBoxLeft,
  WalletBoxRight,
  TitleBox,
  DisplayCount,
  GraphContent,
  DescriptionBox,
  ButtonContent,
  BtnPrimary,
  BtnSecondary,
  TitleModal,
  BodyModal,
  FooterModal,
  InputModal,
  LabelModal,
  LabelSpan,
  LabelSpanAlert,
  BtnPrimaryLg,
  ListLinks,
  ListLinksItem,
  ImageModal,
  TextBox,
  BtnSecondaryCenter,
  ListRowDiv,
  ListColumnDiv,
  ColText,
  ColStatus,
  DetailsOperation,
  DetailTitle,
  DetailAmount,
  Clear,
  Margin20,
  ColTextLight,
  ColButton,
  ContentDiv,
  ColDiv,
  StepperTitle,
  StepperSub,
  IconCircle,
  ContentIcons,
  ContentDivLeft,
  PercentageText,
} from "styles/Styled";
import ObjectCalculator from "./object-calculator/ObjectCalculator";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";
import { PrimaryText, SecondaryText } from "styles/colorized";
import { Header2, Paragraph } from "styles/common/index.js";

import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import iconSendBTC from "media/gif/bitcoin_bounce.gif";
import iconReceiveBTC from "media/gif/bitcoin_group.gif";

import WAValidator from "wallet-address-validator";
import ReactTimeAgo from "react-time-ago";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMehBlank,
  faPiggyBank,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import { allowedUsers } from "utils/allowedUsers";
import useGetWallets from "hooks/useGetWallets";

const SaldoMain = ({ walletUSDT, walletUSDTTron, walletBTC, loading }) => {
  const [totalCompra, setTotalCompra] = useState(0);
  const [totalVenta, setTotalVenta] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [calculatorObj, setCalculatorObj] = useState(undefined);
  const [btcValueinUSD, setBTCValue] = useState(0);
  // const [user, setDataUser] = useState({});
  const [couponObj, setCoupon] = useState({});
  const [cuponValue, setCuponValue] = useState(0);

  let { currentToken } = useContext(AuthContext);

  /* Wallet */

  // Data
  const [addressBtc, setAddressBtc] = useState("----");
  const [transactionsData, setTransactionsData] = useState([]);

  // Modals
  const [openReceive, setOpenReceive] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [sendTo, setSendTo] = useState("");
  const [sendAmount, setSendAmount] = useState("0.0000");
  const [feePerOperation, setFeePerOperation] = useState(0.0);

  // For statuses
  const [haveWallet, setHaveWallet] = useState(false);
  const [haveTransactions, setHaveTransactions] = useState(false);

  // Wallets
  // const [walletsData, setWalletsData] = useState([]);
  // const [BTCBalance, setBTCBalance] = useState("0.0000");
  // const [PENBalance, setPENBalance] = useState("0.00");
  // const [USDBalance, setUSDBalance] = useState("0.00");
  // const [USDTBalance, setUSDTBalance] = useState("0.00");
  // const [USDTTronBalance, setUSDTTronBalance] = useState("0.00");

  // const { walletUSDT, walletUSDTTron, walletBTC, loading } = useGetWallets();

  const calculatorObserver = (data) => {
    setBTCValue(data.ammounts[1]);
  };

  // const createWallet = async () => {
  //   let data = {
  //     method: "POST", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${currentToken}`,
  //     },
  //   };
  //   let endpoint = "/client/wallet";
  //   let url = `${feanorapi}${endpoint}`;
  //   await fetch(url, data)
  //     .then((response) => {
  //       if (response.ok) {
  //         getWallet();
  //         //swal({text: "Billetera creada correctamente. ¡Disfruta de los pagos del futuro!", button: true, timer: 3000});
  //         return response.json();
  //       } else {
  //         //swal({text: "Ha habido un error al crear tu billetera. Si el error persiste escríbenos a Soporte.", button: false, timer: 5000});
  //       }
  //     })
  //     .catch((error) => {
  //       //swal({text: "Ha habido un error al crear tu billetera. Si el error persiste escríbenos a Soporte.", button: false, timer: 5000});
  //     });
  // };

  const checkAddressBTC = async (address) => {
    console.log("Verificando dirección BTC...");

    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet/isInternalClient/" + address;
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.client == true) {
          setFeePerOperation(0.00015);
        } else {
          setFeePerOperation(0.001);
        }
      })
      .catch((error) => {
        console.log("Error al verificar la direccion BTC...");
        setFeePerOperation(0.001);
      });
  };

  const getTransactions = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet/transaction";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        if (responseJson) {
          setTransactionsData(responseJson);
          setHaveTransactions(true);
        } else {
          // No billetera
          setHaveTransactions(false);
        }
      })
      .catch((error) => {
        swal({
          text:
            "Ha ocurrido un error mientras cargabamos los datos de tus transacciones",
          button: false,
          timer: 5000,
        });
      });
  };

  function truncFixed(num, to = 0) {
    let strNum = typeof num == "string" ? num : num.toString();
    let result = "";
    let i = 0,
      j = -Infinity;

    for (; i < strNum.length; i++) {
      if (strNum[i] != ".") {
        result += strNum[i];
      } else {
        result += to == 0 ? "" : strNum[i];
        j = i;
      }

      if (i == j + to) break;
    }

    // if you don't need padding remove this if block (all lines below this except return statement)
    if (i == strNum.length) {
      // for loop above terminates without break, means need pad
      if (j == -Infinity) {
        // decimal point not found
        if (to != 0) {
          result += ".";
          for (let k = 0; k < to; k++) {
            result += "0";
          }
        }
      } else {
        // add pad to the rest
        while (i <= j + to) {
          result += "0";
          i++;
        }
      }
    }

    return result;
  }

  // const getWallet = async () => {
  //   let data = {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${currentToken}`,
  //     },
  //   };
  //   let endpoint = "/client/wallet";
  //   let url = `${feanorapi}${endpoint}`;
  //   await fetch(url, data)
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw { type: "non-user" };
  //       }
  //     })
  //     .then((responseJson) => {
  //       console.log(responseJson);

  //       setWalletsData(responseJson);

  //       responseJson.forEach(function(item) {
  //         if (item.currency == "BTC") {
  //           console.log("Wallet BTC detectada");
  //           if (item.balance) {
  //             setBTCBalance(item.balance.toFixed(5));
  //           }
  //         }

  //         if (item.currency == "PEN") {
  //           console.log("Wallet BTC detectada");
  //           if (item.balance) {
  //             setPENBalance(item.balance.toFixed(2));
  //           }
  //         }

  //         if (item.currency == "USD") {
  //           console.log("Wallet BTC detectada");
  //           if (item.balance) {
  //             setUSDBalance(item.balance.toFixed(2));
  //           }
  //         }

  //         if (item.currency == "USDT") {
  //           console.log("Wallet BTC detectada");
  //           if (item.balance) {
  //             setUSDTBalance(item.balance.toFixed(2));
  //           }
  //         }

  //         if (item.currency == "USDT_TRON") {
  //           console.log("Wallet USDT TRON detectada");
  //           if (item.balance) {
  //             setUSDTTronBalance(item.balance.toFixed(2));
  //           }
  //         }
  //       });
  //     })
  //     .catch((error) => {
  //       //createWallet();
  //       //swal({text: "Estamos creando tu billetera de Bitcoins, en unos segundos la verás... :)", button: false, timer: 3000});
  //     });
  // };

  const sendBTC = async () => {
    let body = { toAddress: sendTo, amount: sendAmount };

    // Validacion
    if (sendTo == "") {
      swal({
        text: "No has colocado la dirección Bitcoin (BTC) a enviar.",
        button: true,
        timer: 5000,
      });
      return;
    }

    if (process.env.REACT_APP_ENV_TYPE == "production") {
      if (!WAValidator.validate(sendTo, "BTC", "prod")) {
        swal({
          text:
            "Esta no es una dirección válida de Bitcoin. Recuerda que puedes enviar a una Legacy o SegWit.",
          button: true,
          timer: 5000,
        });
        return;
      }
    } else {
      if (!WAValidator.validate(sendTo, "BTC", "test")) {
        swal({
          text:
            "Esta no es una dirección válida de Bitcoin de TESTNET. Recuerda que puedes enviar a una Legacy o SegWit.",
          button: true,
          timer: 5000,
        });
        return;
      }
    }

    if (sendAmount == "" || sendAmount == "0") {
      swal({
        text: "Coloca el monto en BTC a enviar.",
        button: true,
        timer: 5000,
      });
      return;
    }

    // if (sendAmount < 0.0015 || sendAmount > 1) {
    //   swal({
    //     text: "El monto de bitcoins a enviar debe estar entre 0.0015 y 1 BTC.",
    //     button: true,
    //     timer: 5000,
    //   });
    //   return;
    // }

    if (sendAmount > saldo) {
      swal({
        text: "No puedes enviar un monto que no tienes disponible para usar. ",
        button: true,
        timer: 5000,
      });
      return;
    }

    if (sendAmount > Number(saldo) - Number(feePerOperation)) {
      swal({
        text:
          "No puedes enviar este monto debido a que se debe incluir la comisión de envio. ",
        button: true,
        timer: 5000,
      });
      return;
    }

    const encodeFormData = (data) => {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    };

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encodeFormData(body),
    };
    let endpoint = "/client/wallet/sendBitcoin";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          swal({
            text:
              "¡Se enviaron correctamente " +
              sendAmount +
              " BTC a la direccion indicada! :D",
            button: true,
            timer: 7000,
          });
          return response.json();
        } else {
          swal({
            text:
              "Ha habido un error al enviar los bitcoin. Si el error persiste escribenos a Soporte.",
            button: false,
            timer: 5000,
          });
        }
      })
      .then((responseJson) => {
        setOpenSend(false);
      })
      .catch((error) => {
        swal({
          text:
            "Ha habido un error al enviar los bitcoin. Si el error persiste escribenos a Soporte.",
          button: false,
          timer: 5000,
        });
      });
  };

  const getUser = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/user";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        // setDataUser(responseJson);
        /*if(responseJson.hasRegisterCoupon){
            getCoupon(responseJson.registerCouponId);
          }*/
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    // Call Wallet
    // getWallet();

    // const calculatorObjTmp = new ObjectCalculator(calculatorObserver);
    // calculatorObjTmp.convert();
    // setCalculatorObj(calculatorObjTmp);
    // const interval = setInterval(() => {
    //   calculatorObjTmp.convert();
    // }, 15000);

    // const fetchData = async () => {
    //   const result = await getUser();
    // };

    let options = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/transaction`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-available" };
        }
      })
      .then((responseJson) => {
        // Filter operaciones with estado = ENTREGADO
        const delivered = responseJson.filter(
          (transaccion) => transaccion.state === "DELIVERED"
        );

        // Filter delivered with operacion = BUY
        const operacionesBUY = delivered.filter(
          (transaccion) => transaccion.transactionType === "BUY"
        );
        // Filter delivered with operacion = SELL
        const operacionesSELL = delivered.filter(
          (transaccion) => transaccion.transactionType === "SELL"
        );

        // Get the sum of all BUY

        // User and coupon

        // fetchData();

        const totalBUY =
          operacionesBUY.reduce(
            (sum, value) =>
              typeof value.ammounts[0] === "number"
                ? sum + value.ammounts[0]
                : sum,
            0
          ) + cuponValue;

        // Get the sum of all SELL
        const totalSELL = operacionesSELL.reduce(
          (sum, value) =>
            typeof value.ammounts[0] === "number"
              ? sum + value.ammounts[0]
              : sum,
          0
        );

        const balance = totalBUY - totalSELL;
        setTotalCompra(totalBUY.toFixed(7));
        setTotalVenta(totalSELL.toFixed(7));
        //setSaldo(balance.toFixed(7));

        // calculatorObjTmp.setSELL();
        // calculatorObjTmp.setUSD();
        // calculatorObjTmp.setAmmountA(balance.toFixed(5));

        //setTransacciones(responseJson);
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error en nuestro sistema.",
          button: false,
          timer: 1000,
        });
      });
    return () => {
      // clearTimeout(interval);
    };
  }, [cuponValue]);

  const onOpenModalReceive = () => {
    setOpenReceive(true);
  };

  const onCloseModalReceive = () => {
    setOpenReceive(false);
  };

  const onOpenModalSend = () => {
    setOpenSend(true);
  };

  const onCloseModalSend = () => {
    setOpenSend(false);
  };

  const typeOperation = (string) => {
    if (string == "send") return "Envio";
    if (string == "receive") return "Depósito";
  };

  const maxSendValue = () => {
    setSendAmount(saldo - feePerOperation);
  };

  const onHandleAddressChange = (e) => {
    let addressInput = e.target.value;

    setSendTo(addressInput);

    if (addressInput != "" && WAValidator.validate(addressInput, "BTC")) {
      checkAddressBTC(addressInput);
    } else {
      setFeePerOperation(0.0);
    }
  };

  const onHandleAmountBTCChange = (e) => {
    let amount = e.target.value;

    if (amount.length <= 10) {
      setSendAmount(amount);
    } else {
      return false;
    }

    if (amount <= 1) {
      setSendAmount(amount);
    } else {
      setSendAmount(1);
    }
  };

  const onKeyPressBTC = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  return (
    <>
      {saldo !== undefined ? (
        <>
          <WalletContent id="wallets">
            <WalletBoxLeft>
              <TitleBox>Billeteras</TitleBox>

              <ListRowDiv>
                <ListColumnDiv>
                  <ColTextLight>Moneda</ColTextLight>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColTextLight> Monto </ColTextLight>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColTextLight>Disponible</ColTextLight>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColText> </ColText>
                </ListColumnDiv>
              </ListRowDiv>

              {loading && (
                <div style={{ textAlign: "center" }}>Cargando...</div>
              )}

              <ListRowDiv>
                <ListColumnDiv>
                  <ColText> Bitcoin </ColText>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColText> {`${walletBTC.balance} `} BTC </ColText>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColStatus>
                    <span data-testid="btcbalance">
                      {" "}
                      {`${walletBTC.balance} `}{" "}
                    </span>
                    BTC
                  </ColStatus>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColButton
                    to={`/app/profile/btc`}
                    title="Ver detalle de billetera"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEye} /> Ver{" "}
                  </ColButton>
                </ListColumnDiv>
              </ListRowDiv>

              {walletUSDT.found && (
                <ListRowDiv>
                  <ListColumnDiv>
                    <ColText> Tether ETH</ColText>
                  </ListColumnDiv>

                  <ListColumnDiv>
                    <ColText>₮ {`${walletUSDT.balance} `} </ColText>
                  </ListColumnDiv>

                  <ListColumnDiv>
                    <ColStatus>
                      ₮{" "}
                      <span data-testid="usdtbalance">{`${walletUSDT.balance} `}</span>
                    </ColStatus>
                  </ListColumnDiv>

                  <ListColumnDiv>
                    <ColButton
                      to={`/app/profile/usdt`}
                      title="Ver detalle de billetera"
                    >
                      {" "}
                      <FontAwesomeIcon icon={faEye} /> Ver{" "}
                    </ColButton>
                  </ListColumnDiv>
                </ListRowDiv>
              )}

              <ListRowDiv>
                <ListColumnDiv>
                  <ColText> Tether TRON </ColText>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColText>₮ {`${walletUSDTTron.balance} `} </ColText>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColStatus>
                    ₮{" "}
                    <span data-testid="usdtbalance">{`${walletUSDTTron.balance} `}</span>
                  </ColStatus>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColButton
                    to={`/app/profile/usdt_tron`}
                    title="Ver detalle de billetera"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEye} /> Ver{" "}
                  </ColButton>
                </ListColumnDiv>
              </ListRowDiv>
            </WalletBoxLeft>

            {/* <WalletBoxRight>
              <ContentDiv>
                <ColDiv>
                  <TitleBox>Abonar tu cuenta </TitleBox>
                </ColDiv>
                <ColDiv>
                  <PercentageText>Videotutorial</PercentageText>
                </ColDiv>
              </ContentDiv>

              <iframe
                width="auto"
                height="250"
                src="https://www.youtube.com/embed/eiMEeEOMFgc"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </WalletBoxRight> */}
          </WalletContent>
        </>
      ) : (
        <></>
      )}

      <Modal
        open={openSend}
        onClose={onCloseModalSend}
        center
        closeOnOverlayClick={false}
      >
        <BodyModal>
          <TitleModal>Enviar Bitcoin</TitleModal>

          <ImageModal src={iconSendBTC} />

          <LabelModal>
            <LabelSpan>Dirección de la billetera destino</LabelSpan>
            <InputModal
              type="text"
              value={sendTo}
              onChange={(e) => onHandleAddressChange(e)}
            />
          </LabelModal>

          <LabelModal>
            <LabelSpan>
              Monto a enviar (Disponible: <b>{saldo} BTC</b>).{" "}
              <a href="#" onClick={() => maxSendValue()}>
                Usar todo
              </a>
            </LabelSpan>
            <InputModal
              type="number"
              min="0"
              value={sendAmount}
              onKeyPress={(e) => onKeyPressBTC(e)}
              onChange={(e) => onHandleAmountBTCChange(e)}
            />
          </LabelModal>

          <Margin20></Margin20>

          <DetailsOperation>
            <DetailTitle>Monto a enviar</DetailTitle>
            <DetailAmount>{sendAmount} BTC</DetailAmount>
          </DetailsOperation>

          <Clear></Clear>

          <DetailsOperation>
            <DetailTitle>Comisión</DetailTitle>
            <DetailAmount>{feePerOperation.toFixed(5)} BTC</DetailAmount>
          </DetailsOperation>

          <Clear></Clear>

          <DetailsOperation>
            <DetailTitle>Monto total</DetailTitle>
            <DetailAmount>
              {(Number(sendAmount) + Number(feePerOperation)).toFixed(8)} BTC
            </DetailAmount>
          </DetailsOperation>

          <Margin20></Margin20>
          <Margin20></Margin20>

          <BtnPrimaryLg onClick={() => sendBTC()}>Confirmar envio</BtnPrimaryLg>

          <FooterModal>
            <b>Importante:</b> Asegúrese de introducir correctamente los datos
            de la dirección destino (lo ideal es copiar y pegar). Fëanor no se
            hace responsable de las monedas enviadas a una dirección equivocada.
          </FooterModal>
        </BodyModal>
      </Modal>

      <Modal
        open={openReceive}
        onClose={onCloseModalReceive}
        center
        closeOnOverlayClick={true}
      >
        <BodyModal>
          <TitleModal>Recibir Bitcoin</TitleModal>

          <ImageModal src={iconReceiveBTC} />

          <LabelModal>
            <LabelSpan>Dirección de tu billetera</LabelSpan>
            <InputModal readOnly type="text" value={addressBtc} />
          </LabelModal>

          <FooterModal>
            <b>Importante:</b> Fëanor no se hace responsable de las monedas
            enviadas a una dirección equivocada. Esta es tu dirección que debes
            brindar para recibir bitcoins. Después de realizar un depósito puede
            hacer el seguimiento del progreso en la página <b>Operaciones</b>.
          </FooterModal>
        </BodyModal>
      </Modal>
    </>
  );
};

export default SaldoMain;
