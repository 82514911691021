import styled from "styled-components";
import colors from "styles/common/colors";

export const EditButton = styled.button`
  border: none;
  outline: none;
  background-color: #f2a900;
  text-decoration: none;
  color: ${colors.dark_blue};
  font-family: Montserrat;
  font-weight: 600;
  padding: 16px 32px;
  margin: 10px 0px;
  border-radius: 12px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
  position: relative;
  &:disabled,
  &[disabled] {
    background-color: #cccccc;
    color: #666666;
  }
  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const CatcherBotonera = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-self: flex-start;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0;
  }
`;

export const DisabledLink = styled.span`
  background-color: grey;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 32px;
  border-radius: 12px;
  font-size: 22px;
  margin: 12px 0;
  flex: 0 1 20%;
  text-align: center;
  @media (max-width: 640px) {
    flex: 0 1 60%;
    padding: 12px 0;
    border: none;
    font-size: 12px;
  }
`;
