import styled from "styled-components";
import colors from "./colors";
import Select from "react-select";
import { HashLink } from "react-router-hash-link";

export const Header1 = styled.h1`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.dark_blue};
  font-size: 30px;
  font-weight: 400;
  text-align: center;
`;

export const Header2 = styled.h2`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.dark_blue};
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  @media (max-width: 640px) {
    font-size: 25px;
  }
`;

export const Header3 = styled.h3`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.dark_blue};
  font-size: 25px;
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const Header4 = styled.h4`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.dark_blue};
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  line-height: 2.2;
`;

export const Header5 = styled.h5`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.dark_blue};
  font-size: 14px;
  margin: 0;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.dark_blue};
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  padding: 0 20px;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const TitleSection = styled.h1`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.dark_blue};
  font-size: 25px;
  font-weight: 700;
`;

export const ParagraphSubtitle = styled.p`
  font-family: "Bahnschift Light", sans-serif;
  color: ${colors.dark_blue};
  font-size: 25px;
  font-weight: 300;
`;

export const WarningBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 13px;
  border-radius: 4px;
  background-color: #ffeece;
  margin-top: 20px;
  border: 1px solid #ffc85c;
  left: 0;
  right: 0;
  text-align: center;
  color: #3c4a5b;
`;

export const BtnBlueSm = styled(HashLink)`
  padding: 12px;
  background-color: #1a4bea;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  width: 250px;
  display: inline;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
`;

export const BtnBlueSm2 = styled.button`
  padding: 12px;
  background-color: #1a4bea;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  width: 250px;
  display: inline;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
`;

export const Input = styled.input`
  position: relative;
  height: 30px;
  max-width: 400px;
  width: 100%;
  font-family: "Bahnschift Light";
  font-size: 14px;
  text-align: left;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #3c4a5b;
  box-sizing: border-box;
  text-align: left;
  padding-left: 10px;
  font-weight: 400;
  display: flex;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TextData = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  text-align: left;
  font-family: "Bahnschift Light";
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  outline: none;
  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ContainerLevels = styled.div`
  display: flex;
  max-width: 434px;
`;

export const LevelBox = styled.div`
  flex-basis: 100%;
  min-height: 200px;
  padding: 25px;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  margin: 35px;
  color: #3c4a5b;
  text-align: center;
`;

export const LevelBoxTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  padding: 15px 0px;
  text-align: center;
`;

export const LevelBoxDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  /* padding: 15px 0px; */
  text-align: left;
`;

export const LevelBoxList = styled.ul`
  padding-left: 5px;
`;

export const BtnYellowSm = styled(HashLink)`
  padding: 12px 25px;
  background-color: ${colors.feanor_gold};
  /* background-color: #9C5149; */
  color: #000;
  border-radius: 4px;
  text-align: center;
  /* width: 120px; */
  font-size: 14px;
  letter-spacing: 0.8px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
`;

export const Selector = styled(Select)`
  width: 50%;
  font-family: "Bahnschift Light";
  font-size: 13px;
  border: none;
  color: ${colors.feanor_dark_bg};
  font-weight: 400;
  outline: none;
  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus-visible {
    outline: 1px solid black;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SelectorFull = styled(Selector)`
  width: 100%;
`;

// Alternatives

export const Header1White = styled(Header1)`
  color: #ffffff;
`;

export const Header1Black = styled(Header1)`
  color: ${colors.feanor_dark_bg};
  line-height: 27px;
  margin-top: 43px;
`;

export const ParagraphSubtitleWhite = styled(ParagraphSubtitle)`
  color: #ffffff;
`;

export const BottomLine = styled.img`
  width: 100%;
`;

export const TopLine = styled.img`
  width: 100%;
`;
