import feanorapi from "feanorapi";
import http from "http-common";
import { getToken, headers } from "./common";

export const cancelCardrequest = async (token) => {
  const response = await http.put(
    "/client/card/cancelrequest",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log(response);
  return response.data;
};
export const registerUserInPaycaddy = async (values, token) => {
  const response = await http.post(
    "/client/card/mastercard/registeruser",
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
export const requestCard = async (values, token) => {
  const response = await http.post("/client/card/mastercard/request", values, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const fetchAllCards = async (token) => {
  let data = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let url = `${feanorapi}/client/card`;
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res;
  // return [
  //   { number: "123-123-123-123", balance: "200.4", currency: "USDT" },
  //   { number: "555-144-055-145", balance: "500.2", currency: "USDT" },
  // ];
};

// Ex. props = { amount: 100, cardId: ab5a1235444bcd546555 }
export const fundCardRequest = async (props, token) => {
  let endpoint = `/client/card/mastercard/deposit`;
  let url = `${feanorapi}${endpoint}`;
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(props),
  };
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res;
};

export const fundVisaCardRequest = async (props, token) => {
  let endpoint = `/client/card/visa/deposit`;
  let url = `${feanorapi}${endpoint}`;
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(props),
  };
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res;
};

export const confirmFundVisaCard = async (depositId, token) => {
  let endpoint = `/client/card/visa/confirm/${depositId}`;
  let url = `${feanorapi}${endpoint}`;
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res;
};

// Ex. props = { cardId: ab5a1235444bcd546555, otpToken: 123456 || password: "555555" }
export const fetchCardData = async ({ cardId, otpToken, password }) => {
  let endpoint = `/client/card/mastercard/getdata`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
};

// Ex. props = { cardId: ab5a1235444bcd546555, last6digits: "898989", otpToken: 123456 || password: "555555" }
export const confirmReception = async ({
  cardId,
  last6digits,
  otpToken,
  password,
}) => {
  let endpoint = `/client/card/mastercard/confirmReception`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, last6digits, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
};

// Ex. props = { cardId: ab5a1235444bcd546555, last6digits: "898989", otpToken: 123456 || password: "555555" }
export const changePIN = async ({ cardId, newPin, otpToken, password }) => {
  let endpoint = `/client/card/mastercard/changepin`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, newPin, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
};

// Obiene informacion de la wallet de la tarjeta (paycaddy)
export const fetchCardWallet = async () => {
  let endpoint = `/client/card/mastercard/cardwallet`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};

// Ex. props = { cardId: ab5a1235444bcd546555, otpToken: 123456 || password: "555555" }
export const blockCard = async ({ cardId, otpToken, password }) => {
  let endpoint = `/client/card/mastercard/block`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
};

// Ex. props = { cardId: ab5a1235444bcd546555, otpToken: 123456 || password: "555555" }
export const unblockCard = async ({ cardId, otpToken, password }) => {
  let endpoint = `/client/card/mastercard/unblock`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
};

// Ex. props = { amount: 100, from: "BTC", to: "USDT", skipEmail: true }
// export const converBTCtoUSDT = async (props) => {
//   let endpoint = `/client/card/convert`;
//   let url = `${feanorapi}${endpoint}`;
//   const response = await http.post(url, props, {
//     headers: {
//       Authorization: `Bearer ${getToken()}`,
//     },
//   });
//   return response.data;
// };
export const converBTCtoUSDT = async (props, token) => {
  let endpoint = `/client/card/convert`;
  let url = `${feanorapi}${endpoint}`;
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(props),
  };
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res;
};

export const fetchMastercardTransactions = async () => {
  let endpoint = `/client/card/mastercard/transactions`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};

export const fetchVisaBalance = async ({ cardId }) => {
  let endpoint = `/client/card/visa/balance/${cardId}`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};

export const changeVisaPIN = async ({ cardId, newPin, otpToken, password }) => {
  let endpoint = `/client/card/visa/changepin`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, newPin, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
};

// Ex. props = { cardId: ab5a1235444bcd546555, otpToken: 123456 || password: "555555" }
export const fetchVisaCardData = async ({ cardId, otpToken, password }) => {
  let endpoint = `/client/card/visa/getdata`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const fetchVisaTransactions = async ({ cardId }) => {
  let endpoint = `/client/card/visa/transactions/${cardId}`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};


export const converBTCtoUSDTBusiness = async (props, token) => {
  let endpoint = `/business/card/convert`;
  let url = `${feanorapi}${endpoint}`;
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(props),
  };
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res;
};


export const changeBusinessVisaPIN = async ({ cardId, newPin, otpToken, password }) => {
  let endpoint = `/business/card/visa/changepin`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, newPin, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
};

// Ex. props = { cardId: ab5a1235444bcd546555, otpToken: 123456 || password: "555555" }
export const fetchBusinessVisaCardData = async ({ cardId, otpToken, password }) => {
  let endpoint = `/business/card/visa/getdata`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.post(
    url,
    { cardId, otpToken, password },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};


export const fetchBusinessVisaTransactions = async ({ cardId }) => {
  let endpoint = `/business/card/visa/transactions/${cardId}`;
  let url = `${feanorapi}${endpoint}`;
  const response = await http.get(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
};
