const colors = {
  blue_agente_btc: "#1A4BEA",
  // yellow_agente_btc: '#FFC85C',
  yellow_agente_btc: "#ff8a65",
  ligth_yellow_agente_btc: "rgba(255, 200, 92, .4)",
  grey_agente_btc: "#FFC85C",
  dark_blue: "#3C4A5B",
  super_dark_blue: "#272C49",
  soft_grey: "#727D8A",
  feanor_orange: "#f58634",
  feanor_orange_light: "#F9B883",
  feanor_gold: "#c99e49",
  feanor_dark_bg: "#000000",
};

export default colors;
