export default function LoadingMessage({ loading, createdInSVEmisor }) {
  if (createdInSVEmisor && loading) {
    return "Abonando (1 min aprox) ...";
  }

  if (!createdInSVEmisor && loading) {
    return "Abonando...";
  }

  return "Confirmar abono";
}
