import React, { useEffect, useState } from "react";
import { Header2, Paragraph } from "styles/common/index.js";
import {
  BtnPrimary,
  ButtonContent,
  DescriptionBox,
  DisplayCount,
  ItemBordered,
  TitleBox,
  WalletBoxLeft,
  WalletBoxRight,
  WalletContent,
} from "styles/Styled";
import useGetCards from "hooks/useGetCards";
import useGetUserInfo from "hooks/useGetUser";
import { CardsContainer } from "components/CardRegular/styled";
import useGetCardTransactions from "hooks/useGetCardTransactions";
// import MoreAbout from "./CardDetail/components/MoreAbout";
import { fetchCardWallet } from "services/card.service";
import useGetCardWallet from "hooks/useGetCardWallet";
// import FundCardForm from "./CardDetail/components/FundCardForm";
import useModal from "hooks/useModal";
import useGetWallets from "hooks/useGetWallets";
import swal from "sweetalert";
import { useHistory } from "react-router";
import useGetBusinessCards from "hooks/useGetBusinessCards";
import useGetBusinessInfo from "hooks/useGetBusiness";
import { requestBusinessCard } from "services/businessCard.service";
import MoreAbout from "./sections/MoreAbout";
import LastTransactions from "./sections/LastTransactions";
import "styles/stylesSwiper.css";
import BusinessCards from "./sections/BusinessCards";
import useGetBusinessCardTransactions from "hooks/useGetBusinessCardTransactions";

const FeanorCard = () => {
  const { cards, getCards, regular, premium, loading } = useGetBusinessCards();
  const { businessInfo } = useGetBusinessInfo();
  const { getTransactions, transactions } = useGetBusinessCardTransactions();
  const [loadingRequest, setLoadingRequest] = useState(false);
  //   const { walletUSDT, getWallets, walletBTC } = useGetWallets();

  console.log(premium, "premium");
  const onClickRequestCard = async (e) => {
    e.preventDefault();
    setLoadingRequest(true);
    try {
      const { success } = await requestBusinessCard();
      if (success) {
        swal(
          "¡Éxito!",
          "La tarjeta fue solicitada, le asignaremos una en el transcurso del dia",
          "success",
          {
            timer: 4000,
            buttons: false,
          }
        );
      }
    } catch (error) {
      console.log(error);
      swal(
        "Ocurrió un error",
        "No se pudo realizar la solicitud de su tarjeta, intente nuevamente mas tarde",
        "error"
      );
    } finally {
      getCards();
      setLoadingRequest(false);
    }
  };
  if (cards.length <= 0) {
    return (
      <React.Fragment>
        <Header2 style={{ marginBottom: 0 }}>
          Mi Fëanor Card Corporativa
        </Header2>
        <Paragraph className="boxCenter">
          Esta es una tarjeta exclusiva en Fëanor, con ella podrás abonar USDT a
          tu tarjeta Fëanor y realizar compras o pagos a nivel mundial!.
        </Paragraph>
        <ItemBordered onClick={onClickRequestCard}>
          {loadingRequest ? "Solicitando..." : "Solicitar FëanorCard"}
        </ItemBordered>
        {loading && <p>Cargando...</p>}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header2 style={{ marginBottom: 0 }}>Mi Fëanor Card Corporativa</Header2>
      <Paragraph className="boxCenter">
        Esta es una tarjeta exclusiva en Fëanor, con ella podrás abonar USDT a
        tu tarjeta Fëanor y realizar compras o pagos a nivel mundial!.
      </Paragraph>
      {/* <ItemBordered onClick={onClickRequestCard}>
        {loadingRequest ? "Solicitando..." : "Solicitar FëanorCard"}
      </ItemBordered> */}

      {loading && <p>Cargando...</p>}

      {/* Tarjetas Premium (VISA - Versatec) */}
      {premium.length && (
        <BusinessCards
          otpEnabled={businessInfo.otpEnabled}
          // walletUSDT={walletUSDT}
          // walletBTC={walletBTC}
          // refreshWallets={getWallets}
          premiumCards={premium}
          refreshCards={getCards}
          refreshTransactions={getTransactions}
        />
      )}

      <WalletContent>
        <LastTransactions transactions={transactions} />
        <MoreAbout />
      </WalletContent>
    </React.Fragment>
  );
};

export default FeanorCard;
