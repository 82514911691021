import FormRequestOTP from "components/FormRequestOTP";
import useGetUserInfo from "hooks/useGetUser";
import useModal from "hooks/useModal";
import React, { useState } from "react";
import { disableOTP } from "services/auth.service";

import Styled from "./../UserData/styles";
import FormEnableOTP from "./FormEnableOTP";
import swal2 from "sweetalert2";
import { useContext } from "react";
import { AuthContext } from "Auth";

const TwofactorAuthSection = () => {
  const { fetchUser: refreshUserInfo } = useGetUserInfo(false);
  const { userInfo } = useContext(AuthContext);
  const [Modal, open, openModal, onClose] = useModal();
  const [
    RequestOTPModal,
    isOpenRequestOTP,
    openRequestOTP,
    onCloseRequestOTP,
  ] = useModal();
  const [otpToken, setOtpToken] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const onClickDisableBtn = () => {
    if (userInfo.otpEnabled) {
      openRequestOTP();
    }
  };

  const handleDisableOTP = async (e) => {
    e.preventDefault();
    try {
      const { success } = await disableOTP(otpToken);
      if (success) {
        setErrorMsg("");
        swal2.fire({
          title: "",
          text: "Se desactivó el doble factor de autenticación.",
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        await refreshUserInfo();
        onCloseRequestOTP();
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response?.data?.code === "invalid-otp") {
        setErrorMsg("Codigo de verificación inválido.");
        return;
      }
    }
  };
  return (
    <>
      <RequestOTPModal
        open={isOpenRequestOTP}
        onClose={onCloseRequestOTP}
        closeOnOverlayClick={false}
      >
        <FormRequestOTP
          onSubmit={handleDisableOTP}
          setOtpToken={setOtpToken}
          otpToken={otpToken}
          errorMsg={errorMsg}
        />
      </RequestOTPModal>

      <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
        <FormEnableOTP refreshUserInfo={refreshUserInfo} closeModal={onClose} />
      </Modal>

      <Styled.ProfileTitle>
        Autenticación de 2 factores{" "}
        {userInfo.otpEnabled && (
          <>
            <svg
              style={{ color: "green", height: "17px", marginLeft: "10px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="css-kddq4d"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-4.934-4.483L10.2 13.383l-2.716-2.716-1.768 1.767 4.484 4.484 7.634-7.634-1.768-1.767z"
                fill="currentColor"
              ></path>
            </svg>
            <span style={{ fontSize: "12px" }}>Activado</span>
          </>
        )}
      </Styled.ProfileTitle>
      <Styled.ProfileDescription>
        Protege tu cuenta y transacciones usando Google Authenticator
        (recomendado)
      </Styled.ProfileDescription>
      {userInfo.otpEnabled ? (
        <Styled.ButtonSave
          onClick={() => onClickDisableBtn()}
          style={{ marginTop: 0 }}
        >
          Desactivar
        </Styled.ButtonSave>
      ) : (
        <Styled.ButtonSave onClick={() => openModal()} style={{ marginTop: 0 }}>
          Activar
        </Styled.ButtonSave>
      )}
    </>
  );
};

export default TwofactorAuthSection;
