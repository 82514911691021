import React, { useEffect, useState } from "react";
import {
  TextSaldo,
  AmountCurrency,
  WalletContent,
  WalletBoxLeft,
  WalletBoxRight,
  TitleBox,
  DisplayCount,
  ButtonContent,
  BtnPrimary,
  DescriptionBox,
} from "styles/Styled";
import { PrimaryText } from "styles/colorized";
import useGetCards from "hooks/useGetCards";
import useModal from "hooks/useModal";
import FundCardForm from "./FundCardForm";
import useGetWallets from "hooks/useGetWallets";
import swal from "sweetalert";
import { useHistory } from "react-router";
import moment from "moment";
const BalanceRow = ({ cardNumber, getTransactions }) => {
  const history = useHistory();
  const { cards, getCardByNumber } = useGetCards();
  const { walletUSDT, getWallets, walletBTC, walletUSDTTron } = useGetWallets();
  const [ModalFundCard, open, openModal, onClose] = useModal();
  const [cardDetail, setCardDetail] = useState(getCardByNumber(cardNumber));

  const getCardDetail = () => {
    setCardDetail(getCardByNumber(cardNumber));
  };
  useEffect(() => {
    getCardDetail();
  }, [cards]);

  const hadleOpenFundCard = () => {
    if (!walletUSDT.found && !walletUSDTTron.found) {
      swal({
        text:
          "Aun no cuentas con una billetera USDT porfavor crea una y abona tethers a ella, te redirigeremos a la seccion de creacion de billetera USDT.",
        button: false,
        timer: 6000,
      });
      setTimeout(function() {
        history.push(`/app/profile/usdt`);
      }, 6000);
      return;
    }
    openModal();
  };

  let updatedAt = "";
  if (cardDetail.balanceUpdatedAt) {
    // const panamaOffset = 300; // utcOffset in minutes

    // const localDate = new Date();
    // const localOffset = localDate.getTimezoneOffset();

    // const diffOffset = (panamaOffset - localOffset) * 60000; // offset in miliseconds

    // const panamaUpdatesDate = new Date();
    // panamaUpdatesDate.setHours(18, 0, 0);
    // console.log(cardDetail.balanceUpdatedAt,diffOffset)
    updatedAt = new Date(parseInt(cardDetail.balanceUpdatedAt));
  }
  // const panamaOffset = 300; // utcOffset in minutes

  // const localDate = new Date();
  // const localOffset = localDate.getTimezoneOffset();

  // const diffOffset = (panamaOffset - localOffset) * 60000; // offset in miliseconds

  // const panamaUpdatesDate = new Date();
  // panamaUpdatesDate.setHours(18, 0, 0);
  // const localUpdatesDate = new Date(panamaUpdatesDate.getTime() + diffOffset);

  return (
    <>
      <ModalFundCard
        open={open}
        onClose={onClose}
        center
        closeOnOverlayClick={false}
      >
        <FundCardForm
          cardDetail={cardDetail}
          refreshCardDetail={getCardDetail}
          walletUSDT={walletUSDT}
          walletBTC={walletBTC}
          walletUSDTTron={walletUSDTTron}
          closeModal={onClose}
          refreshWallets={getWallets}
          refreshTransactions={getTransactions}
        />
      </ModalFundCard>

      <TextSaldo>
        <PrimaryText>
          Tienes un saldo total de{" "}
          <AmountCurrency>${cardDetail.balance} Dólares </AmountCurrency>
          en tu tarjeta
        </PrimaryText>
        {updatedAt && (
          <p
            style={{
              fontSize: "11px",
              maxWidth: "500px",
              textAlign: "center",
              color: "#3c4a5b",
            }}
          >
            <AmountCurrency>*</AmountCurrency> Importante: Ultima actualización
            de saldo hecha el {moment(updatedAt).format("DD/MM/YYYY")} a las{" "}
            {moment(
              `${updatedAt.getHours()}:${updatedAt.getMinutes()}`,
              "hh:mm"
            ).format("LT")}{" "}
            de su hora local.
          </p>
        )}
        {/* <p
          style={{
            fontSize: "11px",
            maxWidth: "500px",
            textAlign: "center",
            color: "#3c4a5b",
          }}
        >
          <AmountCurrency>*</AmountCurrency> Importante: El saldo de su Fëanor
          Card se actualizará todos los dias habiles a las 6PM Panama Time (GMT
          -05:00) o a las{" "}
          {moment(localUpdatesDate.getHours(), "hh").format("LT")} en su hora
          local.
        </p> */}
      </TextSaldo>

      <WalletContent>
        <WalletBoxLeft style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "220px" }}>
            <TitleBox>Saldo en tarjeta</TitleBox>
            <DisplayCount>$ {cardDetail.balance} USD</DisplayCount>
          </div>
          <div style={{ width: "220px" }}>
            <TitleBox>Saldo en Wallet USDT</TitleBox>
            <DisplayCount>₮ {walletUSDT.balance} USDT</DisplayCount>
          </div>
        </WalletBoxLeft>

        <WalletBoxRight>
          {cardDetail.found && (
            <DescriptionBox>
              {/* Tienes <b>$ {cardDetail.balance} </b> para usar */}
              Recarga tu FëanorCard usando USDT de tu wallet.
            </DescriptionBox>
          )}

          {cardDetail.found && (
            <ButtonContent>
              <BtnPrimary onClick={hadleOpenFundCard}>Abonar</BtnPrimary>
              {/* <BtnPrimary onClick={onOpenModalSend}>Abonar</BtnPrimary> */}
              {/* <BtnSecondary>Retirar</BtnSecondary> */}
              {/* <BtnSecondary onClick={onOpenModalReceive}>Retirar</BtnSecondary> */}
            </ButtonContent>
          )}
        </WalletBoxRight>
      </WalletContent>
    </>
  );
};

export default BalanceRow;
