import React, { useState, useContext, useEffect } from "react";

import swal from "sweetalert";
import swal2 from "sweetalert2";
import {
  Modal,
  BodyModal,
  CloseBtn,
  TitleModal,
  LabelModal,
  LabelSpan,
  TextAreaMsg,
  InputModal,
  StyledButtonYellow,
  Background,
} from "./styles";
import SuccessMessage from "components/SuccessMessage";
import { SubscribeNFTContext } from "contexts/subscribeNFT";

const Subscribe = ({ projectDetailProp }) => {
  const { subscribeToProject, setProjectDetail } = useContext(
    SubscribeNFTContext
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subscriptionDone, setSubscriptionDone] = useState(false);

  useEffect(() => {
    setProjectDetail(projectDetailProp);

    return () => {
      setProjectDetail({});
    };
  }, []);

  const handleSendEmail = async () => {
    if (name == "") {
      swal({
        text: "Por favor ingrese su Nombre",
        button: true,
        timer: 5000,
      });
      return;
    }

    if (email == "") {
      swal({
        text: "Por favor ingrese su correo electrónico",
        button: true,
        timer: 5000,
      });
      return;
    }

    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      swal({
        text: "El email no es válido",
        button: true,
        timer: 5000,
      });
      return;
    }

    if (phone == "") {
      swal({
        text: "Por favor ingrese su número de celular",
        button: true,
        timer: 5000,
      });
      return;
    }

    try {
      const res = await subscribeToProject({ name, email, phone });
      if (res._id) {
        setSubscriptionDone(true);
      }

      // swal2.fire(
      //   "Mensaje enviado",
      //   "Gracias! nos podremos en contacto pronto",
      //   "success"
      // );
    } catch (error) {
      console.log(error);
      swal2.fire(
        "Error",
        "Ocurrió un error al realizar la subscripción",
        "failure"
      );
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  return (
    <>
      {subscriptionDone ? (
        <SuccessMessage message="Subscripción realizada correctamente" />
      ) : (
        <div>
          <TitleModal>
            Ingrese sus datos para mantenerlo informado sobre los avances de
            este proyecto
          </TitleModal>

          <LabelModal>
            <LabelSpan>Nombre completo</LabelSpan>
            <InputModal
              type="text"
              placeholder="Ejm. Carlos Sanchez"
              value={name}
              onChange={(e) => handleChangeName(e)}
            />
          </LabelModal>

          <LabelModal>
            <LabelSpan>Correo electrónico</LabelSpan>
            <InputModal
              type="text"
              placeholder="Ejm. micorreo@gmail.com"
              value={email}
              onChange={(e) => handleChangeEmail(e)}
            />
          </LabelModal>

          <LabelModal>
            <LabelSpan>Número de celular</LabelSpan>
            <InputModal
              type="tel"
              placeholder="Ejm. +51 987 777 777"
              value={phone}
              onChange={(e) => handleChangePhone(e)}
            />
          </LabelModal>

          <StyledButtonYellow onClick={handleSendEmail}>
            Enviar
          </StyledButtonYellow>
        </div>
      )}
    </>
  );
};

export default Subscribe;
