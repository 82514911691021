/* eslint-disable no-underscore-dangle */
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CalendarToday from "@material-ui/icons/CalendarToday";
import ClearIcon from "@material-ui/icons/Clear";
import { DateRangePicker } from "@material-ui/pickers";
import { AuthContext } from "Auth";
import { icons, translations } from "components/DataTableProps";
import { TransactionsContext } from "contexts/transactions";
import feanorapi from "feanorapi.js";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { translateCategory, translateState } from "utils/utils";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker
// } from "@material-ui/pickers";
const CustomDatePicker = (props) => {
  const [date, setDate] = React.useState(new Date());

  // const [open, setOpen] = React.useState(false);
  // const [, set] = useState([null, null]);
  const [value, setValue] = useState([null, null]);

  // const toggle = () => setOpen(!open);
  return (
    <>
      <DateRangePicker
        startText="Check-in"
        endText="Check-out"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          // alert(typeof newValue)
          // const rangeSplited = newValue.split(",")
          const start = newValue[0];
          const end = newValue[1];

          if (start && end) {
            const startFormatted = start.format("YYYY-MM-DD");
            const endFormatted = end.format("YYYY-MM-DD");
            if (
              startFormatted.toString() === "Invalid date" ||
              endFormatted.toString() === "Invalid date"
            ) {
              return;
            }
            props.onFilterChanged(
              props.columnDef.tableData.id,
              `${startFormatted}|${endFormatted}`
            );
          }
        }}
        renderInput={(startProps, endProps) => {
          let value = "";
          if (startProps.inputProps.value && endProps.inputProps.value) {
            const format = "DD/MMM/YYYY";
            const start = moment(startProps.inputProps.value);
            const end = moment(endProps.inputProps.value);
            value = `${start.format(format)} - ${end.format(format)}`;
          }
          return (
            <div style={{ position: "relative", display: "inline-block" }}>
              <TextField
                variant="standard"
                helperText="."
                // id="standard-basic"
                label=" "
                aria-readonly="false"
                inputProps={{
                  ...startProps.inputProps,
                  // readOnly: true,
                  placeholder: "DD/MMM/YYYY - DD/MMM/YYYY",
                  value: value,
                }}
                InputProps={{
                  readOnly: false,
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarToday />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
              {value && (
                <IconButton
                  style={{
                    position: "absolute",
                    top: ".5rem",
                    margin: "auto",
                    right: "2rem",
                  }}
                  onClick={() => {
                    setValue([null, null]);
                    props.onFilterChanged(props.columnDef.tableData.id, null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </div>
          );
        }}
      />
      {/* <StaticPicker
        displayStaticWrapperAs="desktop"
        value={value}
        onChange={(newValue) => setValue(newValue)}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Delimiter> to </Delimiter>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      /> */}

      {/* <DatePicker
        disableFuture
        label="Responsive"
        openTo="year"
        views={["year", "month", "date"]}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          props.onFilterChanged(props.columnDef.tableData.id, newValue);
        }}
        renderInput={(props) => {
          console.log(props);
          return <TextField {...props} />;
        }}
      /> */}
    </>
  );
};

const TableTransaccionesBusiness = ({ history }) => {
  let { currentToken } = useContext(AuthContext);
  const [columns, setcolumns] = useState([
    {
      title: "Punto de cobro",
      field: "puntoCobro.name",
    },
    {
      title: "Categoría",
      field: "category",
      render: (rowData) => translateCategory(rowData.category),
    },
    {
      title: "Moneda",
      field: "currency",
    },
    {
      title: "Monto",
      field: "amount",
    },
    {
      title: "F. creación",
      field: "createdAt",
      // filtering: false,
      filterComponent: (props) => <CustomDatePicker {...props} />,
      render: (rowData) =>
        rowData ? moment(rowData.createdAt).format("DD-MMM-YYYY HH:mm") : "",
    },
    {
      title: "Estado",
      field: "state",
      cellStyle: (rowData) => colorByTransactionState(rowData),
      render: (rowData) => translateState(rowData.state),
    },
  ]);
  const tableRef = useRef();
  const {
    transactionsTableRef,
    setTransactionsTableRef,
    setQuery,
  } = useContext(TransactionsContext);

  console.log("rederizo");
  useEffect(() => {
    if (
      JSON.stringify(transactionsTableRef) === "{}" ||
      transactionsTableRef === null
    ) {
      setTransactionsTableRef(tableRef);
    }
  }, []);

  const colorByTransactionState = (state) => {
    console.log(state);
    let color = "black";

    switch (state) {
      case "CANCELED":
        color = "red";
        break;
      case "CREATED":
        color = "rgb(55 135 235)";
        break;
      case "REQUESTED":
        color = "rgb(251 181 43)";
        break;
      case "COLLECTED":
        color = "rgb(251 181 43)";
        break;
      case "DELIVERED":
        color = "green";
        break;
      default:
        color = "black";
    }
    return { color };
  };

  return (
    <div id="container-historial-transacciones">
      <MaterialTable
        // tableRef={tableRef}
        localization={{
          ...translations,
          toolbar: {
            searchPlaceholder: "Estado, Moneda, Destino",
            searchTooltip: "Buscar. Para mas filtros ir a Reportes",
          },
        }}
        icons={icons}
        columns={columns}
        title={""}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            let params = "";
            // const { startDate, endDate } = customFilter;
            // params += `start=${startDate}&end=${endDate}`;
            if (query.search) {
              params += `&q=${query.search}`;
            }
            if (query.orderBy) {
              params += `&sort=${query.orderBy.field}&order=${query.orderDirection}`;
            }
            if (query.filters.length) {
              const filter = query.filters.map((filter) => {
                return `&${filter.column.field}${filter.operator}${filter.value}`;
              });
              params += filter.join("");
            }
            params += `&page=${query.page + 1}`;
            params += `&limit=${query.pageSize}`;

            let data = {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "same-origin", // include, *same-origin, omit
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentToken}`,
              },
            };
            let endpoint = "/business/transaction";
            let url = `${feanorapi}${endpoint}?${params}`;
            // setQuery(url);
            fetch(url, data)
              .then((response) => {
                console.log(response);
                if (response.ok) {
                  return response.json();
                } else {
                  throw { type: "non-available" };
                }
              })
              .then((responseJson) => {
                // if (responseJson.length !== 0) {
                resolve({
                  data: responseJson.transactions,
                  page: query.page,
                  totalCount: responseJson.totalRows,
                });
                // }
              });
          })
        }
        options={{
          filtering: true,
          search: false,
          debounceInterval: 700,
          // rowStyle: (rowData) => ({
          //   fontFamily: "Roboto",
          //   fontSize: "15px",
          // }),
        }}
      ></MaterialTable>
    </div>
  );
};

export default withRouter(TableTransaccionesBusiness);
