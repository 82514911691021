import React from "react";
import { Link } from "react-router-dom";
import { HashLink as LinkHash } from "react-router-hash-link";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import bottomLine from "media/lines/gold-line-bottom.png";
import topLine from "media/lines/gold-line-top.png";
import { TopLine, BottomLine } from "styles/common/index";

import Styled, { LinkClassName } from "./styles";
import { website } from "feanorapi";
library.add(fab);

const Footer = () => {
  return (
    <Styled.ContainerFooter>
      <TopLine src={topLine} />
      <Styled.FooterNav>
        <Styled.ContainerSectionFooter>
          <Styled.FooterHeading>Productos</Styled.FooterHeading>
          <Styled.FooterMenu>
            {/* <Styled.LinkHash href="http://google.com"> */}
            <Styled.LinkHash href={`${website}/wallet`}>Wallet</Styled.LinkHash>

            <Styled.LinkHash href={`${website}/empresas`}>
              Fëanor para empresas
            </Styled.LinkHash>
            <Styled.LinkHash href={`${website}/empresas#pasarela-pago`}>
              Pasarela de pago
            </Styled.LinkHash>
            <Styled.LinkHash href={`${website}/empresas#compra-criptoactivos`}>
              Compra de criptoactivos
            </Styled.LinkHash>
            <Styled.LinkHash
              href={`${website}/empresas#custodia-criptoactivos`}
            >
              Custodia de criptoactivos
            </Styled.LinkHash>
          </Styled.FooterMenu>
        </Styled.ContainerSectionFooter>
        <Styled.ContainerSectionFooter>
          <Styled.FooterHeading>Compañía</Styled.FooterHeading>
          <Styled.FooterMenu>
            <Styled.LinkFooter to={`${website}/quienes-somos`}>
              Nosotros
            </Styled.LinkFooter>
            <Styled.ContainerSocialLinks>
              <Styled.SocialLinkFooter
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink facebook"
              >
                <FontAwesomeIcon
                  color={"black"}
                  icon={["fab", "facebook-square"]}
                  className="iconFooter"
                />
              </Styled.SocialLinkFooter>
              <Styled.SocialLinkFooter
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink instagram"
              >
                <FontAwesomeIcon
                  color={"black"}
                  icon={["fab", "instagram"]}
                  className="iconFooter"
                />
              </Styled.SocialLinkFooter>
              <Styled.SocialLinkFooter
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink instagram"
              >
                <FontAwesomeIcon
                  color={"black"}
                  icon={["fab", "youtube"]}
                  className="iconFooter"
                />
              </Styled.SocialLinkFooter>
              <Styled.SocialLinkFooter
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink instagram"
              >
                <FontAwesomeIcon
                  color={"black"}
                  icon={["fab", "whatsapp"]}
                  className="iconFooter"
                />
              </Styled.SocialLinkFooter>
            </Styled.ContainerSocialLinks>
          </Styled.FooterMenu>
        </Styled.ContainerSectionFooter>
        <Styled.ContainerSectionFooter>
          <Styled.FooterHeading>Legal</Styled.FooterHeading>
          <Styled.FooterMenu>
            <Styled.LinkHash href={`${website}/terminos-y-condiciones`}>
              Terminos y condiciones
            </Styled.LinkHash>
            <Styled.LinkHash href={`${website}/politicas-kyc-aml`}>
              KYC
            </Styled.LinkHash>
          </Styled.FooterMenu>
        </Styled.ContainerSectionFooter>        
      </Styled.FooterNav>
      <Styled.FooterCopy>
        © 2022 Fëanor Corp. Todos los derechos reservados.
      </Styled.FooterCopy>
{/* 
      <Styled.FooterAviso>
      Fëanor y sus empresas filiales no son entidades reguladas ante el regulador financiero local. Sin perjuicio de lo anterior, mantenemos estándares de cumplimiento normativo basados en la regulación vigente en cada país que operamos, y estamos sujetos a la legislación pertinente en matería de protección de derechos del consumidor, tratamiento de datos e información privada y obligaciones tributarias, entre otras.

<br></br>Con el objetivo de que la industria se desarrolle en forma responsable, es importante que todos nuestros usuarios sepan que: i. Las criptomonedas no son una moneda de curso legal ni están respaldadas por un Banco Central; ii. Una vez completada debidamente una transferencia de criptomonedas, es imposible revertirla; iii. Las criptomonedas son activos cuyo precio es alcanzado exclusivamente por oferta y demanda, pudiendo ser altamente volátil; iv. Las criptomonedas están afectas a los riesgos tecnológicos, cibernéticos y de fraude inherentes a cualquier actividad transaccional virtual.
      </Styled.FooterAviso> */}
      <BottomLine src={bottomLine} />
    </Styled.ContainerFooter>
  );
};

export default Footer;
