import React from "react";
import {
  DataDelivered,
  DataCanceled,
  DataRequested,
  DataCreated,
} from "components/HistorialTransacciones/HorizontalView/styles";

export const typeOperation = (string) => {
  if (string === "send") return "Envio";
  if (string === "receive") return "Depósito";
  if (string === "deposit") return "Depósito";
  if (string === "convert" || string === "CONVERT") return "Conversión";
  if (string === "cardDeposit") return "Depósito";
  if (string === "withdraw" || string === "WITHDRAW") return "Retiro";
  if (string === "PLATFORM_FEE") return "C. plataforma";
  return string;
};

export const typeState = (string, wrapper = () => <spam></spam>) => {
  if (string === "DELIVERED") return <DataDelivered>Entregado</DataDelivered>;
  else if (string === "CANCELED") return <DataCanceled>Cancelado</DataCanceled>;
  else if (string === "REJECTED") return <DataCanceled>Rechazado</DataCanceled>;
  else if (string === "REQUESTED")
    return <DataRequested>Pendiente</DataRequested>;
  else if (string === "CREATED") return <DataCreated>Creado</DataCreated>;
  else if (string === "COLLECTED")
    return <DataRequested>Pendiente</DataRequested>;
  else return "";
};

export const cardState = (string) => {
  if (string === "REQUESTED") return "Solicitada";
  if (string === "SHIPPING") return "Enviando";
  else if (string === "ACTIVE") return "Activa";
  else if (string === "INACTIVE") return "Inactiva";
};

export const getColorByState = (string) => {
  if (string === "REQUESTED") return "#E67E22";
  if (string === "SHIPPING") return "#3498DB";
  else if (string === "ACTIVE") return "#229954";
  else if (string === "INACTIVE") return "#CB4335";
};

export const typeCategory = (string) => {
  if (string === "send") return "Envio";
  if (string === "receive") return "Depósito";
  if (string === "deposit") return "Depósito";
  if (string === "convert" || string === "CONVERT") return "Conversión";
  if (string === "cardDeposit") return "Abono";
  if (string === "withdraw") return "Retiro";
  if (string === "PLATFORM_FEE") return "Comisión de plataforma";
  if (string === "EARNING") return "Ganancia";
  return ""
};
