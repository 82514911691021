import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AuthContext } from "Auth";
import useGetCardMovimientos from "hooks/useGetCardMovimientos";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactTimeAgo from "react-time-ago";
import {
  fetchBusinessVisaTransactions,
  fetchMastercardTransactions,
  fetchVisaTransactions,
} from "services/card.service";
import swal from "sweetalert";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    maxHeight: 300,
    overflowY: "auto",
  },
  container: {
    marginTop: 30,
    minHeight: 300,
    maxHeight: 450,
    alignItems: "center",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid #ffffff",
    },
  },
});
export default function TableVisaTransactions({ cardId }) {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const classes = useStyles();
  const { userType } = useContext(AuthContext);

  const columns = [
    {
      Header: "Fecha",
      accessor: "FechaOrigen",
      Cell: ({ value }) => <TableCell>{value}</TableCell>,
    },
    {
      Header: "Tarjeta",
      accessor: "NumeroTarjeta",
      Cell: ({ value }) => <TableCell align="right">{value}</TableCell>,
    },
    {
      Header: "Concepto",
      accessor: "Concepto",
      Cell: ({ value }) => <TableCell align="right">{value}</TableCell>,
    },
    {
      Header: "Monto",
      accessor: "MtoTra",
      Cell: ({ value }) => <TableCell align="right">{value}</TableCell>,
    },
  ];

  useEffect(() => {
    const getTransactions = async () => {
      try {
        setLoading(true);
        let response;
        if (userType === "business") {
          response = fetchBusinessVisaTransactions({ cardId });
          return;
        } else {
          response = await fetchVisaTransactions({ cardId });
        }
        setTransactions(response.transactions);
      } catch (error) {
        console.log(error);
        // swal({
        //   text: "Hubo un problema al obtener los movimientos",
        //   button: false,
        //   timer: 3000,
        // });
      } finally {
        setLoading(false);
      }
    };
    getTransactions();
  }, []);

  return (
    <div>
      <Typography variant="h6">Movimientos utltimos 2 meses</Typography>

      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.container}
      >
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress color="primary" size={30} />
          </Grid>
        ) : transactions.length <= 0 ? (
          <Grid container justifyContent="center">
            <Typography variant="body1">
              No se encontraron movimientos
            </Typography>
          </Grid>
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.Header}>
                    <Typography variant="overline"> {column.Header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowY: "scroll" }}>
              {transactions.map((row) => {
                // const rawDate = row.c7FechaHoraTransaccion;
                // const year = rawDate.substring(0, 4);
                // const month = rawDate.substring(4, 6);
                // const day = rawDate.substring(6, 8);
                // const hours = rawDate.substring(8, 10);
                // const minutes = rawDate.substring(10, 12);
                // const seconds = rawDate.substring(12, 14);

                // const formattedDate = new Date(
                //   `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
                // );
                return (
                  <TableRow key={row.CodTra}>
                    <TableCell align="left" component="th" scope="row">
                      {/* <ReactTimeAgo date={row.FechaOrigen} locale="es" /> */}
                      {/* {`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`} */}
                      {row.FechaOrigen}
                    </TableCell>
                    <TableCell align="left">{row.NumeroTarjeta}</TableCell>
                    <TableCell align="left">{row.Concepto}</TableCell>
                    <TableCell align="left">
                      {`$ ${parseFloat(row.MtoTra)}`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
}
