import React from "react";
import doneImage from "images/done.svg";
import Styled from "./styles";
const SuccessMessage = ({ message }) => {
  return (
    <div>
      <Styled.doneImg src={doneImage} alt="" />
      <Styled.doneTxt>{message}</Styled.doneTxt>
    </div>
  );
};

export default SuccessMessage;
