import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetWallets from "hooks/useGetWallets";
import React from "react";
import {
  WalletContent,
  WalletBoxLeft,
  TitleBox,
  ListRowDiv,
  ListColumnDiv,
  ColText,
  ColStatus,
  ColTextLight,
  ColButton,
  BtnPrimary,
  BtnSecondary,
} from "styles/Styled";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import useGetSecondaryWallets from "hooks/useGetSecondaryWallets";
import { createSecondaryWallet } from "services/wallet.service";
import { useContext } from "react";
import { AuthContext } from "Auth";
import ReactTimeAgo from "react-time-ago";
import swal2 from "sweetalert2";

export default function SecondaryWallets() {
  const { wallets, loading, getWallets } = useGetSecondaryWallets();
  let { currentToken } = useContext(AuthContext);

  const createSecWallet = async () => {
    try {
      const response = await createSecondaryWallet(currentToken);
      await getWallets();
      swal2.fire({
        title: "Direccion BTC creada con exito",
        text: "",
        timer: 3000,
        showConfirmButton: false,
        icon: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <WalletContent id="wallets">
      <WalletBoxLeft>
        <TitleBox style={{ justifyContent: "space-between" }}>
          Billeteras Secundarias{" "}
          <BtnPrimary style={{ fontSize: "14px" }} onClick={createSecWallet}>
            Crear billetera BTC
          </BtnPrimary>
        </TitleBox>

        <ListRowDiv>
          <ListColumnDiv>
            <ColTextLight>Moneda</ColTextLight>
          </ListColumnDiv>
          <ListColumnDiv>
            <ColTextLight> F. creación </ColTextLight>
          </ListColumnDiv>

          <ListColumnDiv>
            <ColTextLight> Dirección </ColTextLight>
          </ListColumnDiv>

          <ListColumnDiv>
            <ColText> </ColText>
          </ListColumnDiv>
        </ListRowDiv>

        {loading && <div style={{ textAlign: "center" }}>Cargando...</div>}

        {wallets.map((wallet) => (
          <ListRowDiv>
            <ListColumnDiv style={{ textAlign: "center" }}>
              <ColText> {wallet.currency} </ColText>
            </ListColumnDiv>

            <ListColumnDiv style={{ textAlign: "center" }}>
              <ColText>
                {" "}
                <ReactTimeAgo date={wallet.createdAt} locale="es" />{" "}
              </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColStatus
                style={{ textTransform: "none" }}
              >{`${wallet.address.slice(0, 10)} ... ${wallet.address.slice(
                wallet.address.length - 5,
                wallet.address.length
              )}`}</ColStatus>
            </ListColumnDiv>

            <ListColumnDiv>
              <BtnSecondary
                style={{
                  fontSize: "13px",
                  margin: 0,
                }}
                onClick={() => {
                  navigator.clipboard.writeText(wallet.address);
                  swal2.fire({
                    title: "Dirección copiada",
                    text: "",
                    timer: 1000,
                    showConfirmButton: false,
                    icon: "success",
                  });
                }}
              >
                Copiar
              </BtnSecondary>
            </ListColumnDiv>
          </ListRowDiv>
        ))}
        {/* {walletUSDT.found && (
          <ListRowDiv>
            <ListColumnDiv>
              <ColText> Tether ETH</ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColText>₮ {`${walletUSDT.balance} `} </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColStatus>
                ₮{" "}
                <span data-testid="usdtbalance">{`${walletUSDT.balance} `}</span>
              </ColStatus>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColButton
                to={`/app/profile/usdt`}
                title="Ver detalle de billetera"
              >
                {" "}
                <FontAwesomeIcon icon={faEye} /> Ver{" "}
              </ColButton>
            </ListColumnDiv>
          </ListRowDiv>
        )}

        <ListRowDiv>
          <ListColumnDiv>
            <ColText> Tether TRON </ColText>
          </ListColumnDiv>

          <ListColumnDiv>
            <ColText>₮ {`${walletUSDTTron.balance} `} </ColText>
          </ListColumnDiv>

          <ListColumnDiv>
            <ColStatus>
              ₮{" "}
              <span data-testid="usdtbalance">{`${walletUSDTTron.balance} `}</span>
            </ColStatus>
          </ListColumnDiv>

          <ListColumnDiv>
            <ColButton
              to={`/app/profile/usdt_tron`}
              title="Ver detalle de billetera"
            >
              {" "}
              <FontAwesomeIcon icon={faEye} /> Ver{" "}
            </ColButton>
          </ListColumnDiv>
        </ListRowDiv> */}
      </WalletBoxLeft>
    </WalletContent>
  );
}
