import React, { useContext } from "react";
import useGetQuote from "hooks/useGetQuote";
import useGetWallets from "hooks/useGetWallets";
import currencyTypes from "models/currencies";
import operationTypes from "models/operations";
import {
  AmountCurrency,
  BtnPrimary,
  BtnSecondary,
  ButtonContent,
  DescriptionBox,
  DisplayCount,
  TextSaldo,
  TitleBox,
  WalletBoxLeft,
  WalletBoxRight,
  WalletContent,
} from "styles/Styled";
import { createUSDTTronWallet } from "services/wallet.service";
import { AuthContext } from "Auth";
import useModal from "hooks/useModal";
import { PrimaryText, SecondaryText } from "styles/colorized";
import FormReceiveUSDTTron from "./FormReceiveUSDTTron";
import useGetUserInfo from "hooks/useGetUser";
import swal from "sweetalert";
import { Redirect, useHistory } from "react-router";
import constants from "utils/constants";
import { internal } from "routes";
import FormSendUSDTTron from "./FormSendUSDTTron";

const BalanceRow = ({ refreshTransactions }) => {
  const history = useHistory();
  const { currentToken } = useContext(AuthContext);
  const { userInfo } = useGetUserInfo(currentToken);
  const [
    ModalSend,
    isOpenModalSend,
    openModalSend,
    onCloseModalSend,
  ] = useModal();
  const [
    ModalReceive,
    isOpenModalReceive,
    openModalReceive,
    onCloseModalReceive,
  ] = useModal();
  const { walletUSDTTron, getWallets } = useGetWallets();
  const { rates } = useGetQuote({
    amount: 100,
    from: currencyTypes.USDT,
    to: currencyTypes.USD,
    operationType: operationTypes.sell,
  });

  const createWallet = async () => {
    // if (!allowedUsers.includes(userInfo.email)) {
    //   swal({
    //     title: "Funcionalidad no disponible",
    //     text:
    //       "Esta funcionalidad no esta disponible para su cuenta, porfavor contacte con soporte para mas informacion",
    //     buttons: {
    //       ok: {
    //         text: "Entendido!",
    //       },
    //     },
    //   });
    //   return;
    // }
    if (
      userInfo.kycVerificationStatus !== constants.verificationStatus.verified
    ) {
      swal({
        title: "Validacion de KYC necesaria",
        text:
          "Porfavor complete el proceso de KYC para poder contar con una billetera USDT, se le redirigira a la pagina de validacion KYC",
        buttons: {
          ok: {
            text: "Entendido!",
          },
        },
      });
      return history.push(internal.validationPage);
    }

    const wallet = await createUSDTTronWallet(currentToken);
    if (wallet) {
      await getWallets();
    }
  };

  const handleClickButtonSend = () => {
    if (
      userInfo.kycVerificationStatus !== constants.verificationStatus.verified
    ) {
      swal({
        text:
          "Para hacer envios es necesario la verificación de cuenta, te redirireccionaremos al proceso de validacion.",
        button: false,
        timer: 4000,
      });
      setTimeout(function() {
        history.push(`/app/profile/activation`);
      }, 4000);
      return;
    }
    openModalSend();
  };

  return (
    <>
      <ModalSend
        open={isOpenModalSend}
        onClose={onCloseModalSend}
        center
        closeOnOverlayClick={false}
      >
        <FormSendUSDTTron
          walletUSDTTron={walletUSDTTron}
          refreshWallets={getWallets}
          refreshTransactions={refreshTransactions}
          onCloseModalSend={onCloseModalSend}
        />
      </ModalSend>

      <ModalReceive
        open={isOpenModalReceive}
        onClose={onCloseModalReceive}
        center
        closeOnOverlayClick={false}
      >
        <FormReceiveUSDTTron walletUSDTTronAddress={walletUSDTTron.address} />
      </ModalReceive>

      <TextSaldo>
        <PrimaryText>
          Tienes un saldo total de{" "}
          <AmountCurrency>{walletUSDTTron.balance} USDT (TRON)</AmountCurrency>,
          y la tasa de venta es 1 USDT (TRON) = {rates.USDTUSDSell} USD
        </PrimaryText>
      </TextSaldo>

      <WalletContent>
        <WalletBoxLeft>
          <TitleBox>Saldo</TitleBox>
          <DisplayCount>{walletUSDTTron.balance} USDT (TRON)</DisplayCount>
        </WalletBoxLeft>

        <WalletBoxRight>
          {walletUSDTTron.found && (
            <DescriptionBox>
              Tienes disponible <b>{walletUSDTTron.balance} USDT (TRON) </b>{" "}
              para enviar
            </DescriptionBox>
          )}

          {walletUSDTTron.found && (
            <ButtonContent>
              <BtnPrimary onClick={handleClickButtonSend}>Enviar</BtnPrimary>
              <BtnSecondary onClick={openModalReceive}>Recibir</BtnSecondary>
            </ButtonContent>
          )}

          {!walletUSDTTron.found && (
            <DescriptionBox>
              Aún no tienes una billetera. Creala ahora mismo con un clic.
            </DescriptionBox>
          )}

          {!walletUSDTTron.found && (
            <ButtonContent>
              <BtnPrimary onClick={createWallet}>Crear</BtnPrimary>
            </ButtonContent>
          )}
        </WalletBoxRight>
      </WalletContent>
    </>
  );
};

export default BalanceRow;
