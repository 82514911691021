import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "Auth";
import SaldoDoughnuts from "./charts/SaldoDoughnut";
import {
  TextSaldo,
  AmountCurrency,
  TextCuponAviso,
  TextCuponAvisoVenc,
  WalletContent,
  WalletBox,
  WalletBoxLeft,
  WalletBoxRight,
  TitleBox,
  DisplayCount,
  GraphContent,
  DescriptionBox,
  ButtonContent,
  BtnPrimary,
  BtnSecondary,
  TitleModal,
  BodyModal,
  FooterModal,
  InputModal,
  LabelModal,
  LabelSpan,
  LabelSpanAlert,
  BtnPrimaryLg,
  ListLinks,
  ListLinksItem,
  ImageModal,
  TextBox,
  BtnSecondaryCenter,
  ListRowDiv,
  ListColumnDiv,
  ColText,
  ColStatus,
  DetailsOperation,
  DetailTitle,
  DetailAmount,
  Clear,
  Margin20,
  ColState,
  InputContainer,
  LoginButton,
  BtnSeeMore,
} from "styles/Styled";
import ObjectCalculator from "./object-calculator/ObjectCalculator";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";
import { PrimaryText, SecondaryText } from "styles/colorized";
import { Header2, Paragraph } from "styles/common/index.js";

import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import iconSendBTC from "media/gif/bitcoin_bounce.gif";
import iconReceiveBTC from "media/gif/bitcoin.gif";

import WAValidator from "wallet-address-validator";
import ReactTimeAgo from "react-time-ago";
import { Link, useHistory } from "react-router-dom";

import { getQuote } from "components/object-calculator/helper.js";

// import ReactGA from 'react-ga';

import analyticsCode from "analyticsCode.js";
import QRAddress from "./QRAddress";

import swal2 from "sweetalert2";
import { IconButton } from "@material-ui/core";
import { Icon } from "@iconify/react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import constants from "utils/constants";
import FormRequestOTP from "./FormRequestOTP";
import useSendBTC from "hooks/useSendBTC";
import { LinkAyuda } from "./LinkAyuda";
import { debounce } from "lodash";
import { useReCaptcha } from "hooks/useReCaptcha";

const SaldoBTC = () => {
  let history = useHistory();

  const [totalCompra, setTotalCompra] = useState(0);
  const [totalVenta, setTotalVenta] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [calculatorObj, setCalculatorObj] = useState(undefined);
  const [btcValueinUSD, setBTCValue] = useState(0);
  const [btcToUSD, setBtcToUSD] = useState(0);
  const [rateBTCSell, setRateBTCSell] = useState(0);

  const [user, setDataUser] = useState({});
  const [couponObj, setCoupon] = useState({});
  const [cuponValue, setCuponValue] = useState(0);

  let { currentToken } = useContext(AuthContext);

  /* Wallet */

  // Data
  const [addressBtc, setAddressBtc] = useState("----");
  const [transactionsData, setTransactionsData] = useState([]);

  // Modals
  const [openReceive, setOpenReceive] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [sendTo, setSendTo] = useState("");
  const [sendAmount, setSendAmount] = useState("0.0000");
  const [sendAmountUSD, setSendAmountUSD] = useState("0.00");
  const [feePerOperation, setFeePerOperation] = useState(0.0);

  // For statuses
  const [haveWallet, setHaveWallet] = useState(false);
  const [haveTransactions, setHaveTransactions] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);

  const [showQR, setShowQR] = useState(false);
  const { ReCaptcha, captcha, onChangeCaptcha } = useReCaptcha();

  /* refactors */
  const {
    loading,
    success,
    setSuccess,
    transferBTC,
    runValidations,
  } = useSendBTC();
  /*For OTP validation */
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [otpToken, setOtpToken] = useState("");

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(addressBtc);
    swal2.fire({
      title: "Dirección copiada",
      text: "",
      timer: 1000,
      showConfirmButton: false,
      icon: "success",
    });
  };

  const calculatorObserver = (data) => {
    setBTCValue(data.ammounts[1]);
  };

  const createWallet = async () => {
    const jsonBody = JSON.stringify({ currency: "BTC" });

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: jsonBody,
    };
    let endpoint = "/client/wallet";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          getWallet();
          //swal({text: "Billetera creada correctamente. ¡Disfruta de los pagos del futuro!", button: true, timer: 3000});
          return response.json();
        } else {
          //swal({text: "Ha habido un error al crear tu billetera. Si el error persiste escríbenos a Soporte.", button: false, timer: 5000});
        }
      })
      .catch((error) => {
        //swal({text: "Ha habido un error al crear tu billetera. Si el error persiste escríbenos a Soporte.", button: false, timer: 5000});
      });
  };

  const checkAddressBTC = async (address) => {
    console.log("Verificando dirección BTC...");

    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet/isInternalClient/" + address;
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.client == true) {
          setFeePerOperation(0.00015);
        } else {
          setFeePerOperation(0.001);
        }
      })
      .catch((error) => {
        console.log("Error al verificar la direccion BTC...");
        setFeePerOperation(0.001);
      });
  };

  const getTransactions = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet/transaction?currency=BTC&limit=5";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        if (responseJson) {
          setTransactionsData(responseJson);
          setHaveTransactions(true);
        } else {
          // No billetera
          setHaveTransactions(false);
        }
      })
      .catch((error) => {
        swal({
          text:
            "Ha ocurrido un error mientras cargabamos los datos de tus transacciones",
          button: false,
          timer: 5000,
        });
      });
  };

  const calculateQuote = async (balance) => {
    const quote = await getQuote(balance.toFixed(8), "BTC", "USDT", "SELL");

    if (quote.ammounts) {
      setBtcToUSD(quote.ammounts[1]);
      setRateBTCSell(quote.rates.BTCUSDTSell);
    }
  };

  const calculateSendAmount = async (amount) => {
    const quote = await getQuote(amount.toFixed(8), "BTC", "USD", "SELL");

    if (quote.ammounts) {
      setSendAmountUSD(quote.ammounts[1]);
    }
  };

  const getWallet = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          createWallet();
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        console.log(responseJson);

        const walletData = responseJson;

        // Si tiene billetera
        if (walletData) {
          console.log("Wallets detectadas.");

          walletData.forEach(function (item) {
            console.log(item);
            if (item.currency == "BTC") {
              console.log("Wallet BTC detectada");

              setHaveWallet(true);
              setAddressBtc(item.address);
              if (item.balance != undefined && item.balance != null) {
                setSaldo(item.balance.toFixed(8));
                // Calcular cotización
                calculateQuote(item.balance);
              }
              getTransactions();
            }
          });
        } else {
          // No billetera
          setHaveWallet(false);
          createWallet();
        }
      })
      .catch((error) => {
        //createWallet();

        swal({
          text: "Hubo un problema al obtener tu billetera de Bitcoin",
          button: false,
          timer: 3000,
        });
      });
  };

  const getUser = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/user";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        setDataUser(responseJson);
        setSuperAdmin(responseJson.isSuperAdmin);
        /*if(responseJson.hasRegisterCoupon){
            getCoupon(responseJson.registerCouponId);
          }*/
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    if (success) {
      swal({
        text: `¡Se enviaron correctamente ${sendAmount} BTC a la direccion indicada! :D`,
        button: true,
        timer: 7000,
      });
      console.log("call wallet");
      getWallet();
      onCloseModalSend();
    }

    return () => {
      setSuccess(false);
      setShowOTPForm(false);
    };
  }, [success]);

  useEffect(() => {
    // Call Wallet
    getWallet();
    getUser();
  }, []);

  const onOpenModalReceive = () => {
    setOpenReceive(true);
  };

  const onCloseModalReceive = () => {
    setOpenReceive(false);
  };

  const onOpenModalSend = () => {
    if (user.kycVerificationStatus !== constants.verificationStatus.verified) {
      swal({
        text:
          "Para hacer envios es necesario la verificación de cuenta, te redirireccionaremos al proceso de validacion.",
        button: false,
        timer: 4000,
      });
      setTimeout(function () {
        history.push(`/app/profile/activation`);
      }, 4000);
      return;
    }
    setOpenSend(true);
  };

  const onCloseModalSend = () => {
    setOpenSend(false);
  };

  const typeOperation = (string) => {
    if (string == "send") return "Envio";
    if (string == "receive") return "Depósito";
    if (string == "convert") return "Conversión";
    if (string == "PLATFORM_FEE") return "C. Plataforma";
  };

  const typeState = (string) => {
    if (string == "DELIVERED") return "Entregado";
    else if (string == "CANCELED") return "Cancelado";
    else if (string == "REQUESTED") return "Pendiente";
    else if (string == "CREATED") return "Creado";
    else return "Creado";
  };

  const maxSendValue = () => {
    setSendAmount((saldo - feePerOperation).toFixed(8));
    calculateSendAmount(parseFloat(saldo));
  };

  const onHandleAddressChange = (e) => {
    let addressInput = e.target.value;
    setSendTo(addressInput);

    if (addressInput != "" && WAValidator.validate(addressInput, "BTC")) {
      checkAddressBTC(addressInput);
    } else {
      setFeePerOperation(0.001);
    }
  };

  const onHandleAmountBTCChange = (e) => {
    const btcBalance = parseFloat(saldo);
    let amount =
      e.target.value.charAt(0) === "." ? "0" + e.target.value : e.target.value;
    let amountFixed =
      amount.length > 10 ? parseFloat(amount).toFixed(8) : amount;

    if (amountFixed >= btcBalance) {
      maxSendValue();
      return;
    }

    calculateSendAmount(parseFloat(amountFixed));
    setSendAmount(amountFixed);
    // if (amount.length <= 10) {
    //   calculateSendAmount(parseFloat(amount));
    //   setSendAmount(amount);
    // } else {
    //   return false;
    // }

    // if (amount <= 1) {
    //   calculateSendAmount(parseFloat(amount));
    //   setSendAmount(amount);
    // } else {
    //   calculateSendAmount(parseFloat(parseFloat(1)));
    //   setSendAmount(1);
    // }
  };

  const onKeyPressBTC = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  const onKeyPressUSD = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  return (
    <>
      {saldo !== undefined ? (
        <>
          <TextSaldo>
            <PrimaryText>
              Tienes un saldo total de{" "}
              <AmountCurrency>{saldo} BTC</AmountCurrency>, y la tasa de venta
              es 1 BTC = {rateBTCSell} USDT
            </PrimaryText>
          </TextSaldo>

          <WalletContent>
            <WalletBoxLeft>
              <TitleBox>Saldo</TitleBox>
              <DisplayCount>{saldo} BTC</DisplayCount>
            </WalletBoxLeft>

            <WalletBoxRight>
              {haveWallet && (
                <DescriptionBox>
                  Tienes disponible <b>{saldo} BTC </b> para enviar
                </DescriptionBox>
              )}

              {haveWallet && (
                <ButtonContent>
                  <BtnPrimary onClick={onOpenModalSend}>Enviar</BtnPrimary>
                  <BtnSecondary onClick={onOpenModalReceive}>
                    Recibir
                  </BtnSecondary>
                </ButtonContent>
              )}

              {!haveWallet && (
                <DescriptionBox>
                  Aún no tienes una billetera. Creala ahora mismo con un clic.
                </DescriptionBox>
              )}

              {!haveWallet && (
                <ButtonContent>
                  <BtnPrimary onClick={createWallet}>Crear</BtnPrimary>
                </ButtonContent>
              )}
            </WalletBoxRight>
          </WalletContent>

          <WalletContent>
            <WalletBoxLeft>
              <TitleBox>Últimas operaciones</TitleBox>

              {Object.keys(transactionsData).length <= 0 && (
                <TextBox>
                  No existen operaciones por el momento :(. Comienza a comprar o
                  depositar.
                </TextBox>
              )}

              {transactionsData.map((transaccion) => {
                const transactionObj = { ...transaccion };
                //const date = new Date(transactionObj.createdAt);

                let amount = transactionObj.amount.toFixed(8);
                if (transactionObj.category == "convert")
                  amount = transactionObj.ammounts[1].toFixed(8);

                return (
                  <ListRowDiv key={transactionObj._id}>
                    <ListColumnDiv>
                      <ColText>{`${amount} BTC`} </ColText>
                    </ListColumnDiv>

                    <ListColumnDiv>
                      <ColText>
                        {" "}
                        <ReactTimeAgo
                          date={transactionObj.createdAt}
                          locale="es"
                        />{" "}
                      </ColText>
                    </ListColumnDiv>

                    <ListColumnDiv>
                      <ColStatus>{`${typeOperation(
                        transactionObj.category
                      )}`}</ColStatus>
                    </ListColumnDiv>

                    <ListColumnDiv>
                      {transactionObj.tx == "external" ? (
                        <ColState>
                          <a
                            target="_blank"
                            href={`https://www.blockchain.com/es/btc/tx/${transactionObj.tx}`}
                            title="Visualizar la transacción en la Blockchain Explorer"
                          >{`${typeState(transactionObj.state)}`}</a>
                        </ColState>
                      ) : (
                        <ColState title="Este es el estado en el que se encuentra la operación">{`${typeState(
                          transactionObj.state
                        )}`}</ColState>
                      )}
                    </ListColumnDiv>
                  </ListRowDiv>
                );
              })}

              {Object.keys(transactionsData).length >= 4 && (
                <BtnSeeMore to={`/app/profile/operations`}>Ver más</BtnSeeMore>
              )}
            </WalletBoxLeft>
            <WalletBoxRight>
              <TitleBox>Conoce más</TitleBox>

              <ListLinks>
                {/* <ListLinksItem>
                  <Link to={`/app/operation/new`}>
                    ¿Sin fondos? Comprar Bitcoin
                  </Link>
                </ListLinksItem> */}
                <ListLinksItem>
                  <a target="_blank" href="#">
                    Comisiones de envio y retiro
                  </a>
                </ListLinksItem>
                <LinkAyuda />
              </ListLinks>
            </WalletBoxRight>
          </WalletContent>

          {Object.keys(couponObj).length > 0 && !couponObj.used && (
            <TextCuponAviso>
              <PrimaryText>
                <b>
                  ¡Actualmente tienes un cupón de regalo para compra! <br />{" "}
                  Aprovéchalo en tu primera compra, se te brindará un monto
                  adicional de Bitcoin. :D
                </b>
              </PrimaryText>
            </TextCuponAviso>
          )}

          {Object.keys(couponObj).length > 0 && couponObj.used && (
            <Paragraph>
              <b>
                Tu cupon de registro ha sido usado exitosamente en tu primera
                compra. Pronto más regalos y novedades. ¡Gracias!
              </b>
            </Paragraph>
          )}

          <Paragraph>
            <AmountCurrency>*</AmountCurrency> Equivalente a su conversión con
            la tasa de VENTA vigente e incluye las comisiones de transacción de
            Feanor.
          </Paragraph>
        </>
      ) : (
        <>
          <Paragraph>
            Debes realizar una compra de bitcoins con Fëanor para poder
            visualizar tu saldo disponible.
          </Paragraph>
        </>
      )}

      <Modal
        open={openSend}
        onClose={() => {
          onCloseModalSend();
          setShowOTPForm(false);
        }}
        center
        closeOnOverlayClick={false}
      >
        <BodyModal>
          <TitleModal>Enviar Bitcoin</TitleModal>
          <ImageModal src={iconSendBTC} />

          {showOTPForm ? (
            <FormRequestOTP
              errorMsg={otpErrorMsg}
              otpToken={otpToken}
              setOtpToken={setOtpToken}
              buttonTitle="Enviar Bitcoins"
              buttonTitleLoading="Enviando..."
              loading={loading}
              onSubmit={debounce(async () => {
                await transferBTC(
                  saldo,
                  sendTo,
                  feePerOperation,
                  sendAmount,
                  otpToken,
                  (error) => {
                    if (
                      error.response &&
                      error.response?.data?.code === "invalid-otp"
                    ) {
                      setOtpErrorMsg("Codigo de verificación inválido.");
                      return;
                    }
                  }
                );
              }, 700)}
            ></FormRequestOTP>
          ) : (
            <>
              <LabelModal>
                <LabelSpan>Dirección de la billetera destino</LabelSpan>
                <InputModal
                  type="text"
                  value={sendTo}
                  onChange={(e) => onHandleAddressChange(e)}
                />
              </LabelModal>

              <LabelModal>
                <LabelSpan>
                  Monto a enviar (Disponible: <b>{saldo} BTC</b>).{" "}
                  <a href="#" onClick={() => maxSendValue()}>
                    Usar todo
                  </a>
                </LabelSpan>
                <InputModal
                  type="number"
                  min="0"
                  value={sendAmount}
                  onKeyPress={(e) => onKeyPressBTC(e)}
                  onChange={(e) => onHandleAmountBTCChange(e)}
                />
              </LabelModal>
              <LabelModal>
                <InputModal
                  type="number"
                  min="0"
                  value={sendAmountUSD}
                  disabled
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "25%",
                    color: "black",
                  }}
                >
                  USD
                </span>
              </LabelModal>

              <Margin20></Margin20>

              <DetailsOperation>
                <DetailTitle>Monto a enviar</DetailTitle>
                <DetailAmount>{sendAmount} BTC</DetailAmount>
              </DetailsOperation>

              <Clear></Clear>

              <DetailsOperation>
                <DetailTitle>Comisión</DetailTitle>
                <DetailAmount>{feePerOperation.toFixed(5)} BTC</DetailAmount>
              </DetailsOperation>

              <Clear></Clear>

              <DetailsOperation>
                <DetailTitle>Monto total</DetailTitle>
                <DetailAmount>
                  {(Number(sendAmount) + Number(feePerOperation)).toFixed(8)}{" "}
                  BTC
                </DetailAmount>
              </DetailsOperation>

              <Margin20></Margin20>
              <Margin20></Margin20>

              <ReCaptcha onChangeCaptcha={onChangeCaptcha} />
              <LoginButton
                disabled={loading}
                onClick={debounce(async () => {
                  const { errorFound } = runValidations(
                    {
                      balance: saldo,
                      toAddress: sendTo,
                      feePerOperation,
                      amount: sendAmount,
                      isUserSuperAdmin: user.isSuperAdmin,
                      captcha,
                    }
                  );
                  if (errorFound) return;

                  if (user.otpEnabled) {
                    setShowOTPForm(true);
                    return;
                  }

                  await transferBTC(
                    saldo,
                    sendTo,
                    feePerOperation,
                    sendAmount,
                    otpToken
                  );
                }, 700)}
              >
                {loading ? "Enviando.." : "Enviar Bitcoins"}
              </LoginButton>
            </>
          )}

          <FooterModal>
            <b>Importante:</b> Asegúrese de introducir correctamente los datos
            de la dirección destino (lo ideal es copiar y pegar). Fëanor no se
            hace responsable de las monedas enviadas a una dirección equivocada.
          </FooterModal>
        </BodyModal>
      </Modal>

      <Modal
        open={openReceive}
        onClose={onCloseModalReceive}
        center
        closeOnOverlayClick={true}
      >
        <BodyModal>
          {showQR ? (
            <QRAddress walletAddress={addressBtc} setShowQR={setShowQR} />
          ) : (
            <div>
              <TitleModal>Recibir Bitcoin</TitleModal>

              <ImageModal src={iconReceiveBTC} />

              <LabelModal>
                <LabelSpan>Dirección de tu billetera</LabelSpan>
                <InputContainer>
                  <IconButton>
                    <FileCopyIcon onClick={() => onCopyToClipboard()} />
                  </IconButton>
                  <IconButton onClick={() => setShowQR(true)}>
                    <Icon icon="mdi:qrcode" />
                  </IconButton>
                  <InputModal readOnly type="text" value={addressBtc} />
                </InputContainer>
              </LabelModal>

              <FooterModal>
                <b>Importante:</b> Fëanor no se hace responsable de las monedas
                enviadas a una dirección equivocada. Esta es tu dirección que
                debes brindar para recibir bitcoins. Después de realizar un
                depósito puede hacer el seguimiento del progreso en la página{" "}
                <b>Operaciones</b>.
              </FooterModal>
            </div>
          )}
        </BodyModal>
      </Modal>
    </>
  );
};

export default SaldoBTC;
