import React, { useContext, useState, useEffect } from "react";
import running_image from "media/icon-running@2x.png";
import arrow_down from "media/arrow-down@2x.png";
import question_mark from "media/question-mark@2x.png";

import {
  Tab,
  SelectCurrency,
  AmountCurrency,
  AmountText,
  StyledSelect,
} from "styles/Styled";

import { Paragraph } from "styles/common/index.js";

import {
  Visor,
  VisorPrecio,
  HeadlineTitle,
  BalanceFIATTitle,
  ArrowDown,
  ArrowUp,
  EtiquetaPrecioCrypto,
  EtiquetaPrecioFiat,
  EtiquetaNombre,
  IngresoPrecio,
  FooterCalculator,
  TitleCalculator,
  CalculatorContainer,
  ContainerMinMax,
  MinMaxElement,
  CalculatorTabs,
  Icon,
  IconClickable,
  Convers,
  SmallLetters,
  TermsLink,
} from "./styles";

import Select from "react-select";

import { ExchangeContext } from "ExchangeContext";
import { AuthContext } from "Auth";
import app, { database } from "base";
import feanorapi from "feanorapi.js";
import Catcher from "./Catcher";

import ObjectCalculator from "components/object-calculator/ObjectCalculator";
import { CurrencyContext } from "CurrencyContext";
import { website } from "feanorapi";
import Sell from "./Sell";
import Buy from "./Buy";
import AvailableBalance from "./common/AvailableBalance";

const initialConvertedLimits = {
  btc: {
    sell: [0, 0],
    buy: [0, 0],
  },
  usd: {
    sell: [0, 0],
    buy: [0, 0],
  },
  pen: {
    sell: [0, 0],
    buy: [0, 0],
  },
};

const Calculator = ({ selected }) => {
  const [calculatorObj, setCalculatorObj] = useState(undefined);
  const { currentToken } = useContext(AuthContext);
  const [menuFiat, setMenuFiat] = useState(false);
  const [menuCrypto, setMenuCrypto] = useState(false);
  //* ******************************//
  //* ******************************//
  //* ******************************//
  const [calculatorViewer, setCalculatorViewer] = useState({
    types: ["IN", "OUT"],
    ammounts: [0.021, 0.467],
    totalAmounts: [0, 0],
    commission: { usd: 0 },
    currencies: ["BTC", "USD"],
    transactionType: "BUY", // SELL/BUY
    rates: {
      BTCUSDBuy: 0.0, // This is shown on landing
      BTCUSDSell: 0.0,
      BTCPENBuy: 0.0,
      USDPENBuy: 0.0,
      USDPENSell: 0.0,
    },
  });
  const [inputA, setInputA] = useState(0);
  const [inputB, setInputB] = useState(0);
  const [limits, setLimits] = useState(initialConvertedLimits);
  //* ******************************//
  //* ******************************//
  //* ******************************//

  const valuePenVenta = useContext(ExchangeContext);
  const valuePenCompra = valuePenVenta - 0.07;

  const [zzComisionBTC, setZzComisionBTC] = useState(0);
  const [zzComisionEnvio, setZzComisionEnvio] = useState(0);

  // Coupons
  const [user, setDataUser] = useState({});
  const [couponObj, setCoupon] = useState({});
  const [cuponValue, setCuponValue] = useState(0);

  // Balances
  const [balanceBTC, setBalanceBTC] = useState(0);
  const [balancePEN, setBalancePEN] = useState(0);
  const [balanceUSD, setBalanceUSD] = useState(0);
  const [balanceUSDT, setBalanceUSDT] = useState(0);

  const [currentCrypto, setCurrentCrypto] = useState("BTC");

  /* ======= COMPRA - VENTA ======= */

  const handleBtnCompra = () => {
    calculatorObj.setBUY();
    const btnVenta = document.querySelector(".btn-venta");
    const btnCompra = document.querySelector(".btn-compra");
    btnVenta.classList.remove("active-tab");
    btnVenta.style.cursor = "pointer";
    btnCompra.classList.add("active-tab");
    btnCompra.style.cursor = "default";
  };

  const handleBtnVenta = () => {
    calculatorObj.setSELL();
    const btnVenta = document.querySelector(".btn-venta");
    const btnCompra = document.querySelector(".btn-compra");
    btnCompra.classList.remove("active-tab");
    btnCompra.style.cursor = "pointer";
    btnVenta.classList.add("active-tab");
    btnVenta.style.cursor = "default";
  };

  /* ======= DOLARES - SOLES ======= */

  const handleBtnDolares = () => {
    calculatorObj.setUSD();
    const btnDolares = document.querySelector(".btn-dolares");
    const btnSoles = document.querySelector(".btn-soles");
    btnSoles.classList.remove("activeBtn");
    btnSoles.style.cursor = "pointer";
    btnDolares.classList.add("activeBtn");
    btnDolares.style.cursor = "default";
  };

  const handleBtnSoles = () => {
    calculatorObj.setPEN();
    const btnDolares = document.querySelector(".btn-dolares");
    const btnSoles = document.querySelector(".btn-soles");
    btnDolares.classList.remove("activeBtn");
    btnDolares.style.cursor = "pointer";
    btnSoles.classList.add("activeBtn");
    btnSoles.style.cursor = "default";
  };

  const calculatorObserver = async (data) => {
    setCalculatorViewer(data);
    const inputTypeA = data.types[0];
    // Setting Ammounts
    if (inputTypeA === "IN") {
      setInputB(data.ammounts[1]);
    } else {
      setInputA(data.ammounts[0]);
    }
    setLimits(data.converted_limits);
  };

  const handleInputA = (e, currencyType) => {
    console.log(currencyType);
    // ADD REGEX
    let val = e.target.value;
    val = val == "00" ? 0.0 : val;

    let re = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;

    if (currencyType === "BTC") {
      val = val >= 1 ? val / 10 : val;
      re = /^([0]+\.)([0-9]?[0-9]{0,10})$/;
    }

    if (val === "" || re.test(val)) {
      const newVal = val === "" ? 0 : val;
      setInputA(newVal);
      calculatorObj.setAmmountA(newVal);
    }
  };

  const handleInputB = (e) => {
    // ADD REGEX
    const val = e.target.value;
    const re = /^([0-9]+\.?[0-9]{0,2})$/;
    if (val === "" || re.test(val)) {
      setInputB(val.replace(/^0+/, ""));
      calculatorObj.setAmmountB(val);
    }
  };

  // Feature: Cupones

  const getUser = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/user";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        setDataUser(responseJson);
        /*if(responseJson.hasRegisterCoupon){
            getCoupon(responseJson.registerCouponId);
          }*/
      })
      .catch((error) => {
        console.log("Ha habido un error en nuestro sistema");
      });
  };

  const getCoupon = (couponId) => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/coupon/${couponId}`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        setCoupon(responseJson);
        setCuponValue(responseJson.quantity);
      })
      .catch((error) => {});
  };

  // Balances

  const getWallet = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        responseJson.forEach(function(item) {
          if (item.currency == "BTC") {
            console.log("Wallet BTC detectada");
            if (item.balance) {
              setBalanceBTC(item.balance.toFixed(8));
            }
          }

          if (item.currency == "USD") {
            console.log("Wallet USD detectada");
            if (item.balance) {
              setBalanceUSD(item.balance.toFixed(2));
            }
          }

          if (item.currency == "USDT") {
            console.log("Wallet USDT detectada");
            if (item.balance) {
              setBalanceUSDT(item.balance.toFixed(2));
            }
          }
        });
      })
      .catch((error) => {
        //createWallet();
      });
  };

  useEffect(() => {
    if (currentToken) {
      getUser();
      setCuponValue(inputA + cuponValue);
      console.log(couponObj);

      getWallet();
    }

    const calculatorObjTmp = new ObjectCalculator(calculatorObserver);
    calculatorObjTmp.convert();
    setCalculatorObj(calculatorObjTmp);
    const interval = setInterval(() => {
      calculatorObjTmp.convert();
    }, 15000);
    /* setTimeout(() => {
      clearInterval(interval);
    }, 10000); */
    // eslint-disable-next-line

    return () => {
      clearTimeout(interval);
    };
  }, [cuponValue]);

  const SelectFIAT = (option) => {
    if (option.value == "PEN") {
      calculatorObj.setPEN();
    } else {
      calculatorObj.setUSD();
    }
  };
  const SelectCrypto = (option) => {
    if (option.value == "BTC") {
      calculatorObj.setUSD();
    } else {
      calculatorObj.setUSDTUSD();
    }
  };

  const toggleMenuFiat = (option) => {
    setMenuFiat(!menuFiat);
  };
  const toggleMenuCrypto = (option) => {
    setMenuCrypto(!menuCrypto);
  };

  if (selected == "sale") {
    handleBtnVenta();
  }

  useEffect(() => {
    let index = 0;
    const indexBTC = calculatorViewer.types.indexOf("BTC");
    const indexUSDT = calculatorViewer.types.indexOf("USDT");
    if (indexBTC >= 0) {
      index = indexBTC;
    }
    if (indexUSDT >= 0) {
      index = indexUSDT;
    }
    setCurrentCrypto(calculatorViewer.currencies[index]);
  }, [calculatorViewer]);

  return (
    <CalculatorContainer>
      <CalculatorTabs>
        <Tab
          type="button"
          onClick={handleBtnCompra}
          className="btn-compra active-tab"
        >
          Comprar
        </Tab>
        <Tab type="button" onClick={handleBtnVenta} className="btn-venta">
          Vender
        </Tab>
      </CalculatorTabs>
      <Convers>
        <TitleCalculator></TitleCalculator>

        {calculatorViewer.transactionType === "BUY" ? (
          <Buy
            AvailableBalanceComp={
              <AvailableBalance
                balanceUSD={balanceUSD}
                currentCrypto={currentCrypto}
                transactionType={calculatorViewer.transactionType}
              />
            }
            currentCrypto={currentCrypto}
            calculatorViewer={calculatorViewer}
            inputA={inputA}
            handleInputA={handleInputA}
            toggleMenuFiat={toggleMenuFiat}
            toggleMenuCrypto={toggleMenuCrypto}
            menuFiat={menuFiat}
            menuCrypto={menuCrypto}
            SelectFIAT={SelectFIAT}
            SelectCrypto={SelectCrypto}
            inputB={inputB}
            handleInputB={handleInputB}
          />
        ) : (
          <Sell
            AvailableBalanceComp={
              <AvailableBalance
                balanceBTC={balanceBTC}
                balanceUSDT={balanceUSDT}
                currentCrypto={currentCrypto}
                transactionType={calculatorViewer.transactionType}
              />
            }
            currentCrypto={currentCrypto}
            balanceBTC={balanceBTC}
            calculatorViewer={calculatorViewer}
            inputA={inputA}
            handleInputA={handleInputA}
            toggleMenuFiat={toggleMenuFiat}
            toggleMenuCrypto={toggleMenuCrypto}
            menuFiat={menuFiat}
            menuCrypto={menuCrypto}
            SelectFIAT={SelectFIAT}
            SelectCrypto={SelectCrypto}
            inputB={inputB}
            handleInputB={handleInputB}
          />
        )}

        <FooterCalculator>
          El precio se actualiza cada <b>&nbsp;10&nbsp;</b> segundos
        </FooterCalculator>
        {currentToken &&
          calculatorViewer.transactionType === "BUY" &&
          calculatorViewer.currencies[1] === "USD" &&
          calculatorViewer.ammounts[1] > 14.99 &&
          calculatorViewer.ammounts[1] <= balanceUSD && (
            <Catcher
              location="compra-bitcoins"
              title={`Comprar ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              montoBitcoins={calculatorViewer.ammounts[0]}
              cantidadDolares={calculatorViewer.ammounts[1]}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              cambioBitcoin={parseFloat(calculatorViewer.rates.BTCUSDBuy)}
              zzComisionBTC={zzComisionBTC}
              zzComisionEnvio={zzComisionEnvio}
              calculatorViewer={calculatorViewer}
            />
          )}

        {!currentToken &&
          calculatorViewer.transactionType === "BUY" &&
          calculatorViewer.currencies[1] === "USD" &&
          calculatorViewer.ammounts[1] > 14.99 &&
          calculatorViewer.ammounts[1] <= balanceUSD && (
            <Catcher
              location="login"
              title={`Comprar ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              montoBitcoins={calculatorViewer.ammounts[0]}
              cantidadDolares={calculatorViewer.ammounts[1]}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              cambioBitcoin={parseFloat(calculatorViewer.rates.BTCUSDBuy)}
              zzComisionBTC={zzComisionBTC}
              zzComisionEnvio={zzComisionEnvio}
              calculatorViewer={calculatorViewer}
            />
          )}

        {/* {currentToken &&
          calculatorViewer.transactionType === "BUY" &&
          calculatorViewer.currencies[1] === "PEN" &&
          calculatorViewer.ammounts[1] >= limits.pen.buy[0] &&
          calculatorViewer.ammounts[1] <= balancePEN && (
            <Catcher
              location="compra-bitcoins"
              title={`Comprar ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              montoBitcoins={calculatorViewer.ammounts[0]}
              cantidadSoles={calculatorViewer.ammounts[1]}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              cambioBitcoin={parseFloat(calculatorViewer.rates.BTCUSDBuy)}
              cambioSoles={calculatorViewer.rates.USDPENBuy}
              zzComisionBTC={zzComisionBTC}
              zzComisionEnvio={zzComisionEnvio}
              calculatorViewer={calculatorViewer}
            />
          )} */}

        {/* {!currentToken &&
          calculatorViewer.transactionType === "BUY" &&
          calculatorViewer.currencies[1] === "PEN" &&
          calculatorViewer.ammounts[1] >= limits.pen.buy[0] &&
          calculatorViewer.ammounts[1] <= balancePEN && (
            <Catcher
              location="login"
              title={`Comprar ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              montoBitcoins={calculatorViewer.ammounts[0]}
              cantidadSoles={calculatorViewer.ammounts[1]}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              cambioBitcoin={parseFloat(calculatorViewer.rates.BTCUSDBuy)}
              cambioSoles={calculatorViewer.rates.USDPENBuy}
              zzComisionBTC={zzComisionBTC}
              zzComisionEnvio={zzComisionEnvio}
              calculatorViewer={calculatorViewer}
            />
          )} */}

        {calculatorViewer.transactionType === "BUY" &&
          calculatorViewer.currencies[1] === "USD" &&
          (calculatorViewer.ammounts[1] < 15 ||
            calculatorViewer.ammounts[1] > balanceUSD) && (
            <Catcher
              title={`Comprar ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              noAmount
              calculatorViewer={calculatorViewer}
            />
          )}

        {/* {calculatorViewer.transactionType === "BUY" &&
          calculatorViewer.currencies[1] === "PEN" &&
          (calculatorViewer.ammounts[1] < limits.pen.buy[0] ||
            calculatorViewer.ammounts[1] > balancePEN) && (
            <Catcher
              title={`Comprar ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              noAmount
              calculatorViewer={calculatorViewer}
            />
          )} */}

        {currentToken &&
          calculatorViewer.transactionType === "SELL" &&
          calculatorViewer.currencies[1] === "USD" &&
          calculatorViewer.ammounts[1] > 14.99 && (
            <Catcher
              location="venta-bitcoins"
              title={`Vender ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              montoBitcoinsVenta={calculatorViewer.ammounts[0]}
              cantidadDolaresVenta={calculatorViewer.ammounts[1]}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              cambioBitcoinVenta={parseFloat(calculatorViewer.rates.BTCUSDSell)}
              zzComisionBTC={zzComisionBTC}
              calculatorViewer={calculatorViewer}
            />
          )}

        {!currentToken &&
          calculatorViewer.transactionType === "SELL" &&
          calculatorViewer.currencies[1] === "USD" &&
          calculatorViewer.ammounts[1] > 14.99 && (
            <Catcher
              location="login"
              title={`Vender ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              montoBitcoinsVenta={calculatorViewer.ammounts[0]}
              cantidadDolaresVenta={calculatorViewer.ammounts[1]}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              cambioBitcoinVenta={parseFloat(calculatorViewer.rates.BTCUSDSell)}
              zzComisionBTC={zzComisionBTC}
              calculatorViewer={calculatorViewer}
            />
          )}

        {/* {currentToken &&
          calculatorViewer.transactionType === "SELL" &&
          calculatorViewer.currencies[1] === "PEN" &&
          calculatorViewer.ammounts[1] >= limits.pen.sell[0] &&
          calculatorViewer.ammounts[1] <= limits.pen.sell[1] && (
            <Catcher
              location="venta-bitcoins"
              title="Vender bitcoins"
              montoBitcoinsVenta={calculatorViewer.ammounts[0]}
              cantidadSolesVenta={calculatorViewer.ammounts[1]}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              cambioBitcoinVenta={parseFloat(calculatorViewer.rates.BTCUSDSell)}
              cambioSolesVenta={calculatorViewer.rates.USDPENSell}
              zzComisionBTC={zzComisionBTC}
              calculatorViewer={calculatorViewer}
            />
          )} */}
        {/* 
        {!currentToken &&
          calculatorViewer.transactionType === "SELL" &&
          calculatorViewer.currencies[1] === "PEN" &&
          calculatorViewer.ammounts[1] >= limits.pen.sell[0] &&
          calculatorViewer.ammounts[1] <= limits.pen.sell[1] && (
            <Catcher
              location="login"
              title="Vender bitcoins"
              montoBitcoinsVenta={calculatorViewer.ammounts[0]}
              cantidadSolesVenta={calculatorViewer.ammounts[1]}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              cambioBitcoinVenta={parseFloat(calculatorViewer.rates.BTCUSDSell)}
              cambioSolesVenta={calculatorViewer.rates.USDPENSell}
              zzComisionBTC={zzComisionBTC}
              calculatorViewer={calculatorViewer}
            />
          )} */}

        {calculatorViewer.transactionType === "SELL" &&
          calculatorViewer.currencies[1] === "USD" &&
          (calculatorViewer.ammounts[1] < 15) && (
            <Catcher
            title={`Vender ${currentCrypto === "USDT"? "Tethers" : "Bitcoins"}`}
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              noAmount
              calculatorViewer={calculatorViewer}
            />
          )}

        {/* {calculatorViewer.transactionType === "SELL" &&
          calculatorViewer.currencies[1] === "PEN" &&
          (calculatorViewer.ammounts[1] < limits.pen.sell[0] ||
            calculatorViewer.ammounts[1] > limits.pen.sell[1]) && (
            <Catcher
              title="Vender bitcoins"
              currency={calculatorViewer.currencies[1]}
              operacion={calculatorViewer.transactionType}
              noAmount
              calculatorViewer={calculatorViewer}
            />
          )} */}

        <FooterCalculator>
          <SmallLetters>
            Las transacciones de compra y venta de activos digitales, tú
            entiendes y aceptas que el precio que se indica en la cotización de
            compra/venta dentro de feanor.io es un aproximado que puede cambiar.{" "}
            <TermsLink href={`${website}/terminos-y-condiciones`}>
              Ver terminos y condiciones
            </TermsLink>
          </SmallLetters>
        </FooterCalculator>
      </Convers>

      <ContainerMinMax>
        <MinMaxElement>
          {`Min:
          ${
            limits[calculatorViewer.currencies[1].toLowerCase()][
              calculatorViewer.transactionType.toLowerCase()
            ][0]
          }
          ${calculatorViewer.currencies[1]}`}
        </MinMaxElement>
        <MinMaxElement>
          {`Max:
          ${
            limits[calculatorViewer.currencies[1].toLowerCase()][
              calculatorViewer.transactionType.toLowerCase()
            ][1]
          } ${calculatorViewer.currencies[1]}`}
        </MinMaxElement>
      </ContainerMinMax>
    </CalculatorContainer>
  );
};

export default Calculator;
