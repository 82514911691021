import useGetTransactions from "hooks/useGetTransactions";
import React from "react";
import { Paragraph } from "styles/common/index.js";
import { AmountCurrency, WalletContent } from "styles/Styled";

import BalanceRow from "./components/BalanceRow";
import LastTransactions from "./components/LastTransactions";
import MoreAboutUSDT from "./components/MoreAboutUSDT";

const SaldoUSDT = () => {
  const { transactions, getTransactions } = useGetTransactions(
    "currency=USDT&limit=5"
  );

  return (
    <>
      <BalanceRow refreshTransactions={getTransactions} />

      <WalletContent>
        <LastTransactions transactions={transactions} />
        <MoreAboutUSDT />
      </WalletContent>

      <Paragraph>
        <AmountCurrency>*</AmountCurrency> Equivalente a su conversión con la
        tasa de VENTA vigente e incluye las comisiones de transacción de Fëanor.
      </Paragraph>
    </>
  );
};

export default SaldoUSDT;
