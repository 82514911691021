import React from "react";
import { Header1Black } from "styles/common";
import { LoginButton, LoginForm, LoginInput } from "styles/Styled";

const FormNewPIN = ({
  errorMsg,
  newPIN,
  setNewPIN,
  onSubmit,
  buttonTitle = "",
  buttonTitleLoading = "",
  loading = false,
}) => {
  return (
    <div>
      <Header1Black>Nuevo PIN</Header1Black>

      {errorMsg && (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginBottom: "2px",
          }}
        >
          {errorMsg}
        </p>
      )}
      <LoginForm
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <LoginInput
          maxLength={4}
          minLength={4}
          name="newPIN"
          placeholder="Ingrese su nuevo PIN"
          style={{
            marginBottom: "2px",
            outlineWidth: `${errorMsg ? "0" : ""}`,
            borderColor: `${errorMsg ? "red" : ""}`,
          }}
          type="tel"
          value={newPIN}
          onChange={(e) => setNewPIN(e.target.value)}
          autoComplete="off"
        />
        <p
          style={{
            color: "black",
            fontSize: "12px",
            marginTop: "2px",
          }}
        >
          Ingrese su nuevo PIN de 4 digitos (solamente números)
        </p>

        <LoginButton type="submit" disabled={loading}>
          {loading
            ? buttonTitleLoading || "Cargando..."
            : buttonTitle || "Continuar"}
        </LoginButton>
      </LoginForm>
    </div>
  );
};

export default FormNewPIN;
