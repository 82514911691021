import React, { useContext } from "react";
import {
  ContainerLevels,
  Header2,
  LevelBox,
  LevelBoxDescription,
  LevelBoxList,
  Paragraph,
} from "styles/common/index.js";
import { AuthContext } from "Auth";

import useGetUserInfo from "hooks/useGetUser";
import swal from "sweetalert";
import { updateKYCApprovalToPending } from "services/user.service";
// import MetamapBtn from "components/MetamapBtn";
import DiditBtn from "components/DiditBtn";

let clientId = "";
let flowId = "";
switch (process.env.REACT_APP_ENV_TYPE) {
  case "development":
    clientId = "6320dbd16d3fae001cc55fe3";
    flowId = "6320dbd16d3fae001cc55fe2";
    break;
  case "staging":
    clientId = "6320dbd16d3fae001cc55fe3";
    flowId = "6320dbd16d3fae001cc55fe2";
    break;
  case "production":
    clientId = "6320dbd16d3fae001cc55fe3";
    flowId = "6321ed273759bb001d1df2e6";
    break;
  default:
    clientId = "6320dbd16d3fae001cc55fe3";
    flowId = "6320dbd16d3fae001cc55fe2";
}

const Activation = () => {
  const { fetchUser } = useGetUserInfo(false);
  let { userInfo: user, currentToken } = useContext(AuthContext);

  const onVerificationFinish = async () => {
    try {
      const resp = await updateKYCApprovalToPending(currentToken);
      console.log(resp);
      if (resp.success) {
        await fetchUser();
        swal({
          text:
            "¡Listo! Tus datos han sido enviados exitosamente, validaremos tu cuenta en el transcurrso del dia.",
          button: false,
          timer: 5000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Header2>Validación de cuenta</Header2>
      <Paragraph>
        En este apartado podrás validar tu cuenta según el nivel que requieras.
        Estos datos nos ayudan a verificar y <br></br>
        validar tu identidad.
      </Paragraph>

      <ContainerLevels>
        <LevelBox>
          <LevelBoxDescription>
            <b>Beneficios:</b>
            <LevelBoxList>
              <li>- Comprar Bitcoin y Tether</li>
              <li>- Vender Bitcoin y Tether</li>
              <li>- Fëanor Card</li>
            </LevelBoxList>
            <b>Requisitos:</b>
            <LevelBoxList>
              <li>- Número de documento de identidad (Cédula o Pasaporte)</li>
              <li>- Número telefonico</li>
              <li>- Fotos del documento (Frontal y trasero)</li>
              <li>- Nombres completos</li>
              <li>- Nacionalidad</li>
            </LevelBoxList>
          </LevelBoxDescription>
          <br></br>

          {/* <MetamapBtn
            userId={user._id}
            isKYCApprovalPending={user.isKYCApprovalPending}
            kycVerificationStatus={user.kycVerificationStatus}
            onVerificationFinish={onVerificationFinish}
          /> */}
          <DiditBtn
            isKYCApprovalPending={user.isKYCApprovalPending}
            kycVerificationStatus={user.kycVerificationStatus}
          />
        </LevelBox>
      </ContainerLevels>
    </React.Fragment>
  );
};

export default Activation;
