import React, { useContext } from "react";
import { Header2, Paragraph, WarningBox } from "styles/common/index.js";
import SaldoMain from "components/SaldoMain";
import Stepper from "components/Stepper";
import aviso from "aviso.json";
import SecondaryWallets from "./sections/SecondaryWallets";
import useGetUserInfo from "hooks/useGetUser";
import useModal from "hooks/useModal";
import { useEffect } from "react";
import { AuthContext } from "Auth";
import useGetWallets from "hooks/useGetWallets";

const Money = () => {
  // const { userInfo, loading } = useGetUserInfo();
  const { userInfo } = useContext(AuthContext);
  const [Modal, isOpenModal, openModal, onCloseModal] = useModal();
  const { walletUSDT, walletUSDTTron, walletBTC, loading } = useGetWallets();

  useEffect(() => {
    if (aviso.message) {
      openModal();
    }
  }, []);
  return (
    <React.Fragment>
      <Stepper
        walletUSDT={walletUSDT}
        walletUSDTTron={walletUSDTTron}
        walletBTC={walletBTC}
        loading={loading}
      />
      {aviso.message && (
        <WarningBox>
          <Paragraph>
            <div>
              <b>Atención: </b>
            </div>
            {aviso.message}
          </Paragraph>
          <Modal
            open={isOpenModal}
            onClose={onCloseModal}
            center
            closeOnOverlayClick={true}
            variant="large"
          >
            <WarningBox>
              <Paragraph>
                <div>
                  <b>Atención: </b>
                </div>
                {aviso.message}
              </Paragraph>
            </WarningBox>
          </Modal>
        </WarningBox>
      )}
      <Header2>Mis Billeteras</Header2>
      <Paragraph className="boxCenter">
        Estas son tus billetera exclusivas en Fëanor, desde aquí puedes enviar y
        recibir bitcoins a tus amigos o familiares. Úsalas sabiamente, funciona
        como una cuenta bancaria. ¡Eres libre de disponer de tus fondos y
        moverlos!.
      </Paragraph>
      <SaldoMain
        walletUSDT={walletUSDT}
        walletUSDTTron={walletUSDTTron}
        walletBTC={walletBTC}
        loading={loading}
      />
      {userInfo.level === "2" && <SecondaryWallets />}
      {/* {!loading && userInfo.level === "2" && <SecondaryWallets />} */}
    </React.Fragment>
  );
};

export default Money;
