import React, { useContext, useEffect, useState } from "react";
import FeanorCardStepper from "components/Stepper/FeanorCardStepper";
import { Header2, Paragraph } from "styles/common/index.js";
import {
  BtnPrimary,
  ButtonContent,
  DescriptionBox,
  DisplayCount,
  ItemBordered,
  TitleBox,
  WalletBoxLeft,
  WalletBoxRight,
  WalletContent,
} from "styles/Styled";
import useGetCards from "hooks/useGetCards";
import useGetUserInfo from "hooks/useGetUser";
import { CardsContainer } from "components/CardRegular/styled";
import LastTransactions from "components/SaldoUSDT/components/LastTransactions";
import useGetCardTransactions from "hooks/useGetCardTransactions";
import MoreAbout from "./CardDetail/components/MoreAbout";
import { fetchCardWallet } from "services/card.service";
import useGetCardWallet from "hooks/useGetCardWallet";
import FundCardForm from "./CardDetail/components/FundCardForm";
import useModal from "hooks/useModal";
import useGetWallets from "hooks/useGetWallets";
import swal from "sweetalert";
import RegularCards from "./sections/RegularCards";
import PremiumCards from "./sections/PremiumCards";
import "styles/stylesSwiper.css";
import { AuthContext } from "Auth";

const FeanorCard = () => {
  const { cards, getCards, regular, premium, loading } = useGetCards();
  // const { userInfo } = useGetUserInfo();
  const { userInfo } = useContext(AuthContext);
  const { getTransactions, transactions } = useGetCardTransactions();
  const { walletUSDT, getWallets, walletBTC, walletUSDTTron } = useGetWallets();

  if (cards.length <= 0) {
    return (
      <React.Fragment>
        <FeanorCardStepper cards={cards}/>
        <Header2 style={{ marginBottom: 0 }}>Mi Fëanor Card</Header2>
        <Paragraph className="boxCenter">
          Esta es una tarjeta exclusiva en Fëanor, con ella podrás abonar USDT a
          tu tarjeta Fëanor y realizar compras o pagos a nivel mundial!.
        </Paragraph>
        {regular.length < 2 && !loading && (
          <ItemBordered to="/app/profile/solicitar_card">
            Solicitar FëanorCard
          </ItemBordered>
        )}
        {loading && <p>Cargando...</p>}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {/* <FeanorCardStepper cards={cards} /> */}
      <Header2 style={{ marginBottom: 0 }}>Mi Fëanor Card</Header2>
      <Paragraph className="boxCenter">
        Esta es una tarjeta exclusiva en Fëanor, con ella podrás abonar USDT a
        tu tarjeta Fëanor y realizar compras o pagos a nivel mundial!.
      </Paragraph>
      {regular.length < 2 && !loading && (
        <ItemBordered to="/app/profile/solicitar_card">
          Solicitar FëanorCard
        </ItemBordered>
      )}
      {loading && <p>Cargando...</p>}

      {/* Tarjetas normales (MASTERCARD -  PayCaddy) */}
      {regular.length > 0 && (
        <RegularCards
          otpEnabled={userInfo.otpEnabled}
          walletUSDT={walletUSDT}
          walletBTC={walletBTC}
          walletUSDTTron={walletUSDTTron}
          regularCards={regular}
          refreshCards={getCards}
          refreshWallets={getWallets}
          refreshTransactions={getTransactions}
        />
      )}

      {/* Tarjetas Premium (VISA - Versatec) */}
      {premium.length > 0 && (
        <PremiumCards
          otpEnabled={userInfo.otpEnabled}
          walletUSDT={walletUSDT}
          walletBTC={walletBTC}
          walletUSDTTron={walletUSDTTron}
          premiumCards={premium}
          refreshCards={getCards}
          refreshWallets={getWallets}
          refreshTransactions={getTransactions}
        />
      )}
      <WalletContent>
        <LastTransactions transactions={transactions} />
        <MoreAbout />
      </WalletContent>
    </React.Fragment>
  );
};

export default FeanorCard;
