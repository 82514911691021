import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Ellipsis } from "react-awesome-spinners";
import {useDropzone} from 'react-dropzone'
import { AuthContext } from "Auth";

import icon_conversion from 'media/icons/icon_conversion@2x.png';
import copyIcon from 'media/icons/copy.svg';
import swal from "sweetalert";

import Styled from './styles';

import feanorapi from "feanorapi.js";
import CountdownTimer from "components/CountdownTimer.js";

import { Header1, Header2, Header3, WarningBox, Paragraph} from "styles/common/index.js";

const EditOperation = ({ history, match }) => {
  const { operationId } = match.params;
  const [loading, setLoading] = useState(true);
  const [wallet, setWallet] = useState("");
  const [imageFile, setImageFile] = useState(undefined);
  const [imageURL, setImageURL] = useState(undefined);
  const [data, setData] = useState({});
  const [operation, setOperation] = useState({});
  const [valueBanco, setValueBanco] = useState("");
  const [editWallet, setEditWallet] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");

  const { currentToken } = useContext(AuthContext);

  // Coupons
  const [user, setDataUser] = useState({});
  const [couponObj, setCoupon] = useState({});
  const [cuponValue, setCuponValue] = useState(0);
  const [hasCoupon, setHaveCoupon] = useState(false);

  // Edit wallet
  const handleEditWallet = () => {
    setEditWallet(!editWallet);
  };

  // Loading page
  const handleLoading = () => {
    setLoading(false);
  }; 


  /* Wallet */

  // Data
  const [addressBtc, setAddressBtc]  = useState("----"); 
  const [haveWallet, setHaveWallet] = useState(false);

  const onDrop = useCallback(files => {
    const imageFile = files[files.length-1];
    const newImageURL = URL.createObjectURL(imageFile);
    setImageURL(newImageURL);
    setImageFile(imageFile);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  // Show confirm modal
  const confirmOperation = async e => {
    e.preventDefault();
    if(!valueBanco){
      swal({ text: "Porfavor, debe seleccionar un banco", button: false });
    }else if(!wallet && operation.transactionType === "BUY"){
      swal({ text: "Porfavor, debe ingresar su wallet", button: false });
    }else if(!accountNumber && operation.transactionType === "SELL"){
      swal({ text: "Porfavor, debe ingresar su número de cuenta", button: false });
    }else{
      swal({ text: "Confirmando...", button: false });
      const formData = new FormData();
      formData.append("voucherImage", imageFile);
      formData.append("wallet", wallet);
      formData.append("bank", valueBanco);
      formData.append("accountNumber", accountNumber);
      const options = {
        method: "PUT",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          Authorization: `Bearer ${currentToken}`
        },
        body: formData
      };
      const endpoint = "/client/transaction/confirm/";
      const url = `${feanorapi}${endpoint}${operationId}`;

      fetch(url, options)
        .then(response => {
          if (response.ok) {
            swal({
              text: "Perfecto, la transacción se está procesando",
              button: false,
              timer: 2000
            });
            history.push(`/app/operation/tracking/${operationId}`);
          } else {
            throw { type: "incorrect-data" };
          }
        })
        .catch(error => {
          swal({
            text: "Ha habido un error en nuestro sistema",
            button: false,
            timer: 1000
          });
        });
    }
  };

  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const createWallet  = async () => {

    const jsonBody = JSON.stringify({ 'currency': 'BTC'});

    let data =  {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        },
        body: jsonBody
    }
    let endpoint = '/client/wallet';
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if(response.ok){
          getWallet();
          
          return response.json();
        }else{
          //swal({text: "Ha habido un error al crear tu billetera. Si el error persiste escríbenos a Soporte.", button: false, timer: 5000});

        }
      })
      .catch((error)=>{
        //swal({text: "Ha habido un error al crear tu billetera. Si el error persiste escríbenos a Soporte.", button: false, timer: 5000});
      })
  }
  

  const getWallet = async () => {
    let data =  {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
    }
    let endpoint = '/client/wallet';
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if(response.ok){
          return response.json();
        }else{
          throw {'type': 'non-user'}
        }
      })
      .then((responseJson)=>{ 

        const walletData = responseJson;
     
        // Si tiene billetera 
        if(walletData) {
          console.log('Wallets detectadas.');
          
          walletData.forEach(function(item) { 
            console.log(item);
            if(item.currency == "BTC") {
              console.log('Wallet BTC detectada');

              setHaveWallet(true);
              setWallet(item.address); 
              
            }
          }); 

        }
        else {
          // No billetera
          setHaveWallet(false);
          console.log('No tiene billetera asociada el usuario');
          createWallet();
        }

      })
      .catch((error)=>{
        console.log('Ocurrio un error al obtener wallet, iniciando creación...');
        createWallet();
       })
  }


  // Get the operation data
  const getOperation = async () => {
    const data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`
      }
    };
    const endpoint = `/client/transaction/${operationId}`;
    const url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw { type: "non-available" };
      })
      .then(responseJson => {
        setOperation(responseJson); 
        
        if(responseJson.currencies[1] === 'PEN') {
          setValueBanco('bcp');
        }
        else { setValueBanco('interbank')}

        setLoading(false);

        // Checar existencia de Wallet 
        getWallet();

        if (responseJson.wallet) {
          //setWallet(responseJson.wallet);
        }
        if (responseJson.bank) {
          setValueBanco(responseJson.bank);
        }
        if (responseJson.accountNumber) {
          setAccountNumber(responseJson.accountNumber);
        }
        if (responseJson.voucherImage) {
          const newImageURL = `${feanorapi}/image/voucher/${responseJson.voucherImage}`;
          updateImageURL(newImageURL);
        }
        scrollTop();
      })
      .catch(error => {
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000
        });
      });
  };


  // Feature: Cupones

  const getUser = async () => {
    let data =  {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
    }
    let endpoint = '/client/user';
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if(response.ok){
          return response.json();
        }else{
          throw {'type': 'non-user'}
        }
      })
      .then((responseJson)=>{
          setDataUser(responseJson);
          /*if(responseJson.hasRegisterCoupon){
            getCoupon(responseJson.registerCouponId);
          }
          else {
            setHaveCoupon(false);
          }*/
      })
      .catch((error)=>{
        swal({text: "Ha habido un error en nuestro sistema", button: false, timer: 1000});
      })
  }

  const getCoupon = (couponId)=>{
    let data =  {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Authorization': `Bearer ${currentToken}`
        },
    }
    let endpoint = `/coupon/${couponId}`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if(response.ok){

          return response.json();
        }
      })
      .then((responseJson)=>{
        setCoupon(responseJson);
        setCuponValue(responseJson.quantity);
        setHaveCoupon(true);

      })
      .catch((error)=>{
      })
  }


  useEffect(() => { 




    const fetchData = async () => {
      const result = await getUser();
    };
    fetchData();

    // Get operation data
    getOperation();


    console.log(couponObj);


    // eslint-disable-next-line
  }, [cuponValue]);

  // Select bank
  const handleSelect = option => {
    setValueBanco(option.value);
  };

  const updateImageURL = imageURL => {
    setImageURL(imageURL);
  };

  const BankOptions = [
    {value: "bcp", label: "BCP", pen: "", usd: ""},
    {value: "interbank", label: "Interbank", usd: "", pen: ""},
    //{value: "bbva", label: "BBVA", usd: "", pen: ""},
  ];

  const BankOptionsObject = {
    bcp: { value: "bcp", label: "BCP", pen: "193-2682028-0-90", usd: "191-96560277-1-31", cci: '00219300268202809012', onlyCurrency: 'PEN'},
    interbank: { value: "interbank", label: "Interbank", usd: "076-3002544727", pen: "076-3002544680", cci:'003-076-003002544727-74', onlyCurrency: 'USD'},
    //bbva: {value: "bbva", label: "BBVA", usd: "0011-0358-01-00053834", pen: "0011-0358-01-00053826"},
  };

  const copyToClipboard = ()=>{
    const el = document.createElement('textarea');
    el.value = BankOptionsObject[valueBanco][operation.currencies[1].toLowerCase()];
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    swal({text: "Cuenta de banco copiada", button: false, timer: 2500});
  }

  return loading ? (
    <Styled.EditOperationWrapper>
      <Ellipsis color={"#3C4A5B"}/>
    </Styled.EditOperationWrapper>
  ) : (
    <Styled.EditOperationWrapper>
      <Styled.TitleEditOperation>{operation.transactionType == "BUY" ? "Comprando Bitcoin" : "Vendiendo Bitcoin"}</Styled.TitleEditOperation>
      <Styled.SubtitleEditOperation>{operation.transactionType == "BUY" ? "¡Estás a punto de comprar Bitcoin! Completa los datos que se indican a continuación" : "¡Estás a punto de vendes tus Bitcoins! Completa los datos que se indican a continuación"}</Styled.SubtitleEditOperation>
      <Styled.EditOperationContainer>
        <Styled.OperationHeader>1. Verifica los detalles de la {operation.transactionType == "BUY" ? " compra":" venta"}</Styled.OperationHeader>
        <Styled.OrderDetail>
          <Styled.ContainerAmmounts>
            <Styled.CardAmmount>
              <Styled.AmmountTitle>{operation.transactionType == "BUY" ? "Total a pagar":"Monto a vender"}</Styled.AmmountTitle>
              {operation.transactionType == "BUY" ? (
                <Styled.AmmountNumberFIAT>{`${operation.ammounts[1].toFixed(2)} ${operation.currencies[1]}`}</Styled.AmmountNumberFIAT>
              ):(
                <Styled.AmmountNumberBTC>{`${operation.ammounts[0].toFixed(7)} ${operation.currencies[0]}`}</Styled.AmmountNumberBTC>
              )}
              <Styled.DeletedAmmount></Styled.DeletedAmmount>
            </Styled.CardAmmount>
            <Styled.IconConversion src={icon_conversion}/>
            <Styled.CardAmmount>
              <Styled.AmmountTitle>Cantidad a recibir</Styled.AmmountTitle>
              {operation.transactionType == "BUY" ? (
                <Styled.AmmountNumberBTC>
                {  hasCoupon && !couponObj.used ?
                  ( (operation.ammounts[0] - cuponValue).toFixed(7) ) :
                  (operation.ammounts[0].toFixed(7))
                } BTC
                </Styled.AmmountNumberBTC>
              ):(
                <Styled.AmmountNumberFIAT>
                {operation.ammounts[1].toFixed(2)} {operation.currencies[1]}
                </Styled.AmmountNumberFIAT>
              )}
              <Styled.DeletedAmmount>{ hasCoupon && operation.transactionType == "BUY" && !couponObj.used && `${operation.ammounts[0].toFixed(7)} BTC`}</Styled.DeletedAmmount>
            </Styled.CardAmmount>
          </Styled.ContainerAmmounts>
          <Styled.ConversionRate>1 BTC =
            {` ${
              operation.transactionType == "SELL"
                ? operation.rates.BTCUSDSell
                : operation.rates.BTCUSDBuy
            } USD`}
          </Styled.ConversionRate>
          <Styled.DetailItem>
            <Styled.DetailTimer>
              <CountdownTimer/>
            </Styled.DetailTimer>
          </Styled.DetailItem>
        </Styled.OrderDetail>
        <Styled.OperationHeader>2. Completa los datos y confirma la operación</Styled.OperationHeader>
        <Styled.EditOperationItemList>
          <Styled.EditOperationItem>
            <Styled.EditOperationItemLabel>
              2.1. Deposito bancario
            </Styled.EditOperationItemLabel>
            <Styled.EditOperationItemLabelDescription>
              {operation.transactionType == "BUY" ? "Banco a dónde nos realizarás el depósito bancario" : "Banco donde se te realizará el depósito"}
            </Styled.EditOperationItemLabelDescription>
        

          {(operation.transactionType == "BUY" && operation.currencies[1] === "PEN") &&(
              <Styled.AccountBox>

               <Styled.BankData>
                  Banco: <b>CAPITAL BANK</b>
                </Styled.BankData>
                <Styled.BankData>
                  Cuenta Dólares: <Styled.BankAccountNumber onClick={copyToClipboard}>06202003272  &nbsp;<Styled.CopyIcon src={copyIcon}/></Styled.BankAccountNumber>
                </Styled.BankData>
                <Styled.BankData>
                  Titular: <b>FËANOR Corp.</b>
                </Styled.BankData>
                <Styled.BankData>
                  Monto a depositar:{" "}
                  <b>
                    {`${operation.ammounts[1]} ${operation.currencies[1]}`}
                  </b>
                </Styled.BankData>
              </Styled.AccountBox>
            )}


          {(operation.transactionType == "BUY" && operation.currencies[1] === "USD") &&(
              <Styled.AccountBox>

              <Styled.BankData>
                 Banco: <b>CAPITAL BANK</b>
               </Styled.BankData>
               <Styled.BankData>
                 Cuenta Dólares: <Styled.BankAccountNumber onClick={copyToClipboard}>06202003272  &nbsp;<Styled.CopyIcon src={copyIcon}/></Styled.BankAccountNumber>
               </Styled.BankData>
               <Styled.BankData>
                 Titular: <b>FËANOR Corp.</b>
               </Styled.BankData>
               <Styled.BankData>
                 Monto a depositar:{" "}
                 <b>
                   {`${operation.ammounts[1]} ${operation.currencies[1]}`}
                 </b>
               </Styled.BankData>
             </Styled.AccountBox>
            )}

          </Styled.EditOperationItem>
          {operation.transactionType == "SELL" && (
            <Styled.EditOperationItem>
              <Styled.EditOperationItemLabel>
                2.2. Ingresa tu número de cuenta en <b>{operation.currencies[1] == 'USD' ? " Dólares" : " Soles"}</b>
              </Styled.EditOperationItemLabel>
              <Styled.EditOperationItemLabelDescription>
                Te enviaremos el dinero al este número de cuenta
              </Styled.EditOperationItemLabelDescription>
              <Styled.TextInput type="text" name="account_number" placeholder={`Número de cuenta en ${operation.currencies[1] == 'USD' ? " Dólares" : " Soles"}`} value={accountNumber} onChange={(e)=>setAccountNumber(e.target.value)}/>
            </Styled.EditOperationItem>
          )}
          {operation.transactionType == "BUY" && (
            <>
              <Styled.EditOperationItem>
                <Styled.EditOperationItemLabel>2.2. Voucher de pago</Styled.EditOperationItemLabel>
                <Styled.EditOperationItemLabelDescription>
                  Sube el voucher de tu depósito realizado.
                </Styled.EditOperationItemLabelDescription>
                <Styled.ContainerImages>
                  <Styled.ContainerInputImage {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <Styled.TextImageInput>Suelta el voucher aquí</Styled.TextImageInput> :
                        <Styled.TextImageInput>{imageURL ? "Cambiar imagen de voucher. JPG, PNG o PDF.":"Adjuntar voucher. JPG, PNG o PDF."}</Styled.TextImageInput>
                    }
                  </Styled.ContainerInputImage>
                  {imageURL && <Styled.DisplayImage src={imageURL}/>}
                </Styled.ContainerImages>
              </Styled.EditOperationItem>
            </>
          )}
          {operation.transactionType == "BUY" && (
            <Styled.EditOperationItemFull>
              
              <Styled.TextInput  type="hidden" name="wallet" placeholder="Ingresa la dirección BTC destino" value={wallet} onChange={(e)=>setWallet(e.target.value)}/>
            </Styled.EditOperationItemFull>
          )}
          {operation.transactionType == "SELL" && (
            <Styled.EditOperationItemFull>
              <Styled.EditOperationItemLabel>
                2.3. Deposite tus bitcoins
              </Styled.EditOperationItemLabel>
              <Styled.EditOperationItemLabelDescription>
                Deposite tus bitcoins en la siguiente wallet: <b> 38jJYLsMWf2dYsgrQzf3bmBXDNwmhJjbft </b>
              </Styled.EditOperationItemLabelDescription>
              <Styled.ContainerImages>
                <Styled.DisplayImage src={"https://chart.googleapis.com/chart?cht=qr&chl=38jJYLsMWf2dYsgrQzf3bmBXDNwmhJjbft&chs=180x180&choe=UTF-8&chld=L|2"}/>
              </Styled.ContainerImages>
            </Styled.EditOperationItemFull>
          )}
            <Styled.Form onSubmit={confirmOperation}>
              <Paragraph>
                <input type="checkbox" required /> He leído y aceptado los{" "}
                <Styled.LinkTC
                  href="#"
                  className="selectedLink"
                >
                  términos y condiciones
                </Styled.LinkTC>
              </Paragraph>
              <Styled.ConfirmButton>
                {operation.transactionType == "BUY" ? "Confirmar Compra":"Confirmar Venta"}
              </Styled.ConfirmButton>
            </Styled.Form>
          </Styled.EditOperationItemList>
      </Styled.EditOperationContainer>
    </Styled.EditOperationWrapper>
  );
};

export default withRouter(EditOperation);
