import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "Auth";
import app from "base";
import "./ToggleableNav.css";

const ToggleableNav = ({ logout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { currentToken, expiration } = useContext(AuthContext);

  if (isOpen) {
    return (
      <div className="nav-toggle nav-toggle--open show-mobile">
        <span className="nav-toggle__button" onClick={toggleMenu}></span>

        <nav className="nav__items">
          <a
            href={`https://dezkapro.com`}
            target="_blank"
            className="nav__item"
          >
            NFT Market
          </a>
          {/*<a
            href={`https://therivusbyeliesaab.app`}
            target="_blank"
            className="nav__item"
          >
            NFT Proyectos
          </a>*/}
          <a
            href={`https://www.compracasasusa.com`}
            target="_blank"
            className="nav__item"
          >
            Proyectos USA
          </a>
          {currentToken ? (
            <>
              <NavLink
                to="/app/operation/new"
                activeClassName="selectedLink"
                className="nav__item"
                onClick={toggleMenu}
              >
                Comprar
              </NavLink>
              <NavLink
                to="/app/profile"
                activeClassName="selectedLink"
                className="nav__item"
                onClick={toggleMenu}
              >
                Perfil
              </NavLink>
              <button type="button" onClick={logout} className="nav__item">
                Cerrar sesión
              </button>
            </>
          ) : (
            <>
              <NavLink
                to="/auth/login"
                className="nav__item"
                onClick={toggleMenu}
              >
                Iniciar sesión
              </NavLink>
              <NavLink
                to="/auth/login_business"
                className="nav__item"
                onClick={toggleMenu}
              >
                Empresas
              </NavLink>
              <NavLink
                to="/auth/register"
                className="nav__item"
                onClick={toggleMenu}
              >
                Registrarse
              </NavLink>
            </>
          )}
        </nav>
        <div className="back-drop" onClick={toggleMenu}></div>
      </div>
    );
  } else {
    return (
      <div className="nav-toggle nav-toggle--closed show-mobile">
        <span
          className="nav-toggle__button show-mobile"
          onClick={toggleMenu}
        ></span>

        <nav className="nav__items">
          <NavLink
            to="/"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Inicio
          </NavLink>
          <NavLink
            to="/operaciones-bitcoin"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Cómo funciona
          </NavLink>
          {/* <NavLink to="/feanor-btc" exact activeClassName="selectedLink" className="nav__item" onClick={toggleMenu}>
                fEANOR
              </NavLink> */}
          <NavLink
            to="/ayuda-bitcoin"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Ayuda
          </NavLink>
          {currentToken ? (
            <NavLink
              to="/perfil"
              activeClassName="selectedLink"
              className="nav__item"
              onClick={toggleMenu}
            >
              Perfil
            </NavLink>
          ) : (
            ""
          )}
          {currentToken ? (
            <>
              <button type="button" onClick={logout} className="nav__item">
                Cerrar sesión
              </button>
            </>
          ) : (
            <NavLink
              to="/auth/login"
              className="nav__item"
              onClick={toggleMenu}
            >
              Iniciar sesión
            </NavLink>
          )}
        </nav>
      </div>
    );
  }
};

export default ToggleableNav;
