import React, { useState, useContext, useEffect } from "react";
import { css } from "glamor";

import { AuthContext } from "Auth";
// Styled components
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Styled from "./styles";

import feanorapiapi from "feanorapi.js";
import swal from "sweetalert";

import useModal from "hooks/useModal";
import { ContractsList } from "pages/Auth/Register/components";
import { getDiditLink } from "services/user.service";
import constants from "utils/constants";

const toastProps = {
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true,
  bodyClassName: css({ fontFamily: "Montserrat" }),
};

const CustomFieldsForm = () => {
  const [Modal, open, openModal, onClose] = useModal();
  const { userInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [buttonState, setButtonState] = useState("Continuar");

  const [residentialAddress, setResidentialAddress] = useState("");
  const [residentialPhone, setResidentialPhone] = useState("");
  const [phone, setPhone] = useState("");
  const [profession, setProfession] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [workplaceName, setWorkplaceName] = useState("");
  const [workPosition, setWorkPosition] = useState("");
  const [yearsInPosition, setYearsInPosition] = useState("");
  const [isEEUUResident, setIsEEUUResident] = useState("no");
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState("no");
  const [checked, setChecked] = useState(false);

  // Get user auth
  let { currentToken } = useContext(AuthContext);

  const updateUserData = async () => {
    setLoading(true);
    setButtonState("Cargando...");

    if (residentialAddress === "") {
      toast(
        "Es obligatorio seleccionar tu Dirección de Residencia",
        toastProps
      );
      return false;
    }
    if (residentialPhone === "") {
      toast("Es obligatorio seleccionar tu Teléfono Residencial", toastProps);
      return false;
    }

    if (phone === "") {
      toast("Es obligatorio seleccionar tu Teléfono Móvil", toastProps);
      return false;
    }

    if (profession === "") {
      toast("Es obligatorio seleccionar tu Profesión que ejerce", toastProps);
      return false;
    }

    if (workplace === "") {
      toast("Es obligatorio seleccionar tu Lugar de Trabajo", toastProps);
      return false;
    }

    if (workplaceName === "") {
      toast(
        "Es obligatorio seleccionar tu Nombre de la Empresa donde Labora Actualmente",
        toastProps
      );
      return false;
    }

    if (workPosition === "") {
      toast("Es obligatorio seleccionar tu Cargo", toastProps);
      return false;
    }

    if (yearsInPosition === "") {
      toast("Es obligatorio seleccionar tus Años en el Cargo", toastProps);
      return false;
    }

    if (isEEUUResident === "") {
      toast(
        "Es obligatorio seleccionar si eres ciudadano o residente de los Estados Unidos",
        toastProps
      );
      return false;
    }

    if (isPoliticallyExposed === "") {
      toast(
        "Es obligatorio seleccionar si eres una Persona Políticamente Expuesta (PEP)",
        toastProps
      );
      return false;
    }

    if (!checked) {
      toast("Porfavor acepte los terminos y condiciones", toastProps);
      return false;
    }

    let data = {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        residentialAddress,
        residentialPhone,
        phone,
        profession,
        workplace,
        workplaceName,
        workPosition,
        yearsInPosition,
        isEEUUResident,
        isPoliticallyExposed,
      }),
    };
    let endpoint = "/client/user/customFields/update";
    let url = `${feanorapiapi}${endpoint}`;
    fetch(url, data)
      .then(async (response) => {
        if (response.ok) {
          const diditLink = await getDiditLink();
          setButtonState("Redirigiendo...");
          window.location.href = diditLink.url;
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {})
      .catch((error) => {
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    console.log({ userInfo });
    if (userInfo) {
      setResidentialAddress(userInfo.residentialAddress ?? "");
      setResidentialPhone(userInfo.residentialPhone ?? "");
      setPhone(userInfo.phone ?? "");
      setProfession(userInfo.profession ?? "");
      setWorkplace(userInfo.workplace ?? "");
      setWorkplaceName(userInfo.workplaceName ?? "");
      setWorkPosition(userInfo.workPosition ?? "");
      setYearsInPosition(userInfo.yearsInPosition ?? "");
      setIsEEUUResident(userInfo.isEEUUResident ?? "");
      setIsPoliticallyExposed(userInfo.isPoliticallyExposed ?? "");
      setChecked(userInfo.agreementAccepted ?? false);
    }
  }, [userInfo]);

  return (
    <Styled.ProfileList>
      {/* {level1Requested && (
        <WarningBox>
          <Paragraph>
            <b>Atención: </b>Previamente ya enviaste tus datos a revisión, por
            favor verificar que todo este en orden. En caso de algun error
            puedes corregirlo cancelando esta solicitud.
            <Styled.ButtonCancel
              onClick={() => {
                cancelRequestLevel1();
              }}
            >
              Cancelar solicitud
            </Styled.ButtonCancel>
          </Paragraph>
        </WarningBox>
      )} */}

      <Modal open={open} onClose={onClose}>
        <p
          style={{
            color: "black",
            padding: "38px",
            lineHeight: "1.8",
            textAlign: "center",
          }}
        >
          Sirva mi firma digital como señal que autorizo expresamente a FËANOR a
          verificar toda la información suministrada en este formulario, de
          acuerdo a las leyes y decretos nacionales e internacionales
          establecidos en función de cumplir la prevención del blanqueo de
          capitales, financiamiento del terrorismo y Financiamiento de la
          Proliferación de Armas de Destrucción Masiva. Igualmente autorizo a
          verificar mis datos en en listas de verificacación internacional como
          ONU, OFAC. Bajo el cumplimiento de la Ley 81 de Protección de datos
          FËANOR hará fiel cumplimiento de dicha ley sobre los datos y
          documentos digitales proporcionados por el cliente.
        </p>
      </Modal>

      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>
          Dirección de Residencia:
        </Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="residentialAddress"
          placeholder="Dirección de Residencia"
          value={residentialAddress}
          onChange={(e) => setResidentialAddress(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Teléfono Residencial:</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="phone"
          placeholder="Teléfono Residencial"
          value={residentialPhone}
          onChange={(e) => setResidentialPhone(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Teléfono Móvil:</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="phone"
          placeholder="Teléfono Móvil"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Profesión que ejerce:</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="profession"
          placeholder="Profesión que ejerce"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Lugar de Trabajo:</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="workplace"
          placeholder="Lugar de Trabajo"
          value={workplace}
          onChange={(e) => setWorkplace(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>
          Nombre de la Empresa donde Labora Actualmente:
        </Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="workplaceName"
          placeholder="Nombre de la Empresa donde Labora Actualmente"
          value={workplaceName}
          onChange={(e) => setWorkplaceName(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Cargo:</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="workPosition"
          placeholder="Cargo"
          value={workPosition}
          onChange={(e) => setWorkPosition(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Años en el Cargo:</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="yearsInPosition"
          placeholder="Años en el Cargo"
          value={yearsInPosition}
          onChange={(e) => setYearsInPosition(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ProfileItem style={{ margin: "17px 0" }}>
        <Styled.ProfileItemLabel>
          ¿Eres ciudadano o residente de los Estados Unidos?:
        </Styled.ProfileItemLabel>

        <Styled.CheckLabel>
          <Styled.CheckInput
            required
            type="radio"
            value={"si"}
            name="isEEUUResident"
            checked={isEEUUResident === "si"}
            onChange={(e) => setIsEEUUResident("si")}
          />
          Si
        </Styled.CheckLabel>
        <Styled.CheckLabel>
          <Styled.CheckInput
            required
            value={"no"}
            type="radio"
            name="isEEUUResident"
            checked={isEEUUResident === "no"}
            onChange={(e) => setIsEEUUResident("no")}
          />
          No
        </Styled.CheckLabel>
      </Styled.ProfileItem>

      <Styled.ProfileItem style={{ margin: "17px 0" }}>
        <Styled.ProfileItemLabel>
          ¿Eres una Persona Políticamente Expuesta (PEP)?:
        </Styled.ProfileItemLabel>

        <Styled.CheckLabel>
          <Styled.CheckInput
            required
            type="radio"
            value={"si"}
            name="isPoliticallyExposed"
            checked={isPoliticallyExposed === "si"}
            onChange={(e) => setIsPoliticallyExposed("si")}
          />
          Si
        </Styled.CheckLabel>

        <Styled.CheckLabel>
          <Styled.CheckInput
            required
            type="radio"
            value={"si"}
            name="isPoliticallyExposed"
            checked={isPoliticallyExposed === "no"}
            onChange={(e) => setIsPoliticallyExposed("no")}
          />
          No
        </Styled.CheckLabel>
      </Styled.ProfileItem>

      <Styled.ProfileItem>
        {/* <Styled.ProfileItemLabel>Contratos:</Styled.ProfileItemLabel> */}
        <ContractsList
          textColor="black"
          checked={checked}
          setChecked={setChecked}
        />
      </Styled.ProfileItem>

      {userInfo.kycVerificationStatus === "" && (
        <Styled.ButtonSave onClick={updateUserData} disabled={loading}>
          {buttonState}
        </Styled.ButtonSave>
      )}
      {userInfo.kycVerificationStatus ===
        constants.verificationStatus.verified && (
        <Styled.ButtonSave disabled={true} className="disabled-link">
          Ya estas verificado!
        </Styled.ButtonSave>
      )}
      {userInfo.isKYCApprovalPending && (
        <Styled.ButtonSave disabled={true} className="disabled-link">
          Aprobación pendiente...
        </Styled.ButtonSave>
      )}
    </Styled.ProfileList>
  );
};

export default CustomFieldsForm;
