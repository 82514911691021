import { makeStyles } from "@material-ui/core";

export const useMUIStyles = makeStyles((theme) => ({
  smallBtn: {
    backgroundColor: "rgb(255 255 255 / 91%)",
    padding: "2px 12px",
    fontSize: "0.74rem",
    margin: "0 0",
    textTransform: "none",
    border: "1px solid #ff8a65",
    // width: "100px",
    "&:hover": {
      backgroundColor: "rgb(255 255 255 / 91%)",
    },
    "@media (max-width: 600px)": {
      margin: "0 5px",
    },
  },
  smallBtnIcon: {
    marginRight: "4px",
    fontSize: "17px",
  },
  transactionsBtn: {
    border: "1px solid #ff8a65",
    textTransform: "none",
    margin: "5px 0",
  },
  fundCardBtn: {
    marginRight: "10px",
    background: "#ff8a65",
    textTransform: "none",
    color: "white",
    padding: "5px 20px",
    margin: "5px 0",
    "&:hover": {
      background: "#d27355",
    },
  },
}));
