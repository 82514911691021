import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { TransactionsContext } from "contexts/transactions";
import { AuthContext } from "Auth";
import swal from "sweetalert";
import { exportExcel } from "./excelFunctions";
import feanorapi from "feanorapi.js";


const ExportButton = () => {
  // const { query } = useContext(TransactionsContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let { currentToken } = useContext(AuthContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportExcel = () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/business/transaction";
    let url = `${feanorapi}${endpoint}`

    fetch(`${url}?file=true`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-available" };
        }
      })
      .then((responseJson) => {
        exportExcel(responseJson.transactions);
      })
      .catch((error) => {
        console.log(error)
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Exportar
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleExportExcel}>Excel</MenuItem>
      </Menu>
    </div>
  );
};

export default ExportButton;
