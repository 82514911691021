import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "Auth";
import SaldoDoughnuts from "./charts/SaldoDoughnut";
import {
  TextSaldo,
  AmountCurrency,
  TextCuponAviso,
  TextCuponAvisoVenc,
  WalletContent,
  WalletBox,
  WalletBoxLeft,
  WalletBoxRight,
  TitleBox,
  DisplayCount,
  GraphContent,
  DescriptionBox,
  ButtonContent,
  BtnPrimary,
  BtnSecondary,
  TitleModal,
  BodyModal,
  FooterModal,
  InputModal,
  LabelModal,
  LabelSpan,
  LabelSpanAlert,
  BtnPrimaryLg,
  ListLinks,
  ListLinksItem,
  ImageModal,
  TextBox,
  BtnSecondaryCenter,
  ListRowDiv,
  ListColumnDiv,
  ColText,
  ColStatus,
  DetailsOperation,
  DetailTitle,
  DetailAmount,
  Clear,
  Margin20,
  BtnPrimaryFull,
  DescriptionModal,
  AlertModal,
  ColState,
  CheckLabel,
  CheckInput,
  BtnSeeMore,
} from "styles/Styled";
import ObjectCalculator from "./object-calculator/ObjectCalculator";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";
import { PrimaryText, SecondaryText } from "styles/colorized";
import { Header2, Paragraph, SelectorFull } from "styles/common/index.js";

import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import iconSendBTC from "media/gif/bitcoin_bounce.gif";
import iconReceiveBTC from "media/gif/bitcoin_group.gif";

import WAValidator from "wallet-address-validator";
import ReactTimeAgo from "react-time-ago";

import ImageUploader from "react-images-upload";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

// import ReactGA from 'react-ga';

import analyticsCode from "analyticsCode.js";
import constants from "utils/constants";

const SaldoUSD = () => {
  let history = useHistory();

  const [totalCompra, setTotalCompra] = useState(0);
  const [totalVenta, setTotalVenta] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [calculatorObj, setCalculatorObj] = useState(undefined);
  const [btcValueinUSD, setBTCValue] = useState(0);
  const [user, setDataUser] = useState({});
  const [couponObj, setCoupon] = useState({});
  const [cuponValue, setCuponValue] = useState(0);

  let { currentToken } = useContext(AuthContext);

  /* Wallet */

  // Data
  const [addressBtc, setAddressBtc] = useState("----");
  const [transactionsData, setTransactionsData] = useState([]);

  // Modals
  const [openReceive, setOpenReceive] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [sendTo, setSendTo] = useState("");
  const [sendAmount, setSendAmount] = useState("0.00");
  const [feePerOperation, setFeePerOperation] = useState(0.0);

  // For statuses
  const [haveWallet, setHaveWallet] = useState(false);
  const [haveTransactions, setHaveTransactions] = useState(false);
  const [fundCreate, setFundCreate] = useState(false);
  const [isFundsLegit, setIsFundsLegit] = useState(false);
  const [loadingButton, setLoadingbutton] = useState(false);

  // Bank Accounts
  const [havePenAccount, setHavePenAccount] = useState(false);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountId, setBankAccountId] = useState("");
  const [bankName, setBankName] = useState("");
  const [banks, setBanks] = useState([]);
  // Abono
  const [fundId, setFundId] = useState("");
  const [imageFund, setImageFund] = useState("");

  // Retiro
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);

  // ReactGA.initialize(analyticsCode);

  const createWallet = async () => {
    const jsonBody = JSON.stringify({ currency: "USD" });

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: jsonBody,
    };
    let endpoint = "/client/wallet";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          getWallet();
          //swal({text: "Billetera creada correctamente. ¡Disfruta de los pagos del futuro!", button: true, timer: 3000});
          return response.json();
        } else {
          //swal({text: "Ha habido un error al crear tu billetera. Si el error persiste escríbenos a Soporte.", button: false, timer: 5000});
        }
      })
      .catch((error) => {
        //swal({text: "Ha habido un error al crear tu billetera. Si el error persiste escríbenos a Soporte.", button: false, timer: 5000});
      });
  };

  const checkAddressBTC = async (address) => {
    console.log("Verificando dirección BTC...");

    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet/isInternalClient/" + address;
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.client == true) {
          setFeePerOperation(0.00015);
        } else {
          setFeePerOperation(0.001);
        }
      })
      .catch((error) => {
        console.log("Error al verificar la direccion BTC...");
        setFeePerOperation(0.001);
      });
  };

  const getTransactions = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet/transaction?currency=USD&limit=4";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        if (responseJson) {
          setTransactionsData(responseJson);
          setHaveTransactions(true);
        } else {
          // No billetera
          setHaveTransactions(false);
        }
      })
      .catch((error) => {
        swal({
          text:
            "Ha ocurrido un error mientras cargabamos los datos de tus transacciones",
          button: false,
          timer: 5000,
        });
      });
  };

  const getWallet = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/wallet";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        console.log(responseJson);

        const walletData = responseJson;

        // Si tiene billetera
        if (walletData) {
          console.log("Wallets detectadas.");
          //setAddressBtc(walletData.wallet.address);

          walletData.forEach(function(item) {
            if (item.currency == "USD") {
              setSaldo(item.balance.toFixed(2));
              setHaveWallet(true);
              getTransactions();
            }
          });
        } else {
          // No billetera
          setHaveWallet(false);
          createWallet();
        }
      })
      .catch((error) => {
        createWallet();
        swal({
          text:
            "Estamos creando tu billetera de Dólares, en unos segundos la verás... :)",
          button: false,
          timer: 3000,
        });
      });
  };

  const createFund = async () => {
    const jsonBody = JSON.stringify({
      amount: sendAmount,
      currency: "USD",
      bankId: bankAccountId,
    });

    // Validacion

    if (sendAmount == "" || sendAmount == "0") {
      swal({
        text: "Coloca el monto en dólares a abonar.",
        button: true,
        timer: 5000,
      });
      return;
    }

    if (sendAmount < 20 || sendAmount > 10000) {
      swal({
        text: "El monto a abonar debe estar entre 20 y 10000.",
        button: true,
        timer: 5000,
      });
      return;
    }

    setLoadingbutton(true);

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        "Content-Type": "application/json",
      },
      body: jsonBody,
    };
    let endpoint = "/client/wallet/deposit";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          setFundCreate(true);
          setLoadingbutton(false);

          // ReactGA.event({
          //   category: 'Abono Dólares',
          //   action: 'Solicitud de abono Dólares Exitosa'
          // });

          return response.json();
        } else {
          swal({
            text:
              "Ha habido un error al crear la solicitud de abono. Intenta más tarde.",
            button: false,
            timer: 5000,
          });
        }
      })
      .then((responseJson) => {
        //setOpenSend(false);
        setFundId(responseJson.transaction._id);
      })
      .catch((error) => {
        swal({
          text:
            "Ha habido un error al crear la solicitud de abono. Intenta más tarde.",
          button: false,
          timer: 5000,
        });
      });
  };

  const confirmFund = async (newProp) => {
    let body = { ...newProp };
    var formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }

    setLoadingbutton(true);

    let data = {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
      body: formData,
    };
    let endpoint = "/client/wallet/deposit/confirm/" + fundId;
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          swal({
            text:
              "Ha habido un error al confirmar tu abono. Notifica a soporte.",
            button: false,
            timer: 5000,
          });
        }
      })
      .then((responseJson) => {
        setOpenSend(false);
        setFundCreate(false);
        getTransactions();
        swal({
          text:
            "¡Listo! Tu operación de abono fue registrada. Durante el transcurso del día revisaremos la solicitud.",
          button: false,
          timer: 5000,
        });
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error al confirmar tu abono. Intenta más tarde.",
          button: false,
          timer: 5000,
        });
      });
  };

  const createWithdrawal = async () => {
    const jsonBody = JSON.stringify({
      amount: sendAmount,
      currency: "USD",
      bankId: bankAccountId,
    });

    // Validacion

    if (bankAccountId == "" || !bankAccountId) {
      swal({
        text: "Porfavor agrega una cuenta bancaria en dolares.",
        button: true,
        timer: 5000,
      });
      return;
    }
    if (sendAmount == "" || sendAmount == "0") {
      swal({
        text: "Coloca el monto en dólares a retirar",
        button: true,
        timer: 5000,
      });
      return;
    }

    // Dólares
    if (sendAmount < 10) {
      swal({
        text: "El monto a retirar debe ser mayor a 10.",
        button: true,
        timer: 5000,
      });
      return;
    }

    setLoadingbutton(true);

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        "Content-Type": "application/json",
      },
      body: jsonBody,
    };
    let endpoint = "/client/wallet/withdraw";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json();
          //swal({text: "Ha habido un error al crear la solicitud de retiro.", button: false, timer: 5000});
        }
      })
      .then((responseJson) => {
        //setOpenSend(false);

        if (responseJson.type != "withdraw-requested") {
          swal({ text: responseJson.msg, button: false, timer: 5000 });
        } else {
          confirmWithdrawal(responseJson.transaction._id);
          swal({
            text: "Retiro solicitado correctamente",
            button: false,
            timer: 5000,
          });
          setWithdrawSuccess(true);
          onCloseModalReceive();

          // ReactGA.event({
          //   category: 'Retiro Dólares',
          //   action: 'Solicitud de retiro Dólares Exitosa'
          // });
        }
        console.log(responseJson);
      })
      .catch((error) => {
        swal({
          text:
            "Ha habido un error al crear la solicitud de retiro. Intenta más tarde.",
          button: false,
          timer: 5000,
        });
      });
  };

  const confirmWithdrawal = async (withdrawalId) => {
    let data = {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        "Content-Type": "application/json",
      },
    };
    let endpoint = "/client/wallet/withdraw/confirm/" + withdrawalId;
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          swal({
            text: "Ha habido un error al confirmar la solicitud de retiro.",
            button: false,
            timer: 5000,
          });
        }
      })
      .then((responseJson) => {
        setOpenReceive(false);
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error al confirmar el retiro. Intenta más tarde.",
          button: false,
          timer: 5000,
        });
      });
  };

  const getUser = async () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/client/user";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        setDataUser(responseJson);
        /*if(responseJson.hasRegisterCoupon){
            getCoupon(responseJson.registerCouponId);
          }*/
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    // Call Wallet
    getWallet();
    getBankAccounts();
    getUser();
    // const fetchData = async () => {
    //   const result = await getUser();
    // };
  }, [withdrawSuccess]);

  const onOpenModalReceive = () => {
    if (user.kycVerificationStatus !== constants.verificationStatus.verified) {
      swal({
        text:
          "Para hacer retiros es necesario la verificación de cuenta, te redirireccionaremos al proceso de validacion.",
        button: false,
        timer: 4000,
      });
      setTimeout(function() {
        history.push(`/app/profile/activation`);
      }, 4000);
      return;
    }
    setOpenReceive(true);
    setLoadingbutton(false);
  };

  const onCloseModalReceive = () => {
    setOpenReceive(false);
    setWithdrawSuccess(false);
  };

  const onOpenModalSend = () => {
    setLoadingbutton(false);

    if (havePenAccount) {
      setOpenSend(true);
    } else {
      swal({
        text:
          "¡Aún no tienes tu cuenta bancaria de Dólares registrada!. Te estamos redirigiendo hacia allá para que puedas registrarla...",
        button: true,
        timer: 4000,
      });
      setTimeout(function() {
        history.push(`/app/profile/settings/`);
      }, 4000);
    }
  };

  const onCloseModalSend = () => {
    setOpenSend(false);
  };

  const typeOperation = (string) => {
    if (string == "send") return "Envio";
    if (string == "receive") return "Depósito";
    if (string == "deposit") return "Depósito";
    if (string == "withdraw") return "Retiro";
    if (string == "convert") return "Conversión";
  };

  const typeState = (string) => {
    if (string == "DELIVERED") return "Entregado";
    else if (string == "CANCELED") return "Cancelado";
    else if (string == "REQUESTED") return "Pendiente";
    else if (string == "CREATED") return "Creado";
    else return "Creado";
  };

  const maxSendValue = () => {
    setSendAmount(saldo - feePerOperation);
  };

  const onHandleAddressChange = (e) => {
    let addressInput = e.target.value;

    setSendTo(addressInput);

    if (addressInput != "" && WAValidator.validate(addressInput, "BTC")) {
      checkAddressBTC(addressInput);
    } else {
      setFeePerOperation(0.0);
    }
  };

  const onHandleAmountBTCChange = (e) => {
    let amount = e.target.value;

    if (amount.length <= 10) {
      setSendAmount(amount);
    } else {
      return false;
    }

    if (amount <= 99999) {
      setSendAmount(amount);
    } else {
      setSendAmount(1);
    }
  };

  const onKeyPressBTC = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  // Abonos y retiros

  const onImageVoucher = (files) => {
    toast("Subiendo foto de voucher, espere unos segundos ...", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: false,
    });
    setImageFund(files[files.length - 1]);

    // ReactGA.event({
    //   category: 'Archivo',
    //   action: 'Subir archivo correctamente en Abono Dólares'
    // });
  };

  const getBankAccounts = () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        let bankAccountsData = responseJson.banks;

        if (responseJson.usd != 0) {
          setHavePenAccount(true);
          let accountsSelect = [];
          bankAccountsData.forEach(function(item) {
            if (item.currency == "USD") {
              accountsSelect.push({
                value: item._id,
                label: `${item.accountNumber} - ${item.name}`,
              });
              setBankAccountId(item._id);
            }
          });
          setBanks(accountsSelect);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectBank = (option) => {
    // setAccountType(option.value);
    // setBankAccountNumber(item.accountNumber);
    setBankAccountId(option.value);
    // setBankName(item.name);
  };
  return (
    <>
      {saldo !== undefined ? (
        <>
          <TextSaldo>
            <PrimaryText>
              Tienes un saldo total de{" "}
              <AmountCurrency>{saldo} Dólares</AmountCurrency>
            </PrimaryText>
          </TextSaldo>

          <WalletContent>
            <WalletBoxLeft>
              <TitleBox>Saldo</TitleBox>
              <DisplayCount>{saldo} USD</DisplayCount>
            </WalletBoxLeft>

            <WalletBoxRight>
              {haveWallet && (
                <DescriptionBox>
                  Tienes <b>$ {saldo} </b> para usar
                </DescriptionBox>
              )}

              {haveWallet && (
                <ButtonContent>
                  <BtnPrimary onClick={onOpenModalSend}>Abonar</BtnPrimary>
                  <BtnSecondary onClick={onOpenModalReceive}>
                    Retirar
                  </BtnSecondary>
                </ButtonContent>
              )}

              {!haveWallet && (
                <DescriptionBox>
                  Aún no tienes una billetera. Creala ahora mismo con un clic.
                </DescriptionBox>
              )}

              {!haveWallet && (
                <ButtonContent>
                  <BtnPrimary onClick={createWallet}>Crear</BtnPrimary>
                </ButtonContent>
              )}
            </WalletBoxRight>
          </WalletContent>

          <WalletContent>
            <WalletBoxLeft>
              <TitleBox>Últimas operaciones</TitleBox>

              {Object.keys(transactionsData).length <= 0 && (
                <TextBox>
                  No existen operaciones por el momento :(. Comienza a comprar o
                  depositar.
                </TextBox>
              )}

              {transactionsData.map((transaccion) => {
                const transactionObj = { ...transaccion };
                //const date = new Date(transactionObj.createdAt);

                let amount = transactionObj.amount.toFixed(2);
                if (transactionObj.category == "convert")
                  amount = transactionObj.ammounts[0].toFixed(2);

                return (
                  <ListRowDiv key={transaccion._id}>
                    <ListColumnDiv>
                      <ColText>{`${amount} USD`} </ColText>
                    </ListColumnDiv>

                    <ListColumnDiv>
                      <ColText>
                        <ReactTimeAgo
                          date={transactionObj.createdAt}
                          locale="es"
                        />{" "}
                      </ColText>
                    </ListColumnDiv>

                    <ListColumnDiv>
                      <ColStatus>{`${typeOperation(
                        transactionObj.category
                      )}`}</ColStatus>
                    </ListColumnDiv>

                    <ListColumnDiv>
                      <ColState title="Este es el estado en el que se encuentra la operación">{`${typeState(
                        transactionObj.state
                      )}`}</ColState>
                    </ListColumnDiv>
                  </ListRowDiv>
                );
              })}

              {Object.keys(transactionsData).length >= 4 && (
                <BtnSeeMore to={`/app/profile/operations`}>Ver más</BtnSeeMore>
              )}
            </WalletBoxLeft>
            <WalletBoxRight>
              <TitleBox>Conoce más</TitleBox>

              <ListLinks>
                <ListLinksItem>
                  <Link to={`/app/operation/new`}> Comprar Bitcoin</Link>
                </ListLinksItem>
                <ListLinksItem>
                  <a target="_blank" href="#">
                    Comisiones de retiro
                  </a>
                </ListLinksItem>
                <ListLinksItem>
                  <a target="_blank" href="#">
                    Ayuda
                  </a>
                </ListLinksItem>
              </ListLinks>
            </WalletBoxRight>
          </WalletContent>
        </>
      ) : (
        <>
          <Paragraph>
            Debes realizar un abono de dólares para visualizar tu saldo
            correspondiente.
          </Paragraph>
        </>
      )}

      <Modal
        open={openSend}
        onClose={onCloseModalSend}
        center
        closeOnOverlayClick={false}
      >
        {!fundCreate && (
          <BodyModal>
            <TitleModal>Abonar dinero a mi billetera Dólares</TitleModal>

            <DescriptionModal>
              Te permite ingresar dinero a tu billetera, esto luego podrás
              utilizarlo para comprar bitcoins.
            </DescriptionModal>

            <AlertModal>
              Los abonos pueden demorar hasta 1 día hábil en ser procesado y
              estar disponible en tu cuenta de Fëanor, de lunes a viernes entre
              las 9:00 y 18:00 Horas. (GMT -5)
            </AlertModal>

            <LabelModal>
              <LabelSpan>Moneda</LabelSpan>
              <InputModal type="text" value="Dólares ($)" disabled />
            </LabelModal>
            <LabelModal>
              <LabelSpan>Monto a abonar (Mínimo: 20 USD)</LabelSpan>
              <InputModal
                type="number"
                min="0"
                value={sendAmount}
                onKeyPress={(e) => onKeyPressBTC(e)}
                onChange={(e) => onHandleAmountBTCChange(e)}
              />
            </LabelModal>

            <LabelModal>
              <LabelSpan>
                Cuenta origen (Desde dónde nos enviarás el dinero)
              </LabelSpan>
              <InputModal
                type="text"
                value={bankAccountNumber + " - " + bankName}
                disabled
              />
            </LabelModal>

            <CheckLabel>
              <CheckInput
                required
                type="checkbox"
                name="isFundsLegit"
                checked={isFundsLegit}
                onChange={(e) => setIsFundsLegit(true)}
              />
              Declaro que el origen de los fondos y/o valores relacionados con
              la operación que antecede es legítimo
            </CheckLabel>

            <Margin20></Margin20>

            <DetailsOperation>
              <DetailTitle>Monto a abonar</DetailTitle>
              <DetailAmount>{sendAmount} USD</DetailAmount>
            </DetailsOperation>

            <Clear></Clear>

            <DetailsOperation>
              <DetailTitle>Comisión (¡Gratis!)</DetailTitle>
              <DetailAmount>0.00 USD</DetailAmount>
            </DetailsOperation>

            <Clear></Clear>

            <DetailsOperation>
              <DetailTitle>Monto total</DetailTitle>
              <DetailAmount>
                {(Number(sendAmount) + Number(feePerOperation)).toFixed(2)} USD
              </DetailAmount>
            </DetailsOperation>

            <Margin20></Margin20>
            <Margin20></Margin20>

            <BtnPrimaryLg disabled={loadingButton} onClick={() => createFund()}>
              Solicitar abono
            </BtnPrimaryLg>
            <FooterModal>
              <b>Nota:</b> Una vez que confimes el abono, en el siguiente paso
              te indicaremos el proceso y solicitaremos el voucher de la
              operación. Es importante que envies el dinero desde tu cuenta
              origen.
            </FooterModal>
          </BodyModal>
        )}

        {fundCreate && (
          <BodyModal>
            <TitleModal>Confirmar abono a billetera dólares</TitleModal>

            <DescriptionModal>
              ¡Bien!, ahora un último paso. Necesitamos que deposites el monto
              indicado a nuestra cuenta bancaria oficial. No olvides poner tu
              voucher y clicar en el botón para finalizar.
            </DescriptionModal>

            <LabelModal>
              <LabelSpan>Monto a depositar ($)</LabelSpan>
              <InputModal type="number" min="0" value={sendAmount} disabled />
            </LabelModal>

            <Margin20></Margin20>

            <DetailsOperation>
              <DetailTitle>Banco</DetailTitle>
              <DetailAmount>Capital Bank</DetailAmount>
            </DetailsOperation>

            <Clear></Clear>

            <DetailsOperation>
              <DetailTitle>Tipo de cuenta</DetailTitle>
              <DetailAmount>Cuenta corriente</DetailAmount>
            </DetailsOperation>

            <Clear></Clear>

            <DetailsOperation>
              <DetailTitle>Número de cuenta</DetailTitle>
              <DetailAmount>06202003272</DetailAmount>
            </DetailsOperation>

            <Clear></Clear>

            <DetailsOperation>
              <DetailTitle>Cuenta a nombre de</DetailTitle>
              <DetailAmount>FËANOR Corp.</DetailAmount>
            </DetailsOperation>

            <Clear></Clear>

            <DetailsOperation>
              <DetailTitle>Monto a abonar</DetailTitle>
              <DetailAmount>{sendAmount} USD</DetailAmount>
            </DetailsOperation>

            <Clear></Clear>

            <LabelModal>
              <LabelSpan>Voucher de pago</LabelSpan>
              <ImageUploader
                withPreview={true}
                withIcon={true}
                buttonText="Elegir foto o captura de voucher"
                onChange={onImageVoucher}
                imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                maxFileSize={5242880}
                singleImage={true}
                withLabel={false}
              />
            </LabelModal>

            <BtnPrimaryFull
              disabled={loadingButton}
              onClick={() => confirmFund({ voucherImage: imageFund })}
            >
              Ya realicé el depósito
            </BtnPrimaryFull>
            <FooterModal>
              <b>Nota:</b> El abono será acreditado a tu cuenta una vez que
              confirmemos el depósito.
            </FooterModal>
          </BodyModal>
        )}
      </Modal>

      <Modal
        open={openReceive}
        onClose={onCloseModalReceive}
        center
        closeOnOverlayClick={true}
      >
        <BodyModal>
          <TitleModal>Retirar efectivo de billetera dólares</TitleModal>

          <AlertModal>
            Los abonos pueden demorar hasta de 1 a 2 días hábiles en ser
            procesado y estar disponible en tu cuenta bancaria, solo se
            consideran los días hábiles.
          </AlertModal>

          <LabelModal>
            <LabelSpan>Moneda</LabelSpan>
            <InputModal type="text" value="Dólares ($)" disabled />
          </LabelModal>
          <LabelModal>
            <LabelSpan>Monto a retirar (Mínimo: 20 USD)</LabelSpan>
            <InputModal
              type="number"
              min="0"
              value={sendAmount}
              onKeyPress={(e) => onKeyPressBTC(e)}
              onChange={(e) => onHandleAmountBTCChange(e)}
            />
          </LabelModal>

          <LabelModal>
            <LabelSpan>Cuenta destino (A dónde enviaremos el dinero)</LabelSpan>
            <SelectorFull
              required
              options={banks}
              value={banks.find((e) => e.value === bankAccountId)}
              onChange={handleSelectBank}
            ></SelectorFull>
            {/* <InputModal
              type="text"
              value={bankAccountNumber + " - " + bankName}
              disabled
            /> */}
          </LabelModal>

          <Margin20></Margin20>

          <DetailsOperation>
            <DetailTitle>Monto a retirar</DetailTitle>
            <DetailAmount>{sendAmount} USD</DetailAmount>
          </DetailsOperation>

          <Clear></Clear>

          <DetailsOperation>
            <DetailTitle>Comisión de retiro</DetailTitle>
            <DetailAmount>0.00 USD</DetailAmount>
          </DetailsOperation>

          <Clear></Clear>

          <DetailsOperation>
            <DetailTitle>Monto a recibir</DetailTitle>
            <DetailAmount>
              {(Number(sendAmount) - Number(0.0)).toFixed(2)} USD
            </DetailAmount>
          </DetailsOperation>

          <Margin20></Margin20>
          <Margin20></Margin20>

          <BtnPrimaryLg
            disabled={loadingButton}
            onClick={() => createWithdrawal()}
          >
            Solicitar retiro
          </BtnPrimaryLg>

          <FooterModal>
            <b>Importante:</b> Fëanor no se hace responsable del efectivo
            enviado a una cuenta bancaria errada. Verificamos antes que la
            cuenta te pertenezca y cumpla con tu datos de identidad.{" "}
          </FooterModal>
        </BodyModal>
      </Modal>

      <Modal
        open={false}
        onClose={onCloseModalReceive}
        center
        closeOnOverlayClick={true}
      >
        <BodyModal>
          <TitleModal>Necesitas añadir tus cuentas bancarias</TitleModal>

          <ImageModal src={iconReceiveBTC} />

          <LabelModal>
            <LabelSpan>Dirección de tu billetera</LabelSpan>
            <InputModal readOnly type="text" value={addressBtc} />
          </LabelModal>

          <FooterModal>
            <b>Importante:</b> Fëanor no se hace responsable de las monedas
            enviadas a una dirección equivocada. Esta es tu dirección que debes
            brindar para recibir bitcoins. Después de realizar un depósito puede
            hacer el seguimiento del progreso en la página <b>Operaciones</b>.
          </FooterModal>
        </BodyModal>
      </Modal>
    </>
  );
};

export default SaldoUSD;
