import React, { useEffect } from "react";
import { Header4, Paragraph, WarningBox } from "styles/common/index.js";
import * as Styled from "./styles";
import TableTransaccinesBusiness from "components/TableTransaccinesBusiness";
import { TransactionsProvider } from "contexts/transactions";
import useModal from "hooks/useModal";
import aviso from "aviso.json";

const Transacciones = () => {
  const [Modal, isOpenModal, openModal, onCloseModal] = useModal();
  useEffect(() => {
    if (aviso.message) {
      openModal();
    }
  }, []);

  return (
    <>
      <TransactionsProvider>
        {aviso.message && (
          <WarningBox>
            <Paragraph>
              <div>
                <b>Atención: </b>
              </div>
              {aviso.message}
            </Paragraph>
            <Modal
              open={isOpenModal}
              onClose={onCloseModal}
              center
              closeOnOverlayClick={true}
              variant="large"
            >
              <WarningBox>
                <Paragraph>
                  <div>
                    <b>Atención: </b>
                  </div>
                  {aviso.message}
                </Paragraph>
              </WarningBox>
            </Modal>
          </WarningBox>
        )}

        <Header4>Transacciones</Header4>

        <Styled.SubtitleAndButton>
          <Paragraph>Listado de transacciones.</Paragraph>
        </Styled.SubtitleAndButton>

        <TableTransaccinesBusiness />
      </TransactionsProvider>
    </>
  );
};

export default Transacciones;
