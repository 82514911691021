import FormRequestOTP from "components/FormRequestOTP/FormRequestOTP";
import FormRequestPassword from "components/FormRequestPassword/FormRequestPassword";
import React, { useState } from "react";
import swal from "sweetalert";

export const FormRevealData = ({
  otpEnabled,
  handleFlipCard,
  closeModal,
  cardId,
  setPrivateData,
  fetchCardDataRequest,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmitCredentials = async () => {
    try {
      setLoading(true);
      setErrorMsg("");
      const { success, pan, cvv, dueDate, name, pin } = await fetchCardDataRequest({
        cardId,
        otpToken,
        password,
      });
      if (success) {
        setPrivateData({ pan, cvv, dueDate, name, pin });
        closeModal();
        await new Promise((resolve) => setTimeout(resolve, 400));
        handleFlipCard();
        setErrorMsg("");
      }
    } catch (error) {
      await new Promise((resolve) => setTimeout(resolve, 700));
      console.log(error);
      if (error.response) {
        console.log(error.response);

        if (error.response.data.code === "invalid-otp") {
          setErrorMsg("El código ingresado es inválido");
          return;
        }

        if (error.response.data.code === "incorrect-password") {
          setErrorMsg("La contraseña ingresada es inválida");
          return;
        }

        if (!error.response.data.success) {
          closeModal();
          swal(
            "Ocurrío un error",
            "No se pudo obtener los datos de la tarjeta, por favor intente nuevamente más tarde",
            "error"
          );
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {otpEnabled ? (
        <FormRequestOTP
          errorMsg={errorMsg}
          otpToken={otpToken}
          setOtpToken={setOtpToken}
          buttonTitle="Enviar código"
          buttonTitleLoading="Validando..."
          loading={loading}
          onSubmit={onSubmitCredentials}
        />
      ) : (
        <FormRequestPassword
          errorMsg={errorMsg}
          password={password}
          setPassword={setPassword}
          buttonTitle="Validar contraseña"
          buttonTitleLoading="Validando..."
          loading={loading}
          onSubmit={onSubmitCredentials}
        />
      )}
    </>
  );
};
