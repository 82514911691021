import React from "react";
import Table from "@material-ui/core/Table";
import Typografy from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const TxDetail = ({ transaction }) => {
  return (
    <div style={{ marginTop: "30px" }}>
      <TableContainer>
        <Table aria-label="simple table">
          {transaction && (
            <TableBody>
              {transaction.tx && (
                <TableRow>
                  <TableCell variant="head" align="left">
                    <Typografy>
                      <b>Hash</b>
                    </Typografy>
                  </TableCell>
                  <TableCell variant="head" align="center">
                    {transaction.tx}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell variant="head" align="left">
                  <Typografy>
                    <b>Tipo de transacción</b>
                  </Typografy>
                </TableCell>
                <TableCell variant="head" align="center">
                  {transaction.transactionType}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="left">
                  <Typografy>
                    <b>Monto</b>
                  </Typografy>
                </TableCell>
                <TableCell variant="head" align="center">
                  {transaction.amountCurrency}
                </TableCell>
              </TableRow>
              {transaction.fee && (
                <TableRow>
                  <TableCell variant="head" align="left">
                    <Typografy>
                      <b>Comisión</b>
                    </Typografy>
                  </TableCell>
                  <TableCell variant="head" align="center">
                    {transaction.fee}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell variant="head" align="left">
                  <Typografy>
                    <b>Total Ejecutado</b>
                  </Typografy>
                </TableCell>
                <TableCell variant="head" align="center">
                  {transaction.totalExecuted}
                </TableCell>
              </TableRow>
              {!!transaction.rate && (
                <TableRow>
                  <TableCell variant="head" align="left">
                    <Typografy>
                      <b>Tasa</b>
                    </Typografy>
                  </TableCell>
                  <TableCell variant="head" align="center">
                    {transaction.rate || "-"}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell variant="head" align="left">
                  <Typografy>
                    <b>F. Creación</b>
                  </Typografy>
                </TableCell>
                <TableCell variant="head" align="center">
                  {transaction.date.getDate()}/
                  {("0" + (transaction.date.getMonth() + 1)).slice(-2)}/
                  {1900 + transaction.date.getYear()} -{" "}
                  {("0" + transaction.date.getHours()).slice(-2)}:
                  {("0" + transaction.date.getMinutes()).slice(-2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="left">
                  <Typografy>
                    <b>Estado</b>
                  </Typografy>
                </TableCell>
                <TableCell variant="head" align="center">
                  {transaction.state}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default TxDetail;
