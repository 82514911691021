import feanorapi from "feanorapi";
import http from "http-common";
import { authHeaders, getToken, headers } from "./common";

export const generateOTP = async () => {
  console.log(
    "🚀 ~ file: auth.service.js:14 ~ generateOTP ~ authHeaders",
    authHeaders
  );
  const response = await http.post(
    "/client/otp/generate",
    {},
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // ...headers,
        // ...authHeaders,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const enableOTP = async (otpToken) => {
  const response = await http.post(
    "/client/otp/enable",
    { token: otpToken },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const disableOTP = async (otpToken) => {
  const response = await http.post(
    "/client/otp/disable",
    { otpToken },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const verifyOTP = async (temporalToken, otpToken) => {
  const response = await http.post(
    "/auth/otp/verify",
    { otpToken, temporalJWT: temporalToken },
    {
      headers: {
        ...authHeaders,
      },
    }
  );
  console.log(response);
  return response.data;
};
