import styled from "styled-components";

export const TableDesktop = styled.table`
  width: 100%;
  border-collapse: collapse;
  @media (max-width: 600px) {
    display: none;
  }
`;

export default {
  TableDesktop
}
