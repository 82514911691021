const constants = {
  verificationStatus: {
    verified: "verified",
    reviewNeeded: "reviewNeeded",
    rejected: "rejected",
  },
  networks: {
    "0x4": {
      name: "rinkeby",
      link: "https://rinkeby.etherscan.io/",
      rarible: "https://rinkeby.rarible.com/token/",
    },
    "0x1": {
      name: "mainnet",
      link: "https://etherscan.io/",
      rarible: "https://rarible.com/token/",
    },
    "0xaa36a7": {
      name: "sepolia",
      link: "https://sepolia.etherscan.io",
      rarible: "https://rarible.com/token/",
    },
  },
  currencyTypes: {
    USDT: "USDT",
    BTC: "BTC",
    USDT_TRON: "USDT_TRON",
  },
  minDepositAmount: process.env.REACT_APP_ENV_TYPE === "production" ? 10 : 0,
};

export const operations = {
  REVEAL_DATA: "revealData",
  CONFIRM_RECETION: "confirmReception",
  BLOCK: "block",
  UNBLOCK: "unblock",
  CHANGE_PIN: "changePIN",
};

export const c1TipoExpense = [
  "PeticiónAutorización",
  "PeticionAutorizacion",
  "AutorizaciónComunicación",
  "AutorizacionComunicacion",
  "TransaccionCorregidaNegativa"
];
export const c1TipoIncome = [
  "ComunicaciónAnulación",
  "ComunicacionAnulacion",
  "TransaccionCorregidaPositiva",
];
export const c1TipoNeutral = [
  "ReversalRequest",
  "DENEGADA",
  "DENEGADA. PIN INCORRECTO",
  "DENEGADA. INTENTOS PIN EXCEDIDO",
  "DENEGADA. TARJETA NO EFECTIVA",
  "DENEGADA. CVV2 INCORRECTO",
  "DENEGADA. INCORRECTO CVV2",
  "IMPORTE SUPERA LÍMITE",
  "NO HAY FONDOS",
  "EXCEDIDO NUMERO DE OPERACION DIARIO",
  "FECHA CADUCIDAD ERRONEA",
  "NotificacionDenegacion",
  "TransaccionConfirmada"
];

export default constants;
