import React from "react";
import { Link } from "react-router-dom";
import {
  ListLinks,
  ListLinksItem,
  TitleBox,
  WalletBoxRight,
} from "styles/Styled";

const MoreAboutUSDTTron = () => {
  return (
    <WalletBoxRight>
      <TitleBox>Conoce más</TitleBox>

      <ListLinks>
        {/* <ListLinksItem>
          <Link to={`#`}>¿Sin fondos? Comprar Tethers de tron</Link>
          <Link to={`/app/operation/new`}>¿Sin fondos? Comprar Tethers de tron</Link>
        </ListLinksItem> */}
        <ListLinksItem>
          <a target="_blank" href="#">
            Comisiones de envio y retiro
          </a>
        </ListLinksItem>
        <ListLinksItem>
          <a target="_blank" href="#">
            Ayuda
          </a>
        </ListLinksItem>
      </ListLinks>
    </WalletBoxRight>
  );
};

export default MoreAboutUSDTTron;
