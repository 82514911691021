import axios from "axios";

import feanorapi from 'feanorapi.js';


export default axios.create({
  baseURL:  `${feanorapi}`,
  headers: {
    "Content-type": "application/json"
  }
});