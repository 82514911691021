import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Container,
  makeStyles,
  withStyles,
  LinearProgress,
  Typography,
  Tabs,
  Box,
  Button,
  Tab,
  Link,
} from "@material-ui/core";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import ImageGallery from "react-image-gallery";

import feanorapiapi from "feanorapi.js";

import * as Styled from "./styles";
import useModal from "hooks/useModal";
import colors from "styles/common/colors";
import { timeFormatter } from "utils/utils.js";
import { PurchaseNFTProvider } from "contexts/purchaseNFT";
import { SubscribeNFTProvider } from "contexts/subscribeNFT";

import PurchaseSteper from "./Purchase";
import SubscribeForm from "./Subscribe";

import { AuthContext } from "Auth";

import "react-image-gallery/styles/css/image-gallery.css";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    tabs: {
      borderRight: `1px solid ${colors.feanor_orange}`,
    },
  },
  containerTabs: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    color: "black",
  },
  tab: {
    fontSize: "12px",
    fontWeight: "600",
  },
  indicator: {
    background: colors.feanor_orange,
  },
  containerForm: {
    height: "700px",
    // width: "80%",
    padding: theme.spacing(7),
  },
}));
const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 300 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: colors.feanor_gold,
  },
}))(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        // border: "1px solid rgb(0,0,0,0.4)",
        marginLeft: "20px",
        // borderRadius: "10px",
        width: "80%",
      }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProjectDetail = (props) => {
  const history = useHistory();

  let projectId = props.match.params.id;
  const classes = useStyles();
  const [Modal, open, openModal, onClose] = useModal();

  const [project, setProject] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const {
    currentToken,
    expiration,
    clearAuthentication,
    userType,
  } = useContext(AuthContext);
  const isAuthenticated =
    currentToken &&
    expiration &&
    parseInt(expiration) - Date.now() > 1000 &&
    userType === "person";
  !isAuthenticated && clearAuthentication();

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getProject = () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
    };
    let endpoint = `/public/project/${projectId}`;
    let url = `${feanorapiapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.project) {
          setProject(responseJson.project);
        } else history.push("/nft-market/proyectos");
      })
      .catch((error) => {
        console.log(error);
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  useEffect(() => {
    getProject();

    return () => {
      setProject({});
    };
  }, []);

  const timeRemaining_Handler = (endDate) => {
    let timeRemaining = new Date();
    endDate = new Date(endDate);
    timeRemaining = endDate - timeRemaining;
    timeRemaining = timeRemaining / (1000 * 60 * 60 * 24);

    return timeRemaining.toFixed(1) > 0 ? timeRemaining.toFixed(1) : 0;
  };

  const getImages = (project) => {
    let imgs = [];
    if (project.secondaryImages?.length) {
      imgs = project?.secondaryImages?.map((e) => ({
        original: `${feanorapiapi}/public/image/general/${e}`,
        thumbnail: `${feanorapiapi}/public/image/general/${e}`,
      }));
      // imgs = [...imgs];
    }
    if (project.mainImage) {
      imgs.push({
        original: `${feanorapiapi}/public/image/general/${project.mainImage}`,
        thumbnail: `${feanorapiapi}/public/image/general/${project.mainImage}`,
      });
    }
    return imgs;
  };

  const percentage_handler = (selledNfts, totalNfts) => {
    if (selledNfts && totalNfts) {
      const percentage = (selledNfts / totalNfts) * 100;

      return percentage;
    } else {
      return 0;
    }
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container spacing={10}>
        <Grid
          item
          xs={12}
          sm={6}
          // className={classes.projectItem}
        >
          {JSON.stringify(project) !== "{}" && (
            <ImageGallery items={getImages(project)} />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          // className={classes.projectItem}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              // className={classes.projectItem}
            >
              <BorderLinearProgress
                variant="determinate"
                value={percentage_handler(
                  project.selledNfts,
                  project.totalNfts
                )}
              />
            </Grid>
            <Grid
              item
              xs={6}
              // className={classes.projectItem}
            >
              <Typography>
                <Styled.Subtitle> RECOLECTADO</Styled.Subtitle>
              </Typography>
              <Typography>
                <Styled.Data> {project.selledNfts} NFTs</Styled.Data>
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              // className={classes.projectItem}
            >
              <Typography>
                <Styled.Subtitle> META</Styled.Subtitle>
              </Typography>
              <Typography>
                <Styled.Data> {project.totalNfts} NFTs</Styled.Data>
              </Typography>
            </Grid>
            {/* <Grid
              item
              xs={6}
              // className={classes.projectItem}
            >
              <Typography>
                <Styled.Subtitle> INVERSIONISTAS</Styled.Subtitle>
              </Typography>
              <Typography>
                <Styled.Data> {project.nftsAvailable} NFTs</Styled.Data>
              </Typography>
            </Grid> */}
            <Grid item xs={6}>
              <Typography>
                <Styled.Subtitle> COSTO</Styled.Subtitle>
              </Typography>
              <Typography>
                <Styled.Data> {project.tokenCost} ETH</Styled.Data>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Styled.Subtitle> RETORNO</Styled.Subtitle>
              </Typography>
              <Typography>
                <Styled.Data> {project.retorno} %</Styled.Data>
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              // className={classes.projectItem}
            >
              <Typography>
                <Styled.Subtitle>INICIO</Styled.Subtitle>
              </Typography>
              <Typography>
                <Styled.Data>{timeFormatter(project.startDate)}</Styled.Data>
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              // className={classes.projectItem}
            >
              <Typography>
                <Styled.Subtitle>CIERRE</Styled.Subtitle>
              </Typography>
              <Typography>
                <Styled.Data>{timeFormatter(project.endDate)}</Styled.Data>
              </Typography>
            </Grid>
            {/* <Grid
              item
              xs={6}
              // className={classes.projectItem}
            >
              <Typography>
                <Styled.Subtitle> TIEMPO RESTANTE</Styled.Subtitle>
              </Typography>
              <Typography>
                <Styled.Data>
                  {timeRemaining_Handler(project.endDate)} Días
                </Styled.Data>
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={12}
              // className={classes.projectItem}
            >
              {project.status != "closed" && (
                <Typography>
                  <Button
                    style={{
                      backgroundColor: colors.feanor_gold,
                      textTransform: "none",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => openModal()}
                  >
                    {project.status === "blocked" && "Suscribirse"}
                    {project.status === "opened" && "Invertir"}
                  </Button>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        {JSON.stringify(project) !== "{}" && (
          <Grid container spacing={2} className={classes.containerTabs}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={currentTab}
              onChange={handleChangeTab}
              aria-label="Vertical tabs example"
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
            >
              <Tab
                className={classes.tab}
                label="Información"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tab}
                label="Productor"
                {...a11yProps(1)}
              />
              <Tab
                className={classes.tab}
                label="Actualizaciones"
                {...a11yProps(2)}
              />
              <Tab
                className={classes.tab}
                label="Archivos adjuntos"
                {...a11yProps(3)}
              />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
              <p>{project.shortDescription}</p>
              <pre
                className="long-description"
                dangerouslySetInnerHTML={{
                  __html: project.description,
                }}
              ></pre>
              {/* {project.description} */}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  // className={classes.projectItem}
                >
                  <Typography>
                    <Styled.Subtitle> NOMBRE</Styled.Subtitle>
                  </Typography>
                  <Typography>
                    <Styled.Data>{project.author.name}</Styled.Data>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              Actualizaciones
            </TabPanel>

            <TabPanel value={currentTab} index={3}>
              {isAuthenticated ? (
                project.attachments.map((e) => {
                  return (
                    e && (
                      <li key={e}>
                        {e.split("--")[0]}{" "}
                        <a
                          target="_blank"
                          href={`${feanorapiapi}/public/file/general/${e}`}
                        >
                          (descargar)
                        </a>
                      </li>
                    )
                  );
                })
              ) : (
                <Typography
                  className={classes.root}
                  style={{ textAlign: "center", color: "black" }}
                >
                  Por favor inicie sesión{" "}
                  <Link
                    href="#"
                    onClick={() => {
                      history.push(`/auth/login`);
                      window.location.reload();
                    }}
                  >
                    aquí
                  </Link>{" "}
                  para poder visualizar esta sección
                </Typography>
              )}
            </TabPanel>
          </Grid>
        )}
      </Grid>
      <Modal
        open={open}
        onClose={onClose}
        classNames={{ modal: "customModal" }}
      >
        <PurchaseNFTProvider>
          {project.status === "opened" && (
            <PurchaseSteper
              projectDetailProp={project}
              getProject={getProject}
            />
          )}
        </PurchaseNFTProvider>

        <SubscribeNFTProvider>
          {project.status === "blocked" && (
            <SubscribeForm projectDetailProp={project} />
          )}
        </SubscribeNFTProvider>
      </Modal>
    </Container>
  );
};

export default ProjectDetail;
