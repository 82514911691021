import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "Auth";
import swal from "sweetalert";
import { HashLink } from 'react-router-hash-link';

const ConfigurationsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { clearAuthentication } = useContext(AuthContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSession = () => {
    console.log(history);
    swal({
      text: "Se ha cerrado sesión. Muchas gracias",
      button: false,
      timer: 3000,
    });
    clearAuthentication();
    history.push("/auth/login_business");
  };

  const securityOnClick = () => {
    history.push("/business/dashboard/security");
  };
  return (
    <>
      <Button
        aria-controls="menu-list-grow"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Preferencias
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={securityOnClick}>Seguridad</MenuItem>
        {/* <MenuItem >Configuracion</MenuItem> */}
        <MenuItem onClick={handleCloseSession}>Salir</MenuItem>
      </Menu>
    </>
  );
};

export default ConfigurationsMenu;
