import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./Login/index.js";
import Register from "./Register/index.js";
import Recover from "./Recover/index.js";
import ChangePassword from "./ChangePassword/index.js";
import NotFound from "pages/NotFound";
import Styled from './styles';
import LoginBusiness from "./LoginBusiness/index.js";
import OTP from "./2fa/OTP.jsx";
import BussinessOTP from "./2fa/BussinessOTP.jsx";

const Auth = () => {
  return (
    <Styled.AuthContainer>
      <Styled.AuthBox>
        <Switch>
          <Route path="*/login" component={Login} />
          <Route path="*/login_business" component={LoginBusiness} />
          <Route path="*/register" component={Register} />
          <Route path="*/change_password/:token" component={ChangePassword} />
          <Route path="*/recover" component={Recover}/>
          <Route path="*/2fa" component={OTP}/>
          <Route path="*/2fa_business" component={BussinessOTP}/>
          <Route path="*/" component={Auth}>
            <Redirect to="/auth/login" />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Styled.AuthBox>
    </Styled.AuthContainer>
  );
};

export default Auth;
