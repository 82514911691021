import React, { useEffect, useState, useContext } from "react";
import ImageUploader from 'react-images-upload';
import { css } from 'glamor';

import { AuthContext } from "Auth";
// Styled components
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Styled from './styles';

import feanorapi from 'feanorapi';
import swal from 'sweetalert';
import {  Selector, WarningBox, Paragraph } from 'styles/common/index.js';

const UserData = () => {
  
  const [level1Requested, setLevel1Requested] = useState(false);
  const [level1Completed, setLevel1Completed] = useState(false);
  const [user, setDataUser] = useState({});

  
  const [typePerson, setTypePerson] = useState('natural');


  // Natural 
  const [profession, setProfession] = useState("");
  const [incomeRank, setIncomeRank] = useState("");
  const [residenceAddress, setResidenceAddress] = useState(""); 

  // Juridica 
  const [ruc, setRuc] = useState(""); 
  const [legalRepresentative, setLegalRepresentative] = useState("");
 
 
  // Get user auth
  let { currentToken } = useContext(AuthContext);

  const getUser = () => {
    let data =  {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
    }
    let endpoint = '/client/user';
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if(response.ok){
          return response.json();
        }else{
          throw {'type': 'non-user'}
        }
      })
      .then((responseJson)=>{
          setDataUser(responseJson);
          setLevel1Requested(responseJson.isSecondLevelApprovalPending);
          setLevel1Completed(responseJson.secondLevelApproved); 

          setProfession(responseJson.profession);
          setIncomeRank(responseJson.incomeRank);
          setResidenceAddress(responseJson.residenceAddress);
          setRuc(responseJson.ruc);
          setLegalRepresentative(responseJson.legalRepresentative);

      })
      .catch((error)=>{
        swal({text: "Ha habido un error en nuestro sistema", button: false, timer: 1000});
      })
  }

  const getImages = () => {
    let data =  {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
    }
    let endpoint = '/client/user';
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if(response.ok){
          return response.json();
        }else{
          throw {'type': 'non-user'}
        }
      })
      .then((responseJson)=>{
          setDataUser(responseJson);
        
      })
      .catch((error)=>{
        swal({text: "Ha habido un error en nuestro sistema", button: false, timer: 1000});
      })
  }


  const requestLevel1 = () => {
    let data =  {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
    }
    let endpoint = '/client/user/secondlevel/requestvalidation';
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if(response.ok){

          getUser();
          
          toast("Solicitud de validación NIVEL 2 enviada exitosamente :)", {position: toast.POSITION.TOP_CENTER, hideProgressBar: true, bodyClassName: css({fontFamily: 'Montserrat'})});
          
          return response.json();
        }else{
          throw {'type': 'non-user'}
        }
      })
      .then((responseJson)=>{

      })
      .catch((error)=>{
        swal({text: "Ha habido un error en nuestro sistema", button: false, timer: 1000});
      })
  }

  const cancelRequestLevel1 = () => {
    let data =  {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
    }
    let endpoint = '/client/user/secondlevel/cancelrequestvalidation';
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if(response.ok){
          getUser();
          return response.json();
        }else{
          throw {'type': 'non-user'}
        }
      })
      .then((responseJson)=>{

        
        getUser();
          
        
      })
      .catch((error)=>{
        swal({text: "Ha habido un error en nuestro sistema", button: false, timer: 1000});
      })
  }

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [currentToken]);

 

  const updateData = (newProp) => {
    
    toast("Actualizando datos...", {position: toast.POSITION.TOP_CENTER, hideProgressBar: true, bodyClassName: css({fontFamily: 'Montserrat'})});
    let body = {...newProp};
    var formData = new FormData();
    for ( let key in body ) {
        formData.append(key, body[key]);
    }
    let data =  {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Authorization': `Bearer ${currentToken}`
        },
        body: formData // body data type must match "Content-Type" header
    }
    let endpoint = '/client/user/secondlevel/update';
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if(response.ok){ 
          
          // Obtener usuario
          getImages();
        }
        return response.json();
      })
      .then((responseJson)=>{
        toast(responseJson.msg, {position: toast.POSITION.TOP_CENTER, hideProgressBar: true, bodyClassName: css({fontFamily: 'Montserrat'})});
      })
      .catch((error)=>{
        swal({text: "Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.", button: false, timer: 1000});
      })
  }

  const onDropDNIFront = files => {
    toast("Subiendo foto selfie, espere unos segundos ...", {position: toast.POSITION.TOP_CENTER, hideProgressBar: false, bodyClassName: css({fontFamily: 'Montserrat'})});
    const image = files[files.length-1];
    updateData({pictureWithDocument:image});
  };

  const onDropDNIBack = files => {
    toast("Subiendo foto de recibo de luz o agua, espere unos segundos ...", {position: toast.POSITION.TOP_CENTER, hideProgressBar: false, bodyClassName: css({fontFamily: 'Montserrat'})});
    const image = files[files.length-1];
    updateData({utilitiesWater:image});
  };
  

  const handleSelect = option => {
    setTypePerson(option.value);
  };

  const handleSelectIncome = option => {
    setIncomeRank(option.value);
  };

 

  const DocumentOptions = [
    {value: "natural", label: "Persona Natural"},
    {value: "juridica", label: "Persona Jurídica (Institución o Empresa)"},
  ];

  const DocumentOptionsObject = {
    natural: {value: "natural", label: "Persona Natural"},
    juridica: {value: "juridica", label: "Persona Jurídica"}
  }; 


  const IncomeOptions = [
    {value: 1, label: "De 10 000-15 000 dólares"},
    {value: 2, label: "De 15 000-50 000 dólares"},
    {value: 3, label: "Mayor a 50 000 dólares"},
  ];

  const IncomeOptionsObject = {
    1: {value: "low", label: "De 10 000-15 000 dólares"},
    2: {value: "mid", label: "De 15 000-50 000 dólares"},
    3: {value: "high", label: "Mayor a 50 000 dólares"}
  };




  return (
    <Styled.ProfileList >
       
       {(level1Requested) && (
       <WarningBox>
          <Paragraph>
          <b>Atención: </b>Previamente ya enviaste tus datos a revisión, por favor verificar que todo este en orden. En caso de algun error puedes corregirlo cancelando esta solicitud. 
          
          <Styled.ButtonCancel onClick={()=> { cancelRequestLevel1();} }>Cancelar solicitud</Styled.ButtonCancel>

          

          </Paragraph>
          
        </WarningBox>
      )} 


      {(level1Completed) && (
       <WarningBox>
          <Paragraph>
          <b>¡Todo en orden!: </b>Previamente ya enviaste tus datos y tu cuenta ya esta validado en NIVEL 2. 
          

          

          </Paragraph>
          
        </WarningBox>
      )} 

      

      <Styled.ProfileItem className={`${level1Requested || level1Completed ? "disabled-link" : ""}`}>
        <Styled.ProfileItemLabel>
        Tipo de persona:
        </Styled.ProfileItemLabel>
        <Selector required options={DocumentOptions}  value={DocumentOptionsObject[typePerson]}
              onChange={handleSelect}></Selector>

      </Styled.ProfileItem>

      <Styled.ContainerImages className={`${level1Requested || level1Completed ? "disabled-link" : ""}`}>

      {(typePerson == 'natural') && (
        <Styled.ProfileItem>
          <Styled.ProfileItemLabel>Foto con documento en la mano, y un papel con la fecha de hoy escrita:</Styled.ProfileItemLabel>
          {user.pictureWithDocument && <Styled.DisplayImage src={`${feanorapi}/public/image/general/${user.pictureWithDocument}`}/>}
          {!user.pictureWithDocument && <ImageUploader
            withIcon={false}
            buttonText='Elegir imagen de selfie de documento y fecha'
            onChange={onDropDNIFront}
            imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
          />}
        </Styled.ProfileItem>
      )} 
        <Styled.ProfileItem >
          <Styled.ProfileItemLabel>Foto de recibo de agua o luz:</Styled.ProfileItemLabel>
          {user.utilitiesWater && <Styled.DisplayImage src={`${feanorapi}/public/image/general/${user.utilitiesWater}`}/>}
          {!(user.utilitiesWater) && <ImageUploader
            withIcon={false}
            buttonText='Elegir imagen de recibo de agua o luz'
            onChange={onDropDNIBack}
            imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
          />}
        </Styled.ProfileItem>
      </Styled.ContainerImages>

      {(typePerson == 'natural') && (
      <Styled.ProfileItem className={`${level1Requested || level1Completed ? "disabled-link" : ""}`}>
        <Styled.ProfileItemLabel>
          Profesión:
        </Styled.ProfileItemLabel>
        <Styled.TextInput required type="text" name="profession" placeholder="Ingresa tu profesión" value={profession} onChange={(e)=>setProfession(e.target.value)}/>
      </Styled.ProfileItem>
      )} 

      
      
      {(typePerson == 'natural') && (
      <Styled.ProfileItem className={`${level1Requested || level1Completed ? "disabled-link" : ""}`}>
        <Styled.ProfileItemLabel>
          Dirección de Residencia:
        </Styled.ProfileItemLabel>
        <Styled.TextInput required type="text" name="residenceAddress" placeholder="Ingresa tu dirección donde resides actualmente" value={residenceAddress} onChange={(e)=>setResidenceAddress(e.target.value)}/>
      </Styled.ProfileItem>
      )}
     
     {(typePerson == 'juridica') && (
      <Styled.ProfileItem className={`${level1Requested || level1Completed ? "disabled-link" : ""}`}>
        <Styled.ProfileItemLabel>
          RUC:
        </Styled.ProfileItemLabel>
        <Styled.TextInput required type="text" name="ruc" placeholder="Ingresa RUC" value={ruc} onChange={(e)=>setRuc(e.target.value)}/>
      </Styled.ProfileItem>
      )}
      
      {(typePerson == 'juridica') && (
      <Styled.ProfileItem className={`${level1Requested || level1Completed ? "disabled-link" : ""}`}>
        <Styled.ProfileItemLabel>
          Actividad principal:
        </Styled.ProfileItemLabel>
        <Styled.TextInput required type="text" name="activity" placeholder="Ingresa actividad principal" value={profession} onChange={(e)=>setProfession(e.target.value)}/>
      </Styled.ProfileItem>
      )}
      
      {(typePerson == 'juridica') && (
      <Styled.ProfileItem className={`${level1Requested || level1Completed ? "disabled-link" : ""}`}>
        <Styled.ProfileItemLabel>
          Representante Legal
        </Styled.ProfileItemLabel>
        <Styled.TextInput required type="text" name="legalRepresentative" placeholder="Ingresa representante legal" value={legalRepresentative} onChange={(e)=>setLegalRepresentative(e.target.value)}/>
      </Styled.ProfileItem>
      )}

      <Styled.ProfileItem className={`${level1Requested || level1Completed ? "disabled-link" : ""}`}>
        <Styled.ProfileItemLabel>
        Monto Anual a depositar en Fëanor (aprox):
        </Styled.ProfileItemLabel>
        <Selector required options={IncomeOptions}  value={IncomeOptionsObject[incomeRank]}
              onChange={handleSelectIncome}></Selector>

      </Styled.ProfileItem>
     
 

      <Styled.ButtonSave className={`${level1Requested || level1Completed ? "disabled-link" : ""}`} disabled={level1Requested} onClick={()=> {updateData({profession, residenceAddress, ruc, legalRepresentative, incomeRank}); requestLevel1();} }>
      {level1Requested && !level1Completed ? "Pendiente de aprobación" : ""}
      {!level1Requested && !level1Completed ? "Enviar mi información" : ""}
      {!level1Requested && level1Completed ? "Nivel completado" : ""}


      </Styled.ButtonSave> 

    </Styled.ProfileList>
  );
};

export default UserData;
