/* eslint-disable no-underscore-dangle */
import React from "react";
import * as styledComponents from './styles';

const Navigator = ({ beforeEnabled, before, page, next, nextEnabled }) => {
  return (
    <styledComponents.PaginatorContainer>
      {beforeEnabled &&
        <styledComponents.Button left onClick={before}>
          ❮ Anterior
        </styledComponents.Button>
      }
      {/* <styledComponents.Number>{1}</styledComponents.Number> */}
      <styledComponents.Number>{page + 1}</styledComponents.Number>
      {nextEnabled &&
        <styledComponents.Button right onClick={next}>
          Siguiente ❯
        </styledComponents.Button>
      }
    </styledComponents.PaginatorContainer>
  );
};

export default Navigator;
