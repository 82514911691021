import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { toast } from "react-toastify";
import ReactGA from "react-ga";
import analyticsCode from "analyticsCode.js";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import es from "javascript-time-ago/locale/es";
// import ReactPixel from 'react-facebook-pixel';
import moment from "moment";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import esDP from "date-fns/locale/es";
import { LocalizationProvider } from "@material-ui/pickers";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import CookieConsent from "react-cookie-consent";

registerLocale("es", esDP);
moment.locale("es", {
  months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
    "_"
  ),
  monthsShort: "Ene._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.".split(
    "_"
  ),
  weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
  weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
  weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
});

JavascriptTimeAgo.addLocale(en);
JavascriptTimeAgo.addLocale(es);

// ReactGA.initialize(analyticsCode);
// ReactGA.pageview(window.location.pathname + window.location.search);

// const options = {
//     autoConfig: true, // set pixel's autoConfig
//     debug: false, // enable logs
//   };

// ReactPixel.init('638564803493448', {}, options);
// ReactPixel.pageView(); // For tracking page view
// ReactPixel.fbq('track', 'PageView');

toast.configure();

ReactDOM.render(
  <LocalizationProvider dateAdapter={MomentUtils}>
    <App />
    <CookieConsent
      buttonText="Entendido"
      buttonStyle={{
        background: "#c99e49",
        color: "black",
        fontSize: "13px",
        borderRadius: "20px",
      }}
    >
      Este sitio web usa cookies para mejorar la experiencia de usuario.
    </CookieConsent>
  </LocalizationProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
