import React from "react";

export default function VerticalView({ transacciones }) {
  if (
    transacciones.length === 0 ||
    transacciones === undefined ||
    transacciones === null
  ) {
    return (
      <div className="container-table-phone">
        <h3 className="title-table-phone">No hay transacciones</h3>
      </div>
    );
  }

  return transacciones.map((transaction) => {
    return (
      <table className="container-table-phone" key={transaction._id}>
        <tbody className="row-transaccion-phone">
          {/* <tr>
                <td className="column-header-phone">Hash</td>
                <td>{transaction.tx?.slice(0, 10) + "..."}</td>
              </tr> */}
          <tr>
            <td className="column-header-phone">Tipo</td>
            <td>{transaction.transactionType}</td>
          </tr>
        </tbody>
        <tbody className="row-transaccion-phone">
          <tr>
            <td className="column-header-phone">Monto</td>
            <td> {transaction.transaction}</td>
          </tr>
        </tbody>
        <tbody className="row-transaccion-phone">
          <tr>
            <td className="column-header-phone">Comisión</td>
            <td>{transaction.fee}</td>
          </tr>
        </tbody>
        <tbody className="row-transaccion-phone">
          <tr>
            <td className="column-header-phone">Total Ejecutado</td>
            <td>{transaction.totalExecuted}</td>
          </tr>
        </tbody>
        {/* <tbody className="row-transaccion-phone">
              <tr>
                <td className="column-header-phone">Total Ejecutado</td>
                <td>
                  {`${transaction.ammounts[0]} ${transaction.currencies[0]}`}
                </td>
              </tr>
            </tbody> */}
        <tbody className="row-transaccion-phone">
          <tr>
            <td className="column-header-phone">Tasa</td>
            <td>{transaction.rate || "-"}</td>
          </tr>
        </tbody>
        <tbody className="row-transaccion-phone">
          <tr>
            <td className="column-header-phone">Fecha de creación</td>
            <td>
              {transaction.date.getDate()}/
              {("0" + (transaction.date.getMonth() + 1)).slice(-2)}/
              {1900 + transaction.date.getYear()} -{" "}
              {("0" + transaction.date.getHours()).slice(-2)}:
              {("0" + transaction.date.getMinutes()).slice(-2)}
            </td>
          </tr>
        </tbody>
        <tbody className="row-transaccion-phone">
          <tr>
            <td className="column-header-phone">Estado</td>
            <td>{transaction.state}</td>
          </tr>
        </tbody>
      </table>
    );
  });
}
