import React from "react";
import {
  WalletBoxRight,
  TitleBox,
  ListLinks,
  ListLinksItem,
} from "styles/Styled";
import { Link } from "react-router-dom";

const MoreAbout = () => {
  return (
    <WalletBoxRight>
      <TitleBox>Conoce más</TitleBox>

      <ListLinks>
        <ListLinksItem>
          <a target="_blank" href="https://www.feanor.io/terminos-y-condiciones#card">
            Comisiones
          </a>
        </ListLinksItem>
        <ListLinksItem>
          <a target="_blank" href="https://t.me/Feanor_Corp">
            Soporte
          </a>
        </ListLinksItem>
      </ListLinks>
    </WalletBoxRight>
  );
};

export default MoreAbout;
