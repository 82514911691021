import feanorapi from "feanorapi";
import { getToken, headers } from "./common";
import currencyTypes from "models/currencies";

export const fetchWallets = async (currentToken) => {
  let data = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
  };
  let url = `${feanorapi}/client/wallet`;
  const response = await fetch(url, data);
  const wallets = await response.json();
  return wallets;
};

export const fetchSecondaryWallets = async (currentToken) => {
  let data = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
  };
  let url = `${feanorapi}/client/secondary-wallets`;
  const response = await fetch(url, data);
  const wallets = await response.json();
  return wallets;
};


export const createETHWallet = async (currentToken) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({
      currency: "ETH",
    }),
  };
  let url = `${feanorapi}/client/wallet`;
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res.wallet;
};

export const createUSDTWallet = async (currentToken) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({
      currency: "USDT",
    }),
  };
  let url = `${feanorapi}/client/wallet`;
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res.wallet;
};

export const createUSDTTronWallet = async (currentToken) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({
      currency: currencyTypes.USDT_TRON,
    }),
  };
  let url = `${feanorapi}/client/wallet`;
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res.wallet;
};

export const getChainId = async (currentToken) => {
  let data = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
  };
  let url = `${feanorapi}/public/eth/chain`;
  const response = await fetch(url, data);
  const res = await response.json();
  return res.chainId;
};

export const mintTokens = async (currentToken, amount, projectId) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({ amount, projectId }),
  };
  let url = `${feanorapi}/client/project/invest`;
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res;
};

export const checkIsInternalWallet = async (walletAddress) => {
  let data = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: { ...headers, Authorization: `Bearer ${getToken()}` },
  };
  let endpoint = `/client/wallet/isInternalClient/${walletAddress}`;
  let url = `${feanorapi}${endpoint}`;
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res;
};


export const createSecondaryWallet = async (currentToken) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentToken}`,
    },
    body: JSON.stringify({
      currency: currencyTypes.BTC,
    }),
  };
  let url = `${feanorapi}/client/secondary-wallets`;
  const response = await fetch(url, data);
  const res = await response.json();
  console.log(res);
  return res.wallet;
};