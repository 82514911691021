import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "PrivateRoute";
import Profile from "./Profile/index.js";
import Operation from "./Operation/index.js";

const App = () => {
  return (
    <Switch>
      <PrivateRoute path="*/profile" component={Profile} />
      <PrivateRoute path="*/operation" component={Operation} />
      <Redirect from="/" to="app/profile" />
    </Switch>
  );
};

export default App;
