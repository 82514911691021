import React, { useState } from "react";
import { Header1, Header1Black } from "styles/common";
import { LoginButton, LoginForm, LoginInput } from "styles/Styled";

const FormRequestOTP = ({
  errorMsg,
  otpToken,
  setOtpToken,
  onSubmit,
  buttonTitle = "",
  buttonTitleLoading = "",
  loading = false,
}) => {
  return (
    <div>
      <Header1Black>Verificación de seguridad</Header1Black>

      {errorMsg && (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginBottom: "2px",
          }}
        >
          {errorMsg}
        </p>
      )}
      <LoginForm
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <LoginInput
          name="otpToken"
          type="tel"
          placeholder="Código del autenticador"
          style={{
            marginBottom: "2px",
            outlineWidth: `${errorMsg ? "0" : ""}`,
            borderColor: `${errorMsg ? "red" : ""}`,
          }}
          autoComplete="off"
          value={otpToken}
          onChange={(e) => setOtpToken(e.target.value)}
        />
        <p
          style={{
            color: "black",
            fontSize: "12px",
            marginTop: "2px",
          }}
        >
          Introduce el código de 6 dígitos de la aplicación Google Authenticator
        </p>

        <LoginButton type="submit" disabled={loading}>
          {loading
            ? buttonTitleLoading || "Cargando..."
            : buttonTitle || "Verificar código"}
        </LoginButton>
      </LoginForm>
    </div>
  );
};

export default FormRequestOTP;
