import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "Auth";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import "./styles.css";
import { BtnBlueSm, BtnBlueSm2 } from "styles/common/index.js";
import feanorapi from "feanorapi.js";
import { Icon } from "@iconify/react";
import swal from "sweetalert";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import swal2 from "sweetalert2";

const QRViewer = ({ puntoCobroId, setWalletSelected, walletSelected }) => {
  const { currentToken } = useContext(AuthContext);
  const [walletAddress, setWalletAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const QRRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => QRRef.current,
  });

  // const getWalletPuntoCobro = () => {
  //   let data = {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${currentToken}`,
  //     },
  //   };
  //   let endpoint = "/business/punto/wallet";
  //   let url = `${feanorapi}${endpoint}?currency=BTC&punto=${puntoCobroId}`;

  //   fetch(url, data)
  //     .then((response) => {
  //       console.log(response);
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw { type: "non-available" };
  //       }
  //     })
  //     .then((responseJson) => {
  //       if (responseJson) {
  //         setWalletAddress(responseJson.address);
  //       }
  //     });
  // };

  const getWallet = () => {
    setLoading(true);
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/business/punto/wallet";
    let url = `${feanorapi}${endpoint}?currency=${walletSelected.currency}&punto=${puntoCobroId}`;

    fetch(url, data)
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.json();
        } else {
          swal({
            text: "Ocurrio un error al obtener la wallet",
          });
          throw { type: "non-available" };
        }
      })
      .then((responseJson) => {
        console.log("responseJson", responseJson);
        if (responseJson) {
          setWalletAddress(responseJson.address);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress);
    swal2.fire({
      title: "Dirección copiada",
      text: "",
      timer: 1000,
      showConfirmButton: false,
      icon: "success",
    });
  };

  useEffect(() => {
    getWallet();
    return () => {
      setWalletAddress({});
    };
  }, [walletSelected]);

  return (
    <div>
      <div className="backBtn">
        <a className="backBtn__text" onClick={() => setWalletSelected({})}>
          <Icon width="25" icon="eva:arrow-ios-back-fill" />
          Atras
        </a>
      </div>
      {walletAddress && (
        <>
          <div
            ref={QRRef}
            className="PrintSection"
            style={{ textAlign: "center" }}
          >
            <QRCode value={walletAddress} size={149} />
          </div>
          <h4 className="address">
            {walletAddress.toString()}{" "}
            <IconButton onClick={() => onCopyToClipboard()}>
              <FileCopyIcon />
            </IconButton>
          </h4>

          <div style={{ display: "flex" }}>
            <BtnBlueSm
              style={{ display: "block", margin: "20px auto", width: "100px" }}
              onClick={handlePrint}
            >
              Imprimir
            </BtnBlueSm>
          </div>
        </>
      )}

      {loading && <h4 className="address">Cargando...</h4>}
    </div>
  );
};

export default QRViewer;
