import React from "react";
import styled from "styled-components";
import colors from "styles/common/colors";
import mastercardBg from "media/credit-card/mastercard_background.webp";

export const CardContainer = styled.div``;

// const cardWidth = 220;
// const cardHeight = 333;
const cardWidth = 350;
const cardHeight = 200;

const radius = 15;
export const CardsContainer = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  margin: 52px 0;
  grid-template-columns: repeat(auto-fit, minmax(${cardWidth}px, 1fr));
  gap: 15px;
  place-items: center;
`;

export const Card = styled.div`
  width: ${cardWidth}px;
  height: ${cardHeight}px;
  /* margin: 0 auto */
  color: #fff;
  perspective: 1000px;
  display: flex;
  @media screen and (max-width: 480px) {
    width: 300px;
  }
`;

export const CardInner = styled.div`
  width: 100%;
  max-width: 100%;
  /* height: 100%; */
  position: relative;
  transition: transform 1s;

  transform-style: preserve-3d;
  transform: ${({ flipped }) =>
    flipped ? "rotateY(180deg)" : "rotateY(0deg)"};
`;

export const Face = styled.div`
  /* width: 100%;
  height: 100%; */
  box-sizing: border-box;
  background: linear-gradient(45deg, #7c6537, #c48915);
  /* box-shadow: 0px -2px 12px 3px rgb(191 162 103 / 35%) inset
  background: url(${mastercardBg}); */
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 15px 15px;
  border-radius: ${radius}px;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  backface-visibility: hidden;
  transform: translateZ(0);
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: ${radius}px;
  z-index: 2;
  background: ${({ isActive }) =>
    isActive ? "transparent" : "rgb(126 126 126 / 61%)"};
  display: ${({ isActive }) => (isActive ? "none" : "block")};
`;
export const Front = styled(Face)``;
export const Title = styled.div`
  font-weight: 600;
  font-size: 13px;
`;
export const Status = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
`;
export const ChipImage = styled.img`
  width: 30px;
  /* transform: scaleX(1.13); */
  margin-right: 8px;
`;

export const MastercardImage = styled.img`
  width: 46px;
`;

export const BackgroundImg = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: -1;
`;

export const Body = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  position: absolute;
  bottom: 77px;
`;

export const BodyVisa = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  position: absolute;
  bottom: 24px;
  z-index: 5;
`;

export const Footer = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
`;

export const CardNumber = styled.div`
  font-weight: 600;
  font-size: 17px;
  font-family: monospace;
  letter-spacing: 0.8px;
  /* position: absolute; */
  bottom: 55px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  position: absolute;
  bottom: 15px;
  z-index: 2;
  width: 90%;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
`;

export const ShowDataBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
`;

/** BACK */
export const Back = styled(Face)`
  transform: rotateY(180deg);
`;
export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DataSubtitle = styled.div`
  font-size: 11px;
  font-weight: bold;
`;
export const DataValue = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
