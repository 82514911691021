import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { enableOTP, generateOTP } from "services/auth.service";
import { enableBusinessOTP, generateBusinessOTP } from "services/bussiness/auth.service";
import {
  BodyModal,
  BtnPrimaryFull,
  InputModal,
  LabelModal,
  LabelSpan,
  Margin20,
  TitleModal,
} from "styles/Styled";
import swal2 from "sweetalert2";

const FormEnableOTP = ({ refreshBusinessInfo, closeModal }) => {
  const [otpToken, setOtpToken] = useState("");
  const [otpURI, setOtpURI] = useState("");
  const [otpSecret, setOtpSecret] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleEnableOTP = async (e) => {
    e.preventDefault();
    try {
      const { otpEnabled } = await enableBusinessOTP(otpToken);
      if (otpEnabled) {
        setErrorMsg("");
        swal2.fire({
          title: "",
          text: "Se activó correctamente el doble factor de autenticación.",
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        await refreshBusinessInfo();
        closeModal();
      } else {
        setErrorMsg("El código ingresado es incorrecto.");
      }
    } catch (error) {
      if (error.response && error.response?.data?.code === "invalid-otp") {
        setErrorMsg("Codigo de verificación inválido.");
      }
      console.log(error);
    }
  };

  const handleGenerateOTP = async () => {
    try {
      const { success, secret, uri } = await generateBusinessOTP();
      console.log(Date.now())
      if (success && secret && uri) {
        setOtpURI(uri);
        setOtpSecret(secret);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGenerateOTP();
  }, []);

  return (
    <BodyModal>
      <TitleModal>Activar Google Authenticator</TitleModal>

      <ol style={{ color: "black", padding: 0 }}>
        <li>
          <LabelSpan>
            1.{" "}
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            >
              Instala Google Authenticator
            </a>{" "}
            en tu celular.
          </LabelSpan>
        </li>
        <li>
          <LabelSpan>2. Abre la aplicacion Google Authenticator.</LabelSpan>
        </li>
        <li>
          <LabelSpan>
            3. Selecciona el icono "+", luego selecciona "Escanear un codigo
            QR".
          </LabelSpan>
        </li>
        <li>
          <LabelSpan>
            4. Ahora tu celular entrará en modo "escaneo". Cuando este en este
            modo escanea el codigo QR de abajo:
          </LabelSpan>
        </li>
      </ol>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {otpURI && <QRCode value={otpURI} size={149} />}
        <p style={{ color: "black" }}>{otpSecret}</p>
      </div>
      <Margin20></Margin20>

      <form onSubmit={handleEnableOTP}>
        <LabelModal>
          <LabelSpan>
            {" "}
            Una vez que hayas escaneado el codigo QR, ingresa el codigo de 6
            dígitos:
          </LabelSpan>
          <InputModal
            style={{
              outlineWidth: `${errorMsg ? "0" : ""}`,
              borderColor: `${errorMsg ? "red" : ""}`,
            }}
            type="text"
            value={otpToken}
            onChange={(e) => setOtpToken(e.target.value)}
          />
          {errorMsg && (
            <p style={{ color: "red", fontSize: "12px", marginTop: "2px" }}>
              {errorMsg}
            </p>
          )}
        </LabelModal>
      </form>
      <Margin20></Margin20>

      <BtnPrimaryFull disabled={false} onClick={(e) => handleEnableOTP(e)}>
        Confirmar
      </BtnPrimaryFull>
    </BodyModal>
  );
};

export default FormEnableOTP;
