import styled from "styled-components";
import colors from "styles/common/colors";

export const doneImg = styled.img`
  width: 300px;
  margin: auto;
  display: block;
`;

export const doneTxt = styled.h5`
  text-align: center;
  color: ${colors.feanor_dark_bg};
  font-size: 17px;
`;

export default {
  doneImg,
  doneTxt,
};
