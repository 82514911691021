import { toast } from "react-toastify";
import swal from "sweetalert";
import { css } from "glamor";

export const errorType = {
  toast: "TOAST",
  modal: "MODAL",
};

export const handleErrorMessage = (error, type = errorType.toast) => {
  if (type === errorType.modal) {
    console.log(error.response);
    let message =
      "Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.";
    if (error.response && error.response.data.code) {
      console.log( error.response);
      message = error.response.data.message;
    }
    swal({
      text: message,
      button: false,
      timer: 2000,
    });
  }

  if (type === errorType.toast) {
    const toastProps = {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      bodyClassName: css({ fontFamily: "Montserrat" }),
    };
    toast(error, toastProps);
  }
};

export const handleSuccessMessage = (message) => {
  toast(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    bodyClassName: css({ fontFamily: "Montserrat" }),
  });
};
