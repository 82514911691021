import React from "react";
import CardPremium from "components/CardPremium/CardPremium";
import { CardsContainer } from "components/CardRegular/styled";
import useModal from "hooks/useModal";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  ButtonContent,
  CardsBox,
  DescriptionBox,
  DisplayCount,
  TitleBox,
  WalletContent,
} from "styles/Styled";
import swal from "sweetalert";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import FundCardForm from "../CardDetail/components/FundCardForm";
import { useMUIStyles } from "styles/muiStyes";
import { Button } from "@material-ui/core";
import { fetchVisaBalance } from "services/card.service";
import { useEffect } from "react";
import UpdatedAt from "components/UpdatedAt/UpdatedAt";
import TableVisaTransactions from "components/TableVisaTransaction/TableVisaTransactions";
import useGetUserInfo from "hooks/useGetUser";
import { useContext } from "react";
import { PlatformFeeContext } from "contexts/platformFeeProvider";
import swal2 from "sweetalert2";
import { AuthContext } from "Auth";

export default function PremiumCards({
  otpEnabled,
  walletUSDT,
  walletBTC,
  walletUSDTTron,
  premiumCards,
  refreshCards,
  refreshWallets,
  refreshTransactions,
}) {
  const { platformFee } = useContext(PlatformFeeContext);
  const { userName } = useContext(AuthContext);
  const history = useHistory();
  const [ModalFundCard, open, openModal, onClose] = useModal();
  const [swiper, setSwiper] = useState(null);
  const [, setCurrentSlide] = useState(0);
  const classes = useMUIStyles();
  const [fetchingBalances, setFetchingBalances] = useState(false);
  const [
    ModalTransactions,
    openTransactions,
    openModalTransactions,
    onCloseTransactions,
  ] = useModal();

  const fetchBalances = async () => {
    setFetchingBalances(true);
    const requests = premiumCards.map(async (card) => {
      if (card.createdInSVEmisor) {
        return {
          balance: (await fetchVisaBalance({ cardId: card._id })).balance,
          balanceUpdatedAt: Date.now(),
        };
      }
      return {
        balance: card.balance,
        balanceUpdatedAt: card.balanceUpdatedAt,
      };
    });

    const results = await Promise.allSettled(requests);
    results.map((result, index) => {
      if (result.status === "fulfilled") {
        premiumCards[index].balance = result.value.balance;
        premiumCards[index].updatedAt = new Date(result.value.balanceUpdatedAt);
      } else {
        premiumCards[index].balance = "error";
      }
    });

    setFetchingBalances(false);
  };

  useEffect(() => {
    fetchBalances();
  }, []);

  const hadleOpenFundCard = () => {
    if (!walletUSDT.found && !walletUSDTTron.found) {
      swal({
        text:
          "Aun no cuentas con una billetera USDT porfavor crea una y abona tethers a ella.",
        button: false,
        timer: 6000,
      });
      setTimeout(function() {
        history.push(`/app/profile/money`);
      }, 6000);
      return;
    }

    if (!platformFee.charged) {
      swal2.fire({
        icon: "warning",
        title: `Estimado/a ${userName}:`,
        html:
          "El siguiente desglose es para que conozca algunos costos relevantes a su tarjeta.<br/> $225 - Mantenimiento de plataforma, seguro de fraude, emisión y envío. Este pago se realiza una única vez por año y debe realizarlo previo a la solicitud de su tarjeta. <br/> 5% - Comisión del monto abonado a su uso de tarjeta",
        showCancelButton: false,
        confirmButtonText: "Entendido!",
      });
      return;
    }
    openModal();
  };

  return (
    <WalletContent>
      <CardsBox style={{ paddingBottom: "20px" }}>
        <TitleBox style={{ textAlign: "center" }}>Tarjetas premium</TitleBox>
        <TitleBox style={{ marginBottom: "0" }}>Saldo</TitleBox>
        {premiumCards[swiper?.activeIndex] && (
          <div>
            <DisplayCount style={{ marginTop: "0", marginBottom: "0" }}>
              {fetchingBalances
                ? "Obteniendo..."
                : premiumCards[swiper?.activeIndex].balance === "error"
                ? "No disponible"
                : `$ ${premiumCards[swiper?.activeIndex].balance} USD`}
            </DisplayCount>
            <UpdatedAt date={premiumCards[swiper?.activeIndex].updatedAt} />
          </div>
        )}

        <DescriptionBox style={{ margin: 0 }}>
          Recarga tu FëanorCard Premium usando USDT de tu wallet.
        </DescriptionBox>
        <ButtonContent>
          <Button
            className={classes.fundCardBtn}
            onClick={hadleOpenFundCard}
            data-testid="fundvisa"
          >
            Abonar
          </Button>

          <Button
            className={classes.transactionsBtn}
            variant="outlined"
            onClick={openModalTransactions}
          >
            Movimientos
          </Button>
          <ModalTransactions
            open={openTransactions}
            onClose={onCloseTransactions}
            closeOnOverlayClick={true}
          >
            {premiumCards[swiper?.activeIndex] && (
              <TableVisaTransactions
                cardId={premiumCards[swiper?.activeIndex]._id}
              />
            )}
          </ModalTransactions>
          <ModalFundCard
            open={open}
            onClose={onClose}
            center
            closeOnOverlayClick={false}
          >
            <FundCardForm
              cardDetail={premiumCards[swiper?.activeIndex]}
              // refreshCardDetail={refreshCards}
              walletUSDT={walletUSDT}
              walletBTC={walletBTC}
              walletUSDTTron={walletUSDTTron}
              closeModal={onClose}
              refreshWallets={refreshWallets}
              refreshTransactions={refreshTransactions}
              refreshCardsBalance={fetchBalances}
            />
          </ModalFundCard>
        </ButtonContent>
        <CardsContainer style={{ margin: 0 }}>
          <Swiper
            className="swiperPremiumCards"
            onSlideChange={() => setCurrentSlide(swiper.activeIndex)}
            onSwiper={setSwiper}
            // effect={"cards"}
            grabCursor={true}
            // navigation={true}
            slidesPerView={1}
            spaceBetween={30}
            // pagination={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            // modules={[Navigation, Pagination]}
            // modules={[EffectCards, Navigation, Pagination]}
          >
            {premiumCards.map((card) => (
              <SwiperSlide key={card._id}>
                <CardPremium
                  cardPrintedName={card.namePrinted}
                  refreshCards={refreshCards}
                  cardNumber={card.number}
                  status={card.status}
                  isActive={card.isActive}
                  cardId={card._id}
                  isPhysicalCard={true}
                  otpEnabled={otpEnabled}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </CardsContainer>
      </CardsBox>
    </WalletContent>
  );
}
