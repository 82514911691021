import React from "react";
import { Header2, Paragraph } from "styles/common/index.js";
import SaldoBTC from "components/SaldoBTC";

const MoneyBTC = () => {
  return (
    <React.Fragment>
      <Header2>Billetera BTC</Header2>
      <Paragraph className="boxCenter">
      Esta es tu billetera exclusiva en Fëanor, desde aquí puedes enviar y recibir bitcoins a tus amigos o familiares. Úsala sabiamente, funciona como una cuenta bancaria. ¡Eres libre de disponer de tus fondos y moverlos!. 
      </Paragraph>
      <SaldoBTC />
    </React.Fragment>
  );
};

export default MoneyBTC;
