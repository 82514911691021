import styled from "styled-components";
import colors from './common/colors';

export const PrimaryText = styled.span`
  color: ${colors.dark_blue};
  font-family: Montserrat;
  box-sizing: border-box;
  line-height: 1.3;
  @media (max-width: 640px) {
    font-size: 17.5px;
  }
`;

export const SecondaryText = styled.span`
  color: ${colors.yellow_agente_btc};
  font-family: Montserrat;
  box-sizing: border-box;
`;
