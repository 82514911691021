import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "Auth";
import "react-toastify/dist/ReactToastify.css";
import {
  Header2,
  Header4,
  Paragraph,
  WarningBox,
} from "styles/common/index.js";
import Styled from "./styles";

import ReactFlagsSelect from "react-flags-select";

import useGetUserInfo from "hooks/useGetUser";
import {
  cancelCardrequest,
  registerUserInPaycaddy,
  requestCard,
} from "services/card.service";
import {
  errorType,
  handleErrorMessage,
  handleSuccessMessage,
} from "utils/messages.utils";
import "./datePickerStyles.css";
import useGetCards from "hooks/useGetCards";
import swal2 from "sweetalert2";
import { useHistory } from "react-router-dom";
import { countries } from "utils/countries";

const RequestCardForm = () => {
  const history = useHistory();
  const { userInfo: user } = useGetUserInfo();
  const { cards, getCards: refreshCards } = useGetCards();

  const [shippingAddr1, setShippingAddr1] = useState("");
  const [shippingAddr2, setShippingAddr2] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingProvince, setShippingProvince] = useState("");
  const [shippingZipCode, setShippingZipCode] = useState("");

  const [loadingState, setLoadingState] = useState(false);

  let { currentToken } = useContext(AuthContext);

  // useEffect(() => {
  //   swal2.fire({
  //     title: "FëanorCard creada con éxito",
  //     text: "Le redireccionaremos a la página de visualización de sus tarjetas",
  //     timer: 4000,
  //     showConfirmButton: false,
  //     icon: "success",
  //   });
  //   setTimeout(() => {
  //     history.push("/app/profile/card");
  //   }, 4000);
  // }, []);
  useEffect(() => {
    if (user._id) {
      setShippingAddr1(user.shippingAddr?.address1 || user.address);
      setShippingAddr2(user.shippingAddr?.address2);
      setShippingCountry(user.shippingAddr?.country || user.residenceCountry);
      setShippingCity(user.shippingAddr?.city);
      setShippingProvince(user.shippingAddr?.province);
      setShippingZipCode(user.shippingAddr?.zipCode);
    }
  }, [user]);

  const cancelRequest = async () => {
    try {
      const data = await cancelCardrequest(currentToken);
      if (data.success) {
        // setHasCard(false);
      }
    } catch (error) {
      handleErrorMessage(error, errorType.modal);
    }
  };

  const sendCardRequest = async () => {
    if (!shippingAddr1) {
      handleErrorMessage("La dirección de entrega es obligatorio");
      return false;
    }

    if (!shippingCity) {
      handleErrorMessage("La ciudad de entrega es obligatorio");
      return false;
    }

    if (!shippingProvince) {
      handleErrorMessage(
        "El estado / provincia / region de entrega es obligatorio"
      );
      return false;
    }

    if (!shippingZipCode) {
      handleErrorMessage("El código Zip del lugar de entrega es obligatorio");
      return false;
    }

    if (!shippingCountry) {
      handleErrorMessage("El país de entrega es obligatorio");
      return false;
    } else {
      try {
        setLoadingState(true);
        const data = {
          address1: shippingAddr1,
          address2: shippingAddr2,
          country: shippingCountry,
          city: shippingCity,
          province: shippingProvince,
          zipCode: shippingZipCode,
        };
        const { success } = await registerUserInPaycaddy(data, currentToken);
        if (!success) {
          throw new Error("Error al registrar usuario en Paycaddy");
        }
        await requestCard(data, currentToken);

        await refreshCards();

        swal2.fire({
          title: "FëanorCard creada con éxito",
          text:
            "Le redireccionaremos a la página de visualización de sus tarjetas",
          timer: 4000,
          showConfirmButton: false,
          icon: "success",
        });
        setTimeout(() => {
          history.push("/app/profile/card");
        }, 4000);
      } catch (error) {
        handleErrorMessage(error, errorType.modal);
      }
      setLoadingState(false);
    }
  };

  return (
    <Styled.ProfileList>
      <Header2>Solicitud de Fëanor Card</Header2>
      {cards.length > 0 && (
        <div></div>
        // <WarningBox>
        //   <Paragraph>
        //     Solicitud de FeanorCard enviada correctamente.
        //     {/* <b>Atención: </b> */}
        //     {/* Previamente ya enviaste una solicitud de Feanor
        //     Card.  */}
        //     {/* En caso de algun error puedes corregirlo cancelando esta
        //     solicitud. */}
        //     {/* <Styled.ButtonCancel
        //       onClick={() => {
        //         cancelRequest();
        //       }}
        //     >
        //       Cancelar solicitud
        //     </Styled.ButtonCancel> */}
        //   </Paragraph>
        // </WarningBox>
      )}

      <br />
      <Header4>Datos del titular</Header4>
      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Nombres completos:</Styled.ProfileItemLabel>
        <Styled.ProfileItemValue>{user.name}</Styled.ProfileItemValue>
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Tipo de documento:</Styled.ProfileItemLabel>
        <Styled.ProfileItemValue>{user.typeDocument}</Styled.ProfileItemValue>
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>
          Número de documento (Cédula u otro):
        </Styled.ProfileItemLabel>
        <Styled.ProfileItemValue>{user.dni}</Styled.ProfileItemValue>
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Celular:</Styled.ProfileItemLabel>
        <Styled.ProfileItemValue>{user.phone}</Styled.ProfileItemValue>
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>País</Styled.ProfileItemLabel>
        <Styled.ProfileItemValue>
          {countries[user.residenceCountry]}
        </Styled.ProfileItemValue>
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        <Styled.ProfileItemLabel>Dirección:</Styled.ProfileItemLabel>
        <Styled.ProfileItemValue>{user.address}</Styled.ProfileItemValue>
      </Styled.ProfileItem>

      <br />
      <Header4>Dirección de envío</Header4>
      <Styled.ProfileItem>
        {/* <Styled.ProfileItem className={`${cards.length > 0  ? "disabled-link" : ""}`}> */}
        <Styled.ProfileItemLabel>Linea de dirección 1:</Styled.ProfileItemLabel>
        {
          <Styled.TextInput
            required
            type="text"
            name="directionLine1"
            placeholder="Dirección / Calle(opcional) / Número de puerta(opcional)"
            value={shippingAddr1}
            onChange={(e) => setShippingAddr1(e.target.value)}
          />
        }
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        {/* <Styled.ProfileItem
        className={`${cards.length > 0 ? "disabled-link" : ""}`}
      > */}
        <Styled.ProfileItemLabel>Referencia:</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="directionLine1"
          placeholder="Complemento a la dirección (opcional)"
          value={shippingAddr2}
          onChange={(e) => setShippingAddr2(e.target.value)}
        />
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        {/* <Styled.ProfileItem
        className={`${cards.length > 0 ? "disabled-link" : ""}`}
      > */}
        <Styled.ProfileItemLabel>País</Styled.ProfileItemLabel>
        <ReactFlagsSelect
          required
          countries={Object.keys(countries)}
          customLabels={countries}
          searchPlaceholder="Busca..."
          placeholder="Selecciona tu país"
          searchable
          className="menu-flags"
          selected={shippingCountry}
          onSelect={(code) => setShippingCountry(code)}
        />
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        {/* <Styled.ProfileItem
        className={`${cards.length > 0 ? "disabled-link" : ""}`}
      > */}
        <Styled.ProfileItemLabel>Ciudad:</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="city"
          placeholder="Ciudad"
          value={shippingCity}
          onChange={(e) => setShippingCity(e.target.value)}
        />
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        {/* <Styled.ProfileItem
        className={`${cards.length > 0 ? "disabled-link" : ""}`}
      > */}
        <Styled.ProfileItemLabel>
          Estado/Provincia/Región
        </Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="state"
          placeholder="Provincia"
          value={shippingProvince}
          onChange={(e) => setShippingProvince(e.target.value)}
        />
      </Styled.ProfileItem>
      <Styled.ProfileItem>
        {/* <Styled.ProfileItem
        className={`${cards.length > 0 ? "disabled-link" : ""}`}
      > */}
        <Styled.ProfileItemLabel>Zip o código postal</Styled.ProfileItemLabel>
        <Styled.TextInput
          required
          type="text"
          name="state"
          placeholder="Ejm. 17000"
          value={shippingZipCode}
          onChange={(e) => setShippingZipCode(e.target.value)}
        />
      </Styled.ProfileItem>

      <Styled.ButtonSave
        disabled={loadingState}
        // className={`${cards.length > 0 ? "disabled-link" : ""}`}
        // disabled={false}
        onClick={() => {
          sendCardRequest();
        }}
      >
        {loadingState ? "Enviando solicitud..." : "Enviar solicitud"}
      </Styled.ButtonSave>
    </Styled.ProfileList>
  );
};

export default RequestCardForm;
