import { c1TipoExpense, c1TipoIncome, c1TipoNeutral } from "./constants";

export const timeFormatter = (dateString) => {
  var a = new Date(dateString);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + " " + month + " " + year;
  return time;
};

export const translateCategory = (category) => {
  let translated = "";
  switch (category) {
    case "cardDeposit":
      translated = "Abono a tarjeta";
      break;
    case "CONVERT":
      translated = "Conversion";
      break;
    case "receive":
      translated = "Deposito";
      break;
    case "EARNING":
      translated = "Ganancia referidos";
      break;

    default:
      translated = "";
  }
  return translated;
};

export const translateState = (state) => {
  let translated = "";
  switch (state) {
    case "CANCELED":
      translated = "CANCELADO";
      break;
    case "CREATED":
      translated = "CREADO";
      break;
    case "DELIVERED":
      translated = "ENTREGADO";
      break;
    case "REQUESTED":
      translated = "SOLICITADO";
      break;
    case "COLLECTED":
      translated = "SOLICITADO";
      break;
    default:
      translated = "";
  }
  return translated;
};

export const c1TipoToColor = (c1Tipo) => {
  if (c1TipoExpense.includes(c1Tipo)) {
    return "red";
  }
  if (c1TipoIncome.includes(c1Tipo)) {
    return "green";
  }
  if (c1TipoNeutral.includes(c1Tipo)) {
    return "gray";
  }
  return "black";
};
export const c1TipoToStatus = (c1Tipo) => {
  if (c1TipoExpense.includes(c1Tipo)) {
    return "Procesado";
  }
  if (c1TipoIncome.includes(c1Tipo)) {
    return "Procesado";
  }
  if (c1TipoNeutral.includes(c1Tipo)) {
    return "Rechazado";
  }
  return "";
};
export const c1TipoToSymbol = (c1Tipo) => {
  if (c1TipoExpense.includes(c1Tipo)) {
    return "-";
  }
  if (c1TipoIncome.includes(c1Tipo)) {
    return "+";
  }
  if (c1TipoNeutral.includes(c1Tipo)) {
    return "";
  }
  return "";
};