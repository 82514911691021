import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import useGetCardMovimientos from "hooks/useGetCardMovimientos";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import { c1TipoToColor, c1TipoToStatus, c1TipoToSymbol } from "utils/utils";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    maxHeight: 300,
    overflowY: "auto",
  },
  container: {
    marginTop: 30,
    minHeight: 300,
    maxHeight: 450,
    alignItems: "center",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid #ffffff",
    },
  },
});
export default function TableTransactions() {
  const classes = useStyles();
  const { loading, transactions } = useGetCardMovimientos({
    cardProvider: "MASTERCARD",
  });

  const columns = [
    {
      Header: "Fecha",
      accessor: "c7FechaHoraTransaccion",
      Cell: ({ value }) => <TableCell>{value}</TableCell>,
    },
    {
      Header: "Tarjeta",
      accessor: "cardInfo.number",
      Cell: ({ value }) => <TableCell align="right">{value}</TableCell>,
    },
    {
      Header: "Comercio",
      accessor: "c43IdentificadorComercio",
      Cell: ({ value }) => <TableCell align="right">{value}</TableCell>,
    },
    {
      Header: "Monto",
      accessor: "c4ImporteTransaccion",
      Cell: ({ value }) => <TableCell align="right">{value}</TableCell>,
    },
    {
      Header: "Estado",
      accessor: "c1Tipo",
      Cell: ({ value }) => (
        <TableCell align="right" color="red">
          {value}
        </TableCell>
      ),
    },
  ];

  return (
    <div>
      <Typography variant="h6">Movimientos</Typography>

      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.container}
      >
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress color="primary" size={30} />
          </Grid>
        ) : transactions.length <= 0 ? (
          <Grid container justifyContent="center">
            <Typography variant="body1">
              No se encontraron movimientos
            </Typography>
          </Grid>
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.Header}>
                    <Typography variant="overline" we>
                      {" "}
                      {column.Header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowY: "scroll" }}>
              {transactions.map((row) => {
                const rawDate = row.c7FechaHoraTransaccion;
                const year = rawDate.substring(0, 4);
                const month = rawDate.substring(4, 6);
                const day = rawDate.substring(6, 8);
                const hours = rawDate.substring(8, 10);
                const minutes = rawDate.substring(10, 12);
                const seconds = rawDate.substring(12, 14);

                const formattedDate = new Date(
                  `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
                );
                return (
                  <TableRow key={row._id}>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      style={{ color: c1TipoToColor(row.c1Tipo) }}
                    >
                      <ReactTimeAgo date={formattedDate} locale="es" />
                      {/* {`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`} */}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ color: c1TipoToColor(row.c1Tipo) }}
                    >
                      {row.cardInfo[0].number}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ color: c1TipoToColor(row.c1Tipo) }}
                    >
                      {row.c43IdentificadorComercio}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ color: c1TipoToColor(row.c1Tipo) }}
                    >
                      {`${c1TipoToSymbol(row.c1Tipo)} $ ${parseFloat(
                        row.c4ImporteTransaccion
                      ) / 100}`}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ color: c1TipoToColor(row.c1Tipo) }}
                    >
                      {c1TipoToStatus(row.c1Tipo)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
}
