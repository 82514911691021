import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { getQuote } from "components/object-calculator/helper.js";
import { sendBTC } from "services/transaction.service";
import WAValidator from "wallet-address-validator";

const useSendBTC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const runValidations = (
    { balance,
      toAddress,
      feePerOperation,
      amount,
      isUserSuperAdmin,
      captcha = ""
    }
  ) => {
    if (toAddress == "") {
      swal({
        text: "No has colocado la dirección  Bitcoin (BTC) a enviar.",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    if (process.env.REACT_APP_ENV_TYPE == "production") {
      if (!WAValidator.validate(toAddress, "BTC", "prod")) {
        swal({
          text:
            "Esta no es una dirección válida de Bitcoin. Recuerda que puedes enviar a una Legacy o SegWit.",
          button: true,
          timer: 5000,
        });
        return { errorFound: true };
      }
    } else {
      if (!WAValidator.validate(toAddress, "BTC", "test")) {
        swal({
          text:
            "Esta no es una dirección válida de Bitcoin de TESTNET. Recuerda que puedes enviar a una Legacy o SegWit.",
          button: true,
          timer: 5000,
        });
        return { errorFound: true };
      }
    }

    if (amount === "" || amount === "0" || Number(amount) <= 0) {
      swal({
        text: "Coloca el monto en BTC a enviar.",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    // if (amount > 1 && isUserSuperAdmin == true) {
    //   swal({
    //     text: "El monto de bitcoins a enviar no debe ser mayor a 1 BTC.",
    //     button: true,
    //     timer: 5000,
    //   });
    //   return { errorFound: true };
    // }

    //for tests
    // if ((amount < 0.000001 || amount > 1) && isUserSuperAdmin == false) {

    // if ((amount < 0.0015 || amount > 1) && isUserSuperAdmin == false) {
    //   swal({
    //     text: "El monto de bitcoins a enviar debe estar entre 0.0015 y 1 BTC.",
    //     button: true,
    //     timer: 5000,
    //   });
    //   return { errorFound: true };
    // }

    if (amount > Number(balance) && isUserSuperAdmin == false) {
      swal({
        text: "No puedes enviar un monto que no tienes disponible para usar. ",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    if (
      amount > (Number(balance) - Number(feePerOperation)).toFixed(8) &&
      isUserSuperAdmin == false
    ) {
      swal({
        text:
          "No puedes enviar este monto debido a que se debe incluir la comisión de envio. ",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    if (!captcha) {
      swal({
        text: "Debes completar el captcha para continuar.",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    return { errorFound: false };
  };

  const transferBTC = async (
    balance,
    toAddress,
    feePerOperation,
    amount,
    otpToken = "",
    onFail = null
  ) => {
    try {
      setLoading(true);
      const resp = await sendBTC(toAddress, amount, otpToken);
      setSuccess(true);
      return resp;
    } catch (error) {
      console.log(error);

      if (error.response) {
        console.log(error.response);
        if (error.response?.data?.code === "kyc-needed") {
          swal({
            text: error.response?.data?.message,
            button: false,
            timer: 5000,
          });
          return;
        }

        if (onFail && error.response?.status !== 500) {
          onFail(error);
          return;
        }
      }

      swal({
        text:
          "Ha habido un error al enviar los bitcoins. Si el error persiste escribenos a Soporte.",
        button: false,
        timer: 5000,
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { loading, transferBTC, success, setSuccess, runValidations };
};

export default useSendBTC;
