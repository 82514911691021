import React, { useContext, useEffect } from "react";
import { PurchaseNFTContext } from "contexts/purchaseNFT";
import { useHistory } from "react-router-dom";
import { AuthContext } from "Auth";
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Box,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const ConfirmPurchase = () => {
  const {
    amount,
    walletType,
    chainId,
    currentAccount,
    connectWallet,
    tokenCost,
    getTokenCost,
    connectFeanorWallet,
  } = useContext(PurchaseNFTContext);
  const {
    currentToken,
    expiration,
    clearAuthentication,
    userType,
  } = useContext(AuthContext);
  let history = useHistory();
  const classes = useStyles();
  const isAuthenticated =
    currentToken &&
    expiration &&
    parseInt(expiration) - Date.now() > 1000 &&
    userType == "person";
  !isAuthenticated && clearAuthentication();

  useEffect(() => {
    getTokenCost();

    if (walletType === "metamask") {
      connectWallet();
    }

    if (walletType === "feanor") {
      connectFeanorWallet(currentToken);
    }
  }, []);

  return (
    <div>
      {walletType === "feanor" && !isAuthenticated ? (
        <Typography
          className={classes.root}
          style={{ textAlign: "center", color: "black" }}
        >
          Por favor inicie sesión{" "}
          <Link
            href="#"
            onClick={() => {
              history.push(`/auth/login`);
              window.location.reload();
            }}
          >
            aquí
          </Link>{" "}
          y vuelva a realizar el proceso de compra
        </Typography>
      ) : (
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell variant="head" align="left">
                  <Box fontWeight="Bold">Red:</Box>
                </TableCell>
                <TableCell align="left">{chainId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="left">
                  <Box fontWeight="Bold">Tipo de wallet:</Box>
                </TableCell>
                <TableCell align="left">{walletType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="left">
                  <Box fontWeight="Bold">Dirección ETH:</Box>
                </TableCell>
                <TableCell align="left">{currentAccount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="left">
                  <Box fontWeight="Bold">NFTs a comprar:</Box>
                </TableCell>
                <TableCell align="left">{amount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" align="left">
                  <Box fontWeight="Bold">Costo por NFT:</Box>
                </TableCell>
                <TableCell align="left">{tokenCost}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ConfirmPurchase;
