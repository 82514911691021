import styled from "styled-components";
import { BtnBlueSm } from "styles/common/index.js";
import colors from "styles/common/colors"

export const SubtitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FiltersContainer = styled(BtnBlueSm)`
    line-height: 2;
    padding: 5px 2px;
    font-size: 14px;
    height: 30px;
    color: black;
    background: ${colors.feanor_orange};
`;

export const actionIcon = styled.span`
  margin-right: 10px;
  cursor: pointer;
  padding: 5px;
`