import React from "react";
import { Header2, Paragraph } from "styles/common/index.js";
// import Level1Form from "./Level1/index";
import CustomFieldsForm from "./CustomFieldsForm";

const Activation = () => {
  return (
    <React.Fragment>
      <Header2>Validación de cuenta</Header2>
      <Paragraph>
        Por favor, complete el siguiente formulario para continuar con el
        proceso de validación.
      </Paragraph>

      <CustomFieldsForm />
    </React.Fragment>
  );
};

export default Activation;
