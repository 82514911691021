import React, { useState, createContext } from "react";
export const TransactionsContext = createContext();

export const TransactionsProvider = ({ children }) => {
  const [transactionsTableRef, setTransactionsTableRef] = useState({});
  const [customFilter, setCustomFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [query, setQuery] = useState("");

  return (
    <TransactionsContext.Provider
      value={{
        transactionsTableRef,
        setTransactionsTableRef,
        customFilter,
        setCustomFilter,
        query,
        setQuery,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};
