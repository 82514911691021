import TablePuntosCobro from "components/TablePuntosCobro";
import React, { useState } from "react";
import { Header4, Paragraph } from "styles/common/index.js";
import * as Styled from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormAdd from "./FormAdd";
import BalanceBusiness from "components/BalanceBusiness";
import useModal from "hooks/useModal";
import { PuntosCobroProvider } from "contexts/puntosCobro";

const PuntosCobro = () => {
  const [Modal, open, openModal, onClose] = useModal();

  return (
    <>
      <PuntosCobroProvider>
        <Header4>Puntos de cobro</Header4>

        <Styled.SubtitleAndButton>
          <Paragraph></Paragraph>
          <Styled.BtnAddRow onClick={openModal}>
            <Styled.actionIcon>
              <FontAwesomeIcon icon={faPlus} />
            </Styled.actionIcon>
            Nuevo punto de cobro
          </Styled.BtnAddRow>
        </Styled.SubtitleAndButton>

        <br />
        <Styled.SubtitleAndButton>
          <BalanceBusiness />
        </Styled.SubtitleAndButton>

        <TablePuntosCobro />

        <Modal open={open} onClose={onClose}>
          <FormAdd close={onClose} />
        </Modal>
      </PuntosCobroProvider>
    </>
  );
};

export default PuntosCobro;
