import styled from "styled-components";

export const AuthHref = styled.span`
  font-weight: 600;
  font-family: "Bahnschift Light", sans-serif;
  color: white;
  font-size: 13px;
  cursor: pointer;
  margin-left: 4px;
  &:hover {
    opacity: 0.8;
  }
`;

export const TermsConditionsCheckerContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: "Bahnschift Light", sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  width: 100%;
`;

export default {
  AuthHref,
  TermsConditionsCheckerContainer,
};
