export const getToken = () => {
  return localStorage.getItem("token-feanor");
};
export const getOtpJWT = () => {
  return localStorage.getItem("otp-token-feanor");
};

export const authHeaders = {
  Authorization: `Bearer ${getToken()}`,
  OTPAuthorization: `${getOtpJWT()}`
};
export const headers = {
  "Content-Type": "application/json",
};
