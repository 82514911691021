import React from "react";
import { Bold, Paragraph } from "./styles";

const UpdatesAnnouncement = ({ children }) => {
  return (
    <div id="updatesalert">
      <Paragraph>Estimados clientes:</Paragraph>
      <Paragraph>
        Nos dirigimos a ustedes con el objeto de notificarle que{" "}
        <Bold>FËANOR</Bold> ha modificado los siguientes contratos:
        <Paragraph>
          1. Contrato de compraventa de activos digitales.
        </Paragraph>
      </Paragraph>
      <Paragraph>{children}</Paragraph>

      {/* <Paragraph>
        Queda entendido que una vez hecha la notificación de que trata el
        párrafo anterior en la Plataforma de FËANOR (www.fëanor.io){" "}
        <Bold>EL CLIENTE</Bold> tendrá un período de treinta (30) días para
        aceptar o rechazar la misma. Si <Bold>EL CLIENTE</Bold> dejase
        transcurrir dicho término sin rechazar las modificaciones o adiciones
        notificadas, se entenderá su silencio como la tácita aceptación de esta.
      </Paragraph> */}
      <Paragraph>
        Confiamos en poder continuar brindándole nuestros servicios de la manera
        más eficiente y oportuna.
      </Paragraph>
      <Paragraph>
        En nombre de <Bold>FËANOR</Bold> quedamos a su entera disposición para
        cualquier pregunta o consulta.
      </Paragraph>
      <Paragraph>Sin otro particular, quedamos de ustedes.</Paragraph>
      <Paragraph>
        <Bold>FËANOR CORPORATION.</Bold>
      </Paragraph>
    </div>
  );
};

export default UpdatesAnnouncement;
