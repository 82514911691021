import styled from "styled-components";
import colors from "styles/common/colors";
import { Link } from "react-router-dom";
import circuitLinesLeftRight from "media/bg/bg-how-to-start.png";

export const AuthContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${circuitLinesLeftRight});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: black;
  box-sizing: border-box;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  @media (max-width: 640px) {
    padding: 0 24px;
  }

  @media (max-width: 320px) {
    padding: 0 16px;
  }
`;

export const AuthBox = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AuthInput = styled.input`
  position: relative;
  height: 40px;
  max-width: 400px;
  width: 100%;
  font-family: "Bahnschift Light";
  font-size: 16px;
  text-align: left;
  margin: 10px 0;
  border: 1px solid #3c4a5b;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
  font-weight: 400;
  display: flex;
  outline: none;

  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const AuthButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: black;
  background: ${colors.feanor_gold};
  font-family: "Bahnschift Light";
  font-weight: 400;
  width: 100%;
  height: 45px;
  padding: 17px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 3px rgba(44, 44, 44, 0.2);
  transition: box-shadow 0.3s;
  &:disabled {
    background: #ffd16685;
    cursor: default;
  }
  &:hover {
    box-shadow: 2px 2px 8px rgba(44, 44, 44, 0.2);
  }
  &:active {
    box-shadow: 2px 2px 3px rgba(44, 44, 44, 0.2);
  }
`;

export const AuthText = styled.div`
  font-family: "Bahnschift Light", sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AuthLink = styled(Link)`
  font-weight: 600;
  font-family: "Bahnschift Light", sans-serif;
  color: white;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

export const AuthForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputDescription = styled.form`
  font-size: 12px;
  font-family: sans-serif;
`;

export default {
  AuthContainer,
  AuthBox,
  AuthInput,
  AuthButton,
  AuthText,
  AuthLink,
  AuthForm,
  InputDescription,
};
