import React, { useEffect, useState, useContext, Component } from "react";
import { withRouter } from "react-router";
import { Switch, Route, Redirect } from "react-router-dom";

import Styled, { CurrentItemCSS } from "./styles";

import { Header2 } from "styles/common/index.js";

import { AuthContext } from "Auth";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profileComponents, profileComponentsMobile } from "./menuItems";

const MobileMenu = ({ location, history }) => {
  const currentOptions = profileComponentsMobile.filter(
    (option) => `/business/dashboard/${option.value}` == location.pathname
  );

  return (
    <>
      {!!currentOptions.length && (
        <Styled.TabSelector
          value={{
            value: currentOptions[0].value,
            label: currentOptions[0].label,
          }}
          onChange={(option) =>
            history.push(`/business/dashboard/${option.value}`)
          }
          options={profileComponentsMobile}
        />
      )}
    </>
  );
};

const DesktopMenu = ({ location, history }) => {
  const sendAnalitycs = () => {
    // ReactGA.event({
    //   category: 'Botones',
    //   action: 'Boton Calculadora Bitcoin'
    // });
  };

  return (
    <Styled.MenuDashboard>
      {profileComponents.map((component) => {
        if (!component.show) return null;

        return (
          <Styled.ItemDashboard
            key={component.value}
            to={`/business/dashboard/${component.value}`}
            activecss={
              location.pathname === `/business/dashboard/${component.value}` &&
              CurrentItemCSS
                ? location.pathname ===
                    `/business/dashboard/${component.value}` && CurrentItemCSS
                : undefined
            }
          >
            <FontAwesomeIcon icon={component.icon} /> {component.label}
          </Styled.ItemDashboard>
        );
      })}
    </Styled.MenuDashboard>
  );
};

const Dashboard = ({ location, history }) => {
  let { currentToken } = useContext(AuthContext);

  const [data, setData] = useState({});

  // ReactGA.initialize(analyticsCode);

  const getUser = () => {
    let data = {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = "/business/user";
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-user" };
        }
      })
      .then((responseJson) => {
        setData(responseJson);
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const sendAnalitycs = () => {
    // ReactGA.event({
    //   category: 'Botones',
    //   action: 'Boton Calculadora Bitcoin'
    // });
  };

  useEffect(() => {
    getUser();
  }, [currentToken]);

  return (
    <Styled.ProfileWrapper>
      <Header2>
        Bienvenido <strong>{data.name}</strong>
      </Header2>
      <Styled.Dashboard>
        <Styled.Sidebar>
          <MobileMenu location={location} history={history} />
          <DesktopMenu location={location} history={history} />
        </Styled.Sidebar>

        <Styled.ContainerProfileData>
          <Switch>
            {profileComponents.map((component) => {
              return (
                <Route
                  key={component.value}
                  path={`*/${component.value}`}
                  component={component.component}
                />
              );
            })}
            <Redirect from="/" to="dashboard/transacciones" />
          </Switch>
        </Styled.ContainerProfileData>
      </Styled.Dashboard>
    </Styled.ProfileWrapper>
  );
};

export default withRouter(Dashboard);
