import React, { useContext, useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import * as Styled from "./styles";
import { timeFormatter } from "utils/utils.js";
import { categoriesMock } from "./../mocks";
import feanorapiapi from "feanorapi.js";
import swal from "sweetalert";
import { PurchaseNFTProvider } from "contexts/purchaseNFT";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  cardMedia: {
    paddingTop: "96.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  projectItem: {
    transition: ".5s all",
    "&:hover": {
      transform: "translateY(-5px)",
    },
  },
  toolbarSecondary: {
    marginTop: "20px",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  toolbarLink: {
    borderRadius: "4px",
    padding: theme.spacing(1),
    flexShrink: 0,
    color: "black",
    cursor: "pointer",
    transition: ".3s all",
    "&:hover": {
      background: "rgb(0,0,0,.1)",
      textDecoration: "none",
    },
  },
}));

const Projects = () => {
  let history = useHistory();
  const classes = useStyles();
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const getUser = () => {
      let data = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
      };
      let endpoint = "/public/project";
      let url = `${feanorapiapi}${endpoint}`;
      fetch(url, data)
        .then((response) => {
          return response.json();
        })
        .then((responseJson) => {
          console.log(responseJson);
          setProjects(responseJson.projects);
        })
        .catch((error) => {
          console.log(error);
          swal({
            text: "Ha habido un error en nuestro sistema",
            button: false,
            timer: 1000,
          });
        });
    };

    getUser();
    return () => {
      setProjects([]);
    };
  }, []);

  return (
    <div>
      <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      >
        {categoriesMock.map((section) => (
          <Link
            key={section.name}
            variant="body2"
            // href={section.url}
            className={classes.toolbarLink}
          >
            {section.name}
          </Link>
        ))}
      </Toolbar>

      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={2}>
          {projects.map((project) => (
            <Grid
              item
              key={project._id}
              xs={12}
              sm={6}
              md={4}
              className={classes.projectItem}
            >
              <Card
                className={classes.card}
                onClick={() => {
                  history.push(`proyectos/${project._id}`);
                }}
              >
                <CardMedia
                  className={classes.cardMedia}
                  // image={project.mainImage}
                  image={`${feanorapiapi}/public/image/general/${project.mainImage}`}
                  title={project.name}
                />
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5">
                    <Styled.CardTitle>{project.name}</Styled.CardTitle>
                  </Typography>
                  <div
                    style={{
                      fontSize: "14px",
                      marginBottom: "10px",
                      color: "rgb(0,0,0,0.5)",
                    }}
                  >
                    {"Se unió el " + timeFormatter(project.createdAt)}
                  </div>
                  <Typography>
                    <Styled.CardDescription>
                      {project.shortDescription}
                    </Styled.CardDescription>
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small" color="primary">
                    Ver
                  </Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Projects;
