import React, { useRef, useContext, useEffect } from "react";
import { BtnYellowSm, Paragraph } from "styles/common/index.js";
import colors from "styles/common/colors";
import constants from "utils/constants";
import { AmountCurrency } from "styles/Styled";

let flowId = "";
let clientId = "";
switch (process.env.REACT_APP_ENV_TYPE) {
  case "development":
    clientId = "6320dbd16d3fae001cc55fe3";
    flowId = "6320dbd16d3fae001cc55fe2";
    break;
  case "staging":
    clientId = "6320dbd16d3fae001cc55fe3";
    flowId = "6320dbd16d3fae001cc55fe2";
    break;
  case "production":
    clientId = "6320dbd16d3fae001cc55fe3";
    flowId = "6321ed273759bb001d1df2e6";
    break;
  default:
    clientId = "6320dbd16d3fae001cc55fe3";
    flowId = "6320dbd16d3fae001cc55fe2";
}

const MetamapBtn = ({
  userId,
  isKYCApprovalPending,
  kycVerificationStatus,
  onVerificationFinish,
}) => {
  const button = useRef(null);

  useEffect(() => {
    if (button.current) {
      button.current.addEventListener("metamap:loaded", ({ detail }) =>
        console.log("[METAMAP] Loaded", detail)
      );
      button.current.addEventListener("metamap:userStartedSdk", ({ detail }) =>
        console.log("[METAMAP] User started verification", detail)
      );
      button.current.addEventListener(
        "metamap:userFinishedSdk",
        ({ detail }) => {
          console.log("[METAMAP] User finished verification", detail);
          onVerificationFinish();
        }
      );
      button.current.addEventListener("metamap:exitedSdk", ({ detail }) => {});
    }
  }, [button]);

  return (
    <>
      {!isKYCApprovalPending && !kycVerificationStatus && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <br />
          <metamap-button
            ref={button}
            clientid={clientId}
            flowid={flowId}
            metadata={`{"userId": "${userId}"}`}
            color={colors.feanor_gold}
            style={{ margin: "auto" }}
          ></metamap-button>
          <p
            style={{
              fontSize: "10px",
              maxWidth: "500px",
              textAlign: "center",
              color: "#3c4a5b",
            }}
          >
            <AmountCurrency>*</AmountCurrency> Importante: El documento de
            identidad debe ser una foto nítida del mismo, no se permiten
            escaneos o cualquier otro tipo de digitalización del documento.
          </p>
        </div>
      )}
      {kycVerificationStatus === constants.verificationStatus.verified &&
        !isKYCApprovalPending && (
          <BtnYellowSm
            title="Ya te encuentras aprobado en Nivel 1, no necesitas hacer nada más. ¡Felicitaciones!"
            className="disabled-link"
            to="/app/profile/level1"
          >
            KYC Aprobado!
          </BtnYellowSm>
        )}
      {isKYCApprovalPending && (
        <BtnYellowSm
          title="Ya te encuentras aprobado en Nivel 1, no necesitas hacer nada más. ¡Felicitaciones!"
          className="disabled-link"
          to="/app/profile/level1"
        >
          Aprobación pendiente...
        </BtnYellowSm>
      )}
      <br />

      <div></div>
    </>
  );
};

export default MetamapBtn;
