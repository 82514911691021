import React, { useState, createContext } from "react";
import constants from "utils/constants";
import { subscribe } from "services/project.service";
export const SubscribeNFTContext = createContext();

export const SubscribeNFTProvider = ({ children }) => {
  const [projectDetail, setProjectDetail] = useState({});

  const subscribeToProject = async (props) => {
    try {
      console.log(projectDetail)
      const psubscriber = await subscribe({
        ...props,
        projectId: projectDetail._id,
      });
      console.log(psubscriber);
      return psubscriber;
    } catch (error) {
      console.log(new Error(error));
    }
  };
  return (
    <SubscribeNFTContext.Provider
      value={{
        projectDetail,
        setProjectDetail,
        subscribeToProject,
      }}
    >
      {children}
    </SubscribeNFTContext.Provider>
  );
};
