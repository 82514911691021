import React, { useState, useEffect, useContext } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as Styled from "./styles";
import { Paragraph } from "styles/common/index.js";
import ModalDetail from "./ModalDetail";
import { AuthContext } from "Auth";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";

const BalanceBusiness = () => {
  const [openDetail, setOpenDetail] = useState(false);
  const [totalBalances, setTotalBalances] = useState({
    totalBTCBalance: 0.0,
    totalUSDBalance: 0.0,
    totalUSDTBalance: 0.0,
    totalUSDTTronBalance: 0.0,
  });
  const { currentToken } = useContext(AuthContext);
  const [balanceSelected, setBalanceSelected] = useState("");

  const onOpenModalDetail = (currency) => {
    setOpenDetail(true);
    setBalanceSelected(currency);
  };

  const onCloseModalDetail = () => {
    setOpenDetail(false);
  };

  useEffect(() => {
    const getTotalBalances = async () => {
      let data = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentToken}`,
        },
      };
      let endpoint = "/business/punto/totalbalances";
      let url = `${feanorapi}${endpoint}`;
      await fetch(url, data)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((responseJson) => {
          if (responseJson) {
            setTotalBalances(responseJson);
          }
        })
        .catch((error) => {
          swal({
            text: "Ha ocurrido un error mientras obteniamos los saldos totales",
            button: false,
            timer: 5000,
          });
        });
    };

    getTotalBalances();
  }, []);

  return (
    <div style={{ marginBottom: "40px" }}>
      <Paragraph>Saldos totales</Paragraph>

      <Styled.ContainerCards>
        {/* <Styled.Card onClick={() => onOpenModalDetail("USD")}>
          <div>
            <Styled.BalanceAmountTitle>$ USD</Styled.BalanceAmountTitle>
            <Styled.BalanceAmount>
              {totalBalances.totalUSDBalance}
            </Styled.BalanceAmount>
          </div>
          <Styled.IconSeeDetails icon={faChevronRight} />
        </Styled.Card> */}

        <Styled.Card onClick={() => onOpenModalDetail("BTC")}>
          <div>
            <Styled.BalanceAmountTitle>₿ BTC</Styled.BalanceAmountTitle>
            <Styled.BalanceAmount>
              {totalBalances.totalBTCBalance}
            </Styled.BalanceAmount>
          </div>
          <Styled.IconSeeDetails icon={faChevronRight} />
        </Styled.Card>

        <Styled.Card onClick={() => onOpenModalDetail("USDT")}>
          <div>
            <Styled.BalanceAmountTitle>₮ ETH</Styled.BalanceAmountTitle>
            <Styled.BalanceAmount>
              {totalBalances.totalUSDTBalance}
            </Styled.BalanceAmount>
          </div>
          <Styled.IconSeeDetails icon={faChevronRight} />
        </Styled.Card>

        <Styled.Card onClick={() => onOpenModalDetail("USDT_TRON")}>
          <div>
            <Styled.BalanceAmountTitle>₮ TRON</Styled.BalanceAmountTitle>
            <Styled.BalanceAmount>
              {totalBalances.totalUSDTTronBalance}
            </Styled.BalanceAmount>
          </div>
          <Styled.IconSeeDetails icon={faChevronRight} />
        </Styled.Card>
      </Styled.ContainerCards>

      <ModalDetail
        onClose={onCloseModalDetail}
        open={openDetail}
        balanceSelected={balanceSelected}
      />
    </div>
  );
};

export default BalanceBusiness;
