import React, { useState, createContext, useEffect } from 'react';

export const ExchangeContext = createContext();

export const ExchangeProvider = ({ children }) => {
  const [exchangePen, setExchangePen] = useState(0);

  const getExchange = async () => {
    try {
      const proxyurl = 'https://cors-anywhere.herokuapp.com/';
      const API = 'https://api.cambio.today/v1/quotes/USD/PEN/json?quantity=1&key=13769|6Yrk6Sx2AuAKKm^8zjQjDcDRj^envSej'; // site that doesn’t send Access-Control-*
      const response = await fetch( proxyurl+API);
      console.log(response)
      const data = await response.json();
  
      const valuePen = (data.result.value + 0.035).toFixed(2);
      setExchangePen(valuePen);
      
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getExchange();
  }, []);

  return <ExchangeContext.Provider value={exchangePen}>{children}</ExchangeContext.Provider>;
};
