import feanorapi from 'feanorapi.js';

export const getQuote = async (ammount, from, to, transactionType) => {
  console.log(ammount, from, to, transactionType)
  const token = localStorage.getItem("token-feanor");
  const endpoint = '/public/quote';
  const url = `${feanorapi}${endpoint}`;
  const body = { ammount, from, to, transactionType};
  const data = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  };
  try{
    const response = await fetch(url, data);
    let dataResponse = await response.json();
    localStorage.setItem("lastQuote", JSON.stringify(dataResponse));
    return dataResponse;
    
  }catch(error){
    console.log(error);
    var lastQuote = JSON.parse(localStorage.getItem("lastQuote"));
    return lastQuote;
  }

}
