import { getQuote } from "./helper";

class ObjectCalculator {
  constructor(calculatorObserver) {
    this.ammounts = [0, 0];
    this.totalAmounts = [0, 0];
    this.commission = { usd: 0.3 };
    this.types = ["IN", "OUT"];
    this.currencies = ["BTC", "USD"];
    this.transactionType = "BUY";
    this.calculatorObserver = calculatorObserver;
  }

  setAmmountA(ammount) {
    let newAmmount = parseFloat(ammount);
    if ((newAmmount >= 0) & (newAmmount != undefined)) {
      this.ammounts[0] = parseFloat(newAmmount);
      this.types = ["IN", "OUT"];
      this.convert();
    } else {
      this.ammounts[0] = 0;
      this.types = ["IN", "OUT"];
      this.convert();
    }
  }

  setAmmountB(ammount) {
    let newAmmount = parseFloat(ammount);
    if (newAmmount >= 0 && newAmmount != undefined) {
      this.ammounts[1] = parseFloat(newAmmount);
      this.types = ["OUT", "IN"];
      this.convert();
    } else {
      this.ammounts[1] = 0;
      this.types = ["OUT", "IN"];
      this.convert();
    }
  }

  setUSDTUSD() {
    this.currencies = ["USDT", "USD"];
    this.convert();
  }
  setUSD() {
    this.currencies = ["BTC", "USD"];
    this.convert();
  }

  setPEN() {
    this.currencies = ["BTC", "PEN"];
    this.convert();
  }

  setBUY() {
    this.transactionType = "BUY";
    this.convert();
  }

  setSELL() {
    this.transactionType = "SELL";
    this.convert();
  }

  convert() {
    (async () => {
      const currencyPair =
        this.types[0] == "IN"
          ? `${this.currencies[0]}-${this.currencies[1]}`
          : `${this.currencies[1]}-${this.currencies[0]}`;
      const ammount =
        this.types[0] == "IN" ? this.ammounts[0] : this.ammounts[1];
      const quote = await getQuote(
        ammount,
        currencyPair.split("-")[0],
        currencyPair.split("-")[1],
        this.transactionType
      );
      this.calculatorObserver(quote);
    })();
  }
}

export default ObjectCalculator;
