import { useState, useEffect, useContext } from "react";
import { AuthContext } from "Auth";
import swal from "sweetalert";
import { fetchAllBusinessCards } from "services/businessCard.service";
import { fetchPuntosCobro } from "services/bussiness/puntoCobro.service";

const useGetPuntosCobro = () => {
  const [loading, setLoading] = useState(false);
  const [puntosCobro, setPuntosCobro] = useState([]);

  const getPuntosCobro = async () => {
    try {
      setLoading(true);
      const response = await fetchPuntosCobro();
      setPuntosCobro(response.puntosCobro);
    } catch (error) {
      console.log(error);
      swal({
        text: "Hubo un problema al obtener los puntos de cobro",
        button: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPuntosCobro();
  }, []);

  return {
    puntosCobro,
    loading,
    getPuntosCobro,
  };
};

export default useGetPuntosCobro;
