import { useEffect, useState, useContext } from "react";
import swal from "sweetalert";
import feanorapi from "feanorapi.js";
import { AuthContext } from "Auth";

const useGetCardTransactions = () => {
  const { currentToken } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [fetchingTransactions, setFetchingTransactions] = useState([]);

  const getTransactions = async () => {
    try {
      setFetchingTransactions(true);
      let endpoint = `/client/card/transactions`;
      let url = `${feanorapi}${endpoint}`;
      let data = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentToken}`,
        },
      };
      try {
        const response = await fetch(url, data);
        const res = await response.json();
        console.log(res);
        setTransactions(res.transactions);
      } catch (error) {
        console.log(error);
        swal("ocurrio un error al obtener las transacciones de la tarjeta");
      }
    } catch (error) {
      console.log(error);
      swal({
        text: "Hubo un problema al obtener las transacciones",
        button: false,
        timer: 3000,
      });
    }
    setFetchingTransactions(false);
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return { transactions, fetchingTransactions, getTransactions };
};

export default useGetCardTransactions;
