import React, { useState } from "react"

const { default: ReCAPTCHA } = require("react-google-recaptcha")


const ReCaptcha = ({ onChangeCaptcha }) => {
    return (
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={(value) => {
                console.log("Captcha value: ", value)
                onChangeCaptcha(value)
            }}
            className="recaptcha"
        />
    )
}

export const useReCaptcha = () => {
    const [captcha, setCaptcha] = useState("")

    const onChangeCaptcha = (value) => {
        console.log("New captcha: ", value)
        setCaptcha(value)
    }
    return { ReCaptcha, captcha, onChangeCaptcha }
}
