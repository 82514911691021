import React from "react";
import {
  Collapse,
  makeStyles,
} from "@material-ui/core";
import { Alert as AlertMUI, AlertTitle } from "@material-ui/lab/";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 64,
    height: 64,
    position: "absolute",
    top: 17,
    right: 12,
  },
  root: {
    position: "relative",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const SimpleAlerts = ({ typeMessage, children, open, closeAlert, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <AlertMUI severity={typeMessage}>
          <AlertTitle>
            <strong>{title}</strong>
          </AlertTitle>
          {children}
        </AlertMUI>
      </Collapse>
    </div>
  );
};

export default SimpleAlerts;
