import React, { useState, useEffect, useContext } from "react";
import {
  BtnPrimaryFull,
  InputContainer,
  InputModal,
  LabelModal,
  LabelSpan,
} from "styles/Styled";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";
import { AuthContext } from "Auth";
import { Grid, IconButton, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import swal2 from "sweetalert2";
import MaterialTable from "material-table";
import { icons, translations } from "components/DataTableProps";

const Referidos = () => {
  const [referralLink, setReferralLink] = useState("");
  const [referredUsers, setReferredUsers] = useState([]);
  const [earnings, setEarnings] = useState(0);
  let { currentToken } = useContext(AuthContext);

  const handleCreateReferralCode = async () => {
    let endpoint = `/business/referral/generate`;
    let url = `${feanorapi}${endpoint}`;
    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    try {
      const response = await fetch(url, data);
      const res = await response.json();
      console.log(res);
      await fetchReferralLink();
    } catch (error) {
      console.log(error);
      swal("ocurrio un error al obtener el generar el codigo de referido");
    }
  };

  const fetchReferralLink = async () => {
    let endpoint = `/business/referral/link`;
    let url = `${feanorapi}${endpoint}`;
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    try {
      const response = await fetch(url, data);
      const res = await response.json();
      console.log(res);
      setReferralLink(res.referralLink);
    } catch (error) {
      console.log(error);
      swal("ocurrio un error al obtener el link de referido");
    }
  };

  const fetchReferredUsers = async () => {
    let endpoint = `/business/referral/referred`;
    let url = `${feanorapi}${endpoint}`;
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    try {
      const response = await fetch(url, data);
      const res = await response.json();
      console.log(res);
      setReferredUsers(res.referredUsers);
    } catch (error) {
      console.log(error);
      swal("ocurrio un error al obtener los usuarios referidos");
    }
  };

  const fetchEarnings = async () => {
    let endpoint = `/business/referral/earnings`;
    let url = `${feanorapi}${endpoint}`;
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    try {
      const response = await fetch(url, data);
      const res = await response.json();
      console.log(res);
      setEarnings(res.total);
    } catch (error) {
      console.log(error);
      swal("ocurrio un error al obtener los usuarios referidos");
    }
  };
  useEffect(() => {
    fetchReferralLink();
    fetchReferredUsers();
    fetchEarnings();
  }, []);

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    swal2.fire({
      title: "Link copiado",
      text: "",
      timer: 1000,
      showConfirmButton: false,
      icon: "success",
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Referencias
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <LabelModal style={{ margin: 0 }}>
            <Typography variant="subtitle2">Link de referido</Typography>
            <InputContainer>
              <IconButton onClick={() => onCopyToClipboard()}>
                <FileCopyIcon />
              </IconButton>
              <InputModal
                style={{ outline: "none", border: "#f9f9fb" }}
                type="text"
                value={referralLink}
                readOnly
              />
            </InputContainer>
          </LabelModal>
          {!referralLink && (
            <BtnPrimaryFull disabled={false} onClick={handleCreateReferralCode}>
              Generar codigo
            </BtnPrimaryFull>
          )}
        </Grid>
        <Grid item xs={2}>
          <LabelModal style={{ margin: 0 }}>
            <Typography variant="subtitle2">Total referidos</Typography>
            <InputContainer>
              <InputModal
                style={{ outline: "none", border: "#f9f9fb", padding: "11px" }}
                type="text"
                value={referredUsers.length}
                readOnly
              />
            </InputContainer>
          </LabelModal>
        </Grid>

        <Grid item xs={3}>
          <LabelModal style={{ margin: 0 }}>
            <Typography variant="subtitle2">Total ganancias</Typography>
            <InputContainer>
              <InputModal
                style={{ outline: "none", border: "#f9f9fb", padding: "11px" }}
                type="text"
                value={`${earnings} USDT`}
                readOnly
              />
            </InputContainer>
          </LabelModal>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">Usuarios referidos</Typography>
          <MaterialTable
            localization={translations}
            icons={icons}
            columns={[
              {
                title: "Nombre",
                field: "name",
                // cellStyle: rowData => rowData.type == 'send' ? ({color: 'red'}) : ({color: "black"}),
                // render: (rowData) => {
                //     const symbol = rowData.transactionType.isIncome ? '' : '-'
                //     return rowData ? `${symbol} ${rowData.amount.toFixed(2)}` : ""
                // }
              },
              {
                title: "Correo",
                field: "email",
              },
            ]}
            title={""}
            data={referredUsers}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Referidos;
