import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "PrivateRoute";
import Dashboard from "./Dashboard";

// import Operation from "./Operation/index.js";

const AppBusiness = () => {
  return (
    <Switch>
      <PrivateRoute path="*/dashboard" component={Dashboard} />
      <Redirect from="/" to="/business/dashboard" />
    </Switch>
  );
};

export default AppBusiness;
