import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import swal from "sweetalert";
import { DateContext } from "DateContext";
import { AuthContext } from "Auth";
import { CatcherBotonera, DisabledLink, EditButton } from "./styles";
import { DualRing } from "react-awesome-spinners";

import { AlertButton } from "styles/Styled";

import Alerta from "components/modals/Alerta";
import feanorapi from "feanorapi.js";

library.add(fab);

const Catcher = ({
  montoBitcoins,
  montoBitcoinsVenta,
  cantidadDolares,
  cantidadDolaresVenta,
  cantidadSoles,
  cantidadSolesVenta,
  currency,
  title,
  operacion,
  cambioBitcoin,
  cambioBitcoinVenta,
  cambioSoles,
  cambioSolesVenta,
  zzComisionBTC,
  zzComisionEnvio,
  disabled,
  noAmount,
  history,
  calculatorViewer,
}) => {
  // Styles for dashboard
  const buttonStyles = {
    display: "block",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isNoAmount, setIsNoAmount] = useState(false);
  const date = useContext(DateContext);
  const [isLoading, setIsLoading] = useState(false);
  const { currentToken } = useContext(AuthContext);

  // const handleOperation = async () => {
  //   if (!currentToken) {
  //     swal({
  //       text: "Para realizar una transacción, porfavor ingresar",
  //       button: false,
  //     });
  //     history.push(`/login`);
  //   } else {
  //     if (calculatorViewer.variables.BTCUSD == 0) {
  //       swal({
  //         text:
  //           "Estamos calculando tu cotización. Intenta nuevamente en unos segundos o actualiza la página",
  //         button: false,
  //         timer: 4000,
  //       });
  //     } else {
  //       const body = {
  //         types: calculatorViewer.types,
  //         ammounts: calculatorViewer.ammounts,
  //         currencies: calculatorViewer.currencies,
  //         transactionType: calculatorViewer.transactionType,
  //       };
  //       const data = {
  //         method: "POST", // *GET, POST, PUT, DELETE, etc.
  //         mode: "cors", // no-cors, *cors, same-origin
  //         cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //         credentials: "same-origin", // include, *same-origin, omit
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${currentToken}`,
  //         },
  //         body: JSON.stringify(body), // body data type must match "Content-Type" header
  //       };
  //       const endpoint = "/client/transaction";
  //       const url = `${feanorapi}${endpoint}`;
  //       fetch(url, data)
  //         .then((response) => response.json())
  //         .then((responseJson) => {
  //           swal({
  //             text: responseJson.msg,
  //             button: false,
  //             timer: 3000,
  //           });
  //           if (responseJson.type === "transaction-created") {
  //             const newTransactionId = responseJson.transaction._id;
  //             history.push(`/app/operation/edit/${newTransactionId}`);
  //           }
  //         })
  //         .catch((error) => {
  //           swal({
  //             text: "Lo sentimos no se pudo realizar la operación",
  //             button: false,
  //             timer: 3000,
  //           });
  //         });
  //     }
  //   }
  // };

  const handleSwap = async () => {
    if (!currentToken) {
      swal({
        text: "Para realizar una transacción, porfavor ingresar",
        button: false,
      });
      history.push(`/login`);
    } else {
      if (calculatorViewer.variables.BTCUSD == 0) {
        swal({
          text:
            "Estamos calculando tu cotización. Intenta nuevamente en unos segundos o actualiza la página",
          button: false,
          timer: 4000,
        });
      } else {
        // Variables
        let from,
          to,
          amount = "";

        if (calculatorViewer.transactionType == "BUY") {
          from = calculatorViewer.currencies[1];
          to = calculatorViewer.currencies[0];
          amount = calculatorViewer.ammounts[1];
        } else {
          from = calculatorViewer.currencies[0];
          to = calculatorViewer.currencies[1];
          amount = calculatorViewer.ammounts[0];
        }

        const jsonBody = JSON.stringify({ from: from, to: to, amount: amount });

        const data = {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentToken}`,
          },
          body: jsonBody, // body data type must match "Content-Type" header
        };
        const endpoint = "/client/wallet/convert";
        setIsLoading(true);
        const url = `${feanorapi}${endpoint}`;
        fetch(url, data)
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.fromWallet && responseJson.toWallet) {
              setIsLoading(false);

              swal({
                text:
                  "Se realizó correctamente la operación :). Te redireccionaremos a Operaciones para visualizarla.",
                button: false,
                timer: 4000,
              });
              setTimeout(function() {
                history.push(`/app/profile/operations/`);
              }, 3000);
            } else {
              setIsLoading(false);

              swal({
                text: responseJson.msg,
                button: false,
                timer: 4000,
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);

            swal({
              text: "Lo sentimos no se pudo realizar la operación",
              button: false,
              timer: 3000,
            });
          });
      }
    }
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const redirectToDeposit = async () => {
    if (calculatorViewer.transactionType == "BUY") {
      if (calculatorViewer.currencies[1] == "PEN") {
        swal({
          text:
            "Saldo insuficiente. Para comprar esta cantidad primero debes realizar un abono a tu billetera de Soles, te estamos redirigiendo hacia allá...",
          button: true,
          timer: 4000,
        });
        setTimeout(function() {
          history.push(`/app/profile/pen/`);
        }, 4000);
      }

      if (calculatorViewer.currencies[1] == "USD") {
        swal({
          text:
            "Saldo insuficiente. Para comprar esta cantidad primero debes realizar un abono a tu billetera de Dólares, te estamos redirigiendo hacia allá...",
          button: true,
          timer: 4000,
        });
        setTimeout(function() {
          history.push(`/app/profile/usd/`);
        }, 4000);
      }
    }

    if (calculatorViewer.transactionType == "SELL") {
      swal({
        text:
          "El monto mínimo de venta es el equivalente en Bitcoin de 15 USD.",
        button: true,
        timer: 4000,
      });

      /*
        swal({
          text: 'No tienes los suficientes BTC que indicas para vender, te estamos redirigiendo hacia tu billetera para que puedas recibir tus bitcoins...',
          button: true,
          timer: 4000
        });
        setTimeout(function(){history.push(`/app/profile/btc/`);}, 4000)   */
    }
  };

  if (disabled) {
    return (
      <CatcherBotonera>
        <DisabledLink>{title} </DisabledLink>
      </CatcherBotonera>
    );
  }

  if (noAmount) {
    return (
      <CatcherBotonera>
        <AlertButton type="button" onClick={() => {}}>
          {/* <AlertButton type="button" onClick={redirectToDeposit}> */}
          {title}
        </AlertButton>
      </CatcherBotonera>
    );
  }

  const timeOpen =
    (date.hours >= 9 && date.hours < 19 && date.day >= 1 && date.day <= 5) ||
    (date.hours >= 9 && date.hours < 19 && date.day === 6);
  return (
    <CatcherBotonera>
      {true ? (
        <EditButton onClick={() => {}} disabled={isLoading ? true : false}>
          {/* <EditButton onClick={handleSwap} disabled={isLoading ? true : false}> */}
          {isLoading ? "Cargando.." : title}
        </EditButton>
      ) : (
        // <AlertButton type="button" onClick={handleOpenModal}>
        <AlertButton type="button" onClick={() => {}}>
          {title}
        </AlertButton>
      )}
      <Alerta isOpen={isOpen} closeModal={handleCloseModal} />
    </CatcherBotonera>
  );
};

export default withRouter(Catcher);
