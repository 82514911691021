import styled from "styled-components";

export const PaginatorContainer = styled.div`
  width: 100%;
  padding: 10px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 20px;
`;

export const Button = styled.div`
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  color: #3C4A5B;
  position: absolute;
  left: ${props => props.left && "15px"};
  right: ${props => props.right && "40px"};
  &:hover {
    color: #999999;
  }
`;

export const Number = styled.div`
`;
