import React, { useRef, useEffect, useContext } from "react";
import { TitleModal, BodyModal, DescriptionModal } from "styles/Styled";
import colors from "styles/common/colors";
import { Helmet } from "react-helmet";
import useModal from "hooks/useModal";
import {
  updateKYCApprovalToPending,
  updateUserData,
} from "services/user.service";
import swal from "sweetalert";
import { AuthContext } from "Auth";
// import { faCheckCircle, faUserCheck } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { website } from "feanorapi";
import { Button } from "@material-ui/core";
import constants from "utils/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: colors.feanor_gold,
    maxWidth: "200px",
    width: "200px",
    margin: "0px auto 0 auto",
    // color: "white",
    "&:hover": {
      backgroundColor: colors.feanor_gold,
    },
    textTransform: "none",
    fontWeight: "500",
  },
  link: {
    "&:hover": {
      color: colors.feanor_orange,
    },
    textDecoration: "none",
  },
}));

const ContractAgreementModal = ({
  kycVerificationStatus,
  agreementAccepted,
  acceptUpdates,
}) => {
  const { currentToken } = useContext(AuthContext);
  const classes = useStyles();
  const [
    Modal,
    isModalAgreementOpen,
    openModalAgreement,
    onCloseModalAgreement,
  ] = useModal();

  useEffect(() => {
    if (
      !agreementAccepted &&
      kycVerificationStatus === constants.verificationStatus.verified
    ) {
      openModalAgreement();
    }
  }, [agreementAccepted, kycVerificationStatus]);

  const onAccept = async () => {
    try {
      await updateUserData(currentToken, {
        agreementAccepted: true,
      });
      await acceptUpdates();
      onCloseModalAgreement(currentToken);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet></Helmet>
      <Modal
        open={isModalAgreementOpen}
        onClose={onCloseModalAgreement}
        center
        closeOnOverlayClick={false}
      >
        <BodyModal>
          <TitleModal style={{ marginBottom: 0 }}>
            Acuerdo de plataforma y uso de tarjetas
          </TitleModal>
          <DescriptionModal
            style={{
              textAlign: "center",
              margin: "0 auto",
              maxWidth: "600px",
            }}
          >
            Por favor lee y acepta nuestros{" "}
            <a
              className={classes.link}
              target="_blank"
              href={`${website}/terminos-y-condiciones`}
            >
              términos y condiciones
            </a>{" "}
            para poder continuar con el uso de la plataforma. Click{" "}
            <a
              className={classes.link}
              target="_blank"
              href={`${website}/terminos-y-condiciones`}
            >
              aqui
            </a>{" "}
            para visitar la pagina de términos y condiciones.
          </DescriptionModal>
          {/* <FooterModal></FooterModal> */}

          <div style={{ margin: "20px auto" }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={onAccept}
            >
              Acepto
            </Button>
          </div>
        </BodyModal>
      </Modal>
    </>
  );
};

export default ContractAgreementModal;
