import React from "react";

import {
  Visor,
  HeadlineTitle,
  BalanceFIATTitle,
  ArrowDown,
  ArrowUp,
  EtiquetaPrecioCrypto,
  EtiquetaPrecioFiat,
  EtiquetaNombre,
  IngresoPrecio,
} from "./styles";
import arrow_down from "media/arrow-down@2x.png";

import { StyledSelect } from "styles/Styled";
import CryptoPriceInfo from "./common/CryptoPriceInfo";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typografy from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

const Sell = ({
  AvailableBalanceComp,
  balanceBTC,
  calculatorViewer,
  inputA,
  handleInputA,
  toggleMenuFiat,
  toggleMenuCrypto,
  menuFiat,
  menuCrypto,
  SelectFIAT,
  SelectCrypto,
  inputB,
  handleInputB,
  currentCrypto
}) => {
  return (
    <div>
      {/* <BalanceFIATTitle>
        Tienes en bitcoins (BTC): {balanceBTC}
      </BalanceFIATTitle> */}
      {AvailableBalanceComp}

      <HeadlineTitle>
        {calculatorViewer.transactionType === "BUY"
          ? "Quiero comprar"
          : "Quiero vender"}
      </HeadlineTitle>
      <Visor>
        <EtiquetaPrecioCrypto onClick={toggleMenuCrypto}>
          <EtiquetaNombre>
            {calculatorViewer.currencies[0] == "BTC" ? "Bitcoins" : "Tethers"}
          </EtiquetaNombre>
          {calculatorViewer.currencies[0]}
          {menuCrypto ? (
            <ArrowUp src={arrow_down} />
          ) : (
            <ArrowDown src={arrow_down} />
          )}
          <StyledSelect
            value={{
              value: calculatorViewer.currencies[0],
              label: calculatorViewer.currencies[0],
            }}
            onChange={SelectCrypto}
            styles={{
              control: (styles) => ({ ...styles, display: "none" }),
            }}
            menuIsOpen={menuCrypto}
            options={[
              { value: "BTC", label: "Bitcoins" },
              { value: "USDT", label: "Tethers" },
            ]}
          />
        </EtiquetaPrecioCrypto>
        <IngresoPrecio
          className="input-b"
          placeholder="0.0"
          value={inputA}
          onChange={(e) => {
            handleInputA(e, calculatorViewer.currencies[0]);
          }}
          type="tel"
        />
      </Visor>

      {/* <Visor>
        <EtiquetaPrecioCrypto>
          <EtiquetaNombre>Bitcoins</EtiquetaNombre>
          {calculatorViewer.currencies[0]}
        </EtiquetaPrecioCrypto>
        <IngresoPrecio
          className="input-a"
          placeholder="0.0"
          value={inputA}
          onChange={handleInputA}
          type="tel"
        />
      </Visor> */}

      <CryptoPriceInfo
        currentCrypto={currentCrypto}
        calculatorViewer={calculatorViewer}
      />

      <HeadlineTitle>
        {calculatorViewer.transactionType === "BUY"
          ? "Debes pagar..."
          : "Recibirás"}{" "}
      </HeadlineTitle>
      <Visor>
        <EtiquetaPrecioFiat>
          <EtiquetaNombre>Dólares</EtiquetaNombre>
          {calculatorViewer.currencies[1]}
        </EtiquetaPrecioFiat>
        {/* <EtiquetaPrecioFiat onClick={toggleMenuFiat}>
          <EtiquetaNombre>{calculatorViewer.currencies[1]}</EtiquetaNombre>
          {calculatorViewer.currencies[1] == "USD" ? "Dólares" : "Soles"}
          {menuFiat ? (
            <ArrowUp src={arrow_down} />
          ) : (
            <ArrowDown src={arrow_down} />
          )}
          <StyledSelect
            value={{
              value: calculatorViewer.currencies[1],
              label: calculatorViewer.currencies[1],
            }}
            onChange={SelectFIAT}
            styles={{
              control: (styles) => ({ ...styles, display: "none" }),
            }}
            menuIsOpen={menuFiat}
            options={[{ value: "USD", label: "Dólares" }]}
          />
        </EtiquetaPrecioFiat> */}
        <IngresoPrecio
          className="input-b"
          placeholder="0"
          value={inputB}
          onChange={handleInputB}
          type="tel"
        />
      </Visor>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell variant="head" align="left">
              <Typografy component="div">
                <Box fontWeight="Bold">Comisión</Box>
              </Typografy>
            </TableCell>
            <TableCell align="right">
              {calculatorViewer.commission.usd} USD
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" align="left">
              <Typografy component="div">
                <Box fontWeight="Bold">Recibirás</Box>
              </Typografy>
            </TableCell>
            <TableCell align="right">
              {calculatorViewer.totalAmounts[1]} USD
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default Sell;
