import React, { useContext } from "react";
import { Grid, Button, TextField, Box, Container } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { PurchaseNFTContext } from "contexts/purchaseNFT";
const EnterAmount = ({ stepCorrect }) => {
  const { setAmount, amount } = useContext(PurchaseNFTContext);

  return (
    <Container maxWidth="sm">
      <Grid item xs={12}>
        <TextField
          //   error={Boolean(touched.amount && errors.amount)}
          fullWidth
          //   helperText={touched.amount && errors.amount}
          label="Cantidad a comprar"
          margin="normal"
          name="amount"
          //   onBlur={handleBlur}
          onChange={(e) => {
            stepCorrect(true);
            setAmount(parseInt(e.target.value));
          }}
          // InputProps={{ inputProps: { min: 0 } }}
          type="number"
          value={amount}
          variant="outlined"
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Button
          color="primary"
          //   disabled={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          comprar
        </Button>
      </Grid> */}
    </Container>
  );
};

export default EnterAmount;
