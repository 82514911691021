import React, { useState, createContext, useContext, useEffect } from "react";
import feanorapi from "feanorapi.js";
import { AuthContext } from "Auth";
export const PlatformFeeContext = createContext();

export const PlatformFeeProvider = ({ children }) => {
  const [platformFee, setPlatformFee] = useState({});
  const { currentToken } = useContext(AuthContext);

  const getLastPlatformFee = async () => {
    let url = `${feanorapi}/client/fees/platformFee`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    });
    const data = await response.json();
    setPlatformFee(data ?? {});
  };

  useEffect(() => {
    getLastPlatformFee();
  }, []);

  return (
    <PlatformFeeContext.Provider
      value={{
        platformFee,
      }}
    >
      {children}
    </PlatformFeeContext.Provider>
  );
};
