import React, { useState } from "react";
import useModal from "hooks/useModal";

import { AuthHref, TermsConditionsCheckerContainer } from "./styles";
import AllInOne from "./components/AllInOne";
const ContractsList = ({ textColor = "white", checked, setChecked }) => {
  const [Modal, open, openModal, onClose] = useModal();

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
        closeOnOverlayClick={true}
      >
        {<AllInOne />}
      </Modal>

      <TermsConditionsCheckerContainer style={{ color: textColor }}>
        <input
          style={{ marginRight: "5px" }}
          required
          type="checkbox"
          checked={checked}
          onChange={(e) => setChecked(!checked)}
          // name="wasPep"
          // checked={authorizationCheck}
          // onChange={(e) => setAuthorizationCheck(!authorizationCheck)}
        />
        Acepto
        <AuthHref
          onClick={(e) => {
            e.preventDefault();
            openModal(false);
          }}
          style={{ color: textColor }}
        >
          los terminos y condiciones
        </AuthHref>
      </TermsConditionsCheckerContainer>

      {/* 
      <TermsConditionsCheckerContainer>
        <input
          style={{ marginRight: "5px" }}
          required
          type="checkbox"
          // name="wasPep"
          // checked={authorizationCheck}
          // onChange={(e) => setAuthorizationCheck(!authorizationCheck)}
        />
        Acepto el
        <AuthHref
          onClick={() => {
            openModal();
            setOpenPlatform(true);
          }}
        >
          Contrato de plataforma
        </AuthHref>
      </TermsConditionsCheckerContainer>

      <TermsConditionsCheckerContainer>
        <input
          style={{ marginRight: "5px" }}
          required
          type="checkbox"
          // name="wasPep"
          // checked={authorizationCheck}
          // onChange={(e) => setAuthorizationCheck(!authorizationCheck)}
        />
        Acepto el
        <AuthHref
          onClick={() => {
            openModal();
            setOpenTreatment(true);
          }}
        >
          Contrato de Tratamiento
        </AuthHref>
      </TermsConditionsCheckerContainer>

      <TermsConditionsCheckerContainer>
        <input
          style={{ marginRight: "5px" }}
          required
          type="checkbox"
          // name="wasPep"
          // checked={authorizationCheck}
          // onChange={(e) => setAuthorizationCheck(!authorizationCheck)}
        />
        Acepto el
        <AuthHref
          onClick={() => {
            openModal();
            setOpenSellActives(true);
          }}
        >
          Contrato de Venta de activos digitales
        </AuthHref>
      </TermsConditionsCheckerContainer>

      <TermsConditionsCheckerContainer>
        <input
          style={{ marginRight: "5px" }}
          required
          type="checkbox"
          // name="wasPep"
          // checked={authorizationCheck}
          // onChange={(e) => setAuthorizationCheck(!authorizationCheck)}
        />
        Acepto el
        <AuthHref
          onClick={() => {
            openModal();
            setOpenBuyActives(true);
          }}
        >
          Contrato de Compra de activos digitales
        </AuthHref>
      </TermsConditionsCheckerContainer> */}
    </>
  );
};

export default ContractsList;
