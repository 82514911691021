import styled from "styled-components";

export const Subtitle = styled.span`
  color: black;
  font-weight: bold;
  font-size: 13px;
  @media (max-width: 330px) {
    font-size: 13px;
  }
`;

export const Data = styled.span`
  color: black;
  font-size: 12px;
  @media (max-width: 330px) {
    font-size: 12px;
  }
`;
