import React, { useEffect, useState, useContext } from "react";
import ImageUploader from "react-images-upload";
import { css } from "glamor";

import { AuthContext } from "Auth";
// Styled components
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Styled, { CurrentItemCSS } from "./styles";

import feanorapi from "feanorapi";
import swal from "sweetalert";
import { PrimaryText, SecondaryText } from "styles/colorized";

import { Input, TextData, SelectorFull } from "styles/common/index.js";

import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// import ReactGA from 'react-ga';

import analyticsCode from "analyticsCode.js";
import TwofactorAuthSection from "../components/TwofactorAuthSection";

const UserData = () => {
  // const [user, setDataUser] = useState({});
  const { userInfo: user } = useContext(AuthContext);
  const [phone, setPhone] = useState("");
  const [wallet, setWallet] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [dni, setDni] = useState("");
  const [dniFrontUrl, setDniFrontUrl] = useState("");
  const [dniBackUrl, setDniBackUrl] = useState("");
  const [coupon, setCoupon] = useState({});
  const [couponCode, setCouponCode] = useState("");

  // Get user auth
  let { currentToken, userType } = useContext(AuthContext);

  // Bank Accounts
  const [bankAccounts, setBankAccounts] = useState([]);
  const [havePenAccount, setHavePenAccount] = useState(false);
  const [haveUsdAccount, setHaveUsdAccount] = useState(false);
  const [isInternationalBank, setIsInternationalBank] = useState(false);
  const [editBankAccountMode, setEditBankAccountMode] = useState(false);

  let [currencyBa, setCurrencyBa] = useState("USD");
  let [numberBa, setNumberBa] = useState("");
  let [bankNameBa, setBankNameBa] = useState("allbankcorp");
  let [cciBa, setCciBa] = useState("");
  let [accountType, setAccountType] = useState("corriente");
  let [addressBa, setAddressBa] = useState("");
  let [swiftBa, setSwiftBa] = useState("");
  let [bankNameInt, setBankNameInt] = useState("");

  let [bankAccountIdEdit, setBankAccountIdEdit] = useState("");

  const [openModalBank, setOpenModalBank] = useState(false);
  let [isMyAccount, setIsMyAccount] = useState(false);

  const [bornDate, setBornDate] = useState(new Date());

  // ReactGA.initialize(analyticsCode);

  // const getUser = () => {
  //   let data = {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${currentToken}`,
  //     },
  //   };
  //   let endpoint = "/client/user";
  //   let url = `${feanorapi}${endpoint}`;
  //   fetch(url, data)
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw { type: "non-user" };
  //       }
  //     })
  //     .then((responseJson) => {
  //       setDataUser(responseJson);
  //       setPhone(responseJson.phone);
  //       setWallet(responseJson.wallet);
  //       setAccountNumber(responseJson.accountNumber);
  //       setDni(responseJson.dni);
  //       setBornDate(responseJson.bornDate);
  //       /*if(responseJson.hasRegisterCoupon){
  //           getCoupon(responseJson.registerCouponId);
  //         }*/
  //     })
  //     .catch((error) => {
  //       swal({
  //         text: "Ha habido un error en nuestro sistema",
  //         button: false,
  //         timer: 1000,
  //       });
  //     });
  // };

  useEffect(() => {
    // getUser();
    // getBankAccounts();
    // eslint-disable-next-line
    setPhone(user.phone || user.signupPhone);
    setBornDate(user.birthday);
  }, [user]);

  const updateData = (newProp) => {
    /*const wallet = newProp.wallet;
    if(wallet){
      if(!(25 <= wallet.length && wallet.length <= 34) || !(wallet.startsWith('1') || wallet.startsWith('3') || wallet.startsWith('bc1'))){
        swal({text: "La dirección de la wallet ingresada no es de Bitcoin", button: false, timer: 4000});
        return;
      }
    }*/
    toast("Actualizando datos...", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      bodyClassName: css({ fontFamily: "Montserrat" }),
    });
    let body = { ...user, ...newProp };
    var formData = new FormData();

    const encodeFormData = (data) => {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    };

    for (let key in body) {
      formData.append(key, body[key]);
    }
    let data = {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encodeFormData(body), // body data type must match "Content-Type" header
    };
    let endpoint = "/client/user/regular/update";
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          // getUser();
        }
        return response.json();
      })
      .then((responseJson) => {
        toast(responseJson.msg, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          bodyClassName: css({ fontFamily: "Montserrat" }),
        });
      })
      .catch((error) => {
        swal({
          text:
            "Lo sentimos, hubo un error en el sistema y no pudimos actualizar los datos. Intente nuevamente más adelante.",
          button: false,
          timer: 1000,
        });
      });
  };

  /**
   *
   * Bank Accounts
   * Date: 01-09-2020
   *
   */

  const getBankAccounts = () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        setBankAccounts(responseJson.banks);
        // if (responseJson.pen != 0) setHavePenAccount(true);
        // if (responseJson.usd != 0) setHaveUsdAccount(true);
        if (responseJson.usd > 2) setHaveUsdAccount(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBankAccount = (bankId) => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank/${bankId}`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        let bankData = responseJson.bank;
        if (bankData) {
          setBankNameBa(bankData.name);
          setCurrencyBa(bankData.currency);
          setNumberBa(bankData.accountNumber);
          setCciBa(bankData.cci);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const recoverPassword = () => {
    const jsonBody = JSON.stringify({ email: user.email, userType });

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonBody,
    };
    let endpoint = `/auth/recover`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          swal({
            text:
              "¡Correo enviado correctamente! Revisa tu bandeja de entrada y sigue los pasos para cambiar tu contraseña",
            button: true,
            timer: 3000,
          });
          return response.json();
        }
      })
      .catch((error) => {
        swal({
          text:
            "Hubo un error al enviar el correo. Si el error persiste escríbenos a Soporte.",
          button: false,
          timer: 5000,
        });
        console.log(error);
      });
  };

  const editBankAccount = (bankId) => {
    // Cargar datos a editar
    getBankAccount(bankId);
    setBankAccountIdEdit(bankId);
    setEditBankAccountMode(true);
    setOpenModalBank(true);
  };

  const createBankAccount = async () => {
    // Validación
    if (bankNameBa == "") {
      swal({
        text: "Elige un banco antes de crear la cuenta",
        button: false,
        timer: 5000,
      });
      return;
    }
    if (numberBa == "") {
      swal({
        text: "Debes colocar un número de cuenta bancaria",
        button: false,
        timer: 5000,
      });
      return;
    }
    if (accountType == "") {
      swal({
        text: "Debes colocar el tipo de cuenta",
        button: false,
        timer: 5000,
      });
      return;
    }
    if (!isMyAccount) {
      swal({
        text: "Debes aceptar que es tu cuenta personal lo que estás ingresando",
        button: false,
        timer: 5000,
      });
      return;
    }

    if (numberBa.length < 6 || numberBa.length >= 30) {
      swal({
        text:
          "La cuenta bancaria debe tener más de  6 caracteres y menos de 30.",
        button: false,
        timer: 5000,
      });
      return;
    }

    // if (cciBa.length < 6 || numberBa.length >= 30) {
    //   swal({
    //     text:
    //       "La cuenta interbancaria debe tener más de  6 caracteres y menos de 30.",
    //     button: false,
    //     timer: 5000,
    //   });
    //   return;
    // }

    // if (isInternationalBank) {
    // if (bankNameInt == "") {
    //   swal({
    //     text: "Debes ingresar el nombre del banco",
    //     button: false,
    //     timer: 5000,
    //   });
    //   return;
    // }
    // if (addressBa == "") {
    //   swal({
    //     text: "Debes ingresar la dirección del banco",
    //     button: false,
    //     timer: 5000,
    //   });
    //   return;
    // }
    // if (swiftBa == "") {
    //   swal({
    //     text:
    //       "Debes ingresar el código SWIFT de tu cuenta bancaria. Si no la tienes, solicitarla a tu banco.",
    //     button: false,
    //     timer: 5000,
    //   });
    //   return;
    // }
    // }

    // Enviar

    let finalBankName;
    // if (isInternationalBank) finalBankName = bankNameInt;
    // else finalBankName = bankNameBa;
    finalBankName = bankNameBa;

    const jsonBody = JSON.stringify({
      currency: currencyBa,
      accountNumber: numberBa,
      name: finalBankName,
      cci: accountType,
      address: addressBa,
      swift: swiftBa,
    });

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: jsonBody,
    };
    let endpoint = `/client/user/bank`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          swal({
            text: "¡Se añadió correctamente tu cuenta de banco! :)",
            button: true,
            timer: 5000,
          });
          onCloseModalBank();
          return response.json();
        } else {
          console.log(response);
          swal({
            text: "¡Completar todos los campos faltantes antes de guardar!",
            button: true,
            timer: 5000,
          });
          //return response.json();
        }
      })
      .then((responseJson) => {
        /*if(responseJson.type == 'missing-fields') {
          swal({text: '¡Completar todos los campos faltantes antes de guardar!', button: true, timer: 5000});
        }*/
        // getBankAccounts();
      })
      .catch((error) => {
        console.log(error);
        swal({
          text:
            "Ocurrió un error al añadir tu cuenta de banco. Intenta más tarde.",
          button: true,
          timer: 5000,
        });
      });
  };

  const deactivateBankAccount = (bankId, showMessage) => {
    let data = {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/user/bank/${bankId}`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        if (showMessage) {
          swal({
            text: "Cuenta bancaria eliminada correctamente :)",
            button: true,
            timer: 5000,
          });
        }
        getBankAccounts();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Methods for utilities

  const onOpenModalBank = () => {
    setOpenModalBank(true);
    setCurrencyBa("USD");
    // if (havePenAccount) {
    // }
    // if (haveUsdAccount) {
    //   setCurrencyBa("PEN");
    // }

    // ReactGA.modalview('/app/profile/settings/agregar-cuenta-bancaria');
  };

  const onCloseModalBank = () => {
    setOpenModalBank(false);

    //Clean

    setBankNameBa("allbankcorp");
    setCurrencyBa("USD");
    setNumberBa("");
    setCciBa("");
    setBankAccountIdEdit("");
    setEditBankAccountMode(false);
  };

  const CurrencyOptions = [
    {
      value: "USD",
      label: "Dólares Americanos ($)",
      isDisabled: haveUsdAccount,
    },
  ];

  const CurrencyOptionsObject = {
    USD: { value: "USD", label: "Dólares Americanos ($)" },
  };

  const BanksOptions = [
    { value: "allbankcorp", label: "Allbank Corp" },
    { value: "bacinternationalbank", label: "BAC International Bank, Inc." },
    { value: "balboaandtrust", label: "Balboa Bank & Trust Corp" },
    { value: "bancoaliado", label: "Banco Aliado" },
    { value: "azteca", label: "Banco Azteca" },
    { value: "bancobacpanama", label: "Banco BAC de Panamá." },
    { value: "bolivariano", label: "Banco Bolivariano" },
    { value: "citibank", label: "Banco Citibank" },
    { value: "davivienda", label: "Banco Davivienda" },
    { value: "bancobogota", label: "Banco de Bogotá" },
    { value: "bancopacifico", label: "Banco del Pacífico" },
    { value: "bancodelta", label: "Banco Delta" },
    { value: "bancoficohsa", label: "Banco Ficohsa" },
    { value: "gtcontinental", label: "Banco G&T Continental" },
    { value: "hipotecario", label: "Banco Hipotecario Nacional" },
    { value: "bancogeneral", label: "Banco General" },
    { value: "internacionalcr", label: "Banco Internacional de Costa Rica" },
    { value: "lahipotecaria", label: "Banco La Hipotecaria" },
    { value: "lafisepanama", label: "Banco Lafise Panamá" },
    {
      value: "latcomercioex",
      label: "Banco Latinoamericano de Comercio Exterior",
    },
    { value: "nacionalpanama", label: "Banco Nacional de Panamá" },
    { value: "bancopanama", label: "Banco Panamá" },
    { value: "lavivienda", label: "Banco Panameño de la Vivienda" },
    { value: "pichinchapanama", label: "Banco Pichincha Panamá" },
    { value: "bancoprival", label: "Banco Prival" },
    { value: "bancouniversal", label: "Banco Universal" },
    { value: "bancolombia", label: "Bancolombia" },
    { value: "banesco", label: "Banesco" },
    { value: "banisi", label: "BANISI" },
    { value: "banistmo", label: "Banistmo" },
    { value: "leumileisrael", label: "Bank Leumi-Le Israel B.M." },
    { value: "bancochina", label: "Bank of China Limited" },
    { value: "bbpbank", label: "BBP Bank" },
    { value: "btcbankinternational", label: "BCT Bank International" },
    { value: "cajaahorros", label: "Caja de Ahorros" },
    { value: "capitalbank", label: "Capital Bank Inc" },
    { value: "citibankna", label: "Citibank, N.A. Sucursal Panamá" },
    { value: "credicorp", label: "Credicorp Bank" },
    { value: "fpbbank", label: "FPB Bank Inc" },
    { value: "globalbank", label: "Global Bank Corporation" },
    { value: "koreaexchange", label: "Korea Exchange Bank" },
    {
      value: "megainternational",
      label: "Mega International Commercial Bank Co",
    },
    { value: "mercantilbank", label: "Mercantil Bank (Panamá)" },
    { value: "metrobank", label: "Metrobank" },
    { value: "mibanco", label: "MiBanco" },
    { value: "mmgbank", label: "MMG Bank Corporation" },
    { value: "multibank", label: "Multibank" },
    { value: "produbank", label: "Produbank (Panamá)" },
    { value: "stgeorgesbank", label: "St. Georges Bank & Company" },
    { value: "bankofnovapanama", label: "The Bank of Nova Scotia (Panamá)" },
    {
      value: "bankofnovascotiabank",
      label: "The Bank of Nova Scotia (Scotiabank)",
    },
    { value: "towerbank", label: "Towerbank International" },
    { value: "unibank", label: "Unibank" },
  ];

  const BanksOptionsObject = {
    allbankcorp: { value: "allbankcorp", label: "Allbank Corp" },
    bacinternationalbank: {
      value: "bacinternationalbank",
      label: "BAC International Bank, Inc.",
    },
    balboaandtrust: {
      value: "balboaandtrust",
      label: "Balboa Bank & Trust Corp",
    },
    bancoaliado: { value: "bancoaliado", label: "Banco Aliado" },
    azteca: { value: "azteca", label: "Banco Azteca" },
    bancobacpanama: { value: "bancobacpanama", label: "Banco BAC de Panamá." },
    bolivariano: { value: "bolivariano", label: "Banco Bolivariano" },
    citibank: { value: "citibank", label: "Banco Citibank" },
    davivienda: { value: "davivienda", label: "Banco Davivienda" },
    bancobogota: { value: "bancobogota", label: "Banco de Bogotá" },
    bancopacifico: { value: "bancopacifico", label: "Banco del Pacífico" },
    bancodelta: { value: "bancodelta", label: "Banco Delta" },
    bancoficohsa: { value: "bancoficohsa", label: "Banco Ficohsa" },
    gtcontinental: { value: "gtcontinental", label: "Banco G&T Continental" },
    hipotecario: { value: "hipotecario", label: "Banco Hipotecario Nacional" },
    bancogeneral: { value: "bancogeneral", label: "Banco General" },
    internacionalcr: {
      value: "internacionalcr",
      label: "Banco Internacional de Costa Rica",
    },
    lahipotecaria: { value: "lahipotecaria", label: "Banco La Hipotecaria" },
    lafisepanama: { value: "lafisepanama", label: "Banco Lafise Panamá" },
    latcomercioex: {
      value: "latcomercioex",
      label: "Banco Latinoamericano de Comercio Exterior",
    },
    nacionalpanama: {
      value: "nacionalpanama",
      label: "Banco Nacional de Panamá",
    },
    bancopanama: { value: "bancopanama", label: "Banco Panamá" },
    lavivienda: { value: "lavivienda", label: "Banco Panameño de la Vivienda" },
    pichinchapanama: {
      value: "pichinchapanama",
      label: "Banco Pichincha Panamá",
    },
    bancoprival: { value: "bancoprival", label: "Banco Prival" },
    bancouniversal: { value: "bancouniversal", label: "Banco Universal" },
    bancolombia: { value: "bancolombia", label: "Bancolombia" },
    banesco: { value: "banesco", label: "Banesco" },
    banisi: { value: "banisi", label: "BANISI" },
    banistmo: { value: "banistmo", label: "Banistmo" },
    leumileisrael: {
      value: "leumileisrael",
      label: "Bank Leumi-Le Israel B.M.",
    },
    bancochina: { value: "bancochina", label: "Bank of China Limited" },
    bbpbank: { value: "bbpbank", label: "BBP Bank" },
    btcbankinternational: {
      value: "btcbankinternational",
      label: "BCT Bank International",
    },
    cajaahorros: { value: "cajaahorros", label: "Caja de Ahorros" },
    capitalbank: { value: "capitalbank", label: "Capital Bank Inc" },
    citibankna: {
      value: "citibankna",
      label: "Citibank, N.A. Sucursal Panamá",
    },
    credicorp: { value: "credicorp", label: "Credicorp Bank" },
    fpbbank: { value: "fpbbank", label: "FPB Bank Inc" },
    globalbank: { value: "globalbank", label: "Global Bank Corporation" },
    koreaexchange: { value: "koreaexchange", label: "Korea Exchange Bank" },
    megainternational: {
      value: "megainternational",
      label: "Mega International Commercial Bank Co",
    },
    mercantilbank: { value: "mercantilbank", label: "Mercantil Bank (Panamá)" },
    metrobank: { value: "metrobank", label: "Metrobank" },
    mibanco: { value: "mibanco", label: "MiBanco" },
    mmgbank: { value: "mmgbank", label: "MMG Bank Corporation" },
    multibank: { value: "multibank", label: "Multibank" },
    produbank: { value: "produbank", label: "Produbank (Panamá)" },
    stgeorgesbank: {
      value: "stgeorgesbank",
      label: "St. Georges Bank & Company",
    },
    bankofnovapanama: {
      value: "bankofnovapanama",
      label: "The Bank of Nova Scotia (Panamá)",
    },
    bankofnovascotiabank: {
      value: "bankofnovascotiabank",
      label: "The Bank of Nova Scotia (Scotiabank)",
    },
    towerbank: { value: "towerbank", label: "Towerbank International" },
    unibank: { value: "unibank", label: "Unibank" },
  };

  const AccountTypesOptions = [
    { value: "corriente", label: "Cuenta corriente" },
    { value: "ahorros", label: "Cuenta de Ahorros" },
  ];
  const AccountTypesOptionsObj = {
    corriente: { value: "corriente", label: "Cuenta corriente" },
    ahorros: { value: "ahorros", label: "Cuenta de Ahorros" },
  };

  const handleSelectAccountType = (option) => {
    setAccountType(option.value);
  };

  const handleSelectBank = (option) => {
    setBankNameBa(option.value);
    // setIsInternationalBank(true);

    // if (option.value !== "other") {
    //   setBankNameBa(option.value);
    //   setIsInternationalBank(true);
    //   // setCurrencyBa("USD");
    //   swal({
    //     text:
    //       "Ya que tu cuenta de banco es internacional, te solicitaremos unos detalles adicionales debajo: Nombre de tu banco, dirección y SWIFT",
    //     button: true,
    //     timer: 8000,
    //   });
    // } else {
    //   setBankNameBa(option.value);
    //   setIsInternationalBank(false);
    //   // setCurrencyBa("USD");
    // }
  };

  const handleSelectCurrency = (option) => {
    setCurrencyBa(option.value);
  };

  return (
    <>
      <Styled.ProfileWrapper>
        <TwofactorAuthSection />

        <Styled.Padding20></Styled.Padding20>
        {/* 
        <Styled.ProfileTitle>Cuentas bancarias</Styled.ProfileTitle>
        <Styled.ProfileDescription>
          Estas son tus cuentas a las que Fëanor depositará los retiros de
          dinero que hagas. Solo se deben agregar cuentas bancarias asociadas a
          tu identidad registrada en tu cuenta (Persona Natural o Empresa).{" "}
        </Styled.ProfileDescription>

        <Styled.Content>
          {bankAccounts.map((item) => {
            const bankObj = { ...item };
            //const date = new Date(transactionObj.createdAt);

            return (
              <Styled.BoxLeft key={bankObj._id}>
                <Styled.ContentDiv>
                  <Styled.TitleBox>
                    Cuenta {`${bankObj.currency}`}
                  </Styled.TitleBox>
                  <Styled.ContentIcons>
                    <Styled.Icon>
                      <FontAwesomeIcon
                        onClick={() => editBankAccount(bankObj._id)}
                        title="Editar cuenta bancaria"
                        icon={faPencilAlt}
                      />
                    </Styled.Icon>
                    <Styled.Icon>
                      <FontAwesomeIcon
                        onClick={() => deactivateBankAccount(bankObj._id, true)}
                        title="Eliminar cuenta bancaria"
                        icon={faTrash}
                      />
                    </Styled.Icon>
                  </Styled.ContentIcons>
                </Styled.ContentDiv>

                <Styled.ItemsBox>
                  <Styled.Item>
                    Nro. de cta: {`${bankObj.accountNumber}`}
                  </Styled.Item>
                  <Styled.Item>Tipo: {`${bankObj.cci}`}</Styled.Item>
                  <Styled.Item>Banco: {`${bankObj.name}`}</Styled.Item>
                </Styled.ItemsBox>
              </Styled.BoxLeft>
            );
          })}
        </Styled.Content>
*/}
        {/* {(!havePenAccount || !haveUsdAccount) && (
          <Styled.AddButtonLink onClick={onOpenModalBank}>
            + Añadir Cuenta Bancaria
          </Styled.AddButtonLink>
        )} */}
        {/* {!haveUsdAccount && (
          <Styled.AddButtonLink onClick={onOpenModalBank} to="#">
            + Añadir Cuenta Bancaria
          </Styled.AddButtonLink>
        )}  */}

        <Styled.Padding20></Styled.Padding20>

        <Styled.ProfileTitle>Datos básicos</Styled.ProfileTitle>
        <Styled.ProfileDescription>
          En esta sección puedes actualizar tus datos básicos.
        </Styled.ProfileDescription>
        {/* <Styled.ProfileDescription>
          Estas son tus cuentas a las que Fëanor depositará los retiros de
          dinero que hagas. Solo se deben agregar cuentas bancarias asociadas a
          tu identidad registrada en tu cuenta (Persona Natural o Empresa).{" "}
        </Styled.ProfileDescription> */}

        <Styled.ProfileList>
          <Styled.ProfileItem>
            <Styled.ProfileItemLabel>Nombre:</Styled.ProfileItemLabel>
            <Styled.ProfileItemValue>{user.name}</Styled.ProfileItemValue>
          </Styled.ProfileItem>
          <Styled.ProfileItem>
            <Styled.ProfileItemLabel>Email:</Styled.ProfileItemLabel>
            <Styled.ProfileItemValue>{user.email}</Styled.ProfileItemValue>
          </Styled.ProfileItem>

          <Styled.ProfileItem>
            <Styled.ProfileItemLabel>Teléfono:</Styled.ProfileItemLabel>
            <Styled.TextInput
              type="text"
              name="phone"
              placeholder="Teléfono"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Styled.ProfileItem>

          <Styled.ProfileItem>
            <Styled.ProfileItemLabel>
              Fecha de nacimiento:
            </Styled.ProfileItemLabel>
            <Styled.ProfileItemValue>{user.birthday}</Styled.ProfileItemValue>
            {/* <Styled.TextInputContainer>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="dia / mes / año"
                showMonthDropdown
                showYearDropdown
                locale="es"
                selected={bornDate}
                onChange={(date) => setBornDate(date)}
              />
            </Styled.TextInputContainer> */}
          </Styled.ProfileItem>

          <Styled.ButtonSave
            onClick={() =>
              updateData({ bornDate, phone, dni, coupon: couponCode })
            }
          >
            Guardar
          </Styled.ButtonSave>
        </Styled.ProfileList>

        <Styled.Padding20></Styled.Padding20>

        <Styled.ProfileTitle>
          Renovar contraseña de la cuenta
        </Styled.ProfileTitle>

        <Styled.ProfileDescription>
          {" "}
          Puedes cambiar tu contraseña a una nueva si lo deseas, llegará tu
          correo un enlace con el proceso para cambiar la contraseña.{" "}
        </Styled.ProfileDescription>

        <Styled.ProfileList>
          <Styled.ButtonSave onClick={() => recoverPassword()}>
            Cambiar contraseña
          </Styled.ButtonSave>
        </Styled.ProfileList>
      </Styled.ProfileWrapper>

      <Modal
        open={openModalBank}
        onClose={onCloseModalBank}
        center
        closeOnOverlayClick={false}
      >
        <Styled.BodyModal>
          {!editBankAccountMode ? (
            <Styled.TitleModal>Añadir Cuenta Bancaria</Styled.TitleModal>
          ) : (
            <Styled.TitleModal>Editar Cuenta Bancaria</Styled.TitleModal>
          )}

          <Styled.LabelModal>
            <Styled.LabelSpan>Banco</Styled.LabelSpan>
            <SelectorFull
              required
              options={BanksOptions}
              value={BanksOptionsObject[bankNameBa]}
              onChange={handleSelectBank}
            ></SelectorFull>
          </Styled.LabelModal>

          <Styled.LabelModal>
            <Styled.LabelSpan>Moneda</Styled.LabelSpan>
            <SelectorFull
              isDisabled={isInternationalBank}
              isOptionDisabled={(option) => option.isDisabled}
              required
              options={CurrencyOptions}
              value={CurrencyOptionsObject[currencyBa]}
              onChange={handleSelectCurrency}
            ></SelectorFull>
          </Styled.LabelModal>

          <Styled.LabelModal>
            <Styled.LabelSpan>Número de cuenta</Styled.LabelSpan>
            <Styled.InputModal
              type="text"
              onChange={(e) => setNumberBa(e.target.value)}
              value={numberBa}
            />
          </Styled.LabelModal>

          {/* {!isInternationalBank && (
            <Styled.LabelModal>
              <Styled.LabelSpan>Tipo de cuenta</Styled.LabelSpan>
              <Styled.InputModal
                placeholder="Ingresa el tipo de cuenta"
                type="text"
                onChange={(e) => setCciBa(e.target.value)}
                type="text"
                value={cciBa}
              />
            </Styled.LabelModal>
          )} */}
          <Styled.LabelModal>
            <Styled.LabelSpan>Tipo de cuenta</Styled.LabelSpan>
            <SelectorFull
              required
              options={AccountTypesOptions}
              value={AccountTypesOptionsObj[accountType]}
              onChange={handleSelectAccountType}
            ></SelectorFull>
          </Styled.LabelModal>
          {/* <Styled.InputModal
              placeholder="Ingresa el tipo de cuenta"
              type="text"
              onChange={(e) => setCciBa(e.target.value)}
              type="text"
              value={cciBa}
            /> */}

          <Styled.CheckLabel>
            <Styled.CheckInput
              required
              type="checkbox"
              name="isMyAccount"
              checked={isMyAccount}
              onChange={(e) => setIsMyAccount(true)}
            />
            Declaro que es mi cuenta personal
          </Styled.CheckLabel>

          {/* {isInternationalBank && (
            <>
              <Styled.LabelModal>
                <Styled.LabelSpan>Nombre del Banco</Styled.LabelSpan>
                <Styled.InputModal
                  placeholder="Banco de Chile"
                  type="text"
                  onChange={(e) => setBankNameInt(e.target.value)}
                  type="text"
                  value={bankNameInt}
                />
              </Styled.LabelModal>

              <Styled.LabelModal>
                <Styled.LabelSpan>Dirección del banco</Styled.LabelSpan>
                <Styled.InputModal
                  placeholder="Ejem: Santiago, Chile"
                  type="text"
                  onChange={(e) => setAddressBa(e.target.value)}
                  type="text"
                  value={addressBa}
                />
              </Styled.LabelModal>

              <Styled.LabelModal>
                <Styled.LabelSpan>Código SWIFT</Styled.LabelSpan>
                <Styled.InputModal
                  placeholder="Ingresa código SWIFT"
                  type="text"
                  onChange={(e) => setSwiftBa(e.target.value)}
                  type="text"
                  value={swiftBa}
                />
              </Styled.LabelModal>
            </>
          )} */}

          {!editBankAccountMode ? (
            <Styled.BtnPrimaryLg onClick={() => createBankAccount()}>
              Agregar cuenta
            </Styled.BtnPrimaryLg>
          ) : (
            <Styled.BtnPrimaryLg
              onClick={() => {
                deactivateBankAccount(bankAccountIdEdit, false);
                createBankAccount();
              }}
            >
              Guardar cambios
            </Styled.BtnPrimaryLg>
          )}

          <Styled.FooterModal>
            <b>Importante:</b> Tus cuentas personales pueden funcionar tanto
            para transferir a Fëanor como para recibir tus retiros. Solo ingresa
            cuentas que te pertenezcan.
          </Styled.FooterModal>
        </Styled.BodyModal>
      </Modal>
    </>
  );
};

export default UserData;
