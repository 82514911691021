import useGetCardTransactions from "hooks/useGetCardTransactions";
import React from "react";
import { Redirect, useLocation } from "react-router";
import { internal } from "routes";
import { Header2 } from "styles/common/index.js";
import { WalletContent } from "styles/Styled";
import BalanceRow from "./components/BalanceRow";
import LastTransactions from "./components/LastTransactions";
import MoreAbout from "./components/MoreAbout";

const CardDetail = () => {
  const { state } = useLocation();
  const { getTransactions, transactions } = useGetCardTransactions();

  if (!state || !state.cardNumber) {
    return <Redirect to={internal.feanoCardMain} />;
  }

  return (
    <>
      <Header2>Fëanor Card nº {state.cardNumber}</Header2>
      <BalanceRow
        cardNumber={state.cardNumber}
        getTransactions={getTransactions}
      />

      <WalletContent>
        <LastTransactions
          cardNumber={state.cardNumber}
          transactions={transactions}
        />
        <MoreAbout />
      </WalletContent>
    </>
  );
};

export default CardDetail;
