import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter, useLocation } from "react-router";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";
import StyledAuth from "../styles";
import { Header1White } from "styles/common";
import { ContractsList } from "./components";
import { SetupOTP } from "./SetupOTP";

const Register = ({ history }) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [signupSuccess, setSignupSuccess] = useState(false);

  const handleRecaptcha = (value) => {
    console.log("Captcha value:", value);
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setButtonEnabled(true);
    swal({ text: "Registrando usuario...", button: false, timer: 1000 });

    const { name, email, password, signupPhone } = e.target.elements;
    const checker = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!password.value.match(checker)) {
      swal({
        text:
          "Recuerda que tu contraseña debe tener entre 8 y 15 caracteres, 1 mayúcula , 1 minúscula, 1 número y 1 caracter especial",
        button: false,
        timer: 7000,
      });

      setTimeout(() => {
        setButtonEnabled(false);
      }, 3000);
    } else {
      let body = {
        username: email.value,
        name: name.value,
        email: email.value,
        password: password.value,
        referrerCode: queryParams.get("m"),
        signupPhone: signupPhone.value,
      };
      let data = {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body), // body data type must match "Content-Type" header
      };
      let endpoint = "/auth/register";
      let url = `${feanorapi}${endpoint}`;
      console.log(url);
      fetch(url, data)
        .then((response) => {
          response.json().then((json) => {
            console.log(response);
            console.log(json);

            if (response.ok) {
              // setSignupSuccess(true);
              swal({
                text:
                  "¡Listo! Por favor, revisa tu correo electrónico para confirmar tu cuenta. Puede que llegue en la carpeta de Spam, revisa ahi también.",
                button: false,
                timer: 9000,
              });
              history.push("/auth/login");

              // ReactGA.event({
              //   category: 'Usuario',
              //   action: 'Creación de cuenta'
              // });
            } else if (json.code === "invalid-referrer")
              swal({ text: json.message, button: false, timer: 2000 });
            else if (json.code === "missing-fields") {
              swal({ text: "Campo faltantes", button: false, timer: 2000 });
              return;
            } else if (response.status === 400) {
              swal({ text: "Correo ya existente", button: false, timer: 2000 });
              return;
            } else {
              swal({ text: json.msg, button: false, timer: 2000 });
            }

            setTimeout(() => {
              setButtonEnabled(false);
            }, 3000);
          });
        })
        .catch((error) => {
          setTimeout(() => {
            setButtonEnabled(false);
          }, 3000);

          swal({
            text: "Lo sentimos, no se pudo realizar el registro",
            button: false,
            timer: 2000,
          });
        })
        .finally(() => {
          setButtonEnabled(false);
        });
    }
  };

  // const scrollTop = () => {
  //   window.scrollTo(0, 0);
  // };

  useEffect(() => {
    // scrollTop();
  }, []);

  return (
    <div style={{ marginBottom: "20px" }}>
      <>
        <Header1White>Registro</Header1White>
        <StyledAuth.AuthText
          style={{ "font-size": "16px", "text-align": "center" }}
        >
          Ingresa tu correo electrónico y contraseña para registrarte.
        </StyledAuth.AuthText>
        <StyledAuth.AuthForm onSubmit={handleSignUp}>
          <StyledAuth.AuthInput
            name="name"
            type="text"
            placeholder="Nombre completo*"
            required
          />
          <StyledAuth.AuthInput
            name="signupPhone"
            type="tel"
            placeholder="Número de celular*"
            required
          />
          <StyledAuth.AuthInput
            name="email"
            type="email"
            placeholder="Tu correo electrónico*"
            required
          />
          <StyledAuth.AuthInput
            name="password"
            type="password"
            placeholder="Ingresar contraseña"
            required
          />
          <StyledAuth.AuthInput
            type="password"
            placeholder="Repite tu contraseña*"
            required
          />

          <ContractsList checked={checked} setChecked={setChecked} />
          <ReCAPTCHA
            sitekey="6LehkHMdAAAAANcU5w-29EK7hM6JfqWeIbBzzc4K"
            onChange={handleRecaptcha}
            className="recaptcha"
          />

          <StyledAuth.AuthButton disabled={buttonEnabled} type="submit">
            Crear cuenta
          </StyledAuth.AuthButton>
        </StyledAuth.AuthForm>
        <StyledAuth.AuthText>
          ¿Ya tienes una cuenta? &nbsp;
          <StyledAuth.AuthLink to="/auth/login" style={{ fontSize: "18px" }}>
            Inicia sesión
          </StyledAuth.AuthLink>
        </StyledAuth.AuthText>
      </>
    </div>
  );
};

export default withRouter(Register);
