import useGetTransactions from "hooks/useGetTransactions";
import React from "react";
import { Paragraph } from "styles/common/index.js";
import { AmountCurrency, WalletContent } from "styles/Styled";

import BalanceRow from "./components/BalanceRow";
import LastTronTransactions from "./components/LastTronTransactions";
import MoreAboutUSDTTron from "./components/MoreAboutUSDTTron";

const SaldoUSDTTron = () => {
  const { transactions, getTransactions } = useGetTransactions(
    "currency=USDT_TRON&limit=5"
  );

  return (
    <>
      <BalanceRow refreshTransactions={getTransactions} />

      <WalletContent>
        <LastTronTransactions transactions={transactions} />
        <MoreAboutUSDTTron />
      </WalletContent>

      <Paragraph>
        <AmountCurrency>*</AmountCurrency> Equivalente a su conversión con la
        tasa de VENTA vigente e incluye las comisiones de transacción de Fëanor.
      </Paragraph>
    </>
  );
};

export default SaldoUSDTTron;
