import InputObscurable from "components/InputObscurable/InputObscurable";
import React, { useState } from "react";
import { Header1, Header1Black } from "styles/common";
import { LoginButton, LoginForm, LoginInput } from "styles/Styled";

const FormRequestPassword = ({
  errorMsg,
  password,
  setPassword,
  onSubmit,
  buttonTitle = "",
  buttonTitleLoading = "",
  loading = false,
}) => {
  return (
    <div>
      <Header1Black>Verificación de seguridad</Header1Black>

      {errorMsg && (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginBottom: "2px",
          }}
        >
          {errorMsg}
        </p>
      )}
      <LoginForm
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <InputObscurable
          name="password"
          placeholder="Contraseña"
          style={{
            marginBottom: "2px",
            outlineWidth: `${errorMsg ? "0" : ""}`,
            borderColor: `${errorMsg ? "red" : ""}`,
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p
          style={{
            color: "black",
            fontSize: "12px",
            marginTop: "2px",
          }}
        >
          Ingrese su contraseña de inicio de sesión
        </p>

        <LoginButton type="submit" disabled={loading}>
          {loading
            ? buttonTitleLoading || "Cargando..."
            : buttonTitle || "Verificar contraseña"}
        </LoginButton>
      </LoginForm>
    </div>
  );
};

export default FormRequestPassword;
