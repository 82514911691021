import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { getQuote } from "components/object-calculator/helper.js";

const useGetQuote = ({ amount, from, to, operationType }) => {
  const [rates, setRates] = useState({
    BTCUSDSell: 0,
    USDTUSDSell: 0,
  });
  const [conversion, setConversion] = useState(0.0);

  const fetchQuote = async () => {
    try {
      const quote = await getQuote(amount, from, to, operationType);
      console.log(quote)
      setConversion(quote.ammounts[1]);
      setRates({
        BTCUSDSell: quote.rates.BTCUSDSell,
        USDTUSDSell: quote.rates.USDTUSDSell,
      });
    } catch (error) {
      console.log(error);
      swal({
        text: "Hubo un problema al obtener la conversion",
        button: false,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    fetchQuote();
  }, []);

  return { conversion, rates };
};

export default useGetQuote;
