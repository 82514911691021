import { useState, useEffect, useContext } from "react";
import { AuthContext } from "Auth";
import { fetchSecondaryWallets, fetchWallets } from "services/wallet.service";
import swal from "sweetalert";
import currencyTypes from "models/currencies";

const useGetSecondaryWallets = () => {
  const { currentToken } = useContext(AuthContext);
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(false);

  const getWallets = async () => {
    setLoading(true);
    try {
      const response = await fetchSecondaryWallets(currentToken);
      setWallets(response);
    } catch (error) {
      console.log(error);
      swal({
        text: "Hubo un problema al obtener tu billetera",
        button: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWallets();
  }, []);

  return {
    getWallets,
    wallets,
  };
};

export default useGetSecondaryWallets;
