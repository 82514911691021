import styled from "styled-components";
import colors from "styles/common/colors.js";
import theme from "styles/theme";

export const TopHeader = styled.header`
  /* position: absolute; */
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 20;
  padding: 0 0 0 150px;

  @media screen and (max-width: 640px) {
    left: 0;
    padding: 0;
  }
  @media screen and (max-width: 320px) {
    left: 0;
    padding: 0;
  }
`;

export const Sun = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 400px;
  right: 50px;
  top: -300px;
  background: ${colors.yellow_agente_btc};
  box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.1);
  animation-name: shine;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  @keyframes shine {
    from {
      box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.1);
    }
    to {
      box-shadow: 0 5px 60px 5px rgba(0, 0, 0, 0.1);
    }
  }

  @media screen and (max-width: 640px) {
    display: none;
  }
`;

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 21;
`;

export const GeneralLinksContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: flex-start;
`;

export const Item = styled.li`
  margin-left: 10px;
  position: relative;
  font-family: "Bahnschift Light", sans-serif;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 160px;
  /* margin-top: 5px; */
  margin: 10px 0;
  height: 80px;

  @media screen and (max-width: 640px) {
    max-width: 120px;
  }
`;

export const LogoutBtn = styled.button`
  background: #f2a900;
  border: none;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: 6px 18px;
  border-radius: 24px;

  @media screen and (max-width: 640px) {
    padding: 3px 6px 4px;
  }
`;

export const Menu = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: flex-end;
  padding-right: 150px;
  box-sizing: border-box;
  align-items: center;
  @media screen and (max-width: 640px) {
    font-size: 14px;
    font-weight: 400;
    padding-inline-start: 10px;
  }
  @media screen and (max-width: 320px) {
    font-size: 11px;
  }
`;

export default {
  TopHeader,
};

export const SmallMenuItem = styled.a`
  color: black;
  padding: 10px;
  display: block;
  padding: 13px 14px;
  &:hover {
    color: ${colors.feanor_orange};
  }
`;

export const ItemBordered = styled.a`
  margin-left: 15px;
  position: relative;
  white-space: nowrap;
  font-size: 14px;
  border: 1px solid  ${colors.feanor_orange};
  cursor: pointer;
  padding: 10px ;
  border-radius: ${theme.borderRadius};
  font-weight: 600;
`;