import React from "react";
import TwoFactorAuth from "./sections/TwoFactorAuth";
import UpdatePassword from "./sections/UpdatePassword";

const Security = () => {
  return (
    <div style={{ width: "75%" }}>
      <TwoFactorAuth />
      <br />
      <br />
      <UpdatePassword />
    </div>
  );
};

export default Security;
