import { useState, useEffect, useContext } from "react";
import { AuthContext } from "Auth";
import swal from "sweetalert";
import { fetchAllBusinessCards } from "services/businessCard.service";
import {
  fetchPuntoCobroWallets,
  fetchPuntosCobro,
} from "services/bussiness/puntoCobro.service";

const useGetPuntoCobroWallets = () => {
  const [loading, setLoading] = useState(false);
  const [puntoCobroWallets, setPuntoCobroWallets] = useState([]);

  const getPuntoCobroWallets = async (puntoCobroId) => {
    if (!puntoCobroId) {
      return;
    }

    try {
      setLoading(true);
      const response = await fetchPuntoCobroWallets(puntoCobroId);
      response.wallets = response.wallets.filter(
        (wallet) =>
          wallet.currency === "USDT" ||
          wallet.currency === "USDT_TRON" ||
          wallet.currency === "BTC"
      );

      setPuntoCobroWallets(response.wallets);
    } catch (error) {
      console.log(error);
      swal({
        text: "Hubo un problema al obtener las wallets del punto de cobro",
        button: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPuntoCobroWallets();
  }, []);

  return {
    puntoCobroWallets,
    loading,
    getPuntoCobroWallets,
  };
};

export default useGetPuntoCobroWallets;
