import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "Auth";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import "./styles.css";
import { BtnBlueSm2 } from "styles/common/index.js";
import feanorapi from "feanorapi.js";
import { Icon } from "@iconify/react";
import swal from "sweetalert";

const QRAddress = ({ setShowQR, walletAddress }) => {
  const QRRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => QRRef.current,
  });

  return (
    <div>
      <div className="backBtn">
        <a className="backBtn__text" onClick={() => setShowQR(false)}>
          <Icon width="25" icon="eva:arrow-ios-back-fill" />
          Atras
        </a>
      </div>
      <div ref={QRRef} className="PrintSection" style={{ textAlign: "center" }}>
        <QRCode value={walletAddress} size={149} />
      </div>
      <h4
        style={{
          color: "black",
          textAlign: "center",
        }}
      >
        {walletAddress}
      </h4>

      <BtnBlueSm2
        style={{ display: "block", margin: "20px auto", width: "100px" }}
        onClick={handlePrint}
      >
        Imprimir
      </BtnBlueSm2>
    </div>
  );
};

export default QRAddress;
