import React from "react";
import { Header2, Paragraph } from "styles/common/index.js";
import UserData from "./UserData/index";

const Settings = () => {
  return (
    <React.Fragment>
      <Header2>Configuración de cuenta</Header2>
      <Paragraph>
        En esta sección puedes ver todos tus datos de registro, también podrás
        actualizar tu información.
      </Paragraph>
      <UserData />
    </React.Fragment>
  );
};

export default Settings;
