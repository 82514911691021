import React, { useContext, useState } from "react";
import swal from "sweetalert";
import { Header1White } from "styles/common/index";
import StyledAuth from "../styles";
import { AuthContext } from "Auth";
import { Redirect, Route } from "react-router";
import { verifyOTP } from "services/auth.service";
import { useHistory } from "react-router-dom";
import { verifyBusinessOTP } from "services/bussiness/auth.service";

const BussinessOTP = () => {
  const history = useHistory();

  const [otpToken, setOtpToken] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const {
    temporalToken,
    expiration,
    clearAuthentication,
    updateToken,
    updateExpiration,
  } = useContext(AuthContext);

  const isAuthenticated =
    temporalToken && expiration && parseInt(expiration) - Date.now() > 1000;

  !isAuthenticated && clearAuthentication();

  const handleVerifyBusinessToken = async (e) => {
    e.preventDefault();
    try {
      const { otpValid, token, dateExpiration } = await verifyBusinessOTP(
        temporalToken,
        otpToken
      );

      if (otpValid) {
        setErrorMsg("");
        updateToken(token);
        updateExpiration(dateExpiration);
        swal({ text: "Ingresando...", button: false, timer: 1000 });

        history.push("/business");
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response?.data?.code === "invalid-otp") {
        setErrorMsg("Codigo de verificación inválido.");
        return;
      }

      if (
        error.response &&
        error.response?.data?.code === "invalid-temporal-token"
      ) {
        history.push("/auth/login_business");
      }
    }
  };
  return (
    <>
      <Route
        render={() => {
          return isAuthenticated ? (
            <div>
              <Header1White>Verificación de seguridad</Header1White>

              {errorMsg && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "2px",
                  }}
                >
                  {errorMsg}
                </p>
              )}
              <StyledAuth.AuthForm onSubmit={handleVerifyBusinessToken} autoComplete="off">
                <StyledAuth.AuthInput
                  name="otpToken"
                  type="tel"
                  placeholder="Código del autenticador"
                  style={{
                    marginBottom: "2px",
                    outlineWidth: `${errorMsg ? "0" : ""}`,
                    borderColor: `${errorMsg ? "red" : ""}`,
                  }}
                  onChange={(e) => setOtpToken(e.target.value)}
                />
                <StyledAuth.InputDescription>
                  Introduce el código de 6 dígitos de la aplicación Google
                  Authenticator
                </StyledAuth.InputDescription>
                <StyledAuth.AuthButton type="submit">
                  Verificar código
                </StyledAuth.AuthButton>
              </StyledAuth.AuthForm>
            </div>
          ) : (
            <Redirect to="/auth/login" />
          );
        }}
      />
    </>
  );
};

export default BussinessOTP;
