import { fetchTransactions } from "services/transaction.service";
import swal from "sweetalert";
import { AuthContext } from "Auth";
import { useEffect, useState, useContext } from "react";

const useGetTransactions = (urlParameters = "") => {
  const [transactions, setTransactions] = useState([]);
  let { currentToken } = useContext(AuthContext);

  const getTransactions = async () => {
    try {
      const response = await fetchTransactions(currentToken, urlParameters);
      if (response) {
        setTransactions(response);
      }
    } catch (error) {
      console.log(error);
      swal({
        text:
          "Ha ocurrido un error mientras cargabamos los datos de tus transacciones",
        button: false,
        timer: 5000,
      });
    }
  };

  useEffect(() => {
    getTransactions();

    return () => {
      setTransactions([]);
    };
  }, []);

  return { transactions, getTransactions };
};

export default useGetTransactions;
