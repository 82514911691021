import feanorapi from "feanorapi";

export const subscribe = async (props) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(props),
  };
  let url = `${feanorapi}/public/project/subscribe`;
  const response = await fetch(url, data);

  const res = await response.json();
  return res.projectSubscriber;
};
