import Alert from 'components/Alert'
import { useState } from 'react'

const useAlertMessage = () => {
    const [content, setContent] = useState({
        open: false,
        title: '--------------------------',
        description: '',
        severity: 'error'
    })

    const handleClose = () => {
        setContent({...content, open: false })
    }

    const showAlert = (title, severity, descriptionParam = '') => {
        let alertContent = {...content, title, severity, description: descriptionParam, open: true }

        setContent(alertContent)
    }

    return [
        Alert,
        showAlert, content.title,
        content.description,
        content.severity, content.open, handleClose
    ]

}


export default useAlertMessage