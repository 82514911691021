const translations = {
  pagination: {
    labelRowsPerPage: 'Filas por página',
    firstTooltip: 'Primera página',
    lastTooltip: 'Última página',
    previousTooltip: 'Anterior página',
    nextTooltip: 'Siguiente página',
    labelRowsSelect: '',
    labelDisplayedRows: '{from} - {to} de {count}'
  },
  toolbar: { searchPlaceholder: 'Buscar', searchTooltip: 'Buscar' },
  header: { actions: 'Detalle' },
  body: { emptyDataSourceMessage: 'No hay datos para mostrar' }
};

export default translations;
