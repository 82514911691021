import ReactTimeAgo from "react-time-ago";
import {
  BtnSeeMoreNotLink,
  ColState,
  ColStatus,
  ColText,
  ListColumnDiv,
  ListRowDiv,
  TextBox,
  TitleBox,
  WalletBoxLeft,
} from "styles/Styled";
import { typeCategory, typeState } from "utils/translator.utils";
import React, { useState } from "react";

const LastTransactions = ({ transactions }) => {
  const [limit, setLimit] = useState(5);
  return (
    <WalletBoxLeft>
      <TitleBox>Últimas operaciones</TitleBox>

      {transactions.length <= 0 && (
        <TextBox>No existen operaciones por el momento :(.</TextBox>
      )}

      {transactions.slice(0, limit).map((transaction) => {
        let amount = transaction.amount.toFixed(2);

        return (
          <ListRowDiv key={transaction._id}>
            <ListColumnDiv>
              <ColText>{transaction.businessCard?.number} </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColText>{`${amount} USD`} </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColText>
                <ReactTimeAgo date={transaction.createdAt} locale="es" />{" "}
              </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColStatus>{`${typeCategory(
                transaction.category
              )}`}</ColStatus>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColState title="Este es el estado en el que se encuentra la operación">
                {typeState(transaction.state)}
              </ColState>
            </ListColumnDiv>
          </ListRowDiv>
        );
      })}

      {transactions.length >= 4 && limit != transactions.length && (
        <BtnSeeMoreNotLink
          onClick={(e) => {
            setLimit(transactions.length);
          }}
        >
          Ver todo
        </BtnSeeMoreNotLink>
      )}
    </WalletBoxLeft>
  );
};

export default LastTransactions;
