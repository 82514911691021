import ReactTimeAgo from "react-time-ago";
import {
  BtnSeeMore,
  ColState,
  ColStatus,
  ColText,
  ListColumnDiv,
  ListRowDiv,
  TextBox,
  TitleBox,
  WalletBoxLeft,
} from "styles/Styled";
import { typeCategory, typeState } from "utils/translator.utils";
import React from "react";

const LastTransactions = ({ transactions }) => {
  return (
    <WalletBoxLeft>
      <TitleBox>Últimas operaciones</TitleBox>

      {transactions.length <= 0 && (
        <TextBox>No existen operaciones por el momento :(.</TextBox>
      )}

      {transactions.slice(0, 5).map((transaction) => {
        const transactionObj = { ...transaction };
        //const date = new Date(transactionObj.createdAt);

        let amount = transactionObj.amount.toFixed(2);
        if (transactionObj.category === "convert")
          amount = transactionObj.ammounts[0].toFixed(2);

        return (
          <ListRowDiv key={transaction._id}>
            <ListColumnDiv>
              <ColText>{`${amount} USD`} </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColText>
                <ReactTimeAgo date={transactionObj.createdAt} locale="es" />{" "}
              </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColStatus>{`${typeCategory(
                transactionObj.category
              )}`}</ColStatus>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColState title="Este es el estado en el que se encuentra la operación">
                {typeState(transactionObj.state)}
              </ColState>
            </ListColumnDiv>
          </ListRowDiv>
        );
      })}

      {transactions.length >= 4 && (
        <BtnSeeMore to={`/app/profile/operations`}>Ver más</BtnSeeMore>
      )}
    </WalletBoxLeft>
  );
};

export default LastTransactions;
