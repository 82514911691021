import React, { useRef, useEffect, useContext } from "react";
import { TitleModal, BodyModal, DescriptionModal } from "styles/Styled";
import colors from "styles/common/colors";
import { Helmet } from "react-helmet";
import useModal from "hooks/useModal";
import { updateKYCApprovalToPending } from "services/user.service";
import swal from "sweetalert";
import { AuthContext } from "Auth";
// import { faCheckCircle, faUserCheck } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MetamapBtn from "components/MetamapBtn";
import DiditBtn from "components/DiditBtn";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: colors.feanor_gold,
    maxWidth: "200px",
    margin: "20px auto 0 auto",
    // color: "white",
    "&:hover": {
      backgroundColor: colors.feanor_gold,
    },
    textTransform: "none",
  },
}));

const VerificationModal = ({
  userId,
  fetchUser,
  isKYCApprovalPending,
  kycVerificationStatus,
}) => {
  let { currentToken } = useContext(AuthContext);
  const [
    Modal,
    isModalVerificationOpen,
    openModalVerification,
    onCloseModalVerification,
  ] = useModal();
  const ref = useRef(null);
  // const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!isKYCApprovalPending && !kycVerificationStatus) {
      openModalVerification();
    }
  }, [isKYCApprovalPending, kycVerificationStatus]);

  const onVerificationFinish = async () => {
    try {
      const resp = await updateKYCApprovalToPending(currentToken);
      console.log(resp);
      if (resp.success) {
        await fetchUser();
        swal({
          text:
            "¡Listo! Tus datos han sido enviados exitosamente, validaremos tu cuenta en el transcurrso del dia.",
          button: false,
          timer: 5000,
        });
        // setShowSuccessMsg(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet></Helmet>
      <Modal
        open={isModalVerificationOpen}
        onClose={onCloseModalVerification}
        center
        closeOnOverlayClick={false}
      >
        <BodyModal>
          <TitleModal style={{ marginBottom: 0 }}>
            Verificacion de identidad requerida
          </TitleModal>
          <DescriptionModal
            style={{
              textAlign: "center",
              margin: "0 auto",
              maxWidth: "600px",
            }}
          >
            Solo falta un paso más y es que tienes que completar el proceso de
            verificación de identidad (KYC) para poder disfrutar de Fëanor al
            100%.
          </DescriptionModal>
          {/* <FooterModal></FooterModal> */}

          <div style={{ margin: "10px auto" }}>
            <DiditBtn
              isKYCApprovalPending={isKYCApprovalPending}
              kycVerificationStatus={kycVerificationStatus}
              closeModal={onCloseModalVerification}
            />

            {/* ya no se usará metamap */}
            {/* <MetamapBtn
              userId={userId}
              onVerificationFinish={onVerificationFinish}
              isKYCApprovalPending={isKYCApprovalPending}
              kycVerificationStatus={kycVerificationStatus}
            /> */}
          </div>
        </BodyModal>

        {/* {showSuccessMsg && (
          <BodyModal>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="#248340"
              style={{ margin: "auto", fontSize: "28px" }}
            />
            <TitleModal style={{ marginBottom: 0 }}>
              Datos enviados exitosamente
            </TitleModal>
            <DescriptionModal
              style={{
                textAlign: "center",
                margin: "0 auto",
                maxWidth: "600px",
              }}
            >
              ¡Listo! Tus datos han sido enviados exitosamente, validaremos tu
              cuenta en el transcurrso del dia
            </DescriptionModal>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                onCloseModalVerification();
              }}
            >
              Continuar
            </Button>
          </BodyModal>
        )} */}
      </Modal>
    </>
  );
};

export default VerificationModal;
