let endpoint = "";
let website = "";
let ethExplorer = "";
let tronExplorer = "";
switch (process.env.REACT_APP_ENV_TYPE) {
  case "development":
    endpoint = process.env.REACT_APP_FEANOR_DEV;
    website = process.env.REACT_APP_WEBSITE_DEV;
    ethExplorer = "https://rinkeby.etherscan.io";
    tronExplorer = "https://nile.tronscan.org/#";
    break;
  case "staging":
    endpoint = process.env.REACT_APP_FEANOR_TEST;
    website = process.env.REACT_APP_WEBSITE_DEV;
    ethExplorer = "https://rinkeby.etherscan.io";
    tronExplorer = "https://nile.tronscan.org/#";
    break;
  case "production":
    endpoint = process.env.REACT_APP_FEANOR_PROD;
    website = process.env.REACT_APP_WEBSITE_PROD;
    ethExplorer = "https://etherscan.io";
    tronExplorer = "https://tronscan.io/#";
    break;
  default:
    endpoint = process.env.REACT_APP_FEANOR_DEV;
    website = process.env.REACT_APP_WEBSITE_DEV;
    ethExplorer = "https://rinkeby.etherscan.io";
    tronExplorer = "https://nile.tronscan.org/#";
}

export { website, ethExplorer, tronExplorer };
export default endpoint;
