import React from "react";
import { Redirect } from "react-router";
import { internal } from "routes";
import swal from "sweetalert";
import constants from "utils/constants";
import { useContext } from "react";
import { AuthContext } from "Auth";

const VerifyIsValidatedByKYC = ({ children, skip = false, ...rest }) => {
  const { userInfo } = useContext(AuthContext)  
  // const { userInfo, loading } = useGetUserInfo();

  if (skip) {
    return children;
  }
  if (
    // !loading &&
    JSON.stringify(userInfo) !== null &&
    JSON.stringify(userInfo) !== "{}"
  ) {
    if (
      userInfo.kycVerificationStatus !== constants.verificationStatus.verified
    ) {
      swal({
        title: "Validacion de KYC necesaria",
        text:
          "Porfavor complete el proceso de KYC para usar esta función, se le redirigira a la pagina de validacion KYC",
        buttons: {
          ok: {
            text: "Entendido!",
          },
        },
      });
      return <Redirect to={internal.validationPage} />;
    }
    return children;
  }
  return children;
};

export default VerifyIsValidatedByKYC;
