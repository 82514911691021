import { useState, useEffect, useContext } from "react";
import { AuthContext } from "Auth";
import { fetchWallets } from "services/wallet.service";
import swal from "sweetalert";
import currencyTypes from "models/currencies";

const useGetWallets = () => {
  const { currentToken } = useContext(AuthContext);
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [walletBTC, setWalletBTC] = useState({
    balance: 0.0,
    address: "",
    currency: currencyTypes.BTC,
    found: false,
  });
  const [walletUSDT, setWalletUSDT] = useState({
    balance: 0.0,
    address: "",
    currency: currencyTypes.USDT,
    found: false,
  });
  const [walletUSDTTron, setWalletUSDTTron] = useState({
    balance: 0.0,
    address: "",
    currency: currencyTypes.USDT_TRON,
    found: false,
  });
  const getWallets = async () => {
    setLoading(true);
    try {
      const response = await fetchWallets(currentToken);
      setWallets(response);

      const btcWallet = response.find((w) => w.currency === currencyTypes.BTC);
      const usdtWallet = response.find(
        (w) => w.currency === currencyTypes.USDT
      );
      const usdtTronWallet = response.find(
        (w) => w.currency === currencyTypes.USDT_TRON
      );
      if (btcWallet) {
        console.log("found btc wallet", btcWallet);
        setWalletBTC({
          ...btcWallet,
          balance: btcWallet.balance.toFixed(8),
          found: true,
        });
      }
      if (usdtWallet) {
        console.log("found usdt wallet", usdtWallet);
        setWalletUSDT({
          ...usdtWallet,
          balance: usdtWallet.balance.toFixed(2),
          found: true,
        });
      }
      if (usdtTronWallet) {
        console.log("found usdt tron wallet", usdtTronWallet);
        setWalletUSDTTron({
          ...usdtTronWallet,
          balance: usdtTronWallet.balance.toFixed(2),
          found: true,
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        text: "Hubo un problema al obtener tu billetera",
        button: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWallets();
  }, []);

  return {
    wallets,
    walletBTC,
    walletUSDT,
    walletUSDTTron,
    getWallets,
    loading,
  };
};

export default useGetWallets;
