import React, { useEffect, useState } from "react";
import { BtnYellowSm } from "styles/common/index.js";
import constants from "utils/constants";
import { AmountCurrency } from "styles/Styled";
// import { getDiditLink } from "services/user.service";
import { useHistory } from "react-router-dom";

const DiditBtn = ({
  userId,
  isKYCApprovalPending,
  kycVerificationStatus,
  onVerificationFinish,
  closeModal = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [buttonState, setButtonState] = useState("Verificarme");
  const history = useHistory();

  useEffect(() => {}, []);

  const onClickVerify = async (e) => {
    if (loading) return;

    e.preventDefault();
    closeModal();
    return history.push("/app/profile/level1");

    // try {
    //   setLoading(true);
    //   setButtonState("Cargando...");
    //   const diditLink = await getDiditLink();

    //   // abrir link en pestaña actual
    //   setButtonState("Redirigiendo...");
    //   window.location.href = diditLink.url;
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setLoading();
    // }
  };

  return (
    <>
      {!isKYCApprovalPending && !kycVerificationStatus && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <br />
          <BtnYellowSm
            title=""
            className=""
            style={{ width: "140px", margin: "auto", color: "white" }}
            onClick={onClickVerify}
          >
            {buttonState}
            {/* {loading ? "Cargando..." : "Verificarme"} */}
          </BtnYellowSm>
          <p
            style={{
              fontSize: "10px",
              maxWidth: "500px",
              textAlign: "center",
              color: "#3c4a5b",
            }}
          >
            <AmountCurrency>*</AmountCurrency> Importante: El documento de
            identidad debe ser una foto nítida del mismo, no se permiten
            escaneos o cualquier otro tipo de digitalización del documento.
          </p>
        </div>
      )}
      {kycVerificationStatus === constants.verificationStatus.verified &&
        !isKYCApprovalPending && (
          <BtnYellowSm
            title="Ya te encuentras aprobado en Nivel 1, no necesitas hacer nada más. ¡Felicitaciones!"
            className="disabled-link"
            to="/app/profile/level1"
          >
            KYC Aprobado!
          </BtnYellowSm>
        )}
      {isKYCApprovalPending && (
        <BtnYellowSm
          title="Ya te encuentras aprobado en Nivel 1, no necesitas hacer nada más. ¡Felicitaciones!"
          className="disabled-link"
          to="/app/profile/level1"
        >
          Aprobación pendiente...
        </BtnYellowSm>
      )}
      <br />

      <div></div>
    </>
  );
};

export default DiditBtn;
