import { AuthContext } from "Auth";
import { useEffect, useState, useContext } from "react";
import { getUserInfo } from "services/user.service";
import swal from "sweetalert";

const useGetUserInfo = (enabled = true) => {
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  let {
    currentToken,
    updateUserName,
    setUserInfo: setLocalUserInfo,
  } = useContext(AuthContext);

  const fetchUser = async () => {
    try {
      setLoading(true);
      // await new Promise((res, rej) => {
      //   setTimeout(function() {
      //     res();
      //   }, 5000);
      // });
      const user = await getUserInfo(currentToken);
      console.log("user info fetched")
      if (!user) throw new Error("Non User");
      updateUserName(user.name);
      setLocalUserInfo(user);
      setUserInfo(user);
    } catch (error) {
      console.log(error);
      swal({
        text: "Ha habido un error en nuestro sistema",
        button: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (enabled) {
      fetchUser();
    }
  }, []);

  return { userInfo, loading, fetchUser };
};

export default useGetUserInfo;
