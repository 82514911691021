import React from "react";
import moment from "moment";
import { AmountCurrency } from "styles/Styled";

export default function UpdatedAt({ date }) {
  if (!date || date.toString() === "Invalid Date") {
    return "";
  }

  return (
    <p
      style={{
        marginTop: "0",
        fontSize: "11px",
        maxWidth: "500px",
        color: "#3c4a5b",
      }}
    >
      <AmountCurrency>*</AmountCurrency> Saldo actualizado el{" "}
      {moment(date).format("DD/MM/YYYY")} a las{" "}
      {moment(`${date.getHours()}:${date.getMinutes()}`, "hh:mm").format("LT")}{" "}
      de su hora local.
    </p>
  );
}
