import React, { useState, useContext, useEffect } from "react";
import {
  BodyModal,
  BtnPrimaryLg,
  Clear,
  DescriptionModal,
  DetailAmount,
  DetailsOperation,
  DetailTitle,
  FooterModal,
  InputModal,
  LabelModal,
  LabelSpan,
  Margin20,
  TitleModal,
} from "styles/Styled";
import feanorapi from "feanorapi.js";
import { AuthContext } from "Auth";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import { SelectorFull } from "styles/common";
import constants from "utils/constants";
import { SettingsCell } from "@material-ui/icons";
import { createConvert, createSwap, fetchQuote } from "services/quote.service";
import {
  converBTCtoUSDTBusiness,
  fundCardRequest,
  fundVisaCardRequest,
} from "services/card.service";
import useGetPuntosCobro from "hooks/useGetPuntosCobro";
import useGetPuntoCobroWallets from "hooks/useGetPuntoCobroWallets";
import {
  confirmFundBusinessCardRequest,
  fundBusinessCardRequest,
} from "services/businessCard.service";
import LoadingMessage from "components/LoadingCardDepoitMsg/LoadingCardDepositMsg";
import debounce from "lodash/debounce";

const defaultWallet = {
  balance: 0,
  currency: "",
  address: "",
};

export default function FundBusinessCardForm({
  cardDetail,
  refreshCardDetail,
  walletUSDT,
  walletBTC,
  closeModal,
  refreshWallets,
  refreshTransactions,
  cardProvider = "visa",
  refreshCardsBalance,
}) {
  const { currentToken } = useContext(AuthContext);

  const [amountToFund, setAmountToFund] = useState("");
  const [convertedAmount, setConvertedAmount] = useState("");
  const [feanorFee, setFeanorFee] = useState(0.0);
  const [referrerFee, setReferrerFee] = useState(0.0);
  const [loading, setLoading] = useState(false);

  const { puntosCobro } = useGetPuntosCobro();
  const puntosCobroOptions = puntosCobro.map((punto) => ({
    value: punto._id,
    label: punto.name,
  }));
  const [puntoCobroSelected, setPuntoCobroSelected] = useState({
    name: "",
    _id: "",
  });

  const { puntoCobroWallets, getPuntoCobroWallets } = useGetPuntoCobroWallets();
  const puntoCobroWalletsOptions = puntoCobroWallets.map((puntoWallet) => ({
    value: puntoWallet._id,
    label: `Billetera ${
      puntoWallet.currency === "USDT" ? "USDT_ETH" : puntoWallet.currency
    }`,
  }));
  const [walletSelected, setWalletSelected] = useState(defaultWallet);

  useEffect(() => {
    if (puntosCobro.length > 0) {
      handlePuntoCobroChange(puntosCobro[0]._id);
    }
  }, [puntosCobro]);

  useEffect(() => {
    handlePuntoCobroWalletChange(puntoCobroWallets[0]?._id);
  }, [puntoCobroWallets]);

  const fetchBusinesCardDepositFees = async (amount) => {
    let endpoint = `/business/fees/card/deposits`;
    let url = `${feanorapi}${endpoint}`;
    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({ amount }),
    };
    try {
      const response = await fetch(url, data);
      const res = await response.json();
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
      swal("ocurrio un error al calcular las comisiones");
    }
  };

  const handlePuntoCobroChange = async (puntoCobroId) => {
    const punto = puntosCobro.find((punto) => punto._id === puntoCobroId);
    setPuntoCobroSelected(punto);
    await getPuntoCobroWallets(puntoCobroId);
  };
  const handlePuntoCobroWalletChange = async (puntoCobroWalletId) => {
    const wallet = puntoCobroWallets.find(
      (puntoWallet) => puntoWallet._id === puntoCobroWalletId
    );
    setWalletSelected(wallet ?? defaultWallet);
  };

  const maxFundValue = async (e) => {
    e.preventDefault();
    const fees = await fetchBusinesCardDepositFees(walletSelected.balance);
    setFeanorFee(fees.feanorFee);
    setAmountToFund(walletSelected.balance);
  };

  const maxFundBTCValue = async (e) => {
    e.preventDefault();

    const quote = await getBTCQuote({ finalAmount: walletBTC.balance });

    setAmountToFund(walletSelected.balance);
    const convertedAmount = quote.totalAmounts[1] ?? 0;
    setConvertedAmount(convertedAmount);

    const fees = await fetchBusinesCardDepositFees(convertedAmount);
    setFeanorFee(fees.feanorFee);
  };

  const maxFundUSDTTronValue = async (e) => {
    e.preventDefault();
    setAmountToFund(walletSelected.balance);
    const fees = await fetchBusinesCardDepositFees(walletSelected.balance);
    setFeanorFee(fees.feanorFee);
  };

  const getBTCQuote = async ({ finalAmount }) => {
    const quote = await fetchQuote({
      ammount: finalAmount,
      from: "BTC",
      to: constants.currencyTypes.USDT_TRON,
      transactionType: "SELL",
    });

    return quote;
  };

  const onAmountChange = async (e) => {
    const amount = parseFloat(e.target.value);
    let finalAmount = 0;
    if (amount <= parseFloat(walletSelected.balance) && amount > 0) {
      finalAmount = amount;
    } else if (amount > parseFloat(walletSelected.balance)) {
      finalAmount = walletSelected.balance;
    }
    if (finalAmount === 0) {
      setAmountToFund("");
    } else {
      setAmountToFund(finalAmount);
    }

    const fees = await fetchBusinesCardDepositFees(finalAmount);
    setFeanorFee(fees.feanorFee);
  };

  const onAmountBTCChange = async (e) => {
    const amount = parseFloat(e.target.value);

    let finalAmount = 0;
    if (amount <= parseFloat(walletSelected.balance) && amount > 0) {
      finalAmount = amount;
    } else if (amount > parseFloat(walletSelected.balance)) {
      finalAmount = walletSelected.balance;
    }

    // if (finalAmount === 0) {
    //   setAmountToFund("");
    // } else {
    // }
    setAmountToFund(finalAmount);

    const quote = await getBTCQuote({ finalAmount });

    const convertedAmount = quote.totalAmounts[1] ?? 0;
    setConvertedAmount(convertedAmount);

    const fees = await fetchBusinesCardDepositFees(convertedAmount);
    setFeanorFee(fees.feanorFee);
  };

  const onAmountUSDTTronChange = async (e) => {
    const amount = parseFloat(e.target.value);

    let finalAmount = 0;
    if (amount <= parseFloat(walletSelected.balance) && amount > 0) {
      finalAmount = amount;
    } else if (amount > parseFloat(walletSelected.balance)) {
      finalAmount = walletSelected.balance;
    }

    if (finalAmount === 0) {
      setAmountToFund("");
    } else {
      setAmountToFund(finalAmount);
    }

    const fees = await fetchBusinesCardDepositFees(finalAmount);
    setFeanorFee(fees.feanorFee);
  };

  const fundCard = debounce(async () => {
    setLoading(true);
    let amount = parseFloat(amountToFund);
    try {
      if (Boolean(walletSelected._id) === false) {
        throw new Error("Porfavor seleccione una billetera USDT");
      }
      if (amount <= 0) {
        throw new Error("El monto debe ser mayor a 0");
      }
      let depositProps = {
        amount,
        cardId: cardDetail._id,
        // walletPuntoCobroId: walletSelected._id,
        puntoCobroId: puntoCobroSelected._id,
        currencyTarget: walletSelected.currency,
        currencyOrigin: walletSelected.currency,
      };

      if (constants.currencyTypes.BTC === walletSelected.currency) {
        if (convertedAmount < constants.minDepositAmount) {
          throw new Error(
            `El monto minimo para depositar es ${constants.minDepositAmount} USDT`
          );
        }

        const conversion = await converBTCtoUSDTBusiness(
          {
            amount,
            puntoCobroId: puntoCobroSelected._id,
            from: constants.currencyTypes.BTC,
            transactionType: "SELL",
          },
          currentToken
        );
        if (!conversion.success) {
          throw new Error(
            conversion.message ?? "Error al realizar la conversion"
          );
        }
        depositProps = {
          ...depositProps,
          amount: conversion.toAmount,
          currencyTarget: constants.currencyTypes.USDT_TRON,
          exchangeDepositId: conversion.exchangeTransaction._id,
        };
      }

      /* Creating de deposit to fund the card. */
      let response = await fundBusinessCardRequest(depositProps, currentToken);
      if (cardDetail.createdInSVEmisor && response.success) {
        response = await confirmFundBusinessCardRequest(
          response.transaction._id,
          currentToken
        );
      }

      if (response.code === "v-failed") {
        await getPuntoCobroWallets(puntoCobroSelected._id);
        await refreshTransactions();
        // await refreshCardDetail();
        refreshCardsBalance();
        swal2.fire(
          "Abono en proceso",
          "Aprobaremos su transacción en el transcurso del día",
          "warning"
        );
        closeModal();
        return;
      }

      if (!response.success) {
        let msg =
          response.message ?? "Ocurrió un error al realizar el deposito";
        if (response.code === "v-failed") {
          msg = "Ocurrió un error al realizar el deposito";
        }
        throw new Error(msg);
      }
      await getPuntoCobroWallets(puntoCobroSelected._id);
      await refreshTransactions();
      // await refreshCardDetail();
      refreshCardsBalance();
      swal2.fire(
        "Abono realizado",
        "El abono fue realizado correctamente y lo aprobaremos en el transcurso del dia",
        "success"
      );
      closeModal();
    } catch (error) {
      console.log(error);
      swal(error.message);
    } finally {
      setLoading(false);
    }
  }, 700);

  return (
    <BodyModal>
      <TitleModal>Abonar dinero a mi Fëanor Card</TitleModal>
      {/* <ImageModal src={iconSendBTC} /> */}

      <DescriptionModal style={{ textAlign: "center" }}>
        Te permite ingresar dinero a tu FëanorCard, esto luego podrás utilizarlo
        para hacer compras a nivel mundial.
      </DescriptionModal>

      <LabelModal>
        <LabelSpan>Tarjeta a abonar</LabelSpan>
        <InputModal
          type="text"
          value={cardDetail.number}
          readOnly
          onChange={() => {}}
        />
      </LabelModal>
      <LabelModal>
        <LabelSpan>Seleccione un Punto de cobro</LabelSpan>
        <SelectorFull
          required
          options={puntosCobroOptions}
          value={puntosCobroOptions.find(
            (punto) => punto.value === puntoCobroSelected._id
          )}
          onChange={(option) => {
            handlePuntoCobroChange(option.value);
            setAmountToFund("");
            setConvertedAmount(0);
            setFeanorFee(0);
            setReferrerFee(0);
          }}
        ></SelectorFull>
      </LabelModal>
      <LabelModal>
        <LabelSpan>Seleccione una billetera</LabelSpan>
        <SelectorFull
          required
          options={puntoCobroWalletsOptions}
          value={puntoCobroWalletsOptions.find(
            (wallet) => wallet.value === walletSelected._id
          )}
          onChange={(option) => {
            handlePuntoCobroWalletChange(option.value);
            setAmountToFund("");
            setConvertedAmount(0);
            setFeanorFee(0);
            setReferrerFee(0);
          }}
        ></SelectorFull>
      </LabelModal>

      {walletSelected.currency === constants.currencyTypes.USDT && (
        <LabelModal>
          <LabelSpan>
            Monto a abonar (Disponible:{" "}
            <b>
              {walletSelected.balance} {walletSelected.currency}
            </b>
            ).{" "}
            <a href="#" onClick={(e) => maxFundValue(e)}>
              Usar todo
            </a>
          </LabelSpan>
          <InputModal
            type="number"
            min="0"
            value={amountToFund}
            placeholder="0.00"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onChange={onAmountChange}
          />
        </LabelModal>
      )}

      {walletSelected.currency === constants.currencyTypes.BTC && (
        <LabelModal>
          <LabelSpan>
            Monto a abonar (Disponible:{" "}
            <b>
              {walletSelected.balance} {walletSelected.currency}
            </b>
            ).{" "}
            <a href="#" onClick={(e) => maxFundBTCValue(e)}>
              Usar todo
            </a>
          </LabelSpan>
          <InputModal
            type="number"
            min="0"
            value={amountToFund}
            placeholder="0.00"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onChange={onAmountBTCChange}
          />
        </LabelModal>
      )}

      {walletSelected.currency === constants.currencyTypes.USDT_TRON && (
        <LabelModal>
          <LabelSpan>
            Monto a abonar (Disponible:{" "}
            <b>
              {walletSelected.balance} {walletSelected.currency}
            </b>
            ).{" "}
            <a href="#" onClick={(e) => maxFundUSDTTronValue(e)}>
              Usar todo
            </a>
          </LabelSpan>
          <InputModal
            type="number"
            min="0"
            value={amountToFund}
            placeholder="0.00"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onChange={onAmountUSDTTronChange}
          />
        </LabelModal>
      )}

      <DetailsOperation>
        <DetailTitle>Comisión 4%</DetailTitle>
        <DetailAmount>
          {(parseFloat(feanorFee) + parseFloat(referrerFee)).toFixed(2)} USDT
        </DetailAmount>
      </DetailsOperation>
      {walletSelected.currency === constants.currencyTypes.BTC && (
        <DetailsOperation>
          <DetailTitle>Monto a recibir</DetailTitle>
          <DetailAmount>
            {convertedAmount
              ? (parseFloat(convertedAmount) - parseFloat(feanorFee)).toFixed(2)
              : "0.00"}{" "}
            USDT
          </DetailAmount>
        </DetailsOperation>
      )}
      {walletSelected.currency === constants.currencyTypes.USDT ||
        (walletSelected.currency === constants.currencyTypes.USDT_TRON && (
          <DetailsOperation>
            <DetailTitle>Monto a recibir</DetailTitle>
            <DetailAmount>
              {amountToFund
                ? (parseFloat(amountToFund) - parseFloat(feanorFee)).toFixed(2)
                : "0.00"}{" "}
              USDT
            </DetailAmount>
          </DetailsOperation>
        ))}

      {/* <DetailsOperation>
        <DetailTitle>Comisión Fëanor</DetailTitle>
        <DetailAmount>{feanorFee} USDT</DetailAmount>
      </DetailsOperation>

      <Clear></Clear>

      <DetailsOperation>
        <DetailTitle>Comisión Referidor</DetailTitle>
        <DetailAmount>{referrerFee} USDT</DetailAmount>
      </DetailsOperation>

      <Clear></Clear> */}
      {walletSelected.currency === constants.currencyTypes.USDT && (
        <DetailsOperation>
          <DetailTitle>Monto a recibir</DetailTitle>
          <DetailAmount data-testid="toreceive">
            {amountToFund
              ? (parseFloat(amountToFund) - parseFloat(feanorFee)).toFixed(2)
              : "0.00"}{" "}
            USDT
          </DetailAmount>
        </DetailsOperation>
      )}
      {/* {currentCurrency === constants.currencyTypes.BTC && (
        <DetailsOperation>
          <DetailTitle>Monto a recibir</DetailTitle>
          <DetailAmount>
            {convertedAmount
              ? (
                  parseFloat(convertedAmount) -
                  parseFloat(referrerFee) -
                  parseFloat(feanorFee)
                ).toFixed(2)
              : "0.00"}{" "}
            USDT
          </DetailAmount>
        </DetailsOperation>
      )} */}

      <Clear></Clear>

      {/* <DetailsOperation>
        <DetailTitle>Monto total</DetailTitle>
        <DetailAmount>
          {amountToFund
            ? parseFloat(amountToFund) +
              parseFloat(feanorFee) +
              parseFloat(referrerFee)
            : 0}{" "}
          USDT
        </DetailAmount>
      </DetailsOperation> */}

      <Margin20></Margin20>
      <Margin20></Margin20>

      <BtnPrimaryLg disabled={loading} onClick={() => fundCard()}>
        <LoadingMessage
          loading={loading}
          createdInSVEmisor={cardDetail.createdInSVEmisor}
        />
      </BtnPrimaryLg>

      <FooterModal>
        <b>Importante:</b> No recomendamos realizar abonos si la tarjeta aún no
        ha sido recibida o esta en proceso de envio.
      </FooterModal>
    </BodyModal>
  );
}
