import React, { useContext } from "react";
import useGetQuote from "hooks/useGetQuote";
import useGetWallets from "hooks/useGetWallets";
import currencyTypes from "models/currencies";
import operationTypes from "models/operations";
import {
  AmountCurrency,
  BtnPrimary,
  BtnSecondary,
  ButtonContent,
  DescriptionBox,
  DisplayCount,
  TextSaldo,
  TitleBox,
  WalletBoxLeft,
  WalletBoxRight,
  WalletContent,
} from "styles/Styled";
import { createUSDTWallet } from "services/wallet.service";
import { AuthContext } from "Auth";
import useModal from "hooks/useModal";
import FormSendUSDT from "./FormSendUSDT";
import { PrimaryText, SecondaryText } from "styles/colorized";
import FormReceiveUSDT from "./FormReceiveUSDT";
import useGetUserInfo from "hooks/useGetUser";
import swal from "sweetalert";
import { Redirect, useHistory } from "react-router";
import constants from "utils/constants";
import { internal } from "routes";

const BalanceRow = ({ refreshTransactions }) => {
  const history = useHistory();
  const { currentToken } = useContext(AuthContext);
  const { userInfo } = useGetUserInfo(currentToken);
  const [
    ModalSend,
    isOpenModalSend,
    openModalSend,
    onCloseModalSend,
  ] = useModal();
  const [
    ModalReceive,
    isOpenModalReceive,
    openModalReceive,
    onCloseModalReceive,
  ] = useModal();
  const { walletUSDT, getWallets } = useGetWallets();
  const { rates } = useGetQuote({
    amount: 100,
    from: currencyTypes.USDT,
    to: currencyTypes.USD,
    operationType: operationTypes.sell,
  });

  const createWallet = async () => {
    if (
      userInfo.kycVerificationStatus !== constants.verificationStatus.verified
    ) {
      swal({
        title: "Validacion de KYC necesaria",
        text:
          "Porfavor complete el proceso de KYC para poder contar con una billetera USDT, se le redirigira a la pagina de validacion KYC",
        buttons: {
          ok: {
            text: "Entendido!",
          },
        },
      });
      return history.push(internal.validationPage);
    }

    const wallet = await createUSDTWallet(currentToken);
    if (wallet) {
      await getWallets();
    }
  };

  const handleClickButtonSend = () => {
    if (
      userInfo.kycVerificationStatus !== constants.verificationStatus.verified
    ) {
      swal({
        text:
          "Para hacer envios es necesario la verificación de cuenta, te redirireccionaremos al proceso de validacion.",
        button: false,
        timer: 4000,
      });
      setTimeout(function() {
        history.push(`/app/profile/activation`);
      }, 4000);
      return;
    }
    openModalSend();
  };

  return (
    <>
      <ModalSend
        open={isOpenModalSend}
        onClose={onCloseModalSend}
        center
        closeOnOverlayClick={false}
      >
        <FormSendUSDT
          walletUSDT={walletUSDT}
          refreshWallets={getWallets}
          refreshTransactions={refreshTransactions}
          onCloseModalSend={onCloseModalSend}
        />
      </ModalSend>

      <ModalReceive
        open={isOpenModalReceive}
        onClose={onCloseModalReceive}
        center
        closeOnOverlayClick={false}
      >
        <FormReceiveUSDT walletUSDTAddress={walletUSDT.address} />
      </ModalReceive>

      <TextSaldo>
        <PrimaryText>
          Tienes un saldo total de{" "}
          <AmountCurrency>{walletUSDT.balance} USDT</AmountCurrency>, y la tasa
          de venta es 1 USDT = {rates.USDTUSDSell} USD
        </PrimaryText>
      </TextSaldo>

      <WalletContent>
        <WalletBoxLeft>
          <TitleBox>Saldo</TitleBox>
          <DisplayCount>{walletUSDT.balance} USDT</DisplayCount>
        </WalletBoxLeft>

        <WalletBoxRight>
          {walletUSDT.found && (
            <DescriptionBox>
              Tienes disponible <b>{walletUSDT.balance} USDT </b> para enviar
            </DescriptionBox>
          )}

          {walletUSDT.found && (
            <ButtonContent>
              <BtnPrimary onClick={handleClickButtonSend}>Enviar</BtnPrimary>
              <BtnSecondary onClick={openModalReceive}>Recibir</BtnSecondary>
            </ButtonContent>
          )}

          {/* Desabilitado por que ahora se usara USDT TRON */}
          {/* {!walletUSDT.found && (
            <DescriptionBox>
              Aún no tienes una billetera. Creala ahora mismo con un clic.
            </DescriptionBox>
          )}

          {!walletUSDT.found && (
            <ButtonContent>
              <BtnPrimary onClick={createWallet}>Crear</BtnPrimary>
            </ButtonContent>
          )} */}
        </WalletBoxRight>
      </WalletContent>
    </>
  );
};

export default BalanceRow;
