import React from "react";
import { BalanceFIATTitle } from "./../styles";
const AvailableBalance = ({
  transactionType,
  balanceUSD,
  balanceBTC,
  balanceUSDT,
  currentCrypto,
}) => {
  return (
    <div>
      <BalanceFIATTitle>
        {transactionType === "BUY"
          ? "Tienes en dólares ($): " + balanceUSD
          : currentCrypto === "USDT"
          ? "Tienes en tethers (₮): " + balanceUSDT
          : "Tienes en bitcoins (₿): " + balanceBTC}
      </BalanceFIATTitle>
    </div>
  );
};

export default AvailableBalance;
