/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { withRouter } from "react-router";
import * as styledComponents from "./styles";
import edit_icon from "media/icons/icon_edit@2x.png";
import RemoveRedEyeOutlined from "@material-ui/icons/RemoveRedEyeOutlined";
import IconButton from "@material-ui/core/IconButton";
import useModal from "hooks/useModal";
import TxDetail from "components/TxDetail";

const HorizontalView = ({ transacciones, history }) => {
  const [Modal, open, openModal, onClose] = useModal();
  const [txSelected, setTxSelected] = useState({});

  return (
    <styledComponents.TableDesktop>
      <Modal open={open} onClose={onClose}>
        <TxDetail transaction={txSelected}></TxDetail>
      </Modal>

      <styledComponents.Header>
        <styledComponents.HeaderRow>
          <styledComponents.HeaderName>Detalle</styledComponents.HeaderName>
          <styledComponents.HeaderName>Tipo</styledComponents.HeaderName>
          <styledComponents.HeaderName>Monto</styledComponents.HeaderName>
          <styledComponents.HeaderName>Comisión</styledComponents.HeaderName>
          <styledComponents.HeaderName>
            Total Ejecutado
          </styledComponents.HeaderName>
          <styledComponents.HeaderName>Tasa</styledComponents.HeaderName>

          <styledComponents.HeaderName>F. Creación</styledComponents.HeaderName>
          <styledComponents.HeaderNameState>
            Estado
          </styledComponents.HeaderNameState>
          <styledComponents.HeaderName></styledComponents.HeaderName>
        </styledComponents.HeaderRow>
      </styledComponents.Header>
      <tbody>
        {transacciones.map((transaction) => {
          return (
            <styledComponents.DataRow
              id={transaction._id}
              key={transaction._id}
            >
              <styledComponents.DataBold>
                {/* {`${transaction.tx}...`} */}
                <IconButton
                  onClick={() => {
                    setTxSelected(transaction);
                    openModal();
                  }}
                >
                  <RemoveRedEyeOutlined />
                </IconButton>
              </styledComponents.DataBold>
              <styledComponents.Data>
                {transaction.transactionType}
              </styledComponents.Data>
              <styledComponents.Data>
                {transaction.amountCurrency || "-"}
              </styledComponents.Data>
              <styledComponents.Data>{transaction.fee}</styledComponents.Data>
              <styledComponents.Data>
                {/* <styledComponents.DataBold> */}
                {transaction.totalExecuted}
                {/* </styledComponents.DataBold> */}
              </styledComponents.Data>
              <styledComponents.Data>
                {transaction.rate || "---"}
              </styledComponents.Data>
              <styledComponents.Data>
                {transaction.date.getDate()}/
                {("0" + (transaction.date.getMonth() + 1)).slice(-2)}/
                {1900 + transaction.date.getYear()} -{" "}
                {("0" + transaction.date.getHours()).slice(-2)}:
                {("0" + transaction.date.getMinutes()).slice(-2)}
              </styledComponents.Data>
              <styledComponents.DataState>
                {transaction.state}
              </styledComponents.DataState>
              <styledComponents.Data>
                {transaction.state === "REQUESTED" &&
                  (transaction.transactionType === "SELL" ||
                    transaction.transactionType === "BUY") && (
                    <styledComponents.IconEdit
                      src={edit_icon}
                      onClick={() =>
                        history.push(`/app/operation/edit/${transaction._id}`)
                      }
                    />
                  )}
              </styledComponents.Data>
            </styledComponents.DataRow>
          );
        })}
      </tbody>
    </styledComponents.TableDesktop>
  );
};

export default withRouter(HorizontalView);
