import React from "react";
import { Header2, Paragraph } from "styles/common/index.js";
import SaldoUSDT from "components/SaldoUSDT";

const MoneyUSDT = () => {
  return (
    <React.Fragment>
      <Header2>Billetera Tethers (₮)</Header2>
      <Paragraph className="boxCenter">
      Esta es tu billetera exclusiva en Fëanor, desde aquí puedes enviar y recibir tethers a tus amigos o familiares. Úsala sabiamente, funciona como una cuenta bancaria. ¡Eres libre de disponer de tus fondos y moverlos!. 
      </Paragraph>
      <SaldoUSDT />
    </React.Fragment>
  );
};

export default MoneyUSDT;
