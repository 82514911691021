import React from "react";
import { Redirect, Route } from "react-router-dom";
import { internal } from "routes";
import swal from "sweetalert";
import { useContext } from "react";
import { PlatformFeeContext } from "contexts/platformFeeProvider";
import swal2 from "sweetalert2";
import { AuthContext } from "Auth";

const VerifyPlatformFeeCharged = ({ children, skip = false, ...rest }) => {
  const { platformFee } = useContext(PlatformFeeContext);
  const { userName } = useContext(AuthContext);

  if (skip) {
    return children;
  }

  if (!platformFee.charged) {
    swal2.fire({
      icon: "warning",
      title: `Estimado/a ${userName}:`,
      html:
        "El siguiente desglose es para que conozca algunos costos relevantes a su tarjeta.<br/> $225 - Mantenimiento de plataforma, seguro de fraude, emisión y envío. Este pago se realiza una única vez por año y debe realizarlo previo a la solicitud de su tarjeta. <br/> 5% - Comisión del monto abonado a su uso de tarjeta",
      showCancelButton: false,
      confirmButtonText: "Entendido!",
    });
    return <Redirect to={internal.walletsPage} />;
  }
  return children;
};

export default VerifyPlatformFeeCharged;
