import feanorapi from "feanorapi";
import httpCommon from "http-common";
import { getToken } from "services/common";

export const fetchPuntosCobro = async () => {
  const response = await httpCommon.get("/business/punto", {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      // ...headers,
      // ...authHeaders,
    },
  });
  console.log(response);
  return response.data;
};

export const fetchPuntoCobroWallets = async (puntoCobroId) => {
  const response = await httpCommon.get(
    `/business/punto/wallet/list?punto=${puntoCobroId}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // ...headers,
        // ...authHeaders,
      },
    }
  );
  console.log(response);
  return response.data;
};
