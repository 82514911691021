import React from "react";
import { Header2, Paragraph } from "styles/common/index.js";
import Level2Form from "./Level2/index";


const Activation = () => {
  return (
    <React.Fragment>
      <Header2>Validación de cuenta (Nivel 2)</Header2>
      <Paragraph>
Estas validando tu cuenta hacia el Nivel 2. Esta validación adicional es exclusiva para los usuarios que <br></br>
desean comprar o vender montos altos de Bitcoin. Puedes ser una persona natural o empresa.  </Paragraph>



    <Level2Form/>

      
    </React.Fragment>
  );
};

export default Activation;
