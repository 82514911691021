import feanorapi from "feanorapi";
import http from "http-common";
import { getToken } from "services/common";

export const generateBusinessOTP = async () => {
  const response = await http.post(
    "/business/otp/generate",
    {},
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        // ...headers,
        // ...authHeaders,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const enableBusinessOTP = async (otpToken) => {
  const response = await http.post(
    "/business/otp/enable",
    { token: otpToken },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const disableBusinessOTP = async (otpToken) => {
  const response = await http.post(
    "/business/otp/disable",
    { otpToken },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const verifyBusinessOTP = async (temporalToken, otpToken) => {
  const response = await http.post(
    "/auth/otp/verify-business",
    { otpToken, temporalJWT: temporalToken },
    {
      headers: {},
    }
  );
  console.log(response);
  return response.data;
};
