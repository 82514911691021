import React, { useState, createContext } from "react";
import { ethers } from "ethers";
import constants from "utils/constants";
import {
  fetchWallets,
  createETHWallet,
  getChainId,
  mintTokens,
} from "services/wallet.service";

export const PurchaseNFTContext = createContext();

export const PurchaseNFTProvider = ({ children }) => {
  const [projectDetail, setProjectDetail] = useState({});
  const [amount, setAmount] = useState("");
  const [walletType, setWalletType] = useState("");
  const [chainId, setChainId] = useState("");
  const [loader, setLoader] = useState(false);
  const [tokenCost, setTokenCost] = useState(0);
  const [currentAccount, setCurrentAccount] = useState("");
  const [transaction, setTransaction] = useState({});

  const checkIfChainIsCorrect = async () => {
    try {
      const mapNetworks = {
        "0x4": "rinkeby",
        "0x3": "ropsten",
        "0x5": "goerli",
        "0x1": "mainnet",
        "0xaa36a7": "sepolia",
      };
      const { ethereum } = window;
      // > Comprobamos si estamos en la red correcta

      const chainId = await ethereum.request({ method: "eth_chainId" });
      // const rinkebyChainId = "0x4";
      if (!Object.keys(constants.networks).includes(chainId)) {
        // if (chainId !== rinkebyChainId) {
        setChainId("");
        console.log("Red incorrecta.");
      } else {
        setChainId(constants.networks[chainId].name || chainId);
        console.log("conectado a la red");
      }
    } catch (error) {
      console.log(new Error(error));
    }
  };

  const setupEventListener = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();

        const connectedContract = new ethers.Contract(
          projectDetail.contractAddress,
          projectDetail.contractAbi,
          signer
        );

        // > Capturo el evento
        // connectedContract.on("NewSQIMinted", (from, to, tokensArr) => {
        //   setNewTokensId(tokensArr);
        //   getAvailableNFTs();
        //   getNFTsCollection();
        //   console.log(from, tokensArr);
        // });
        console.log("Setup event listener!");
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTokenCost = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const connectedContract = new ethers.Contract(
          projectDetail.contractAddress,
          projectDetail.contractAbi,
          provider
        );
        const cost = await connectedContract.cost();
        setTokenCost(parseFloat(ethers.utils.formatEther(cost)));
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      setTokenCost(0);
      console.log(error);
    }
  };

  const askContractToMintNft = async (totalCost) => {
    const { ethereum } = window;

    console.log(projectDetail);

    if (ethereum) {
      // > Un "provider" es lo que usamos para comunicarnos con los nodos de Ethereum.
      // En este caso usamos nodos que Metamask proporciona en segundo plano para enviar/recibir datos de nuestro contrato implementado.
      const provider = new ethers.providers.Web3Provider(ethereum);
      // > info: https://docs.ethers.io/v5/api/signer/#signers
      const signer = provider.getSigner();
      // > Crea la conexión con nuestro contrato
      const connectedContract = new ethers.Contract(
        projectDetail.contractAddress,
        projectDetail.contractAbi,
        signer
      );

      console.log("Going to pop wallet now to pay gas...");
      //   const nftTxn = await connectedContract.mintBatch(amountToMint, {
      //     value: ethers.utils.parseEther(totalCost.toString()),
      //   });
      let nftTxn = null;

      console.log(ethers.utils.parseEther(totalCost.toFixed(5)));
      nftTxn = await connectedContract.mintPublic(amount, {
        value: ethers.utils.parseEther(totalCost.toFixed(5)),
      });

      if (nftTxn) {
        setLoader(true);

        console.log("Mining...please wait.");
        const res = await nftTxn.wait();
        setLoader(false);
        return res;
        // getAvailableNFTs();
      } else {
        throw new Error("Not sale type configured");
      }
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  };
  const mintUsingFeanorConn = async (currentToken) => {
    setLoader(true);
    const nftTxn = await mintTokens(currentToken, amount, projectDetail._id);
    setLoader(false);
    return nftTxn;
  };

  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
      // > Escucho evenots! caso en que un usuario ingresa a nuestro sitio y conecta su billetera por primera vez.
      setupEventListener();
      // > check de la red
      checkIfChainIsCorrect();
    } catch (error) {
      console.log(new Error(error));
    }
  };

  const connectFeanorWallet = async (currentToken) => {
    try {
      let ethWallet = null;
      const wallets = await fetchWallets(currentToken);
      ethWallet = wallets.find((e) => e.currency === "ETH");
      console.log(ethWallet);
      if (!ethWallet) {
        ethWallet = await createETHWallet(currentToken);
      }
      setCurrentAccount(ethWallet.address);
      const chainId = await getChainId();
      setChainId(chainId);
    } catch (error) {
      console.log(new Error(error));
    }
  };
  return (
    <PurchaseNFTContext.Provider
      value={{
        projectDetail,
        setProjectDetail,
        amount,
        setAmount,
        walletType,
        setWalletType,
        // metamask
        getTokenCost,
        tokenCost,
        chainId,
        loader,
        currentAccount,
        askContractToMintNft,
        connectWallet,
        transaction,
        setTransaction,
        connectFeanorWallet,
        mintUsingFeanorConn,
      }}
    >
      {children}
    </PurchaseNFTContext.Provider>
  );
};
