import { useState, useEffect } from "react";
import { fetchCardWallet } from "services/card.service";
import swal from "sweetalert";

const useGetCardWallet = () => {
  const [cardWallet, setCardWallet] = useState({});
  const [fetching, setFetching] = useState(false);

  const getCardWallet = async () => {
    try {
      setFetching(true);
      const { cardWallet } = await fetchCardWallet();
      setCardWallet(cardWallet);
    } catch (error) {
      console.log(error);
    }finally{
      setFetching(false);
    }
  };
  useEffect(() => {
    getCardWallet();
  }, []);

  return { cardWallet, fetching, getCardWallet };
};

export default useGetCardWallet;
