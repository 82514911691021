import React, { useState } from "react";
import { LoginInput } from "styles/Styled";
import ShowIcon from "@material-ui/icons/RemoveRedEye";
import HideIcon from "@material-ui/icons/VisibilityOff";
import {
  ClickAwayListener,
  Fade,
  Grow,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Button,
} from "@material-ui/core";
import { InputIcon } from "./styled";
export default function InputObscurable({
  name,
  placeholder,
  style,
  value,
  onChange,
}) {
  const [obscureValue, setObscureValue] = useState(true);

  const handleToggle = () => {
    setObscureValue(!obscureValue);
  };
  return (
    <div style={{ position: "relative" }}>
      <LoginInput
        name={name}
        type={obscureValue ? "password" : "text"}
        placeholder={placeholder}
        style={style}
        value={value}
        onChange={onChange}
      />

      {obscureValue ? (
        <InputIcon>
          <IconButton size="small" onClick={handleToggle}>
            <ShowIcon />
          </IconButton>
        </InputIcon>
      ) : (
        <InputIcon>
          <IconButton size="small" onClick={handleToggle}>
            <HideIcon />
          </IconButton>
        </InputIcon>
      )}
    </div>
  );
}
