import React, { useState } from "react";
import {
  BackgroundImg,
  ButtonsContainer,
  Card as CardStyled,
  CardInner,
  CardNumber,
  ChipImage,
  Front,
  Footer,
  Header,
  MastercardImage,
  ShowDataBtn,
  Back,
  Title,
  InfoRow,
  InfoCol,
  DataSubtitle,
  DataValue,
  CardsContainer,
  Overlay,
  Status,
  Body,
} from "./styled";
import patternImg from "media/credit-card/pattern.png";
import visaImg from "media/credit-card/visa.png";
import mastercardImg from "media/credit-card/mastercard.png";
import chipImg from "media/credit-card/chip.png";
import mapImg from "media/credit-card/map.png";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  ClickAwayListener,
  Fade,
  Grow,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Button,
  Icon,
  withStyles,
} from "@material-ui/core";
import ShowIcon from "@material-ui/icons/RemoveRedEye";
import HideIcon from "@material-ui/icons/VisibilityOff";
import CheckIcon from "@material-ui/icons/Check";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  blockCard,
  changePIN,
  confirmReception,
  fetchCardData,
  unblockCard,
} from "services/card.service";
import FormRequestOTP from "components/FormRequestOTP";
import useModal from "hooks/useModal";
import FormRequestPassword from "components/FormRequestPassword";
import FormValidateCardOnHand from "components/FormValidateCardOnHand";
import swal from "sweetalert";
import { LoginButton } from "styles/Styled";
import FormNewPIN from "components/FormNewPIN/FormNewPIN";
import { useMUIStyles } from "styles/muiStyes";
import { FormChangePIN } from "components/FornChangePIN/FormChangePIN";
import { operations } from "utils/constants";
import { FormRevealData } from "components/FormRevealData/FormRevealData";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "black",
    textTransform: "none",
    fontSize: "12px",
    zIndex: 2,
  },
  superSmallButton: {
    color: "black",
    textTransform: "none",
    fontSize: "1rem",
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#daae58",
    "&:hover": {
      backgroundColor: "#daae58",
    },
  },
}))(Button);

const CardHeader = ({ title, isActive, status }) => {
  return (
    <Header>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <ChipImage src={chipImg} /> */}
        <Title>
          {title}

          {status === "Active" && <Status>activa</Status>}
          {status === "PendingAck" && (
            <Status>pendiente a confirmar recepción</Status>
          )}
          {status === "Cancel" && <Status>Anulada</Status>}
          {status === "Temporarilyblocked" && <Status>Bloqueada</Status>}
        </Title>
      </div>
      <MastercardImage src={mastercardImg} />
    </Header>
  );
};

// const FormRevealData = ({
//   otpEnabled,
//   handleFlipCard,
//   closeModal,
//   cardId,
//   setPrivateData,
// }) => {
//   const [errorMsg, setErrorMsg] = useState("");
//   const [otpToken, setOtpToken] = useState("");
//   const [password, setPassword] = useState("");
//   const [loading, setLoading] = useState(false);

//   const onSubmitCredentials = async () => {
//     try {
//       setLoading(true);
//       setErrorMsg("");
//       const { success, pan, cvv, dueDate, name, pin } = await fetchCardData({
//         cardId,
//         otpToken,
//         password,
//       });
//       if (success) {
//         setPrivateData({ pan, cvv, dueDate, name, pin });
//         closeModal();
//         await new Promise((resolve) => setTimeout(resolve, 400));
//         handleFlipCard();
//         setErrorMsg("");
//       }
//     } catch (error) {
//       await new Promise((resolve) => setTimeout(resolve, 700));
//       console.log(error);
//       if (error.response) {
//         console.log(error.response);

//         if (error.response.data.code === "invalid-otp") {
//           setErrorMsg("El código ingresado es inválido");
//           return;
//         }

//         if (error.response.data.code === "incorrect-password") {
//           setErrorMsg("La contraseña ingresada es inválida");
//           return;
//         }

//         if (!error.response.data.success) {
//           closeModal();
//           swal(
//             "Ocurrío un error",
//             "No se pudo obtener los datos de la tarjeta, por favor intente nuevamente más tarde",
//             "error"
//           );
//           return;
//         }
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       {otpEnabled ? (
//         <FormRequestOTP
//           errorMsg={errorMsg}
//           otpToken={otpToken}
//           setOtpToken={setOtpToken}
//           buttonTitle="Enviar código"
//           buttonTitleLoading="Validando..."
//           loading={loading}
//           onSubmit={onSubmitCredentials}
//         />
//       ) : (
//         <FormRequestPassword
//           errorMsg={errorMsg}
//           password={password}
//           setPassword={setPassword}
//           buttonTitle="Validar contraseña"
//           buttonTitleLoading="Validando..."
//           loading={loading}
//           onSubmit={onSubmitCredentials}
//         />
//       )}
//     </>
//   );
// };

const FormBlockCard = ({ otpEnabled, closeModal, cardId, refreshCards }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmitCredentials = async () => {
    try {
      setLoading(true);
      setErrorMsg("");
      const { success } = await blockCard({
        cardId,
        otpToken,
        password,
      });
      if (success) {
        refreshCards();
        closeModal();
        setErrorMsg("");
      }
    } catch (error) {
      await new Promise((resolve) => setTimeout(resolve, 700));
      console.log(error);
      if (error.response) {
        console.log(error.response);

        if (error.response.data.code === "invalid-otp") {
          setErrorMsg("El código ingresado es inválido");
          return;
        }

        if (error.response.data.code === "incorrect-password") {
          setErrorMsg("La contraseña ingresada es inválida");
          return;
        }

        if (!error.response.data.success) {
          closeModal();
          swal(
            "Ocurrío un error",
            "No se pudo bloquear la tarjeta, por favor intente nuevamente más tarde",
            "error"
          );
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {otpEnabled ? (
        <FormRequestOTP
          errorMsg={errorMsg}
          otpToken={otpToken}
          setOtpToken={setOtpToken}
          buttonTitle="Enviar código"
          buttonTitleLoading="Validando..."
          loading={loading}
          onSubmit={onSubmitCredentials}
        />
      ) : (
        <FormRequestPassword
          errorMsg={errorMsg}
          password={password}
          setPassword={setPassword}
          buttonTitle="Validar contraseña"
          buttonTitleLoading="Validando..."
          loading={loading}
          onSubmit={onSubmitCredentials}
        />
      )}
    </>
  );
};

const FormUnblockCard = ({ otpEnabled, closeModal, cardId, refreshCards }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmitCredentials = async () => {
    try {
      setLoading(true);
      setErrorMsg("");
      const { success } = await unblockCard({
        cardId,
        otpToken,
        password,
      });
      if (success) {
        refreshCards();
        closeModal();
        setErrorMsg("");
      }
    } catch (error) {
      await new Promise((resolve) => setTimeout(resolve, 700));
      console.log(error);
      if (error.response) {
        console.log(error.response);

        if (error.response.data.code === "invalid-otp") {
          setErrorMsg("El código ingresado es inválido");
          return;
        }

        if (error.response.data.code === "incorrect-password") {
          setErrorMsg("La contraseña ingresada es inválida");
          return;
        }

        if (!error.response.data.success) {
          closeModal();
          swal(
            "Ocurrío un error",
            "No se pudo desbloquear la tarjeta, por favor intente nuevamente más tarde",
            "error"
          );
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {otpEnabled ? (
        <FormRequestOTP
          errorMsg={errorMsg}
          otpToken={otpToken}
          setOtpToken={setOtpToken}
          buttonTitle="Enviar código"
          buttonTitleLoading="Validando..."
          loading={loading}
          onSubmit={onSubmitCredentials}
        />
      ) : (
        <FormRequestPassword
          errorMsg={errorMsg}
          password={password}
          setPassword={setPassword}
          buttonTitle="Validar contraseña"
          buttonTitleLoading="Validando..."
          loading={loading}
          onSubmit={onSubmitCredentials}
        />
      )}
    </>
  );
};

const FormConfirmReception = ({
  otpEnabled,
  closeModal,
  cardId,
  refreshCards,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [cardOnHandErrorMsg, setCardOnHandErrorMsg] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [password, setPassword] = useState("");
  const [last6digits, setLast6digits] = useState("");
  const [loading, setLoading] = useState(false);
  const STEPS = {
    VALIDATE_CARD_ON_HAND: "VALIDATE_CARD_ON_HAND",
    REQUEST_CREDENTIALS: "REQUEST_CREDENTIALS",
  };
  const [step, setStep] = useState(STEPS.VALIDATE_CARD_ON_HAND);

  const onSubmitCredentials = async () => {
    try {
      setLoading(true);
      setErrorMsg("");
      const { success } = await confirmReception({
        last6digits,
        cardId,
        password,
        otpToken,
      });
      if (success) {
        closeModal();
        setErrorMsg("");
        setCardOnHandErrorMsg("");
        refreshCards();
        swal("¡Éxito!", "La tarjeta fue confirmada", "success", {
          timer: 3000,
          buttons: false,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response);

        if (error.response.data.code === "card-on-hand-error") {
          setCardOnHandErrorMsg(error.response.data.message);
          setStep(STEPS.VALIDATE_CARD_ON_HAND);
          return;
        }

        if (error.response.data.code === "missing-fields") {
          setCardOnHandErrorMsg("El campo ultimos 6 digitos es requerido");
          setStep(STEPS.VALIDATE_CARD_ON_HAND);
          return;
        }

        if (error.response.data.code === "incorrect-password") {
          setErrorMsg("La contraseña ingresada es inválida");
          setStep(STEPS.REQUEST_CREDENTIALS);
          return;
        }

        if (error.response.data.code === "invalid-otp") {
          setErrorMsg("El código ingresado es inválido");
          setStep(STEPS.REQUEST_CREDENTIALS);
          return;
        }

        closeModal();
        swal(
          "Ocurrió un error",
          "No se pudo confirmar la recepcion de su tarjeta, por favor intente nuevamente más tarde",
          "error"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {step === STEPS.VALIDATE_CARD_ON_HAND && (
        <FormValidateCardOnHand
          errorMsg={cardOnHandErrorMsg}
          last6digits={last6digits}
          setLast6digits={setLast6digits}
          buttonTitle="Continuar"
          buttonTitleLoading="Validando..."
          loading={loading}
          onSubmit={() => {
            setCardOnHandErrorMsg("");
            setStep(STEPS.REQUEST_CREDENTIALS);
          }}
        />
      )}
      {step === STEPS.REQUEST_CREDENTIALS && (
        <div>
          {otpEnabled ? (
            <FormRequestOTP
              errorMsg={errorMsg}
              otpToken={otpToken}
              setOtpToken={setOtpToken}
              buttonTitle="Enviar código"
              buttonTitleLoading="Validando..."
              loading={loading}
              onSubmit={onSubmitCredentials}
            />
          ) : (
            <FormRequestPassword
              errorMsg={errorMsg}
              password={password}
              setPassword={setPassword}
              buttonTitle="Validar contraseña"
              buttonTitleLoading="Validando..."
              loading={loading}
              onSubmit={onSubmitCredentials}
            />
          )}
          <LoginButton
            style={{ background: "#b9b6af" }}
            type="button"
            onClick={() => setStep(STEPS.VALIDATE_CARD_ON_HAND)}
          >
            Atras
          </LoginButton>
        </div>
      )}
    </>
  );
};

export default function CardRegular({
  cardId,
  cardNumber,
  otpEnabled,
  isPhysicalCard,
  isActive,
  status,
  refreshCards,
}) {
  const classesMUI = useMUIStyles();
  const [flipped, setFlipped] = useState(false);
  const anchorRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [privateData, setPrivateData] = useState({
    pan: "",
    pin: "",
    cvv: "",
    dueDate: "",
    name: "",
  });
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const [Modal, isOpenModal, openModal, onCloseModal] = useModal();
  const [operationSelected, setOperationSelected] = useState("");

  const handleFlipCard = () => {
    setFlipped(!flipped);
  };

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <CardStyled>
      <Modal
        open={isOpenModal}
        onClose={onCloseModal}
        center
        closeOnOverlayClick={false}
        variant="small"
      >
        {operationSelected === operations.REVEAL_DATA && (
          <FormRevealData
            cardId={cardId}
            setPrivateData={setPrivateData}
            otpEnabled={otpEnabled}
            handleFlipCard={handleFlipCard}
            closeModal={onCloseModal}
            fetchCardDataRequest={fetchCardData}
          />
        )}
        {operationSelected === operations.CONFIRM_RECETION && (
          <FormConfirmReception
            cardId={cardId}
            otpEnabled={otpEnabled}
            refreshCards={refreshCards}
            closeModal={onCloseModal}
          />
        )}

        {operationSelected === operations.BLOCK && (
          <FormBlockCard
            cardId={cardId}
            otpEnabled={otpEnabled}
            refreshCards={refreshCards}
            closeModal={onCloseModal}
          />
        )}

        {operationSelected === operations.UNBLOCK && (
          <FormUnblockCard
            cardId={cardId}
            otpEnabled={otpEnabled}
            refreshCards={refreshCards}
            closeModal={onCloseModal}
          />
        )}

        {operationSelected === operations.CHANGE_PIN && (
          <FormChangePIN
            cardId={cardId}
            otpEnabled={otpEnabled}
            refreshCards={refreshCards}
            changePINRequest={changePIN}
            closeModal={onCloseModal}
          />
        )}
      </Modal>

      <CardInner flipped={flipped}>
        <Front>
          <Overlay isActive={isActive} />

          {/* <BackgroundImg src={mapImg} /> */}
          {/* <CardHeader
            status={status}
            isActive={isActive}
            title={isPhysicalCard ? "Tarjeta física" : "Tarjeta virtual"}
          /> */}
          <Body>
            {status === "Active" && (
              <Button
                // variant="contained"
                className={classes.button}
                onClick={() => {
                  setOperationSelected(operations.REVEAL_DATA);
                  openModal();
                }}
              >
                <ShowIcon
                  fontSize="small"
                  className={classesMUI.smallBtnIcon}
                />
                Mostrar datos
              </Button>
            )}
            {status === "PendingAck" && (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setOperationSelected(operations.CONFIRM_RECETION);
                  openModal();
                }}
              >
                <CheckIcon fontSize="small" className={classes.icon} />{" "}
                Confirmar recepción
              </ColorButton>
            )}
            <CardNumber style={{ marginTop: "10px" }}>{cardNumber}</CardNumber>
          </Body>

          <Footer>
            {status === "Cancel" && <ButtonsContainer />}
            {status === "PendingAck" && (
              <ButtonsContainer style={{ justifyContent: "flex-end" }}>
                <div>
                  <Title>
                    {isPhysicalCard ? "Física" : "Virtual"}
                    {/* <Status>activa</Status> */}
                  </Title>
                  <MastercardImage src={mastercardImg} />
                </div>
              </ButtonsContainer>
            )}
            {status === "Active" && (
              <ButtonsContainer>
                <div>
                  <Button
                    className={classesMUI.smallBtn}
                    variant="outlined"
                    onClick={() => {
                      handleClose();
                      setOperationSelected(operations.BLOCK);
                      openModal();
                    }}
                  >
                    Bloquear
                  </Button>
                  {status === "Active" && isPhysicalCard && (
                    <Button
                      className={classesMUI.smallBtn}
                      variant="outlined"
                      onClick={() => {
                        handleClose();
                        setOperationSelected(operations.CHANGE_PIN);
                        openModal();
                      }}
                    >
                      Cambiar PIN
                    </Button>
                  )}

                  {/* <IconButton
                    size="small"
                    className={classes.button}
                    ref={anchorRef}
                    aria-controls="menu-list-grow"
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOperationSelected(operations.BLOCK);
                        openModal();
                      }}
                    >
                      Bloquear
                    </MenuItem>

                    {status === "Active" && isPhysicalCard && (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOperationSelected(operations.CHANGE_PIN);
                          openModal();
                        }}
                      >
                        Cambiar PIN
                      </MenuItem>
                    )}
                  </Menu> */}
                </div>
                <div>
                  <Title>
                    {isPhysicalCard ? "Física" : "Virtual"}
                    {/* <Status>activa</Status> */}
                  </Title>
                  <MastercardImage src={mastercardImg} />
                </div>
              </ButtonsContainer>
            )}
            {status === "Temporarilyblocked" && (
              <ButtonsContainer>
                <div>
                  <Button
                    className={classesMUI.smallBtn}
                    variant="outlined"
                    onClick={() => {
                      handleClose();
                      setOperationSelected(operations.UNBLOCK);
                      openModal();
                    }}
                  >
                    Desbloquear
                  </Button>
                  {/* <IconButton
                    size="small"
                    className={classes.button}
                    ref={anchorRef}
                    aria-controls="menu-list-grow"
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setOperationSelected(operations.UNBLOCK);
                        openModal();
                      }}
                    >
                      Desbloquear
                    </MenuItem>
                  </Menu> */}
                </div>
                <div>
                  <Title>{isPhysicalCard ? "Física" : "Virtual"}</Title>
                  <MastercardImage src={mastercardImg} />
                </div>
              </ButtonsContainer>
            )}
          </Footer>
        </Front>

        <Back>
          <CardHeader />
          <Footer>
            <div
              style={{
                position: "absolute",
                top: "190px",
                width: "90%",
                left: "51%",
                color: "black",
                transform: "translateX(-50%)",
              }}
            >
              <InfoRow style={{ justifyContent: "start" }}>
                <InfoCol>
                  <DataSubtitle>CVV</DataSubtitle>
                  <DataValue>{privateData.cvv}</DataValue>
                </InfoCol>
                <InfoCol style={{ marginLeft: "30px" }}>
                  <DataSubtitle>PIN</DataSubtitle>
                  <DataValue>{privateData.pin}</DataValue>
                </InfoCol>
              </InfoRow>
              <InfoRow>
                <InfoCol>
                  <DataSubtitle>Nombre</DataSubtitle>
                  <DataValue>{privateData.name}</DataValue>
                </InfoCol>
                <InfoCol>
                  <DataSubtitle>Expira</DataSubtitle>
                  <DataValue>{privateData.dueDate}</DataValue>
                </InfoCol>
              </InfoRow>
              <InfoRow>
                <CardNumber style={{ position: "inherit" }}>
                  {privateData.pan}
                </CardNumber>
              </InfoRow>
            </div>
            <ButtonsContainer style={{ justifyContent: "flex-end" }}>
              <Button className={classes.button} onClick={handleFlipCard}>
                <HideIcon fontSize="small" className={classes.icon} /> Ocultar
                datos
              </Button>
            </ButtonsContainer>
          </Footer>
        </Back>
      </CardInner>
    </CardStyled>
  );
}
