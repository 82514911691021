import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProjectDetail from "./ProjectDetail";
import Projects from "./Projects";

const NftMarket = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="*/proyectos" component={Projects} />
        <Route exact path="*/proyectos/:id" component={ProjectDetail} />
        <Redirect from="/" to="/nft-market/proyectos" />
      </Switch>
    </BrowserRouter>
  );
};

export default NftMarket;
