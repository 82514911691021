import React from "react";
import { ethExplorer } from "feanorapi";
import ReactTimeAgo from "react-time-ago";
import {
  BtnSeeMore,
  ColState,
  ColStatus,
  ColText,
  ListColumnDiv,
  ListRowDiv,
  TextBox,
  TitleBox,
  WalletBoxLeft,
} from "styles/Styled";
import { typeOperation, typeState } from "utils/translator.utils";

const LastTransactions = ({ transactions }) => {
  if (transactions.length <= 0) {
    return (
      <TextBox>
        No existen operaciones por el momento :(. Comienza a comprar o
        depositar.
      </TextBox>
    );
  }

  return (
    <WalletBoxLeft>
      <TitleBox>Últimas operaciones</TitleBox>

      {transactions.slice(0,8).map((transaccion) => {
        //const date = new Date(transactionObj.createdAt);

        let amount = transaccion.amount.toFixed(2);
        if (transaccion.category == "convert") {
          amount = transaccion.ammounts[1].toFixed(2);
        }

        return (
          <ListRowDiv key={transaccion._id} data-testid='deposit-row'>
            <ListColumnDiv>
              <ColText>{`${amount} USDT`} </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColText>
                <ReactTimeAgo date={transaccion.createdAt} locale="es" />
              </ColText>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColStatus data-testid="type">{`${typeOperation(transaccion.category)}`}</ColStatus>
            </ListColumnDiv>

            <ListColumnDiv>
              {transaccion.tx == "external" ? (
                <ColState>
                  <a
                    target="_blank"
                    href={`${ethExplorer}/tx/${transaccion.tx}`}
                    title="Visualizar la transacción en la Blockchain Explorer"
                  >
                    {typeState(transaccion.state)}
                  </a>
                </ColState>
              ) : (
                <ColState title="Este es el estado en el que se encuentra la operación" data-testid="state">
                  {typeState(transaccion.state)}
                </ColState>
              )}
            </ListColumnDiv>
          </ListRowDiv>
        );
      })}

      {transactions.length >= 4 && (
        <BtnSeeMore to={`/app/profile/operations`}>Ver más</BtnSeeMore>
      )}
    </WalletBoxLeft>
  );
};

export default LastTransactions;
