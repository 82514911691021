/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router";
import { AuthContext } from "Auth";
import "./HistorialTransacciones.scss";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";
import * as styledComponents from "./styles";
import * as styledComponentsHV from "./HorizontalView/styles";
import HorizontalView from "./HorizontalView/index.js";
import Navigator from "./Navigator/index.js";
// import useGetTransactions from "hooks/useGetTransactions";
// import useTablePagination from "hooks/useTablePagination";
// import { fetchTransactions } from "services/transaction.service";
import { typeCategory, typeState } from "utils/translator.utils";
import VerticalView from "./VerticalView";

// const ROWS_PER_PAGE = 5;
// const CURRENT_PAGE = 0;
const collectionsPerPage = 5;

// const monthsArray = [
//   "ENE",
//   "FEB",
//   "MAR",
//   "ABR",
//   "MAY",
//   "JUN",
//   "JUL",
//   "AGO",
//   "SET",
//   "OCT",
//   "NOV",
//   "DEC",
// ];

const formatTransactionsForTable = (transactions) => {
  return transactions.map((transaction) => {
    const date = new Date(transaction.createdAt);
    let transactionType = typeCategory(transaction.category);
    let state = typeState(transaction.state);
    let currencyOperation = transaction.currency;
    let amount = transaction.amount;
    let totalExecuted =
      transaction.amount + transaction.feanorFee + " " + currencyOperation;
    let rate = "";
    let fee = transaction.feanorFee + " " + currencyOperation;
    let amountFIAT = "";
    let amountCurrency = transaction.amount + " " + currencyOperation;
    if (
      transaction.category === "convert" ||
      transaction.category === "CONVERT"
    ) {
      transactionType = `Conversión ${transaction.from}-${transaction.to}`;
      amountCurrency = `${transaction.ammounts[0]} ${transaction.currencies[0]} -
      ${transaction.ammounts[1]} ${transaction.currencies[1]}`;

      fee = !transaction.commission
        ? 0.0 + " USD"
        : transaction.commission?.usd + " USD";

      totalExecuted =
        transaction.currencies[0] === "BTC" ||
        transaction.currencies[0] === "USDT"
          ? `${transaction.totalAmounts[0]} ${transaction.currencies[0]} - ${transaction.totalAmounts[1]} ${transaction.currencies[1]}`
          : `${transaction.totalAmounts[1]} ${transaction.currencies[0]} - ${transaction.totalAmounts[0]} ${transaction.currencies[1]}`;

      // Tasa
      if (transaction.rates) {
        // Case 1 FIAT - CRYPTO o CRYPTO - FIAT: Muestra la tasa en USD
        /**
         * VENTA
         */
        // BTC - USD
        if (transaction.from == "BTC" && transaction.to == "USD") {
          rate = transaction.rates.BTCUSDSell;
        }
        // USDT - USD
        if (transaction.from == "USDT" && transaction.to == "USD") {
          rate = transaction.rates.USDTUSDSell;
        }

        /**
         * COMPRA
         */
        // USD - BTC
        if (transaction.from == "USD" && transaction.to == "BTC") {
          rate = transaction.rates.BTCUSDBuy;
        }
        // USD - USDT
        if (transaction.from == "USD" && transaction.to == "USDT") {
          rate = transaction.rates.USDTUSDBuy;
        }

        // Encuentra el signo de la moneda FIAT (USD)
        if (transaction.from == "BTC" || transaction.from == "USDT") {
          rate += " " + transaction.currencies[1];
        } else {
          rate += " " + transaction.currencies[0];
        }

        // Case 2 BTC - USDT: Muestra la tasa en USDT
        if (transaction.from == "BTC" && transaction.to == "USDT") {
          rate = transaction.rates.BTCUSDTSell ?? transaction.rates.BTCUSDTBuy;
          rate += " " + transaction.currencies[1];
        }
      }
    }

    if (transaction.category === "cardDeposit") {
      const currency = ` ${transaction.currencies[1] || transaction.currency}`
      amountCurrency =
        transaction.amount + currency;
      currencyOperation = currency;
      const feanorFee = transaction.feanorFee ? transaction.feanorFee : 0.0;
      const referrerFee = transaction.referrerFee
        ? transaction.referrerFee
        : 0.0;
      fee =
        feanorFee + referrerFee > 0
          ? (feanorFee + referrerFee).toFixed(3) + currency
          : 0.0 + currency;

      totalExecuted =
        (transaction.amount - feanorFee - referrerFee).toFixed(3) + currency;
    }

    if (transaction.category === "send") {
      fee = transaction.feanorFee + " " + currencyOperation;
      totalExecuted =
        transaction.amount + transaction.feanorFee + " " + currencyOperation;
    }
    return {
      ...transaction,
      date,
      transactionType,
      currencyOperation,
      amount,
      totalExecuted,
      rate,
      fee,
      amountFIAT,
      state,
      amountCurrency,
    };
  });
};
const HistorialTransacciones = ({ history }) => {
  // const styles = {
  //   marginRight: "2px",
  // };
  // const [urlParameters, setUrlParameters] = useState(`limit=${ROWS_PER_PAGE}`);
  // const {
  //   transactions,
  //   getTransactions: refreshTransactions,
  // } = useGetTransactions(urlParameters);

  // const { page, beforeEnabled, before, next, nextEnabled } = useTablePagination(
  //   refreshTransactions
  // );
  const [transacciones, setTransacciones] = useState([]);
  const [beforeEnabled, setActivateBefore] = useState(false);
  const [nextEnabled, setActivateNext] = useState(false);
  const [page, setPage] = useState(0);

  let { currentToken } = useContext(AuthContext);

  const getTransactions = () => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/transaction?skip=${collectionsPerPage *
      page}&limit=${collectionsPerPage}`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-available" };
        }
      })
      .then((responseJson) => {
        if (responseJson.length !== 0) {
          checkBefore();
          checkNext();
        }
        const formattedTransactions = formatTransactionsForTable(responseJson);
        setTransacciones(formattedTransactions);
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  const deactivateBefore = () => {
    setActivateBefore(false);
  };

  const activateBefore = () => {
    setActivateBefore(true);
  };

  const deactivateNext = () => {
    setActivateNext(false);
  };

  const activateNext = () => {
    setActivateNext(true);
  };

  const checkBefore = (val) => {
    if (page == 0) {
      deactivateBefore();
    } else {
      activateBefore();
    }
  };

  const checkNext = (val) => {
    let data = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
    };
    let endpoint = `/client/transaction?skip=${collectionsPerPage *
      (page + 1)}&limit=${collectionsPerPage}`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-available" };
        }
      })
      .then((responseJson) => {
        if (responseJson.length == 0) {
          deactivateNext();
        } else {
          activateNext();
        }
      })
      .catch((error) => {
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  const before = () => {
    setPage(page - 1);
  };

  const next = async () => {
    setPage(page + 1);
  };

  useEffect(() => {
    getTransactions();
  }, [page]);

  // useEffect(() => {
  //   fetchTransactions()

  // }, [setUrlParameters])

  // useEffect(() => {
  //   if (transactions.length !== 0) {
  //     // checkBefore();
  //     // checkNext();
  //     setUrlParameters(
  //       `skip=${ROWS_PER_PAGE * (page + 1)}&limit=${ROWS_PER_PAGE}`
  //     );
  //   }
  // }, [transactions]);

  return (
    <div id="container-historial-transacciones">
      <HorizontalView transacciones={transacciones} history={history} />
      <VerticalView transacciones={transacciones} history={history} />

      <Navigator {...{ beforeEnabled, before, page, next, nextEnabled }} />
    </div>
  );
};

export default withRouter(HistorialTransacciones);
