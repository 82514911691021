import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AuthContext } from "./Auth";
import swal from "sweetalert";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  // Para acceso desde admin
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const {
    currentToken,
    expiration,
    clearAuthentication,
    userType,
    updateToken,
    updateExpiration,
    updateUserType,
  } = useContext(AuthContext);

  useEffect(() => {
    // Para acceso desde admin
    const fromAdminToken = queryParams.get("t");
    const fromAdminExpiration = queryParams.get("e");
    const fromAdminUserType = queryParams.get("ut");
    let isAuthenticated =
      currentToken && expiration && parseInt(expiration) - Date.now() > 1000;
    if (fromAdminToken && fromAdminExpiration) {
      updateToken(fromAdminToken);
      updateExpiration(fromAdminExpiration);
      updateUserType(fromAdminUserType);
      isAuthenticated =
        fromAdminToken &&
        fromAdminExpiration &&
        parseInt(fromAdminExpiration) - Date.now() > 1000;
    }

    setIsAuthenticated(isAuthenticated);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) return;

    if (!currentToken) {
      swal({
        title: "Inicio de Sesión Requerido",
        text: "Porfavor, iniciar sesión para acceder a esta sección",
        button: false,
      });
    } else if (parseInt(expiration) - Date.now() < 1000 || !expiration) {
      swal({
        title: "Tiempo de Sesión Expirado",
        text: "El tiempo de su sesión ha expirado. Porfavor vuelve a ingresar.",
        button: false,
      });
    }
  }, [isAuthenticated, loading]);

  // if (!currentToken) {
  //   swal({
  //     title: "Inicio de Sesión Requerido",
  //     text: "Porfavor, iniciar sesión para acceder a esta sección",
  //     button: false,
  //   });
  // } else if (parseInt(expiration) - Date.now() < 1000 || !expiration) {
  //   swal({
  //     title: "Tiempo de Sesión Expirado",
  //     text: "El tiempo de su sesión ha expirado. Porfavor vuelve a ingresar.",
  //     button: false,
  //   });
  // }
  // const isAuthenticated =
  //   currentToken && expiration && parseInt(expiration) - Date.now() > 1000;
  // !isAuthenticated && clearAuthentication();

  const handleLoginRedirect = (path, text) => {
    console.log("redireccionando a login");
    console.log(text);
    clearAuthentication();
    if (path === "business") {
      return <Redirect to="/auth/login_business" />;
    }
    if (path === "app") {
      return <Redirect to="/auth/login" />;
    }
  };
  return (
    <div>
      {loading ? (
        <div>cargando</div>
      ) : (
        <Route
          {...rest}
          render={(routeProps) => {
            if (loading) return;

            const pathSplited = routeProps.match.url.split("/");
            return isAuthenticated ? (
              (userType === "business" && pathSplited[1] == "business") ||
              (userType === "person" && pathSplited[1] == "app") ? (
                <RouteComponent {...routeProps} />
              ) : (
                handleLoginRedirect(pathSplited[1], "is authenticated")
              )
            ) : (
              handleLoginRedirect(pathSplited[1], "not authenticated")
            );
          }}
        />
      )}
    </div>
  );
};

export default PrivateRoute;
