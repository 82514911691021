import { useEffect, useState } from "react";
import { getBusinessInfo } from "services/bussiness/user.service";
import swal from "sweetalert";

const useGetBusinessInfo = () => {
  const [businessInfo, setBusinessInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchBusiness = async () => {
    try {
      setLoading(true);
      // await new Promise((res, rej) => {
      //   setTimeout(function() {
      //     res();
      //   }, 5000);
      // });
      const business = await getBusinessInfo();
      console.log("business info fetched")
      if (!business) throw new Error("Non business");
      setBusinessInfo(business);
    } catch (error) {
      console.log(error);
      swal({
        text: "Ha habido un error en nuestro sistema",
        button: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBusiness();
  }, []);

  return { businessInfo, loading, fetchBusiness };
};

export default useGetBusinessInfo;
