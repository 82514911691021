import React from "react";
import { Header2, Paragraph } from "styles/common/index.js";
import SaldoUSD from "components/SaldoUSD";

const MoneyUSD = () => {
  return (
    <React.Fragment>
      <Header2>Billetera Dólares ($)</Header2>
      <Paragraph className="boxCenter">
      Esta es tu billetera digital de dólares en Fëanor. 
      </Paragraph>
      <SaldoUSD />
    </React.Fragment>
  );
};

export default MoneyUSD;
