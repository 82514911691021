import styled from "styled-components";
import colors from 'styles/common/colors';

export const TableDesktop = styled.table`
  width: 100%;
  border-collapse: collapse;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Header = styled.thead`
  width: 100%;
  height: 60px;
  font-family: Montserrat;
  font-weight: 600;
  opacity: 0.7;
  border-top: 1px solid rgba(44,44,44,0.05);
  line-height: 20px;
  font-size: 14px;
  color: colors.dark_blue;
  margin: 1px;
  text-align: center;
`;

export const HeaderRow = styled.tr`

`;

export const HeaderName = styled.td`
  text-align: left;
  padding: 5px 15px;
`;

export const HeaderNameState = styled.td`

`;

export const DataRow = styled.tr`
  &:hover {
    background-color: rgba(44,44,44,0.05);
  }
`;

export const Data = styled.td`
  font-family: Montserrat;
  line-height: 20px;
  font-size: 14px;
  padding: 5px 15px;
`;

export const DataState = styled.td`
  font-family: Montserrat;
  line-height: 20px;
  font-size: 14px;
  padding: 5px 0;
`;

export const DataBold = styled.td`
  font-family: Montserrat;
  line-height: 20px;
  font-size: 14px;
  padding: 0 15px;
  font-weight: 600;
`;

export const DataDelivered = styled.p`
  background-color: rgba(32, 201, 151, 0.2);
  color: rgba(32, 201, 151, 1);
  background-opacity: 0.1;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px 5px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
`;

export const DataCanceled = styled.p`
  background-color: rgba(245, 66, 19, 0.2);
  color: rgba(245, 66, 19, 1);
  background-opacity: 0.1;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px 5px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
`;
export const DataRequested = styled.p`
  background-color: rgba(255, 200, 92, 0.2);
  color: rgba(255, 200, 92, 1);
  background-opacity: 0.1;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px 5px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
`;
export const DataCreated = styled.p`
  background-color: rgba(255, 200, 92, 0.2);
  color: rgba(255, 200, 92, 1);
  background-opacity: 0.1;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px 5px;
  font-weight: 700;
  text-align: center;
  margin: 0px;
`;

export const IconEdit = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 5px;
`;
