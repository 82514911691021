import React from "react";
import { Header2, Paragraph } from "styles/common/index.js";
import HistorialTransacciones from "components/HistorialTransacciones/index.js";

const Operations = () => {
  return (
    <React.Fragment>
      <Header2>Historial de Transacciones</Header2>
      <Paragraph>
        Aquí puedes ver tu record de órdenes con Fëanor.
      </Paragraph>
      <HistorialTransacciones />
    </React.Fragment>
  );
};

export default Operations;
