import http from "http-common";
import { getToken } from "services/common";

export const updatePassword = async (currentPassword, newPassword) => {
  const response = await http.post(
    "/business/security/updatepassword",
    {
      current_password: currentPassword,
      new_password: newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};
