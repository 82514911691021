import styled from "styled-components";
import colors from "styles/common/colors";
export const NewOperationWrapper = styled.div`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 5vh;
  background: linear-gradient(
    180deg,
    rgba(236, 243, 248, 1) 0%,
    rgba(236, 243, 248, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-bottom: 100px;
`;

export const GoBackBtnContainer = styled.div`
  color: ${colors.feanor_dark_bg};
  width: 100%;
`;
export const GoBackBtnLink = styled.a`
  color: ${colors.feanor_dark_bg};
  opacity: 0.5;
  transition: 0.3s all;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
`;
export const GoBackBtnIcon = styled.a`
  margin-right: 5px;
  font-size: 14px;
`;

export default {
  NewOperationWrapper,
  GoBackBtnContainer,
  GoBackBtnLink,
  GoBackBtnIcon,
};
