import React from "react";
import { ListLinksItem } from "styles/Styled";

export default function LinkAyuda() {
  return (
    <ListLinksItem>
      <a target="_blank" href="https://wa.me/50765743764?">
        Ayuda
      </a>
    </ListLinksItem>
  );
}
