import React, { useState } from "react";
import Modal from "react-responsive-modal";

const ModalFeanor = ({
  children,
  open,
  onClose,
  classNames,
  closeOnOverlayClick = false,
  variant = "large",
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{ modal: variant }}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      {children}
    </Modal>
  );
};

const useModal = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const openModal = (scroll = true) => {
    if (scroll) {
      // SCROLL TOP PARA QUE EL PUZZLE DEL CAPTCHA SE VEA
      window.scrollTo(0, 0);
    }
    setOpen(true);
  };

  return [ModalFeanor, open, openModal, onClose];
};

export default useModal;
