import React, { useEffect, useState } from "react";
import useSendUSDT from "hooks/useSendUSDT";
import iconSendUSDT from "images/usdt-logo.png";
import { checkIsInternalWallet } from "services/wallet.service";
import {
  BodyModal,
  BtnPrimaryLg,
  Clear,
  DetailAmount,
  DetailsOperation,
  DetailTitle,
  FooterModal,
  ImageTetherModal,
  InputModal,
  LabelModal,
  LabelSpan,
  Margin20,
  SubtitleModal,
  TitleModal,
  LoginButton,
} from "styles/Styled";
import swal from "sweetalert";
import WAValidator from "wallet-address-validator";
import useGetWallets from "hooks/useGetWallets";
import useGetUserInfo from "hooks/useGetUser";
import FormRequestOTP from "components/FormRequestOTP";
import { debounce } from "lodash";
import { useReCaptcha } from "hooks/useReCaptcha";

const feeClients = 0;
const feeNoClients = 10;

const FormSendUSDT = ({
  walletUSDT,
  refreshWallets,
  onCloseModalSend,
  refreshTransactions,
}) => {
  const [sendTo, setSendTo] = useState("");
  const [sendAmount, setSendAmount] = useState("");
  const [feePerOperation, setFeePerOperation] = useState(0.0);
  const {
    loading,
    success,
    setSuccess,
    transferUSDT,
    runValidations,
  } = useSendUSDT();

  /*For OTP validation */
  const { userInfo } = useGetUserInfo();
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const { ReCaptcha, captcha, onChangeCaptcha } = useReCaptcha();

  useEffect(() => {
    if (success) {
      swal({
        text: `¡Se enviaron correctamente ${sendAmount} USDT a la direccion indicada! :D`,
        button: true,
        timer: 7000,
      });
      refreshWallets();
      refreshTransactions();
      onCloseModalSend();
    }

    return () => {
      setSuccess(false);
    };
  }, [success]);

  const onHandleAddressChange = async (e) => {
    let addressInput = e.target.value;
    let fee = feeNoClients;

    setSendTo(addressInput);

    if (
      addressInput != "" &&
      (WAValidator.validate(addressInput, "BTC") ||
        WAValidator.validate(addressInput, "ETH"))
    ) {
      const response = await checkIsInternalWallet(addressInput);
      if (response.client) {
        fee = feeClients;
      }
    }
    setFeePerOperation(fee);
  };

  const maxSendValue = () => {
    setSendAmount((walletUSDT.balance - feePerOperation).toFixed(2));
  };

  const onKeyPressBTC = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };
  const onHandleAmountBTCChange = (e) => {
    const amount = parseFloat(e.target.value);
    const usdtBalance = parseFloat(walletUSDT.balance);

    if (!amount) {
      setSendAmount("");
      return;
    }
    if (amount >= usdtBalance) {
      maxSendValue();
      return;
    }
    // if (amount <= usdtBalance && amount >= 0) {
    setSendAmount(amount);
    // }
    /*} else if (amount > usdtBalance) {
      setSendAmount(usdtBalance);
    }*/

    // if (amount <= 1000) {
    // } else {
    //   setSendAmount(1000);
    // }
  };

  return (
    <BodyModal>
      <TitleModal style={{ marginBottom: 0 }}>Enviar Tethers</TitleModal>
      <SubtitleModal>Tether (USDT) ERC-20 Ethereum</SubtitleModal>

      <ImageTetherModal src={iconSendUSDT} />

      {showOTPForm ? (
        <FormRequestOTP
          errorMsg={otpErrorMsg}
          otpToken={otpToken}
          setOtpToken={setOtpToken}
          buttonTitle="Enviar Tethers"
          buttonTitleLoading="Enviando..."
          loading={loading}
          onSubmit={debounce(async () => {
            setOtpErrorMsg("");
            await transferUSDT(
              walletUSDT.balance,
              sendTo,
              feePerOperation,
              sendAmount,
              otpToken,
              (error) => {
                if (
                  error.response &&
                  error.response?.data?.code === "invalid-otp"
                ) {
                  setOtpErrorMsg("Codigo de verificación inválido.");
                  return;
                }
              }
            );
          }, 700)}
        />
      ) : (
        <>
          <LabelModal>
            <LabelSpan>Dirección de la billetera destino</LabelSpan>
            <InputModal
              type="text"
              value={sendTo}
              onChange={(e) => onHandleAddressChange(e)}
            />
          </LabelModal>

          <LabelModal>
            <LabelSpan>
              Monto a enviar (Disponible: <b>{walletUSDT.balance} USDT</b>).{" "}
              <a href="#" onClick={() => maxSendValue()}>
                Usar todo
              </a>
            </LabelSpan>
            <InputModal
              type="number"
              placeholder="0.00"
              min="0"
              value={sendAmount}
              onKeyPress={(e) => onKeyPressBTC(e)}
              onChange={(e) => onHandleAmountBTCChange(e)}
            />
          </LabelModal>

          <Margin20></Margin20>

          <DetailsOperation>
            <DetailTitle>Monto a enviar</DetailTitle>
            <DetailAmount>{sendAmount} USDT</DetailAmount>
          </DetailsOperation>

          <Clear></Clear>

          <DetailsOperation>
            <DetailTitle>Comisión</DetailTitle>
            <DetailAmount>{feePerOperation.toFixed(2)} USDT</DetailAmount>
          </DetailsOperation>

          <Clear></Clear>

          <DetailsOperation>
            <DetailTitle>Monto total</DetailTitle>
            <DetailAmount>
              {(Number(sendAmount ?? 0) + Number(feePerOperation)).toFixed(2)}{" "}
              USDT
            </DetailAmount>
          </DetailsOperation>

          <Margin20></Margin20>
          <Margin20></Margin20>

          <ReCaptcha onChangeCaptcha={onChangeCaptcha} />
          <LoginButton
            disabled={loading}
            onClick={debounce(async () => {
              const { errorFound } = runValidations(
                {
                  balance: walletUSDT.balance,
                  toAddress: sendTo,
                  feePerOperation,
                  amount: sendAmount,
                  captcha
                }
              );

              if (errorFound) return;

              if (userInfo.otpEnabled) {
                setShowOTPForm(true);
                return;
              }

              await transferUSDT(
                walletUSDT.balance,
                sendTo,
                feePerOperation,
                sendAmount
              );
            }, 700)}
          >
            {loading ? "Enviando.." : "Enviar Tethers"}
          </LoginButton>

          {/* <BtnPrimaryLg>
            {loading ? "Enviando.." : "Confirmar envio"}
          </BtnPrimaryLg> */}
        </>
      )}

      <FooterModal>
        <b>Importante:</b> Asegúrese de introducir correctamente los datos de la
        dirección destino (lo ideal es copiar y pegar). Fëanor no se hace
        responsable de las monedas enviadas a una dirección equivocada.
      </FooterModal>
    </BodyModal>
  );
};

export default FormSendUSDT;
