import React, { useState, useEffect, useContext } from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import {
  WalletBoxLeft,
  TitleBox,
  TitleModal,
  BodyModal,
  InputModal,
  LabelModal,
  LabelSpan,
  ListRowDiv,
  ListColumnDiv,
  ColText,
  Margin20,
  ColTextLight,
} from "styles/Styled";
import * as Styled from "./styles";
import { AuthContext } from "Auth";
import feanorapi from "feanorapi.js";
import swal from "sweetalert";

const ModalDetail = ({ open, onClose, balanceSelected }) => {
  const { currentToken } = useContext(AuthContext);
  const [balances, setBalances] = useState([]);

  useEffect(() => {
    const getTotalBalances = async () => {
      let data = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentToken}`,
        },
      };
      let endpoint = `/business/punto/balances?currency=${balanceSelected}`;
      let url = `${feanorapi}${endpoint}`;
      await fetch(url, data)
        .then((response) => {
          console.log(response);

          if (response.ok) {
            return response.json();
          }
        })
        .then((responseJson) => {
          if (responseJson) {
            setBalances(responseJson.balances);
          }
        })
        .catch((error) => {
          console.log(error);
          swal({
            text: "Ha ocurrido un error mientras obteniamos los saldos totales",
            button: false,
            timer: 5000,
          });
        });
    };

    getTotalBalances();
  }, [balanceSelected]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      closeOnOverlayClick={false}
    >
      
      <BodyModal styles={{ padding: "100px" }}>
        <TitleModal>Detalle de saldos ({balanceSelected})</TitleModal>

        <Styled.BalanceDetailTable>
          <ListRowDiv>
            <ListColumnDiv>
              <ColTextLight>Punto de cobro</ColTextLight>
            </ListColumnDiv>

            <ListColumnDiv>
              <ColTextLight> Saldo {balanceSelected} </ColTextLight>
            </ListColumnDiv>

            {/* <ListColumnDiv>
              <ColTextLight> Cambiar </ColTextLight>
            </ListColumnDiv> */}
          </ListRowDiv>

          {balances.map((e) => {
            return (
              <ListRowDiv key={e._id}>
                <ListColumnDiv>
                  <ColText>{e.puntoCobroId.name}</ColText>
                </ListColumnDiv>

                <ListColumnDiv>
                  <ColText>
                    {" "}
                    {balanceSelected === "USD" || balanceSelected === "USDT"
                      ? e.balance.toFixed(3)
                      : balanceSelected === "BTC"
                      ? e.balance.toFixed(8)
                      : e.balance}
                  </ColText>
                </ListColumnDiv>

                {/* <ListColumnDiv>
                  <ColText> Cambiar a bitcoin </ColText>
                </ListColumnDiv> */}
              </ListRowDiv>
            );
          })}
        </Styled.BalanceDetailTable>
      </BodyModal>
    </Modal>
  );
};

export default ModalDetail;
