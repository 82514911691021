import styled from "styled-components";
import { BtnBlueSm } from "styles/common/index.js";
import colors from "styles/common/colors";
import theme from "styles/theme";

export const SubtitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
  width: 100%;
`;

export const BtnAddRow = styled.a`
  line-height: 2;
  padding: 5px 2px;
  font-size: 14px;
  height: 30px;
  color: black;
  background: ${colors.feanor_orange};
  color: #fff;
  border-radius: ${theme.borderRadius};
  text-align: center;
  width: 250px;
  display: inline;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
`;

export const actionIcon = styled.span`
  margin-right: 10px;
  cursor: pointer;
  padding: 5px;
`;
