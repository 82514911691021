import React from "react";
import CardRegular from "components/CardRegular/CardRegular";
import { CardsContainer } from "components/CardRegular/styled";
import useGetCardWallet from "hooks/useGetCardWallet";
import useModal from "hooks/useModal";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  BtnPrimary,
  ButtonContent,
  CardsBox,
  DescriptionBox,
  DisplayCount,
  TitleBox,
  WalletBoxLeft,
  WalletContent,
} from "styles/Styled";
import swal from "sweetalert";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import FundCardForm from "../CardDetail/components/FundCardForm";
import "styles/stylesSwiper.css";
import { Button } from "@material-ui/core";
import TableTransactions from "../components/TableTransactions";
import { useMUIStyles } from "styles/muiStyes";
import { useContext } from "react";
import swal2 from "sweetalert2";
import { PlatformFeeContext } from "contexts/platformFeeProvider";
import { AuthContext } from "Auth";

export default function RegularCards({
  otpEnabled,
  walletUSDT,
  walletBTC,
  walletUSDTTron,
  regularCards,
  refreshCards,
  refreshWallets,
  refreshTransactions,
}) {
  const { platformFee } = useContext(PlatformFeeContext);
  const { userName } = useContext(AuthContext);
  const { fetching, cardWallet, getCardWallet } = useGetCardWallet();
  const history = useHistory();
  const [ModalFundCard, open, openModal, onClose] = useModal();
  const [
    ModalTransactions,
    openTransactions,
    openModalTransactions,
    onCloseTransactions,
  ] = useModal();
  const [swiper, setSwiper] = useState(null);
  const classes = useMUIStyles();

  const hadleOpenFundCard = () => {
    if (!walletUSDT.found && !walletUSDTTron.found) {
      swal({
        text:
          "Aun no cuentas con una billetera USDT porfavor crea una y abona tethers a ella.",
        button: false,
        timer: 6000,
      });
      setTimeout(function() {
        history.push(`/app/profile/money`);
      }, 6000);
      return;
    }

    if (!platformFee.charged) {
      swal2.fire({
        icon: "warning",
        title: `Estimado/a ${userName}:`,
        html:
          "El siguiente desglose es para que conozca algunos costos relevantes a su tarjeta.<br/> $225 - Mantenimiento de plataforma, seguro de fraude, emisión y envío. Este pago se realiza una única vez por año y debe realizarlo previo a la solicitud de su tarjeta. <br/> 5% - Comisión del monto abonado a su uso de tarjeta",
        showCancelButton: false,
        confirmButtonText: "Entendido!",
      });
      return;
    }
    openModal();
  };

  return (
    <WalletContent>
      <CardsBox>
        <TitleBox style={{ marginBottom: "0" }}>Saldo total</TitleBox>
        {fetching ? (
          <DisplayCount style={{ marginTop: "0", marginBottom: "10px" }}>
            Obteniendo...
          </DisplayCount>
        ) : Boolean(cardWallet?.balance) === false &&
          cardWallet?.balance !== 0 ? (
          <DisplayCount style={{ marginTop: "0", marginBottom: "10px" }}>
            No disponible
          </DisplayCount>
        ) : (
          <DisplayCount style={{ marginTop: "0", marginBottom: "10px" }}>
            $ {cardWallet?.balance} USD
          </DisplayCount>
        )}
        <DescriptionBox style={{ margin: 0 }}>
          Recarga tu FëanorCard usando USDT de tu wallet.
        </DescriptionBox>
        <ButtonContent>
          <Button
            className={classes.fundCardBtn}
            onClick={hadleOpenFundCard}
            data-testid="fundmastercard"
          >
            Abonar
          </Button>
          <Button
            className={classes.transactionsBtn}
            variant="outlined"
            onClick={openModalTransactions}
          >
            Movimientos
          </Button>
          <ModalTransactions
            open={openTransactions}
            onClose={onCloseTransactions}
            closeOnOverlayClick={true}
          >
            <TableTransactions />
          </ModalTransactions>
          <ModalFundCard
            open={open}
            onClose={onClose}
            center
            closeOnOverlayClick={false}
          >
            <FundCardForm
              cardDetail={regularCards[swiper?.activeIndex]}
              refreshCardDetail={refreshCards}
              refreshCardsBalance={getCardWallet}
              walletUSDT={walletUSDT}
              walletBTC={walletBTC}
              walletUSDTTron={walletUSDTTron}
              closeModal={onClose}
              refreshWallets={refreshWallets}
              refreshTransactions={refreshTransactions}
            />
          </ModalFundCard>
        </ButtonContent>
        <CardsContainer style={{ margin: 0 }}>
          <Swiper
            className="swiperRegularCards"
            onSwiper={setSwiper}
            // effect={"cards"}
            grabCursor={true}
            // navigation={true}
            slidesPerView={2}
            // pagination={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            // modules={[Navigation, Pagination]}
            // modules={[EffectCards, Navigation, Pagination]}
            breakpoints={{
              100: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
            }}
          >
            {regularCards.map((card) => (
              <SwiperSlide key={card._id}>
                <CardRegular
                  refreshCards={refreshCards}
                  cardNumber={card.number}
                  status={card.status}
                  isActive={card.isActive}
                  cardId={card._id}
                  isPhysicalCard={card.physicalCard}
                  otpEnabled={otpEnabled}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </CardsContainer>
      </CardsBox>
    </WalletContent>
  );
}
