import React, { useState } from "react";
import {
  BodyModal,
  FooterModal,
  ImageModal,
  InputModal,
  LabelModal,
  LabelSpan,
  TitleModal,
  InputContainer,
  SubtitleModal,
} from "styles/Styled";
import iconReceiveUSDT from "images/tether-logo.svg";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Icon } from "@iconify/react";
import swal2 from "sweetalert2";
import QRAddress from "components/QRAddress";

const FormReceiveUSDT = ({ walletUSDTAddress }) => {
  const [showQR, setShowQR] = useState(false);

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(walletUSDTAddress);
    swal2.fire({
      title: "Dirección copiada",
      text: "",
      timer: 1000,
      showConfirmButton: false,
      icon: "success"
    });
  };

  return (
    <BodyModal>
      {showQR ? (
        <QRAddress walletAddress={walletUSDTAddress} setShowQR={setShowQR} />
      ) : (
        <div>
          <TitleModal style={{ marginBottom: 0 }}>Recibir Tethers</TitleModal>
          <SubtitleModal>Tether (USDT) ERC-20 Ethereum</SubtitleModal>

          <ImageModal src={iconReceiveUSDT} />

          <LabelModal>
            <LabelSpan>Dirección de tu billetera</LabelSpan>
            <InputContainer>
              <IconButton>
                <FileCopyIcon onClick={() => onCopyToClipboard()} />
              </IconButton>
              <IconButton onClick={() => setShowQR(true)}>
                <Icon icon="mdi:qrcode" />
              </IconButton>
              <InputModal readOnly type="text" value={walletUSDTAddress} />
            </InputContainer>
          </LabelModal>

          <FooterModal>
            <b>Importante:</b> Tener encuenta que la dirección mostrada es una
            direccion correspondiente al token Tether (USDT) ERC-20 Ethereum.
            Fëanor no se hace responsable de las monedas enviadas a una
            dirección equivocada. Esta es tu dirección que debes brindar para
            recibir tethers. Después de realizar un depósito puede hacer el
            seguimiento del progreso en la página <b>Operaciones</b>.
          </FooterModal>
        </div>
      )}
    </BodyModal>
  );
};

export default FormReceiveUSDT;
