import styled from "styled-components";
import colors from "styles/common/colors";

export const VisorPrecio = styled.div`
  height: 40px;
  line-height: 40px
  font-size: 20px;
  color: ${colors.dark_blue};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  opacity: 0.7;
  border-radius: 5px;
  font-weight: 600;
  font-family: Montserrat;
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }

  @media screen and (max-width: 320px) {
    font-size: 15px;
  }
`;

export const CalculatorTabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 40px;
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }

  @media (max-width: 320px) {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`;

export const CalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 500px;
`;

export const ContainerMinMax = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BalanceFIATTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 20px;
  color: ${colors.dark_blue};

  font-weight: 500;
  letter-spacing: 1px;
  font-family: Montserrat;
  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
    font-size: 18px;
  }
`;

export const HeadlineTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 24px;
  color: ${colors.dark_blue};
  opacity: 0.7;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: Montserrat;
  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
    font-size: 18px;
  }
`;

export const ArrowDown = styled.img`
  width: 16px;
  height: 8px;
  position: absolute;
  right: 10px;
  color: red;
`;

export const ArrowUp = styled.img`
  width: 16px;
  height: 8px;
  position: absolute;
  right: 10px;
  transform: rotate(180deg);
`;

export const EtiquetaPrecioCrypto = styled.div`
  width: 150px;
  position: absolute;
  right: 0px;
  height: 100%;
  background: rgba(255, 200, 92, 0.2);
  font-weight: 600;
  font-size: 28px;
  border-radius: 0px 5px 5px 0px;
  color: rgba(255, 200, 92, 1);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    font-size: 18px;
    width: 100px;
  }
`;

export const EtiquetaNombre = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const TitleCalculator = styled.div`
  width: 100%;
  font-family: Montserrat;
  color: ${colors.dark_blue};
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const Icon = styled.img`
  width: 20px;
  height: auto;
  margin: 5px;
`;

export const IconClickable = styled.img`
  width: 20px;
  height: auto;
  margin: 5px;
  cursor: pointer;
`;

export const EtiquetaPrecioFiat = styled.div`
  width: 150px;
  position: absolute;
  right: 0px;
  height: 100%;
  background: rgba(32, 201, 151, 0.2);
  font-weight: 600;
  font-size: 28px;
  border-radius: 0px 5px 5px 0px;
  color: rgba(32, 201, 151, 1);
  font-family: Montserrat;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    font-size: 18px;
    width: 100px;
  }
`;

export const IngresoPrecio = styled.input`
  text-align: left;
  align-items: center;
  width: 100%;
  margin: 1px 10px;
  border: none;
  color: #3c4a5b;
  font-weight: 800;
  margin-top: 1px;
  background: transparent;
  &:hover {
    color: blue;
  }
  @media screen and (max-width: 640px) {
    font-size: 24px;
    width: 200px;
  }
`;

export const Visor = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  font-size: 34px;
  margin: 20px 0;
  border: 1px solid #3c4a5b;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  display: flex;
  &:hover {
    border: 1px solid blue;
  }
  -moz-appearance: textfield;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    margin: 12px 0 0;
  }

  @media screen and (max-width: 640px) {
    text-align: center;
    font-size: 24px;
  }

  @media screen and (max-width: 320px) {
    height: 60px;
    line-height: 60px;
  }
`;

export const MinMaxElement = styled.div`
  margin: 20px;
  font-family: Montserrat;
  color: ${colors.dark_blue};
  font-size: 14px;
  text-align: center;
`;

export const FooterCalculator = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: Montserrat;
  color: ${colors.dark_blue};
  font-size: 18px;
  margin-top: 20px;
`;

export const Convers = styled.div`
  *:focus {
    outline: none;
  }
  max-width: 100%;
  vertical-align: middle;
  text-align: center;
  align: center;
  align-items: center;
  position: relative;
  flex: 0 1 50%;
  box-shadow: 0 6px 9px 2px rgba(0, 0, 0, 0.06);
  background: white;
  padding: 20px 40px;
  box-sizing: bordex-box;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 40px 20px;
  }

  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;

export const SmallLetters = styled.div`
  font-size: 10px;
  color: ${colors.feanor_dark_bg};
  width: 100%;
  font-family: Montserrat;
`;

export const TermsLink = styled.a`
  color: ${colors.feanor_dark_bg};
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;




