import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "Auth";
import feanorapi from "feanorapi.js";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";

// import ReactGA from 'react-ga';

import useGetUserInfo from "hooks/useGetUser";
import useGetCards from "hooks/useGetCards";
import constants from "utils/constants";

const FeanorCardStepper = ({ cards }) => {
  // const {
  //   userInfo: { kycVerificationStatus },
  // } = useGetUserInfo();
  const {
    userInfo: { kycVerificationStatus },
  } = useContext(AuthContext);
  // const { cards } = useGetCards();


  return (
    <>
      <div className="md-stepper-horizontal orange">
        <Link
          className={`md-step ${
            kycVerificationStatus === constants.verificationStatus.verified
              ? ""
              : "active"
          }`}
          to={`/app/profile/activation`}
        >
          <div className="md-step-circle">
            <span>
              {kycVerificationStatus === constants.verificationStatus.verified
                ? "✓"
                : "1"}
            </span>
          </div>
          <div className="md-step-title">Valida tu cuenta</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </Link>

        <Link
          // className={`md-step`}
          className={`md-step ${cards.length > 0 ? "" : "active"}`}
          // to={`#`}
          to={`/app/profile/solicitar_card`}
        >
          <div className="md-step-circle">
            <span>{cards.length > 0 ? "✓" : "2"}</span>
          </div>
          <div className="md-step-title">Solicita tu Fëanor Card</div>
          {/* <div className="md-step-optional">Dólares, todos los bancos</div> */}
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </Link>

        <Link
          className={`md-step ${
            kycVerificationStatus === constants.verificationStatus.verified &&
            cards.length > 0
              ? "active"
              : ""
          }`}
          to={`#`}
        >
          <div className="md-step-circle">
            <span>3</span>
          </div>
          <div className="md-step-title">Abona a tu tarjeta</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </Link>
      </div>

      {/* <p>
        ¡Hola! Estamos contentos que nos hayas elegido para comenzar tu
        experiencia Bitcoin. ¿Deseas que te demos un breve tour por el sitio? :)
      </p> */}
    </>
  );
};

export default FeanorCardStepper;
