import React, { useState, useContext } from "react";
import {
  BodyModal,
  BtnPrimaryLg,
  Clear,
  DescriptionModal,
  DetailAmount,
  DetailsOperation,
  DetailTitle,
  FooterModal,
  InputModal,
  LabelModal,
  LabelSpan,
  Margin20,
  TitleModal,
} from "styles/Styled";
import feanorapi from "feanorapi.js";
import { AuthContext } from "Auth";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import { SelectorFull } from "styles/common";
import constants from "utils/constants";
import { SettingsCell } from "@material-ui/icons";
import { fetchQuote } from "services/quote.service";
import {
  confirmFundVisaCard,
  converBTCtoUSDT,
  fundCardRequest,
  fundVisaCardRequest,
} from "services/card.service";
import LoadingMessage from "components/LoadingCardDepoitMsg/LoadingCardDepositMsg";
import currencyTypes from "models/currencies";
import debounce from "lodash/debounce";
import { useReCaptcha } from "hooks/useReCaptcha";

const FundCardForm = ({
  cardDetail,
  // refreshCardDetail,
  walletUSDT,
  walletBTC,
  walletUSDTTron,
  closeModal,
  refreshWallets,
  refreshTransactions,
  cardProvider = "visa",
  refreshCardsBalance,
}) => {
  const { currentToken } = useContext(AuthContext);

  const [amountToFund, setAmountToFund] = useState("");
  const [convertedAmount, setConvertedAmount] = useState("");
  const [feanorFee, setFeanorFee] = useState(0.0);
  const [referrerFee, setReferrerFee] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const { ReCaptcha, captcha, onChangeCaptcha } = useReCaptcha();

  const currencies = [];
  if (walletUSDT.found) {
    currencies.push({
      value: constants.currencyTypes.USDT,
      label: "USDT ETH",
    });
  }
  if (walletUSDTTron.found) {
    currencies.push({
      value: constants.currencyTypes.USDT_TRON,
      label: "USDT TRON",
    });
  }
  if (walletBTC.found) {
    currencies.push({
      value: constants.currencyTypes.BTC,
      label: "BTC",
    });
  }
  const [currentCurrency, setCurrentCurrency] = useState(currencies[0].value);

  const getBTCQuote = async ({ finalAmount }) => {
    const quote = await fetchQuote({
      ammount: finalAmount,
      from: "BTC",
      to: walletUSDTTron.found ? currencyTypes.USDT_TRON : currencyTypes.USDT,
      transactionType: "SELL",
    });

    return quote;
  };

  const maxFundValue = async (e) => {
    e.preventDefault();
    setAmountToFund(walletUSDT.balance);
    const fees = await fetchFees(walletUSDT.balance);
    setFeanorFee(fees.feanorFee);
    setReferrerFee(fees.referrerFee);
  };

  const maxFundUSDTTronValue = async (e) => {
    e.preventDefault();
    setAmountToFund(walletUSDTTron.balance);
    const fees = await fetchFees(walletUSDTTron.balance);
    setFeanorFee(fees.feanorFee);
    setReferrerFee(fees.referrerFee);
  };

  const maxFundBTCValue = async (e) => {
    e.preventDefault();

    const quote = await getBTCQuote({ finalAmount: walletBTC.balance });

    setAmountToFund(walletBTC.balance);
    const convertedAmount = quote.totalAmounts[1] ?? 0;
    setConvertedAmount(convertedAmount);

    const fees = await fetchFees(convertedAmount);
    setFeanorFee(fees.feanorFee);
    setReferrerFee(fees.referrerFee);
  };

  const fetchFees = async (amount) => {
    let endpoint = `/client/fees/card/deposits`;
    let url = `${feanorapi}${endpoint}`;
    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({ amount }),
    };
    try {
      const response = await fetch(url, data);
      const res = await response.json();
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
      swal("ocurrio un error al calcular las comisiones");
    }
  };

  const onAmountChange = async (e) => {
    const amount = parseFloat(e.target.value);

    let finalAmount = 0;
    if (amount <= parseFloat(walletUSDT.balance) && amount > 0) {
      finalAmount = amount;
    } else if (amount > parseFloat(walletUSDT.balance)) {
      finalAmount = walletUSDT.balance;
    }

    if (finalAmount === 0) {
      setAmountToFund("");
    } else {
      setAmountToFund(finalAmount);
    }

    debouncedFetchFees(finalAmount);
  };

  const onAmountUSDTTronChange = async (e) => {
    const amount = parseFloat(e.target.value);

    let finalAmount = 0;
    if (amount <= parseFloat(walletUSDTTron.balance) && amount > 0) {
      finalAmount = amount;
    } else if (amount > parseFloat(walletUSDTTron.balance)) {
      finalAmount = walletUSDTTron.balance;
    }

    if (finalAmount === 0) {
      setAmountToFund("");
    } else {
      setAmountToFund(finalAmount);
    }
  
    debouncedFetchFees(finalAmount);
  };

  const debouncedFetchFees = debounce(async (finalAmount) => {
    const fees = await fetchFees(finalAmount);
    setFeanorFee(fees.feanorFee);
    setReferrerFee(fees.referrerFee);
  }, 800);

  const onAmountBTCChange = async (e) => {
    const amount = parseFloat(e.target.value);

    let finalAmount = 0;
    if (amount <= parseFloat(walletBTC.balance) && amount > 0) {
      finalAmount = amount;
    } else if (amount > parseFloat(walletBTC.balance)) {
      finalAmount = walletBTC.balance;
    }

    // if (finalAmount === 0) {
    //   setAmountToFund("");
    // } else {
    // }
    setAmountToFund(finalAmount);

    const quote = await getBTCQuote({ finalAmount });

    const convertedAmount = quote.totalAmounts[1] ?? 0;
    setConvertedAmount(convertedAmount);

    debouncedFetchFees(convertedAmount);
  };

  const fundCard = debounce(async () => {
    setLoading(true);

    let amount = parseFloat(amountToFund);

    try {
      if (amount <= 0 || isNaN(amount)) {
        throw new Error("El monto debe ser mayor a 0");
      }

      if (!captcha) {
        throw new Error("Por favor, complete el captcha");
      }

      let depositProps = {
        amount,
        cardId: cardDetail._id,
        currencyOrigin: currentCurrency,
        currencyTarget: currentCurrency,
      };

      // if (amount > parseFloat(walletUSDT.balance)) {
      //   throw new Error("El monto es mayor al saldo disponible");
      // }

      /* Converting the amount from BTC to USDT. */
      if (constants.currencyTypes.BTC === currentCurrency) {
        const currencyTarget = walletUSDTTron.found
          ? currencyTypes.USDT_TRON
          : currencyTypes.USDT;

        if (convertedAmount < constants.minDepositAmount) {
          throw new Error(
            `El monto minimo para depositar es ${constants.minDepositAmount} USDT`
          );
        }

        const conversion = await converBTCtoUSDT(
          {
            amount,
            from: constants.currencyTypes.BTC,
            to: currencyTarget,
            transactionType: "SELL",
          },
          currentToken
        );

        if (conversion.code === "resource-locked") {
          return;
        }

        if (!conversion.success) {
          throw new Error(
            conversion.message ?? "Error al realizar la conversion"
          );
        }
        depositProps = {
          ...depositProps,
          amount: conversion.toAmount,
          currencyTarget: currencyTarget,
          exchangeDepositId: conversion.exchangeTransaction._id,
        };
      }

      // if (constants.currencyTypes.USDT_TRON === currentCurrency) {
      //   depositProps = {
      //     ...depositProps,
      //     currencyOrigin: constants.currencyTypes.USDT_TRON,
      //   };
      // }

      /* Creating de deposit to fund the card. */
      let response;
      if (cardDetail.cardProvider === "MASTERCARD") {
        response = await fundCardRequest(depositProps, currentToken);
      }

      if (cardDetail.cardProvider === "VISA") {
        response = await fundVisaCardRequest(depositProps, currentToken);
        if (cardDetail.createdInSVEmisor && response.success) {
          response = await confirmFundVisaCard(
            response.transaction._id,
            currentToken
          );
        }
      }

      if (response.code === "resource-locked") {
        return;
      }

      if (response.code === "v-failed") {
        await refreshWallets();
        await refreshTransactions();
        // await refreshCardDetail();//
        if (refreshCardsBalance) {
          refreshCardsBalance();
        }
        swal2.fire(
          "Abono en proceso",
          "Aprobaremos su transacción en el transcurso del día",
          "warning"
        );
        closeModal();
        return;
      }

      if (!response.success) {
        let msg =
          response.message ?? "Ocurrió un error al realizar el deposito";
        // if (response.code === "v-failed") {
        //   msg = "Ocurrió un error al realizar el deposito";
        // }
        throw new Error(msg);
      }

      await refreshWallets();
      await refreshTransactions();
      // await refreshCardDetail();//
      if (refreshCardsBalance) {
        refreshCardsBalance();
      }

      let msg = "";
      if (cardDetail.cardProvider === "MASTERCARD") {
        msg = "El abono fue realizado correctamente";
      }
      if (cardDetail.cardProvider === "VISA") {
        msg =
          "El abono fue realizado correctamente y lo aprobaremos en el transcurso del dia";
      }
      swal2.fire("Abono realizado", msg, "success");
      closeModal();
    } catch (error) {
      console.log(error);
      swal(error.message);
    } finally {
      setLoading(false);
    }
  }, 700);

  return (
    <BodyModal>
      <TitleModal>Abonar dinero a mi Fëanor Card</TitleModal>
      {/* <ImageModal src={iconSendBTC} /> */}

      <DescriptionModal style={{ textAlign: "center" }}>
        Te permite ingresar dinero a tu FëanorCard, esto luego podrás utilizarlo
        para hacer compras a nivel mundial.
      </DescriptionModal>

      {cardDetail.cardProvider !== "MASTERCARD" && (
        <LabelModal>
          <LabelSpan>Tarjeta a abonar</LabelSpan>
          <InputModal
            type="text"
            value={cardDetail.number}
            readOnly
            onChange={() => {}}
          />
        </LabelModal>
      )}
      <LabelModal>
        <LabelSpan>Seleccione una billetera</LabelSpan>
        <SelectorFull
          required
          options={currencies}
          value={currencies.find((e) => e.value === currentCurrency)}
          onChange={(option) => {
            setCurrentCurrency(option.value);
            setAmountToFund("");
            setConvertedAmount(0);
            setFeanorFee(0);
            setReferrerFee(0);
          }}
        ></SelectorFull>
      </LabelModal>

      {currentCurrency === constants.currencyTypes.USDT && (
        <LabelModal>
          <LabelSpan>
            Monto a abonar (Disponible: <b>{walletUSDT.balance} USDT</b>).{" "}
            <a href="#" onClick={(e) => maxFundValue(e)}>
              Usar todo
            </a>
          </LabelSpan>
          <InputModal
            type="number"
            min="0"
            value={amountToFund}
            placeholder="0.00"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onChange={onAmountChange}
          />
        </LabelModal>
      )}

      {currentCurrency === constants.currencyTypes.BTC && (
        <LabelModal>
          <LabelSpan>
            Monto a abonar (Disponible: <b>{walletBTC.balance} BTC</b>).{" "}
            <a href="#" onClick={(e) => maxFundBTCValue(e)}>
              Usar todo
            </a>
          </LabelSpan>
          <InputModal
            type="number"
            min="0"
            value={amountToFund}
            placeholder="0.00"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onChange={onAmountBTCChange}
          />
        </LabelModal>
      )}

      {currentCurrency === constants.currencyTypes.USDT_TRON && (
        <LabelModal>
          <LabelSpan>
            Monto a abonar (Disponible: <b>{walletUSDTTron.balance} USDT</b>).{" "}
            <a href="#" onClick={(e) => maxFundUSDTTronValue(e)}>
              Usar todo
            </a>
          </LabelSpan>
          <InputModal
            type="number"
            min="0"
            value={amountToFund}
            placeholder="0.00"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onChange={onAmountUSDTTronChange}
          />
        </LabelModal>
      )}

      <DetailsOperation>
        <DetailTitle>Comisión</DetailTitle>
        <DetailAmount data-testid="commission">
          {(parseFloat(feanorFee) + parseFloat(referrerFee)).toFixed(2)} USDT
        </DetailAmount>
      </DetailsOperation>

      {/* <DetailsOperation>
        <DetailTitle>Comisión Fëanor</DetailTitle>
        <DetailAmount>{feanorFee} USDT</DetailAmount>
      </DetailsOperation>

      <Clear></Clear>

      <DetailsOperation>
        <DetailTitle>Comisión Referidor</DetailTitle>
        <DetailAmount>{referrerFee} USDT</DetailAmount>
      </DetailsOperation>

      <Clear></Clear> */}

      {currentCurrency === constants.currencyTypes.USDT && (
        <DetailsOperation>
          <DetailTitle>Monto a recibir</DetailTitle>
          <DetailAmount data-testid="toreceive">
            {amountToFund
              ? (
                  parseFloat(amountToFund) -
                  parseFloat(referrerFee) -
                  parseFloat(feanorFee)
                ).toFixed(2)
              : "0.00"}{" "}
            USDT
          </DetailAmount>
        </DetailsOperation>
      )}
      {currentCurrency === constants.currencyTypes.USDT_TRON && (
        <DetailsOperation>
          <DetailTitle>Monto a recibir</DetailTitle>
          <DetailAmount data-testid="toreceive">
            {amountToFund
              ? (
                  parseFloat(amountToFund) -
                  parseFloat(referrerFee) -
                  parseFloat(feanorFee)
                ).toFixed(2)
              : "0.00"}{" "}
            USDT
          </DetailAmount>
        </DetailsOperation>
      )}
      {currentCurrency === constants.currencyTypes.BTC && (
        <DetailsOperation>
          <DetailTitle>Monto a recibir</DetailTitle>
          <DetailAmount>
            {convertedAmount
              ? (
                  parseFloat(convertedAmount) -
                  parseFloat(referrerFee) -
                  parseFloat(feanorFee)
                ).toFixed(2)
              : "0.00"}{" "}
            USDT
          </DetailAmount>
        </DetailsOperation>
      )}

      <Clear></Clear>

      {/* <DetailsOperation>
        <DetailTitle>Monto total</DetailTitle>
        <DetailAmount>
          {amountToFund
            ? parseFloat(amountToFund) +
              parseFloat(feanorFee) +
              parseFloat(referrerFee)
            : 0}{" "}
          USDT
        </DetailAmount>
      </DetailsOperation> */}

      <Margin20></Margin20>
      <Margin20></Margin20>

      <ReCaptcha onChangeCaptcha={onChangeCaptcha} />

      <BtnPrimaryLg disabled={loading} onClick={() => fundCard()}>
        <LoadingMessage
          loading={loading}
          createdInSVEmisor={cardDetail.createdInSVEmisor}
        />
      </BtnPrimaryLg>

      <FooterModal>
        <b>Importante:</b> No recomendamos realizar abonos si la tarjeta aún no
        ha sido recibida o esta en proceso de envio.
      </FooterModal>
    </BodyModal>
  );
};

export default FundCardForm;
