import moment from "moment";
import { translateCategory, translateState } from "utils/utils";
import xlsx from "xlsx";

const users = [
  {
    id: 0,
    name: "Peter",
    age: 31,
  },
  {
    id: 1,
    name: "John",
    age: 23,
  },
];

export const exportExcel = (transactions) => {
  const newTransactions = transactions.map((e) => {
    return {
      "Punto cobro": e.puntoCobro ? e.puntoCobro.name : "",
      Categoria: translateCategory(e.category),
      Moneda: e.currencies.length
        ? `${e.currencies[0]} / ${e.currencies[1]}`
        : e.currency,
      Monto: e.amount,
      Fecha: moment(e.createdAt).format("DD-MM-YYYY HH:mm:ss"),
      Estado: translateState(e.state),
    };
  });

  let data = xlsx.utils.json_to_sheet(newTransactions);

  const workbook = xlsx.utils.book_new();
  const filename = "corporativos-transactions";
  xlsx.utils.book_append_sheet(workbook, data, filename);
  xlsx.writeFile(workbook, `${filename}.xlsx`);
};

const exportUsersToExcel = (
  users,
  workSheetColumnNames,
  workSheetName,
  filePath
) => {
  const data = users.map((user) => {
    return [user.id, user.name, user.age];
  });
  exportExcel(data, workSheetColumnNames, workSheetName, filePath);
};
