import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { getQuote } from "components/object-calculator/helper.js";
import { sendUSDT } from "services/transaction.service";

const useSendUSDT = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const runValidations = (
    { balance,
      toAddress,
      feePerOperation,
      amount,
      otpToken = "",
      captcha = ""
    }
  ) => {
    if (toAddress == "") {
      swal({
        text: "No has colocado la dirección USDT a enviar.",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    if (amount == "" || amount == "0" || isNaN(amount) || amount < 1) {
      swal({
        text: "Coloca el monto en USDT a enviar.",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    // if (amount < 1 || amount > 1000) {
    //   swal({
    //     text: "El monto de tethers a enviar debe estar entre 1 y 1000 USDT.",
    //     button: true,
    //     timer: 5000,
    //   });
    //   return { errorFound: true };
    // }

    if (amount > Number(balance)) {
      swal({
        text: "No puedes enviar un monto que no tienes disponible para usar. ",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    if (
      Number(amount) > (Number(balance) - Number(feePerOperation)).toFixed(2)
    ) {
      swal({
        text:
          "No puedes enviar este monto debido a que se debe incluir la comisión de envio. ",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    if (!captcha) {
      swal({
        text: "Debes completar el captcha para continuar.",
        button: true,
        timer: 5000,
      });
      return { errorFound: true };
    }

    return { errorFound: false };
  };

  const transferUSDT = async (
    balance,
    toAddress,
    feePerOperation,
    amount,
    otpToken = "",
    onFail = null
  ) => {
    try {
      setLoading(true);
      const resp = await sendUSDT(toAddress, amount, otpToken);
      setSuccess(true);
      return resp;
    } catch (error) {
      console.log(error);

      if (error.response) {
        console.log(error.response);
        if (error.response?.data?.code === "kyc-needed") {
          swal({
            text: error.response?.data?.message,
            button: false,
            timer: 5000,
          });
          return;
        }

        if (onFail && error.response?.status !== 500) {
          onFail(error);
          return;
        }
      }

      swal({
        text:
          "Ha habido un error al enviar los tethers. Si el error persiste escribenos a Soporte.",
        button: false,
        timer: 5000,
      });
    } finally {
      setLoading(false);
    }
    return false;
  };

  return { loading, transferUSDT, success, setSuccess, runValidations };
};

export default useSendUSDT;
