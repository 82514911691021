import React, { useContext } from "react";
import { Header4 } from "styles/common/index.js";
import * as Styled from "./styles";
import TableTransaccinesBusiness from "components/TableTransaccinesBusiness";
import InputDateRange from "components/InputDateRangePicker";
import { TransactionsProvider } from "contexts/transactions";
import ExportButton from "components/ExportButton";
const Reportes = () => {
  return (
    <>
      <TransactionsProvider>

        <Styled.SubtitleAndButton>
          <Header4>Reportes de transacciones.</Header4>
          <ExportButton></ExportButton>
        </Styled.SubtitleAndButton>

        {/* <Styled.SubtitleAndButton>
          <InputDateRange></InputDateRange>
        </Styled.SubtitleAndButton> */}

        <TableTransaccinesBusiness />
      </TransactionsProvider>
    </>
  );
};

export default Reportes;
