import React, { useContext } from "react";
import { Redirect, withRouter } from "react-router";
import { Switch } from "react-router-dom";

import Styled, { CurrentItemCSS } from "./styles";

import { Header2 } from "styles/common/index.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profileComponentsMobile, profileComponents } from "./menuItems";
// import ReactGA from 'react-ga';

import VerifyIsValidatedByKYC from "components/Guards/VerifyIsValidatedByKYC";

import VerificationModal from "components/VerificationModal";
import useGetUserInfo from "hooks/useGetUser";
import { ContractAgreementModal } from "components/ContractAgreementModal";
import useAgreementUpdates from "hooks/useAgreementUpdates";
import { AgreementUpdatesModal } from "components/AgreementUpdatesModal";
import { PlatformFeeProvider } from "contexts/platformFeeProvider";
import VerifyPlatformFeeCharged from "components/Guards/verifyPlatformFeeCharged";
import { AuthContext } from "Auth";

const MobileMenu = ({ location, history }) => {
  const currentOptions = profileComponentsMobile.filter(
    (option) => `/app/profile/${option.value}` == location.pathname
  );

  return (
    <>
      {!!currentOptions.length && (
        <Styled.TabSelector
          isSearchable={false}
          value={{
            value: currentOptions[0].value,
            label: currentOptions[0].label,
          }}
          onChange={(option) => history.push(`/app/profile/${option.value}`)}
          options={profileComponentsMobile}
        />
      )}
    </>
  );
};

const DesktopMenu = ({ location, history }) => {
  const sendAnalitycs = () => {
    // ReactGA.event({
    //   category: 'Botones',
    //   action: 'Boton Calculadora Bitcoin'
    // });
  };

  return (
    <Styled.MenuDashboard>
      {profileComponents.map((component) => {
        if (!component.show) return null;

        return (
          <Styled.ItemDashboard
            key={component.value}
            to={`/app/${component.value}`}
            activecss={
              location.pathname === `/app/${component.value}`
                ? CurrentItemCSS
                : undefined
            }
          >
            <FontAwesomeIcon icon={component.icon} /> {component.label}
          </Styled.ItemDashboard>
        );
      })}

      {/* <Styled.ItemDashboard onClick={sendAnalitycs} to={`/app/operation/new`}>
        <FontAwesomeIcon icon={faCalculator} /> Calculadora
      </Styled.ItemDashboard> */}
    </Styled.MenuDashboard>
  );
};

const Profile = ({ location, history }) => {
  const { loading, fetchUser } = useGetUserInfo();
  const { userInfo: data, setUserInfo } = useContext(AuthContext);
  const {
    isUpdatesAccepted,
    acceptUpdates,
    loading: fetchingAgreementUpdates,
  } = useAgreementUpdates();

  // ReactGA.initialize(analyticsCode);
  if (!loading && JSON.stringify(data) !== "{}") {
    setUserInfo(data);
  }

  return (
    <Styled.ProfileWrapper>
      <Header2>
        Bienvenido <strong>{data.name}</strong>
      </Header2>

      {!loading && (
        <VerificationModal
          fetchUser={fetchUser}
          isKYCApprovalPending={data.isKYCApprovalPending}
          kycVerificationStatus={data.kycVerificationStatus}
          userId={data._id}
        />
      )}
      {!loading && (
        <ContractAgreementModal
          acceptUpdates={acceptUpdates}
          kycVerificationStatus={data.kycVerificationStatus}
          agreementAccepted={data.agreementAccepted}
        />
      )}
      {!fetchingAgreementUpdates && (
        <AgreementUpdatesModal
          isUpdatesAccepted={isUpdatesAccepted}
          acceptUpdates={acceptUpdates}
          kycVerificationStatus={data.kycVerificationStatus}
          agreementAccepted={data.agreementAccepted}
        />
      )}

      <Styled.Dashboard>
        <Styled.Sidebar>
          <MobileMenu location={location} history={history} />
          <DesktopMenu location={location} history={history} />
        </Styled.Sidebar>

        {/* Contenido del dashboard segun la opcion elegida en el sidebar */}
        <Styled.ContainerProfileData>
          <PlatformFeeProvider>
            <Switch>
              {profileComponents.map((component) => {
                // if (component.kycNeeded) {
                //   return (
                //     <VerifyIsValidatedByKYC
                //       key={component.value}
                //       path={`*/${component.value}`}
                //       component={component.component}
                //     ></VerifyIsValidatedByKYC>
                //   );
                // }
                return (
                  <VerifyIsValidatedByKYC
                    key={component.value}
                    path={`*/${component.value}`}
                    skip={!component.kycNeeded}
                  >
                    <VerifyPlatformFeeCharged
                      key={component.value}
                      path={`*/${component.value}`}
                      skip={!component.platformFeeNeeded}
                    >
                      <component.component />
                    </VerifyPlatformFeeCharged>
                  </VerifyIsValidatedByKYC>
                );
                // return (
                //   <Route
                //     key={component.value}
                //     path={`*/${component.value}`}
                //     component={component.component}
                //   />
                // );
              })}
              <Redirect from="/" to="/app/profile/money" />
            </Switch>
          </PlatformFeeProvider>
        </Styled.ContainerProfileData>
      </Styled.Dashboard>
    </Styled.ProfileWrapper>
  );
};

export default withRouter(Profile);
