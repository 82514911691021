import feanorapi from "feanorapi";
import httpCommon from "http-common";
import { getToken, headers } from "./common";

export const fetchTransactions = async (token, urlParameters = "") => {
  let data = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let endpoint = `/client/wallet/transaction?${urlParameters}`;
  let url = `${feanorapi}${endpoint}`;
  const response = await fetch(url, data);

  const res = await response.json();
  return res;
};

export const sendUSDT = async (toAddress, amount, otpToken) => {
  const response = await httpCommon.post(
    "/client/wallet/sendTether",
    { toAddress, amount, otpToken },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const sendUSDTTron = async (toAddress, amount, otpToken) => {
  const response = await httpCommon.post(
    "/client/wallet/send_usdt_tron",
    { toAddress, amount, otpToken },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};

export const sendBTC = async (toAddress, amount, otpToken) => {
  const response = await httpCommon.post(
    "/client/wallet/sendBitcoin",
    { toAddress, amount, otpToken },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  console.log(response);
  return response.data;
};
