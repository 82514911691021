import React, { useContext, useState } from "react";
import visaImg from "media/credit-card/visa.png";
import {
  ButtonsContainer,
  Card as CardStyled,
  CardInner,
  CardNumber,
  DataValue,
  Footer,
  Front,
  Header,
  InfoCol,
  InfoRow,
  MastercardImage,
  Overlay,
  Status,
  Title,
  ChipImage,
  BodyVisa,
  DataSubtitle,
  Back,
} from "./styled";
import chipImg from "media/credit-card/chip.png";
import { useMUIStyles } from "styles/muiStyes";
import { Button } from "@material-ui/core";
import ShowIcon from "@material-ui/icons/RemoveRedEye";
import HideIcon from "@material-ui/icons/VisibilityOff";
import { operations } from "utils/constants";
import useModal from "hooks/useModal";
import { FormChangePIN } from "components/FornChangePIN/FormChangePIN";
import {
  changeBusinessVisaPIN,
  changeVisaPIN,
  fetchBusinessVisaCardData,
  fetchVisaCardData,
} from "services/card.service";
import { FormRevealData } from "components/FormRevealData/FormRevealData";
import { AuthContext } from "Auth";

const CardHeader = ({ title, isActive, status }) => {
  return (
    <Header>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ChipImage src={chipImg} />
        <Title>
          {title}

          {status === "Active" && <Status>activa</Status>}
          {status === "PendingAck" && (
            <Status>pendiente a confirmar recepción</Status>
          )}
          {status === "Cancel" && <Status>Anulada</Status>}
          {status === "Temporarilyblocked" && <Status>Bloqueada</Status>}
          {status === "Requested" && <Status>Pendiente a entregar</Status>}
        </Title>
      </div>
      {/* <MastercardImage src={visaImg} /> */}
    </Header>
  );
};

export default function CardPremium({
  cardId,
  cardNumber,
  cardPrintedName,
  otpEnabled,
  isPhysicalCard,
  isActive,
  status,
  refreshCards,
}) {
  const classesMUI = useMUIStyles();
  const { userType } = useContext(AuthContext);
  const [operationSelected, setOperationSelected] = useState("");
  const [Modal, isOpenModal, openModal, onCloseModal] = useModal();
  const [privateData, setPrivateData] = useState({
    pan: "",
    pin: "",
    cvv: "",
    dueDate: "",
    name: "",
  });
  const [flipped, setFlipped] = useState(false);
  const handleFlipCard = () => {
    setFlipped(!flipped);
  };

  const formatDate = (date) => {
    if (date) {
      return date.slice(3, date.length);
    }
    return date;
  };
  return (
    <CardStyled>
      <Modal
        open={isOpenModal}
        onClose={onCloseModal}
        center
        closeOnOverlayClick={false}
        variant="small"
      >
        {operationSelected === operations.REVEAL_DATA && (
          <FormRevealData
            cardId={cardId}
            setPrivateData={setPrivateData}
            otpEnabled={otpEnabled}
            handleFlipCard={handleFlipCard}
            closeModal={onCloseModal}
            fetchCardDataRequest={(() => {
              if (userType === "business") {
                return fetchBusinessVisaCardData;
              }
              return fetchVisaCardData;
            })()}
          />
        )}
        {operationSelected === operations.CHANGE_PIN && (
          <FormChangePIN
            cardId={cardId}
            otpEnabled={otpEnabled}
            refreshCards={refreshCards}
            closeModal={onCloseModal}
            changePINRequest={(() => {
              if (userType === "business") {
                return changeBusinessVisaPIN;
              }
              return changeVisaPIN;
            })()}
          />
        )}
      </Modal>
      <CardInner flipped={flipped}>
        <Front>
          <Overlay isActive={isActive} />

          {/* <BackgroundImg src={mapImg} /> */}
          <CardHeader
            status={status}
            isActive={isActive}
            title={isPhysicalCard ? "Tarjeta física" : "Tarjeta virtual"}
          />
          <BodyVisa>
            {status === "Requested" ||
              (status === "SVEmisorRequested" && (
                <div>
                  <InfoRow>
                    <InfoCol>
                      <DataValue></DataValue>
                    </InfoCol>
                  </InfoRow>
                  <InfoRow>
                    <CardNumber style={{ position: "inherit" }}>
                      Numero por asignar
                    </CardNumber>
                  </InfoRow>
                </div>
              ))}
            {status === "Active" && (
              <div>
                <InfoRow>
                  <InfoCol>
                    <DataValue>{cardPrintedName}</DataValue>
                  </InfoCol>
                </InfoRow>
                <InfoRow>
                  <CardNumber style={{ position: "inherit" }}>
                    {cardNumber.replace(/(.{4})/g, "$1 ")}
                  </CardNumber>
                </InfoRow>
                <InfoRow style={{ marginTop: "20px" }}>
                  <Button
                    className={classesMUI.smallBtn}
                    variant="outlined"
                    onClick={() => {
                      // handleClose();
                      setOperationSelected(operations.CHANGE_PIN);
                      openModal();
                    }}
                  >
                    Cambiar PIN
                  </Button>
                  <Button
                    style={{ marginLeft: "10px" }}
                    className={classesMUI.smallBtn}
                    variant="outlined"
                    onClick={() => {
                      setOperationSelected(operations.REVEAL_DATA);
                      openModal();
                    }}
                  >
                    <ShowIcon
                      fontSize="small"
                      className={classesMUI.smallBtnIcon}
                    />
                    Mostrar datos
                  </Button>
                </InfoRow>
              </div>
            )}
          </BodyVisa>

          <Footer>
            <ButtonsContainer>
              <div></div>
              <div>
                <MastercardImage src={visaImg} />
              </div>
            </ButtonsContainer>
          </Footer>
        </Front>
        <Back>
          <CardHeader />
          <Footer>
            <div
              style={{
                position: "absolute",
                top: "56px",
                width: "90%",
                left: "51%",
                color: "white",
                transform: "translateX(-50%)",
              }}
            >
              <InfoRow style={{ justifyContent: "start" }}>
                <InfoCol>
                  <DataSubtitle>CVV</DataSubtitle>
                  <DataValue>{privateData.cvv}</DataValue>
                </InfoCol>
              </InfoRow>
              <InfoRow>
                <InfoCol>
                  <DataSubtitle>Nombre</DataSubtitle>
                  <DataValue>{privateData.name}</DataValue>
                </InfoCol>
                <InfoCol>
                  <DataSubtitle>Expira</DataSubtitle>
                  <DataValue>{formatDate(privateData.dueDate)}</DataValue>
                </InfoCol>
              </InfoRow>
              <InfoRow>
                {privateData.pan && (
                  <CardNumber style={{ position: "inherit" }}>
                    {privateData.pan.replace(/(.{4})/g, "$1 ")}
                  </CardNumber>
                )}
              </InfoRow>
            </div>
            <ButtonsContainer style={{ justifyContent: "flex-end" }}>
              <Button
                style={{ marginLeft: "10px" }}
                className={classesMUI.smallBtn}
                variant="outlined"
                onClick={handleFlipCard}
              >
                <HideIcon
                  fontSize="small"
                  className={classesMUI.smallBtnIcon}
                />{" "}
                Ocultar datos
              </Button>
            </ButtonsContainer>
          </Footer>
        </Back>
      </CardInner>
    </CardStyled>
  );
}
