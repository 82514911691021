import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header/index.js";
import Footer from "./components/Footer/index.js";
import Auth from "./pages/Auth/index.js";
import App from "./pages/App/index";
import AppBusiness from "./pages/AppBusiness";
import NotFound from "./pages/NotFound";
import { AuthProvider } from "./Auth";
import { CurrencyProvider } from "./CurrencyContext";
import { BtcPriceProvider } from "./BtcPriceContext";
import { createBrowserHistory } from "history";
import Styles from "./styles/Macro";
import "styles/all.css";
import NftMarket from "pages/NftMarket/index.js";
// import Popup from "components/popup/Popup.jsx";

const Main = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Styles.AppContainer>
          <Header />
          <Switch>
            <Route path="/auth" component={Auth} />
            <Route path="/app" component={App} />
            <Route path="/nft-market" component={NftMarket} />
            <Route path="/business" component={AppBusiness} />
            <Route exact path="/" component={Auth}>
              <Redirect to="/auth" />
            </Route>
          </Switch>
          {/* <Popup /> */}
          <Footer />
        </Styles.AppContainer>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Main;
