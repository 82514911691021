import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WalletBoxLeft } from "styles/Styled";

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const BalanceAmount = styled.span`
  font-size: 22px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  transition: color 0.3s;
  @media (max-width: 640px) {
    
    font-size: 17px;
  }
`;
export const BalanceAmountTitle = styled.div`
  font-size: 15px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  transition: color 0.3s;
  text-align: center;
  @media (max-width: 640px) {
    
    font-size: 17px;
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
  /* height: 100px; */
  padding: 20px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.7);
  }
  &:hover ${BalanceAmount} {
    color: rgb(0, 0, 0, 0.9);
  }
`;

export const IconSeeDetails = styled(FontAwesomeIcon)`
  color: black;
  margin-left: 10px;
`;

export const BalanceDetailTable = styled(WalletBoxLeft)`
  width: 100%;
  margin: 0;
  padding: 0;
`;
