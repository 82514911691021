import React from "react";
import swal from "sweetalert";

const { default: FormNewPIN } = require("components/FormNewPIN/FormNewPIN");
const {
  default: FormRequestOTP,
} = require("components/FormRequestOTP/FormRequestOTP");
const {
  default: FormRequestPassword,
} = require("components/FormRequestPassword/FormRequestPassword");
const { useState } = require("react");
const { changePIN } = require("services/card.service");
const { LoginButton } = require("styles/Styled");

export const FormChangePIN = ({
  otpEnabled,
  closeModal,
  cardId,
  refreshCards,
  changePINRequest,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [newPINErrorMsg, setNewPINErrorMsg] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [password, setPassword] = useState("");
  const [newPin, setNewPIN] = useState("");
  const [loading, setLoading] = useState(false);
  const STEPS = {
    ENTER_NEW_PIN: "ENTER_NEW_PIN",
    REQUEST_CREDENTIALS: "REQUEST_CREDENTIALS",
  };
  const [step, setStep] = useState(STEPS.ENTER_NEW_PIN);

  const onSubmitCredentials = async () => {
    try {
      setLoading(true);
      setErrorMsg("");
      const { success } = await changePINRequest({
        newPin,
        cardId,
        password,
        otpToken,
      });
      if (success) {
        closeModal();
        setErrorMsg("");
        setNewPINErrorMsg("");
        refreshCards();
        swal("¡Éxito!", "Se cambió el PIN correctamente", "success", {
          timer: 3000,
          buttons: false,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response);

        if (error.response.data.code === "invalid-new-pin") {
          setNewPINErrorMsg(error.response.data.message);
          setStep(STEPS.ENTER_NEW_PIN);
          return;
        }

        if (error.response.data.code === "missing-fields") {
          setNewPINErrorMsg("Ingrese un nuevo PIN");
          setStep(STEPS.ENTER_NEW_PIN);
          return;
        }

        if (error.response.data.code === "incorrect-password") {
          setErrorMsg("La contraseña ingresada es inválida");
          setStep(STEPS.REQUEST_CREDENTIALS);
          return;
        }

        if (error.response.data.code === "invalid-otp") {
          setErrorMsg("El código ingresado es inválido");
          setStep(STEPS.REQUEST_CREDENTIALS);
          return;
        }

        closeModal();
        swal(
          "Ocurrió un error",
          "No se pudo cambiar el PIN de su tarjeta, por favor intente nuevamente más tarde",
          "error"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {step === STEPS.ENTER_NEW_PIN && (
        <FormNewPIN
          errorMsg={newPINErrorMsg}
          newPIN={newPin}
          setNewPIN={setNewPIN}
          buttonTitle="Continuar"
          buttonTitleLoading="Validando..."
          loading={loading}
          onSubmit={() => {
            setNewPINErrorMsg("");
            setStep(STEPS.REQUEST_CREDENTIALS);
          }}
        />
      )}
      {step === STEPS.REQUEST_CREDENTIALS && (
        <div>
          {otpEnabled ? (
            <FormRequestOTP
              errorMsg={errorMsg}
              otpToken={otpToken}
              setOtpToken={setOtpToken}
              buttonTitle="Enviar código"
              buttonTitleLoading="Validando..."
              loading={loading}
              onSubmit={onSubmitCredentials}
            />
          ) : (
            <FormRequestPassword
              errorMsg={errorMsg}
              password={password}
              setPassword={setPassword}
              buttonTitle="Validar contraseña"
              buttonTitleLoading="Validando..."
              loading={loading}
              onSubmit={onSubmitCredentials}
            />
          )}
          <LoginButton
            style={{ background: "#b9b6af" }}
            type="button"
            onClick={() => setStep(STEPS.ENTER_NEW_PIN)}
          >
            Atras
          </LoginButton>
        </div>
      )}
    </>
  );
};
