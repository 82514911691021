import { useState, useEffect } from "react";
import swal from "sweetalert";
import {
  fetchMastercardTransactions,
  fetchVisaTransactions,
} from "services/card.service";

const useGetCardMovimientos = ({ cardProvider }) => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const getTransactions = async () => {
    if (!cardProvider) throw new Error("Card provider is required");
    let request = "";
    if (cardProvider === "VISA") {
      request = fetchVisaTransactions;
    }
    if (cardProvider === "MASTERCARD") {
      request = fetchMastercardTransactions;
    }

    try {
      setLoading(true);
      const response = await request();
      setTransactions(response.transactions);
    } catch (error) {
      console.log(error);
      swal({
        text: "Hubo un problema al obtener los movimientos",
        button: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return {
    loading,
    transactions,
    setTransactions,
    getTransactions,
  };
};

export default useGetCardMovimientos;
