import React, { useContext } from "react";
import {
  Grid,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from "@material-ui/core";
import { PurchaseNFTContext } from "contexts/purchaseNFT";
const Success = ({ stepCorrect, handleReset }) => {
  const { transaction } = useContext(PurchaseNFTContext);

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={12} style={{ color: "black" }}>
        NFTs minteados exitosamente
      </Grid>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell variant="head" align="left">
                <Box fontWeight="Bold">Transacción:</Box>
              </TableCell>
              <TableCell align="left">{transaction.transactionHash}</TableCell>
            </TableRow>            
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          disabled={stepCorrect}
          onClick={handleReset}
        >
          Nueva compra
        </Button>
      </Grid>
    </Grid>
  );
};

export default Success;
