import feanorapi from "feanorapi";
import httpCommon from "http-common";
import { getToken } from "services/common";

export const getBusinessInfo = async () => {
  const response = await httpCommon.get("/business/user", {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      // ...headers,
      // ...authHeaders,
    },
  });
  console.log(response);
  return response.data;
};
