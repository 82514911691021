import feanorapi from "feanorapi";

// Ex. props = {ammount: 0, from: "BTC", to: "USD", transactionType: "BUY"}
export const fetchQuote = async (props) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(props),
  };
  let url = `${feanorapi}/public/quote`;
  const response = await fetch(url, data);

  const res = await response.json();
  return res;
};

// Ex. props = { amount: 100, from: "BTC", to: "USDT", skipEmail: true }
export const createSwap = async (props, token) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(props),
  };
  let url = `${feanorapi}/client/wallet/convert`;
  const response = await fetch(url, data);

  const res = await response.json();
  return res;
};
