import feanorapi from "feanorapi";
import { getToken } from "./common";

export const getUserInfo = async (token) => {
  let data = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let url = `${feanorapi}/client/user`;
  const response = await fetch(url, data);

  const res = await response.json();
  return res;
};

export const updateKYCApprovalToPending = async (token) => {
  let data = {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  let url = `${feanorapi}/client/user/updatetokycpending`;
  const response = await fetch(url, data);

  const res = await response.json();
  return res;
};

export const updateUserData = async (token, body) => {
  let data = {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  let url = `${feanorapi}/client/user/regular/update`;
  const response = await fetch(url, data);

  const res = await response.json();
  return res;
};

export const getDiditLink = async (token, body) => {
  let data = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  let url = `${feanorapi}/client/user/getDiditLink`;
  const response = await fetch(url, data);

  const res = await response.json();
  return res;
};
