import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";

import feanorapi from "feanorapi.js";
import swal from "sweetalert";
import { AuthContext } from "Auth";
import queryString from "query-string";

import { Header1White } from "styles/common/index";
import StyledAuth from "../styles";

import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import {
  TitleModal,
  BodyModal,
  FooterModal,
  BtnPrimaryLg,
} from "styles/Styled";
import ExportButton from "components/ExportButton";

const LoginBusiness = ({ history, location }) => {
  const {
    currentToken,
    expiration,
    updateToken,
    updateExpiration,
    updateUserType,
    updateTemporalToken,
  } = useContext(AuthContext);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const isAuthenticated =
    currentToken && expiration && parseInt(expiration) - Date.now() > 1000;

  const [emailValue, setEmailValue] = useState("");
  const [loading, setLoading] = useState(false);

  // Modals
  const [openResend, setOpenResend] = useState(false);

  if (isAuthenticated) history.push("/business");

  const values = queryString.parse(location.search);

  if (values.show_message == "account_verified") {
    swal({
      text: "Muchas gracias. Su cuenta ha sido verificada",
      button: false,
      timer: 4000,
    });
  }

  const resendEmail = async () => {
    const jsonBody = JSON.stringify({ email: emailValue });

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: jsonBody,
    };
    let endpoint = "/auth/resendtoken";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          swal({
            text: "¡Correo enviado correctamente! Revisa tu bandeja de entrada",
            button: true,
            timer: 3000,
          });
          setOpenResend(false);

          return response.json();
        } else {
          swal({
            text:
              "Hubo un error al reenviar enlace de activación. Si el error persiste escríbenos a Soporte.",
            button: false,
            timer: 5000,
          });
          setOpenResend(false);
        }
      })
      .catch((error) => {
        swal({
          text:
            "Hubo un error al reenviar enlace de activación. Si el error persiste escríbenos a Soporte.",
          button: false,
          timer: 5000,
        });
        setOpenResend(false);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setButtonEnabled(false);
    setLoading(true);

    const { email, password } = e.target.elements;

    setEmailValue(email.value);

    let body = { email: email.value, password: password.value };
    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body), // body data type must match "Content-Type" header
    };
    let endpoint = "/auth/login-business";
    let url = `${feanorapi}${endpoint}`;

    console.log(url);
    fetch(url, data)
      .then((response) => {
        setButtonEnabled(true);

        if (response.ok) {
          return response.json();
        } else if (response.status === 403) {
          throw { type: "not-confirmed" };
        } else {
          throw { type: "incorrect-data" };
        }
      })
      .then((responseJson) => {
        setButtonEnabled(true);

        // if (!responseJson.token) {
        //   return swal({
        //     text: "Ha habido un error en nuestro sistema",
        //     button: false,
        //     timer: 1000,
        //   });
        // }

        updateExpiration(responseJson.dateExpiration);
        updateUserType(responseJson.userType);

        if (responseJson.otpEnabled) {
          updateTemporalToken(responseJson.temporalToken);
          history.push("/auth/2fa_business");
          return;
        } else {
          swal({ text: "Ingresando...", button: false, timer: 1000 });
          updateToken(responseJson.token);
        }
      })
      .catch((error) => {
        console.log(error);
        setButtonEnabled(true);

        switch (error.type) {
          case "incorrect-data":
            swal({
              text: "Usuario o contraseña incorrecto",
              button: false,
              timer: 1000,
            });
            break;
          case "not-confirmed":
            //swal({text: "Por favor, revisa tu correo para confirmar tu cuenta", button: false, timer: 10000});

            setOpenResend(true);
            break;
          default:
            swal({
              text: "Ha sabido un error en nuestro sistema",
              button: false,
              timer: 1000,
            });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const scrollTop = () => {
  //   window.scrollTo(0, 0);
  // };

  // useEffect(() => {
  //   scrollTop();
  // });

  const onOpenModalResend = () => {
    setOpenResend(true);
  };

  const onCloseModalResend = () => {
    setOpenResend(false);
  };

  return (
    <>
      <Header1White styles={{ color: "white" }}>
        Inicio de sesión para Empresas
      </Header1White>
      <StyledAuth.AuthForm onSubmit={handleLogin}>
        <StyledAuth.AuthInput
          name="email"
          type="email"
          placeholder="Tu email"
        />
        <StyledAuth.AuthInput
          name="password"
          type="password"
          placeholder="Tu contraseña"
        />
        <StyledAuth.AuthButton type="submit" disabled={!buttonEnabled}>
          {loading ? "Autenticando..." : "Iniciar sesión"}
        </StyledAuth.AuthButton>
      </StyledAuth.AuthForm>
      <StyledAuth.AuthText>
        ¿Olvidaste tu contraseña? &nbsp;
        <StyledAuth.AuthLink to="/auth/recover?type=business">
          Recuperar tu contraseña
        </StyledAuth.AuthLink>
      </StyledAuth.AuthText>

      <Modal
        open={openResend}
        onClose={onCloseModalResend}
        center
        closeOnOverlayClick={false}
      >
        <BodyModal>
          <TitleModal> Necesitas confirmar tu cuenta </TitleModal>

          <FooterModal>
            {" "}
            Se te ha enviado un correo con un enlace de activación, accede a el
            para continuar. De no haber recibido el e-mail puedes volver a
            solicitarlo aquí:{" "}
          </FooterModal>

          <BtnPrimaryLg onClick={() => resendEmail()}>
            Reenviar correo
          </BtnPrimaryLg>
        </BodyModal>
      </Modal>
    </>
  );
};

export default withRouter(LoginBusiness);
