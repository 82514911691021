import React, { useState, useContext } from "react";
import feanorapi from "feanorapi.js";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import {
  TitleModal,
  BodyModal,
  InputModal,
  LabelModal,
  LabelSpan,
  BtnPrimaryFull,
  Margin20,
} from "styles/Styled";
import { AuthContext } from "Auth";
import swal from "sweetalert";
import { PuntosCobroContext } from "contexts/puntosCobro";

const FormAdd = ({ close }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  let { currentToken } = useContext(AuthContext);
  let { puntosCobroTableRef } = useContext(PuntosCobroContext);

  const handleSubmit = () => {
    if (name === "") {
      swal({ text: "Ingrese un nombre para el Punto de Cobro", button: false });
      return;
    }

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({
        name,
        description,
      }),
    };
    let endpoint = `/business/punto`;
    let url = `${feanorapi}${endpoint}`;
    fetch(url, data)
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.json();
        } else {
          throw { type: "non-available" };
        }
      })
      .then((responseJson) => {
        puntosCobroTableRef.current.onQueryChange();
        handleCloseModal();
        return;
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        swal({
          text: "Ha habido un error en nuestro sistema",
          button: false,
          timer: 1000,
        });
      });
  };

  const handleCloseModal = () => {
    setName("");
    setDescription("");
    close();
  };

  return (
    <BodyModal>
      <TitleModal>Agregar Punto de Cobro</TitleModal>

      <LabelModal>
        <LabelSpan>Nombre</LabelSpan>
        <InputModal
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </LabelModal>

      <LabelModal>
        <LabelSpan>Descripcion</LabelSpan>
        <InputModal
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </LabelModal>

      <Margin20></Margin20>

      <BtnPrimaryFull disabled={false} onClick={handleSubmit}>
        Guardar
      </BtnPrimaryFull>
    </BodyModal>
  );
};

export default FormAdd;
