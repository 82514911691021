import React, { useEffect, useState } from "react";
export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({})
  const [token, setCurrentToken] = useState(
    localStorage.getItem("token-feanor")
  );
  const [temporalToken, setTemporalToken] = useState(
    localStorage.getItem("slt-feanor") //short life token (5 minutes)
  );
  const [expiration, setExpiration] = useState(
    localStorage.getItem("token-expires-feanor")
  );
  const [userType, setUserType] = useState(localStorage.getItem("ut-feanor"));

  const [userName, setUserName] = useState(localStorage.getItem("userName"));

  const updateUserName = (uName) => {
    localStorage.setItem("userName", uName);
    setUserName(uName);
  };

  const updateUserType = (utype) => {
    localStorage.setItem("ut-feanor", utype);
    setUserType(utype);
  };

  const updateToken = (token) => {
    localStorage.setItem("token-feanor", token);
    setCurrentToken(token);
  };

  const updateTemporalToken = (token) => {
    localStorage.setItem("slt-feanor", token);
    setTemporalToken(token);
  };

  const updateExpiration = (expiration) => {
    localStorage.setItem("token-expires-feanor", expiration);
    setExpiration(expiration);
  };

  const clearAuthentication = () => {
    updateToken("");
    updateExpiration("");
    updateUserType("");
    updateTemporalToken("");
    updateUserName("");
  };

  return (
    <AuthContext.Provider
      value={{
        userName,
        updateUserName,
        userType,
        updateUserType,
        currentToken: token,
        updateToken: updateToken,
        expiration: expiration,
        updateExpiration: updateExpiration,
        clearAuthentication: clearAuthentication,
        updateTemporalToken: updateTemporalToken,
        temporalToken: temporalToken,
        userInfo,
        setUserInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
