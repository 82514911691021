import React, { useState } from "react";
import {
  BodyModal,
  FooterModal,
  ImageModal,
  InputModal,
  LabelModal,
  LabelSpan,
  TitleModal,
  InputContainer,
  SubtitleModal,
} from "styles/Styled";
import iconReceiveUSDT from "images/tether-logo.svg";
import tronLogo from "images/tron-icon.png";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Icon } from "@iconify/react";
import swal2 from "sweetalert2";
import QRAddress from "components/QRAddress";

const FormReceiveUSDTTron = ({ walletUSDTTronAddress }) => {
  const [showQR, setShowQR] = useState(false);

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(walletUSDTTronAddress);
    swal2.fire({
      title: "Dirección copiada",
      text: "",
      timer: 1000,
      showConfirmButton: false,
      icon: "success",
    });
  };

  return (
    <BodyModal>
      {showQR ? (
        <QRAddress
          walletAddress={walletUSDTTronAddress}
          setShowQR={setShowQR}
        />
      ) : (
        <div>
          <TitleModal style={{ marginBottom: 0 }}>Recibir Tethers Red de Tron</TitleModal>
          <SubtitleModal>Tether (USDT) TRC-20 TRON</SubtitleModal>

          <div style={{ position: "relative" }}>
            <ImageModal src={iconReceiveUSDT} />

            {/* <ImageTetherModal src={iconReceiveUSDT} /> */}
            <img
              src={tronLogo}
              alt=""
              style={{
                width: "58px",
                position: "absolute",
                left: "53%",
                bottom: "3px",
              }}
            />
          </div>
          {/* <ImageModal src={iconReceiveUSDT} /> */}

          <LabelModal>
            <LabelSpan>Dirección de tu billetera</LabelSpan>
            <InputContainer>
              <IconButton>
                <FileCopyIcon onClick={() => onCopyToClipboard()} />
              </IconButton>
              <IconButton onClick={() => setShowQR(true)}>
                <Icon icon="mdi:qrcode" />
              </IconButton>
              <InputModal readOnly type="text" value={walletUSDTTronAddress} />
            </InputContainer>
          </LabelModal>

          <FooterModal>
            <b>Importante:</b> Tener encuenta que la dirección mostrada es una
            direccion correspondiente al token Tether (USDT) TRC-20 Red de TRON.
            Fëanor no se hace responsable de las monedas enviadas a una
            dirección equivocada. Esta es tu dirección que debes brindar para
            recibir tethers. Después de realizar un depósito puede hacer el
            seguimiento del progreso en la página <b>Operaciones</b>.
          </FooterModal>
        </div>
      )}
    </BodyModal>
  );
};

export default FormReceiveUSDTTron;
