import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Logo,
  Nav,
  TopHeader,
  Menu,
  Item,
  SmallMenuItem,
  ItemBordered,
} from "./styles";
import ToggleableNav from "components/navigation/ToggleableNav";
import logo from "media/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import { AuthContext } from "Auth";
import swal from "sweetalert";
import "./index.scss";
import feanorapi from "feanorapi.js";

import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import {
  TitleModal,
  BodyModal,
  FooterModal,
  InputModal,
  LabelModal,
  LabelSpan,
  DescriptionModal,
  BtnPrimaryFull,
  BtnPrimaryLg,
  Margin20,
  DetailsOperation,
  DetailTitle,
  DetailAmount,
  Clear,
} from "styles/Styled";

import { SelectorFull } from "styles/common/index.js";
import { getQuote } from "components/object-calculator/helper.js";

import topLine from "media/lines/gold-line-top.png";
import bottomLine from "media/lines/gold-line-bottom.png";
import { TopLine, BottomLine } from "styles/common/index";
import ConfigurationsMenu from "components/ConfigurationsMenu";
import { website } from "feanorapi";

const Header = ({ location, history }) => {
  const {
    currentToken,
    expiration,
    clearAuthentication,
    userType,
  } = useContext(AuthContext);

  const [haveWalletFiat, setHaveWalletFiat] = useState(false);
  const [saldoPEN, setSaldoPEN] = useState(0);
  const [saldoUSD, setSaldoUSD] = useState(0);
  const [saldoBTC, setSaldoBTC] = useState(0);

  // Form
  const [openModalBuyBitcoin, setOpenModalBuyBitcoin] = useState(false);
  const [openModalSellBitcoin, setOpenModalSellBitcoin] = useState(false);

  const [buyAmount, setBuyAmount] = useState(0.0);

  const [currency, setCurrency] = useState("PEN");
  const [totalReceivedBTC, setTotalReceivedBTC] = useState(0);

  const [buyZone, setBuyZone] = useState(true);
  const [sellZone, setSellZone] = useState(false);

  const [minLimitAmount, setMinLimitAmount] = useState(15.0);
  const [maxLimitAmount, setMaxLimitAmount] = useState(100.0);

  const [sellAmount, setSellAmount] = useState(0.0);
  const [totalReceivedFIAT, setTotalReceivedFIAT] = useState(0.0);
  const [tasaBTC, setTasaBTC] = useState("---");

  const [tasaBTCBuy, setTasaBTCBuy] = useState("---");

  const isAuthenticated =
    currentToken && expiration && parseInt(expiration) - Date.now() > 1000;
  // const getWallet = async () => {
  //   let data = {
  //     method: "GET", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${currentToken}`,
  //     },
  //   };
  //   let endpoint = "/client/wallet";
  //   let url = `${feanorapi}${endpoint}`;
  //   await fetch(url, data)
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         throw { type: "non-user" };
  //       }
  //     })
  //     .then((responseJson) => {
  //       console.log(responseJson);

  //       const walletData = responseJson;

  //       // Si tiene billetera
  //       if (walletData) {
  //         console.log("Wallets detectadas.");

  //         walletData.forEach(function(item) {
  //           if (item.currency == "PEN") {
  //             setSaldoPEN(item.balance.toFixed(2));
  //             setHaveWalletFiat(true);
  //           }

  //           if (item.currency == "USD") {
  //             setSaldoUSD(item.balance.toFixed(2));
  //             setHaveWalletFiat(true);
  //           }

  //           if (item.currency == "BTC") {
  //             setSaldoBTC(item.balance.toFixed(8));
  //           }
  //         });
  //       } else {
  //         setHaveWalletFiat(true);
  //       }
  //     })
  //     .catch((error) => {});
  // };

  const createSwapSell = async () => {
    const jsonBody = JSON.stringify({
      from: "BTC",
      to: currency,
      amount: sellAmount,
    });

    // Validacion

    if (sellAmount == "" || sellAmount == "0") {
      swal({
        text: "Coloca el monto a usar para la compra",
        button: true,
        timer: 5000,
      });
      return;
    }

    if (sellAmount < minLimitAmount || sellAmount > maxLimitAmount) {
      swal({
        text:
          "El monto para la venta debe ser como mínimo " +
          minLimitAmount +
          " " +
          currency,
        button: true,
        timer: 5000,
      });
      return;
    }

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: jsonBody,
    };
    let endpoint = "/client/wallet/convert";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          //throw {'type': 'non-user'}
          return response.json();
        }
      })
      .then((responseJson) => {
        console.log(responseJson);

        if (responseJson.fromWallet && responseJson.toWallet) {
          swal({
            text: "¡Venta exitosa! Ahora tienes el dinero en tu billetera :)",
            button: true,
            timer: 5000,
          });
          onCloseModalSell();
          setTimeout(function() {
            history.push("/app/profile/money");
          }, 3000);
        }

        if (responseJson.type) {
          swal({ text: responseJson.msg, button: true, timer: 5000 });
        }
      })
      .catch((error) => {
        swal({
          text:
            "Ocurrió un error al intentar hacer la venta. Intenta más tarde :(",
          button: true,
          timer: 5000,
        });
        onCloseModalSell();
      });
  };

  const createSwap = async () => {
    const jsonBody = JSON.stringify({
      from: currency,
      to: "BTC",
      amount: buyAmount,
    });

    // Validacion

    if (buyAmount == "" || buyAmount == "0") {
      swal({
        text: "Coloca el monto a usar para la compra",
        button: true,
        timer: 5000,
      });
      return;
    }

    if (buyAmount < minLimitAmount || buyAmount > maxLimitAmount) {
      swal({
        text:
          "El monto para la compra debe ser como mínimo " +
          minLimitAmount +
          " " +
          currency,
        button: true,
        timer: 5000,
      });
      return;
    }

    let data = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: jsonBody,
    };
    let endpoint = "/client/wallet/convert";
    let url = `${feanorapi}${endpoint}`;
    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          //throw {'type': 'non-user'}
          return response.json();
        }
      })
      .then((responseJson) => {
        console.log(responseJson);

        if (responseJson.fromWallet && responseJson.toWallet) {
          swal({
            text: "¡Compra exitosa! Ahora tienes tus BTC en tu billetera :)",
            button: true,
            timer: 5000,
          });
          onCloseModalBuy();
          setTimeout(function() {
            history.push("/app/profile/money");
          }, 3000);
        }

        if (responseJson.type) {
          swal({ text: responseJson.msg, button: true, timer: 5000 });
        }
      })
      .catch((error) => {
        swal({
          text:
            "Ocurrió un error al intentar hacer la compra. Intenta más tarde :(",
          button: true,
          timer: 5000,
        });
        onCloseModalBuy();
      });
  };

  const handleLogOut = () => {
    swal({
      text: "Se ha cerrado sesión. Muchas gracias",
      button: false,
      timer: 3000,
    });
    clearAuthentication();
    // history.push("/");
    // window.location.href = `${website}`;
  };

  useEffect(() => {
    // Call Wallet
    // getWallet();
  }, []);

  const CurrencyOptions = [
    { value: "USD", label: "Dólares Americanos ($)", isDisabled: false },
  ];

  const CurrencyOptionsObject = {
    USD: { value: "USD", label: "Dólares Americanos ($)" },
  };

  // Validacion

  const onKeyPressAmount = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  const setLimits = async (number, currency) => {
    const quote = await getQuote(number, currency, "BTC", "BUY");

    if (quote.rates) {
      setTasaBTCBuy(quote.rates.BTCUSDBuy);
    }

    if (quote.ammounts) {
      setTotalReceivedBTC(quote.ammounts[0]);
    }

    if (quote.converted_limits) {
      if (currency == "PEN") {
        setMinLimitAmount(quote.converted_limits.pen.buy[0]);
        setMaxLimitAmount(quote.converted_limits.pen.buy[1]);
      }

      if (currency == "USD") {
        setMinLimitAmount(quote.converted_limits.usd.buy[0]);
        setMaxLimitAmount(quote.converted_limits.usd.buy[1]);
      }
    }
  };

  const updateLimitsSell = async (number, currency) => {
    const quote = await getQuote(number, "BTC", currency, "SELL");

    if (quote.rates) {
      setTasaBTC(quote.rates.BTCUSDSell);
    }
    if (quote.ammounts) {
      setTotalReceivedFIAT(quote.ammounts[1]);
    }

    if (quote.converted_limits) {
      if (currency == "PEN") {
        setMinLimitAmount(quote.converted_limits.btc.sell[0]);
        setMaxLimitAmount(5);
      }

      if (currency == "USD") {
        setMinLimitAmount(quote.converted_limits.btc.sell[0]);
        setMaxLimitAmount(5);
      }
    }
  };

  const onHandleAmountChange = async (e) => {
    // Validar monto maximo
    let amount = e.target.value;
    setBuyAmount(amount);
    if (amount) setLimits(amount, currency);
  };

  // Sell

  const onKeyPressAmountSell = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };

  const onHandleAmountSellChange = async (e) => {
    // Validar monto maximo
    let amount = e.target.value;
    setSellAmount(amount);
    if (amount) updateLimitsSell(amount, currency);
  };

  const handleSelectCurrency = (option) => {
    let currency = option.value;
    console.log(currency);
    setCurrency(currency);

    setTimeout(() => {
      setLimits(buyAmount, currency);
    }, 1000);
  };

  const handleSelectCurrencySell = (option) => {
    let currency = option.value;
    console.log(currency);
    setCurrency(currency);

    setTimeout(() => {
      updateLimitsSell(sellAmount, currency);
    }, 1000);
  };

  // Modal

  const onOpenModalBuy = () => {
    setOpenModalBuyBitcoin(true);
    //if(havePenAccount) {setCurrencyBa('USD')};
    //if(haveUsdAccount) {setCurrencyBa('PEN')};

    // ReactGA.event({
    //   category: 'Bitcoin',
    //   action: 'Boton Comprar Bitcoin'
    // });
  };

  const onCloseModalBuy = () => {
    setOpenModalBuyBitcoin(false);

    //Clean
    setBuyZone(true);
  };

  const onOpenModalSell = () => {
    setOpenModalSellBitcoin(true);
    // getWallet();
    //if(havePenAccount) {setCurrencyBa('USD')};
    //if(haveUsdAccount) {setCurrencyBa('PEN')};
  };

  const onCloseModalSell = () => {
    setOpenModalSellBitcoin(false);

    //Clean
    setSellZone(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TopLine src={topLine} />
        <TopHeader>
          <NavLink className="NavLogo" to="/">
            <Logo src={logo} alt="Fëanor" />
          </NavLink>
          <Nav className="show-desktop">
            <Menu>
              {/* {currentToken && !location.pathname.includes("app/profile") && (
                <Item>
                  <NavLink
                    to="/app/profile"
                    activeClassName="selected-link"
                    className="nav-link"
                    className="nav-link-cta"
                  >
                    Volver a perfil
                  </NavLink>
                </Item>
              )} */}

              {currentToken && location.pathname.includes("app/profile") && (
                <Item onClick={onOpenModalBuy}>
                  <NavLink
                    to="#"
                    // activeClassName="selected-link"
                    className="nav-link-cta"
                  >
                    <FontAwesomeIcon icon={faBitcoin} /> Comprar bitcoins
                  </NavLink>
                </Item>
              )}

              {currentToken && location.pathname.includes("app/profile") && (
                <Item onClick={onOpenModalSell}>
                  <NavLink
                    to="#"
                    // activeClassName="selected-link"
                    className="nav-link-cta-sec"
                  >
                    <FontAwesomeIcon icon={faBitcoin} /> Vender bitcoins
                  </NavLink>
                </Item>
              )}

              <ItemBordered
                href={`https://dezkapro.com`}
                target="_blank"
                className="nav-link"
              >
                NFT Market
              </ItemBordered>
              {/*  <ItemBordered
                href={`https://therivusbyeliesaab.app`}
                target="_blank"
                className="nav-link"
              >
                NFT Proyectos
              </ItemBordered>*/}

              <ItemBordered
                href={`https://www.compracasasusa.com`}
                target="_blank"
                className="nav-link"
              >
                Proyectos USA
              </ItemBordered>
              {currentToken &&
                userType === "business" &&
                location.pathname.includes("business/dashboard") && (
                  // currentToken && userType==="business" && location.pathname.includes("business/dashboard") && (
                  <ConfigurationsMenu />
                  // )
                )}
              {currentToken && location.pathname.includes("app/profile") && (
                <Item>
                  <NavLink
                    to="/auth/login"
                    // activeClassName="selected-link"
                    className="nav-link"
                    type="button"
                    onClick={handleLogOut}
                  >
                    Cerrar sesión
                  </NavLink>
                </Item>
              )}
              {!isAuthenticated && (
                // currentToken && userType==="business" && location.pathname.includes("business/dashboard") && (
                <>
                  <Item
                    className="menu-dropdown"
                    style={{ margin: "0 10px 0 20px" }}
                  >
                    <span className="nav-link">INICIAR SESIÓN</span>
                    <div className="dropdown-menu">
                      <ul>
                        <SmallMenuItem
                          href={`${
                            location.pathname === "/auth/login_business"
                              ? "#"
                              : "/auth/login_business"
                          }   `}
                        >
                          EMPRESAS
                        </SmallMenuItem>
                        <SmallMenuItem
                          href={`${
                            location.pathname === "/auth/login"
                              ? "#"
                              : "/auth/login"
                          }   `}
                        >
                          PERSONAS
                        </SmallMenuItem>
                      </ul>
                    </div>
                  </Item>
                  <Item style={{ margin: "0 10px" }}>
                    <NavLink to="/auth/register" className="nav-link">
                      REGISTRARSE
                    </NavLink>
                  </Item>
                </>
                // )
              )}
            </Menu>
          </Nav>
          <ToggleableNav logout={handleLogOut} />
        </TopHeader>
        <BottomLine src={bottomLine} />
      </div>

      <Modal
        open={openModalBuyBitcoin}
        onClose={onCloseModalBuy}
        center
        closeOnOverlayClick={false}
      >
        <BodyModal>
          {!buyZone ? (
            <>
              <TitleModal>Comprar bitcoins </TitleModal>

              <DescriptionModal>
                Para comprar bitcoins tienes las siguientes formas, elige una:
              </DescriptionModal>

              <Margin20></Margin20>

              <BtnPrimaryFull onClick={() => setBuyZone(true)}>
                Compra con tu billetera (Instantánea)
              </BtnPrimaryFull>

              <NavLink
                to="/app/operation/new"
                onClick={() => onCloseModalBuy()}
              >
                <BtnPrimaryFull>
                  Compra normal con depósito bancario (15-30 min)
                </BtnPrimaryFull>
              </NavLink>

              <FooterModal>
                <b>Nota:</b> Se aplican las mismas comisiones para ambos métodos
                de compra
              </FooterModal>
            </>
          ) : (
            <>
              <TitleModal>Comprar bitcoins con billetera</TitleModal>

              <DescriptionModal>
                Vas usar el saldo disponible en tus billeteras para comprar
                bitcoins. La compra será instantanea una vez confirmes la
                operación.
              </DescriptionModal>

              <Margin20></Margin20>

              {/* <DetailsOperation>
                <DetailTitle>Disponible en Soles</DetailTitle>
                <DetailAmount>{saldoPEN} PEN</DetailAmount>
              </DetailsOperation> */}

              <Clear></Clear>

              <DetailsOperation>
                <DetailTitle>Disponible en dólares</DetailTitle>
                <DetailAmount>{saldoUSD} USD</DetailAmount>
              </DetailsOperation>

              <Clear></Clear>

              <DetailsOperation>
                <DetailTitle>Tasa de compra</DetailTitle>
                <DetailAmount>{tasaBTCBuy} USD</DetailAmount>
              </DetailsOperation>

              <Clear></Clear>

              <Margin20></Margin20>

              <LabelModal>
                <LabelSpan>Comprar usando:</LabelSpan>
                <SelectorFull
                  isOptionDisabled={(option) => option.isDisabled}
                  required
                  options={CurrencyOptions}
                  value={CurrencyOptionsObject[currency]}
                  onChange={handleSelectCurrency}
                ></SelectorFull>
              </LabelModal>
              <LabelModal>
                <LabelSpan>Monto a comprar:</LabelSpan>
                <InputModal
                  type="number"
                  min="0"
                  value={buyAmount}
                  onKeyPress={(e) => onKeyPressAmount(e)}
                  onChange={(e) => onHandleAmountChange(e)}
                />
              </LabelModal>

              <LabelModal>
                <LabelSpan>Recibirás en bitcoins (BTC):</LabelSpan>
                <InputModal
                  type="number"
                  disabled
                  min="0"
                  value={totalReceivedBTC}
                />
              </LabelModal>

              <BtnPrimaryLg onClick={() => createSwap()}>
                Confirmar compra
              </BtnPrimaryLg>

              <FooterModal>
                <b>Nota:</b> Se aplican las mismas comisiones para ambos métodos
                de compra
              </FooterModal>
            </>
          )}
        </BodyModal>
      </Modal>

      <Modal
        open={openModalSellBitcoin}
        onClose={onCloseModalSell}
        center
        closeOnOverlayClick={false}
      >
        <BodyModal>
          {!sellZone ? (
            <>
              <TitleModal>Vender Bitcoins</TitleModal>

              <DescriptionModal>
                Con Fëanor puedes vender tus bitcoins. Para ello primero debes
                tener fondos de BTC dentro de tu billetera. Si es que ya tienes
                puedes continuar.
              </DescriptionModal>

              <Margin20></Margin20>

              <BtnPrimaryFull onClick={() => setSellZone(true)}>
                Vender mis bitcoins
              </BtnPrimaryFull>

              <FooterModal>
                <b>Nota:</b> Puedes fondearte ingresando al detalle de tu
                billetera BTC y clicando en 'Recibir' se te mostrará tu
                dirección de wallet.
              </FooterModal>
            </>
          ) : (
            <>
              <TitleModal>Vender bitcoins de mi billetera</TitleModal>

              <DescriptionModal>
                Vas usar el saldo disponible en tu billetera Bitcoin para
                venderlo. La venta será instantanea una vez confirmes la
                operación, recibirás dólares o soles.
              </DescriptionModal>

              <Margin20></Margin20>

              <DetailsOperation>
                <DetailTitle>Disponible en BTC</DetailTitle>
                <DetailAmount>{saldoBTC} BTC</DetailAmount>
              </DetailsOperation>
              <Clear></Clear>

              <DetailsOperation>
                <DetailTitle>Tasa de venta</DetailTitle>
                <DetailAmount>{tasaBTC} USD</DetailAmount>
              </DetailsOperation>

              <Clear></Clear>

              <Margin20></Margin20>

              <LabelModal>
                <LabelSpan>Quiero recibir en la siguiente moneda:</LabelSpan>
                <SelectorFull
                  isOptionDisabled={(option) => option.isDisabled}
                  required
                  options={CurrencyOptions}
                  value={CurrencyOptionsObject[currency]}
                  onChange={handleSelectCurrencySell}
                ></SelectorFull>
              </LabelModal>

              <LabelModal>
                <LabelSpan>Monto de BTC a vender:</LabelSpan>
                <InputModal
                  type="number"
                  min="0"
                  value={sellAmount}
                  onKeyPress={(e) => onKeyPressAmountSell(e)}
                  onChange={(e) => onHandleAmountSellChange(e)}
                />
              </LabelModal>

              <LabelModal>
                <LabelSpan>Recibirás en moneda ({currency}):</LabelSpan>
                <InputModal
                  type="number"
                  disabled
                  min="0"
                  value={totalReceivedFIAT}
                />
              </LabelModal>

              <BtnPrimaryLg onClick={() => createSwapSell()}>
                Confirmar venta
              </BtnPrimaryLg>

              <FooterModal>
                <b>Nota:</b> Una vez confirmada la venta se procederá a abonar
                tu dinero a tu billetera
              </FooterModal>
            </>
          )}
        </BodyModal>
      </Modal>
    </>
  );
};

export default withRouter(Header);
