import styled from "styled-components";
import colors from "styles/common/colors";
import { className } from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.feanor_gold};
`;

const ContainerSectionFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterNav = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* border-top: 1px solid rgba(117, 117, 117, 0.5); */
  box-sizing: border-box;
  padding: 45px 120px 96px 120px;
  background: transparent;
  @media screen and (max-width: 640px) {
    font-size: 12px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterCopy = styled.div`
  width: 100%;
  padding: 0 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.feanor_dark_bg};
  padding: 20px;
  font-family: Montserrat;

  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`;

export const FooterAviso = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #727d8a;
  padding: 20px 50px;
  font-size: 12px;
  display: none;
`;

export const FooterColum = styled.div`
  box-sizing: border-box;
`;

export const FooterHeading = styled.h3`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  color: black;
  margin-bottom: 25px;
  font-family: "Bahnschift Light", sans-serif;
  @media screen and (max-width: 640px) {
    font-size: 15px;
    margin-bottom: 16px;
    text-align: center;
  }
`;

export const FooterMenu = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  font-family: "Bahnschift", sans-serif;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    text-align: center;
  }
`;

export const FooterMenuMail = styled.a`
  margin: 0;
  padding: 0;
  display: flex;
  font-family: Montserrat;
  flex-direction: column;
  color: white;
  text-decoration: none;
  @media screen and (max-width: 640px) {
    text-align: center;
  }
`;

export const FooterSocial = styled.ul`
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 200px;
  margin: 0px;
  padding: 30px;
  padding-left: 80px;
  padding-top: 60px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.2);
  list-style: none;
  flex: 0 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 300px 0px 0px 0px;
`;

export const LinkFooter = styled(Link)`
  color: ${colors.feanor_dark_bg};

  margin-top: 10px;
  text-decoration: none;
  font-family: Montserrat;
  &:hover {
    opacity: 0.8;
  }
`;

export const SocialLinkFooter = styled.a`
  color: ${colors.soft_grey};

  margin-top: 10px;
  text-decoration: none;
  font-family: Montserrat;
  &:hover {
    opacity: 0.8;
  }
`;

export const LinkHash = styled.a`
  color: ${colors.feanor_dark_bg};

  margin-top: 10px;
  text-decoration: none;
  font-family: Montserrat;
  &:hover {
    opacity: 0.8;
  }
`;

export const ContainerSocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default {
  ContainerFooter,
  FooterNav,
  FooterCopy,
  FooterAviso,
  FooterColum,
  FooterHeading,
  FooterMenu,
  FooterSocial,
  LinkFooter,
  ContainerSectionFooter,
  ContainerSocialLinks,
  LinkHash,
  SocialLinkFooter,
  FooterMenuMail,
};
