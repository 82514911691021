import Money from "./Money/index.js";
import MoneyBTC from "./Money/btc.js";
import MoneyUSD from "./Money/usd.js";
import MoneyPEN from "./Money/pen.js";
import MoneyUSDT from "./Money/usdt.js";
import Activation from "./Activation/index.js";
import ActivationLvl1 from "./Activation/Level1.js";
import ActivationLvl2 from "./Activation/Level2.js";
import Operations from "./Operations/index.js";
import Settings from "./Settings/index.js";
import FeanorCard from "./FeanorCard/index.js";

import {
  faWallet,
  faCog,
  faUserCheck,
  faHistory,
  faCreditCard,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import RequestCardForm from "./FeanorCard/RequestForm/index.js";
import CardDetail from "./FeanorCard/CardDetail/index.jsx";
import MoneyUSDTTron from "./Money/usdt_tron.js";

export const profileComponentsMobile = [
  {
    value: "card",
    label: "Fëanor Card",
    show: true,
    component: FeanorCard,
    icon: faCreditCard,
  },
  // {
  //   value: "solicitar_card",
  //   label: "-",
  //   isHidden: true,
  //   isDisabled: true,
  //   show: false,
  //   component: RequestCardForm,
  //   kycNeeded: true,
  // },
  // {
  //   value: "card_detail",
  //   label: "Detalle de tarjeta",
  //   isHidden: true,
  //   show: false,
  //   kycNeeded: true,
  //   component: CardDetail,
  // },
  {
    value: "money",
    label: "Billeteras",
    show: true,
    component: Money,
    icon: faWallet,
  },
  // {
  //   value: "usdt",
  //   label: "Billetera USDT",
  //   isHidden: true,
  //   show: false,
  //   component: MoneyUSDT,
  // },
  // {
  //   value: "usdt_tron",
  //   label: "Billetera USDT (TRON)",
  //   isHidden: true,
  //   show: false,
  //   component: MoneyUSDTTron,
  // },
  // {
  //   value: "btc",
  //   label: "Billetera BTC",
  //   isHidden: true,
  //   show: false,
  //   component: MoneyBTC,
  // },
  // {
  //   value: "usd",
  //   label: "Billetera Dólares",
  //   isHidden: true,
  //   show: false,
  //   component: MoneyUSD,
  // },
  {
    value: "operations",
    label: "Operaciones",
    show: true,
    component: Operations,
    icon: faHistory,
  },
  {
    value: "settings",
    label: "Perfil",
    show: true,
    component: Settings,
    icon: faCog,
  },
  {
    value: "activation",
    label: "Validación",
    show: true,
    component: Activation,
    icon: faUserCheck,
  },
];

export const profileComponents = [
  {
    value: "profile/card",
    label: "Fëanor Card",
    show: true,
    component: FeanorCard,
    icon: faCreditCard,
  },
  {
    value: "profile/solicitar_card",
    label: "-",
    isHidden: true,
    isDisabled: true,
    show: false,
    component: RequestCardForm,
    kycNeeded: true,
    platformFeeNeeded: true,
  },
  {
    value: "profile/card_detail",
    label: "Detalle de tarjeta",
    isHidden: true,
    show: false,
    kycNeeded: true,
    component: CardDetail,
  },
  {
    value: "profile/money",
    label: "Billeteras",
    show: true,
    component: Money,
    icon: faWallet,
  },
  // {
  //   value: "operation/new",
  //   label: "Calculadora",
  //   show: true,
  //   icon: faCalculator,
  // },
  {
    value: "profile/operations",
    label: "Operaciones",
    show: true,
    component: Operations,
    icon: faHistory,
  },
  {
    value: "profile/usd",
    label: "Billetera Dólares",
    isHidden: true,
    show: false,
    component: MoneyUSD,
  },
  {
    value: "profile/btc",
    label: "Billetera BTC",
    isHidden: true,
    show: false,
    component: MoneyBTC,
  },
  {
    value: "profile/usdt",
    label: "Billetera Tethers",
    isHidden: true,
    show: false,
    component: MoneyUSDT,
  },
  {
    value: "profile/usdt_tron",
    label: "Billetera Tethers (TRON)",
    isHidden: true,
    show: false,
    component: MoneyUSDTTron,
  },
  {
    value: "profile/settings",
    label: "Perfil",
    show: true,
    component: Settings,
    icon: faCog,
  },
  {
    value: "profile/activation",
    label: "Validación",
    show: true,
    component: Activation,
    icon: faUserCheck,
  },

  {
    value: "profile/level1",
    label: "-",
    isHidden: true,
    isDisabled: true,
    show: false,
    component: ActivationLvl1,
  },
  {
    value: "profile/level2",
    label: "-",
    isHidden: true,
    isDisabled: true,
    show: false,
    component: ActivationLvl2,
  },
];
